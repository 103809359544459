import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { EmailFieldJsonDefinition } from 'src/features/shared/form/domain/json_definitions';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { ProjectIcon } from 'src/ui/elements/icons';


export class ContactsTitleJsonDefinition implements JsonDefinition {
  keys: any;
  constructor() {
    this.keys = [
      {
        key: 'ahh_pj',
        extraOptions: {
          IconComponent: ProjectIcon,
          isAbsoluteTitle: true,
        }
      }
    ];
  }
}




export class ContactJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(index: number) {
    this.keys = [

      { key: `projD.lContact.[${index}].aHCrole`, extraOptions: { gridColSpan: 3 } },
      { key: `projD.lContact.[${index}].aHCroleN`,
      shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          { key: `projD.lContact.[${index}].aHCrole`, value: 99 },
        ]),
         extraOptions: { gridColSpan: 3 } },
      { key: `projD.lContact.[${index}].rN`, extraOptions: { gridColSpan: 3 } },
      { key: `projD.lContact.[${index}].rC`, extraOptions: { gridColSpan: 3 } },
      new EmailFieldJsonDefinition({
        key: `projD.lContact.[${index}].rEmail`,
        extraOptions: { gridColSpan: 3 },
      }),
      { key: `projD.lContact.[${index}].rTel`, extraOptions: { gridColSpan: 3 } },
    ];
  }
}
