import { validateEmailAddress } from "./validators";

export class EmailFieldJsonDefinition {
  key: string;
  error = "invalid Email";
  validate = validateEmailAddress;
  extraOptions: any;

  constructor({ key, extraOptions }: { key: string; extraOptions: any }) {
    this.key = key;
    this.extraOptions = extraOptions;
  }
}
