import React from 'react';
import { appColors } from 'src/app/theme';
import {
  AnimateList,
  AnimatePageEnter,
  PageContainer,
} from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, FlexRow, P } from 'src/ui';
import { TrashIcon } from 'src/ui/elements/icons';
import {
  AreasJsonDefinition,
  AreasTitleJsonDefinition,
  AreasDividerJsonDefinition,
} from '../../data/areas_json_definition';
import { useAreadTypeWithIndex } from '../applications/check_type_of_area';
import { useNumberOfAreas, useSustainableConstructionCalc } from '../applications/use_number_of_areas';

const titleDefinition = new AreasTitleJsonDefinition();

export const Area: React.FC<any> = ({
  index,
  typeOfComponent,
  onRemoveArea,
}) => {
  const Json = new AreasJsonDefinition(index, typeOfComponent);

  const typeOfArea = useAreadTypeWithIndex(0, index);

  if (typeOfComponent === typeOfArea) {
    return (
      <>
        <FlexRow alignItems="center">
          <FormBuilder jsonKeys={Json.keys} />
          <FlexRow
            pb={5}
            pl={1}
            alignSelf="flex-end"
            as="button"
            onClick={() => onRemoveArea(index)}
          >
            <AnimatePageEnter>
              <TrashIcon />
            </AnimatePageEnter>
          </FlexRow>
        </FlexRow>
      </>
    );
  } else {
    return <></>;
  }
};

export const AreaDivider: React.FC<any> = ({ typeArea }) => {
  const Json = new AreasDividerJsonDefinition(typeArea);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};

export const AreasPage: React.FC = () => {
  const { useEnvironmentLoads, useSustainableConstruction } = useSustainableConstructionCalc();

  if (useEnvironmentLoads || useSustainableConstruction) {
    return (
      <PageContainer>
        <FormBuilder jsonKeys={titleDefinition.keys} />

        <AreasInputPage></AreasInputPage>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer>
        <FormBuilder jsonKeys={titleDefinition.keys} />

        <P>{i18n.t(`pages.noneed`)}</P>
      </PageContainer>
    );
  }
}


export const AreasInputPage: React.FC = () => {
  const { areas, addArea, removeArea } = useNumberOfAreas();
  const mappingAreas = [...(Array(areas).keys() as any)];

  return (
    <PageContainer>
      {/* <FormBuilder jsonKeys={titleDefinition.keys} /> */}
      <AreaDivider typeArea={1} />
      {mappingAreas.map((a, i) => (
        <AnimateList key={i}>
          <Area
            key={areas}
            index={a}
            typeOfComponent={1}
            onRemoveArea={() => removeArea(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={() => addArea(1)} mb={4}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.outsidewall`)}
          </P>
        </Box>
      </AnimatePageEnter>

      <AreaDivider typeArea={2} />
      {mappingAreas.map((a, i) => (
        <AnimateList key={i}>
          <Area
            key={areas}
            index={a}
            typeOfComponent={2}
            onRemoveArea={() => removeArea(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={() => addArea(2)} mb={4}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.insidewall`)}
          </P>
        </Box>
      </AnimatePageEnter>

      <AreaDivider typeArea={3} />
      {mappingAreas.map((a, i) => (
        <AnimateList key={i}>
          <Area
            key={areas}
            index={a}
            typeOfComponent={3}
            onRemoveArea={() => removeArea(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={() => addArea(3)} mb={4}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.window`)}
          </P>
        </Box>
      </AnimatePageEnter>

      <AreaDivider typeArea={4} />
      {mappingAreas.map((a, i) => (
        <AnimateList key={i}>
          <Area
            key={areas}
            index={a}
            typeOfComponent={4}
            onRemoveArea={() => removeArea(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={() => addArea(4)} mb={4}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.slab`)}
          </P>
        </Box>
      </AnimatePageEnter>

      <AreaDivider typeArea={5} />
      {mappingAreas.map((a, i) => (
        <AnimateList key={i}>
          <Area
            key={areas}
            index={a}
            typeOfComponent={5}
            onRemoveArea={() => removeArea(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={() => addArea(5)} mb={4}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.roof`)}
          </P>
        </Box>
      </AnimatePageEnter>

      <AreaDivider typeArea={6} />
      {mappingAreas.map((a, i) => (
        <AnimateList key={i}>
          <Area
            key={areas}
            index={a}
            typeOfComponent={6}
            onRemoveArea={() => removeArea(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={() => addArea(6)} mb={4}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.foundation`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </PageContainer>
  );
};
