import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { i18n } from 'src/features/shared/translations/translate';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from 'src/ui/tabs';

export const QuantitativeQualitatiteTabs = ({
  Quantitative,
  Qualitative,
}: any) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const onChange = (index: number) => {
    if (index === 0) {
      replace(pathname.replace('quantitative', 'qualitative'));
    } else {
      replace(pathname.replace('qualitative', 'quantitative'));
    }
  };

  const defaultIndex = pathname.includes('qualitative') ? 0 : 1;

  return (
    <Tabs
      isLazy
      pt={12}
      defaultIndex={defaultIndex}
      onChange={(index: number) => onChange(index)}
    >
      <TabList>
        <Tab>{i18n.t(`tabs.qualitative`)}</Tab>
        <Tab>{i18n.t(`tabs.quantitative`)}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>{Qualitative}</TabPanel>
        <TabPanel px={0}>{Quantitative}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const QuantitativeQualitativePage = ({ name }: { name: string }) => {
  const Quality = useMemo(() => require(`./qualitative/${name}`).default, [
    name,
  ]);
  const Quantitive = useMemo(() => require(`./quantitative/${name}`).default, [
    name,
  ]);

  if (!Quality || !Quantitive) return null;

  return (
    <>
      <QuantitativeQualitatiteTabs
        Quantitative={<Quantitive />}
        Qualitative={<Quality />}
      />
    </>
  );
};

export const Acoustic = () => {
  return <QuantitativeQualitativePage name="acoustic" />;
};

export const Comfort = () => {
  return <QuantitativeQualitativePage name="comfort" />;
};

export const Daylight = () => {
  return <QuantitativeQualitativePage name="daylight" />;
};

export const Energy = () => {
  return <QuantitativeQualitativePage name="energy" />;
};

// export const EnergyDemand = () => {
//   return <QuantitativeQualitativePage name="energydemand" />;
// };

// export const EnergySupply = () => {
//   return <QuantitativeQualitativePage name="energysupply" />;
// };

export const Environment = () => {
  return <QuantitativeQualitativePage name="environment" />;
};

export const PrimaryEnergy = () => {
  return <QuantitativeQualitativePage name="primaryenergy" />;
};

export const Sustainable = () => {
  return <QuantitativeQualitativePage name="sustainable" />;
};

export const Thermal = () => {
  return <QuantitativeQualitativePage name="thermal" />;
};

export const Freshwater = () => {
  return <QuantitativeQualitativePage name="freshwater" />;
};


export const Indoorair = () => {
  return <QuantitativeQualitativePage name="indoorair" />;
};
