import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { getRandomInt } from 'src/features/shared/utils';
import { ROOM_DEFINITION_PATH } from '../../data/rooms_json_definition';

export const useNumberOfRooms = (facade = globalJsonFacade) => {
  const [rooms, setRooms] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForRooms = _.get(globalJson, ROOM_DEFINITION_PATH);

  const numberOfRooms = _.size(draftJsonForRooms);

  const removeRoom = (index: number) => {
    const newDraftJsonForRooms = [...(draftJsonForRooms as any)];

    const filtered = newDraftJsonForRooms.filter(function (value, i, arr) {
      return i !== index;
    });

    const newDraftJson = _.set(
      globalJson as any,
      ROOM_DEFINITION_PATH,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftJsonForRooms = _.get(newDraftJson, ROOM_DEFINITION_PATH);
      const numberOfRooms = _.size(draftJsonForRooms);
      setRooms(numberOfRooms);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });

    });

  };

  const addRoom = () => {
    setRooms(rooms + 1);

    const randomId = _.uniqueId("10") + getRandomInt(50000);   // get a random id
    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].id`,
      randomId
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahroomtype`,
      5
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahtecool`,
      2
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahdfs`,
      5
    );
    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahdfsa`,
      5
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahteopmaxs`,
      5
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahteopmins`,
      5
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahco2lvl`,
      5
    );
    // _.set(
    //   globalJson,
    //   `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahr_co2lvl`,
    //   5
    // );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahaqindb`,
      5
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahaqoutdb`,
      5
    );

    _.set(
      globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahaqprdb`,
      5
    );

    _.set(globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahrod`,
      true
    );
    _.set(globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahrot`,
      true
    );
    _.set(globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahroi`,
      true
    );
    _.set(globalJson,
      `lVariant.[0].building.lZone.0.lRoom.[${rooms}].Active.ahroa`,
      true
    );

    facade.setWithoutMerge(globalJson).then((v) => {

      facade.save(globalJson).then((v) => {

        setGlobalJson(v);
      });
    });
  };

  useEffect(() => {
    setRooms(numberOfRooms);
  }, [numberOfRooms]);

  return {
    rooms,
    addRoom,
    removeRoom,
  };
};
