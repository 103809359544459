

// export const helpArticlesProjectInfo = ["77000481270", "77000481269"];

export const helpArticles = [
  {
    n: "project-information",
    ids: ["77000481270", "77000481269"]
  },
  {
    n: "rooms",
    ids: ["77000481270", ]
  },
  {
    n: "areas",
    ids: ["77000481270"]
  },
  {
    n: "constructions",
    ids: ["77000481270"]
  },
  {
    n: "systems",
    ids: ["77000481270"]
  },
  {
    n: "management",
    ids: ["77000490690"]
  },
  {
    n: "comfort",
    ids: ["77000490697"]
  },
  {
    n: "daylight",
    ids: ["77000490698", "77000490699", "77000490701"]
  },
  {
    n: "thermal",
    ids: ["77000490704", "77000490705", "77000496632"]
  },
  {
    n: "indoorair",
    ids: ["77000490707", "77000490709", "77000496632",]
  },
  {
    n: "acoustic",
    ids: ["77000481270"]
  },
  {
    n: "energy",
    ids: ["77000481270"]
  },
  {
    n: "energydemand",
    ids: ["77000481270"]
  },
  {
    n: "energysupply",
    ids: ["77000481270"]
  },
  {
    n: "primaryenergy",
    ids: ["77000481270"]
  },
  {
    n: "environment",
    ids: ["77000481270"]
  },
  {
    n: "sustainable",
    ids: ["77000481270"]
  },
  {
    n: "freshwater",
    ids: ["77000481270"]
  },
  {
    n: "Results",
    ids: ["77000481270"]
  },
  {
    n: "Settings",
    ids: ["77000481270"]
  },

];
