import { Collapse, useDisclosure } from '@chakra-ui/core';
import _, { findLastKey } from 'lodash';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { appColors, appFontSizes } from 'src/app/theme';
import { useSustainableConstructionCalc } from 'src/features/project_information/presentation/applications/use_number_of_areas';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, Divider, FlexCol, FlexRow, P } from 'src/ui';
import { ArrowDownIcon } from 'src/ui/elements/icons';
import { emotionStyled } from 'src/ui/shared';
import { useVariantsLength } from '../application/new_variantcase';
import {
  projectInfo,
  energyQualitative,
  environmentQualitative,
  comfortQualitative,
  results,
  settings,
  management,
  comfortQuantitative,
  energyQuantitative,
  environmentQuantitative,
} from '../application/sidebar_data';
import { useVariantName } from '../application/use_variantcase';
import { OpenListAnimation } from './animated';

import { ReactComponent as Logo } from './logo.svg';

const ItemContainer = emotionStyled(FlexRow)`
  outline-color: ${appColors.green[50]};
  border-radius: 4px;
  width: calc(100% - 20px);
  transition: all 0.1s;
  background-color: transparent;
  color: ${(props: { isActive: boolean }) =>
    !props.isActive ? appColors.black : appColors.green[50]};
  transform:    ${(props) => (props.isActive ? 'scale(1.02)' : 'initial')};
  * {
    color: ${(props: { isActive: boolean }) =>
    !props.isActive ? appColors.black : appColors.green[50]};
    stroke: ${(props: { isActive: boolean }) =>
    !props.isActive ? appColors.black : appColors.green[50]};
  }
`;

const disableProps = (enabled: boolean) => {
  if (enabled) {
    return {};
  } else {
    return {
      opacity: 1,
      cursor: 'not-allowed',
      clickEvents: 'none',
    };
  }
};


const SidebarBlock = ({ data, variantcase }: any) => {
  const { useSustainable } = useSustainableConstructionCalc();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const blockRoute = variantcase
    ? '/' + variantcase + '/' + data.title.route
    : data.title.route;
  const isTitleActive = pathname.includes(blockRoute);
  const sidebarItemsContent = data.items.map((s: any) => {
    const itemRoute = variantcase ? '/' + variantcase + '/' + s.route : s.route;
    const isActive = pathname.includes(itemRoute);

    const notHidden = s.hideable? useSustainable? true : false : true;

    if (notHidden) {
      return (
        <ItemContainer
          pt={0.5}
          pl={2}
          data-test-id={'sidebar-item:' + s.label}
          isActive={isActive}
          {...disableProps(!!s.route)}
          key={s.label}
          as="button"
          align="center"
          onClick={() => push(itemRoute)}
          _hover={{ bg: appColors.secondary.light }}

        >
          <P fontSize={appFontSizes.small2} color={appColors.gray.dark}>
            {s.label}
          </P>
        </ItemContainer>
      )
    } else {
      return (
       <>
       </>
      );
    }




  });

  const title = (
    <ItemContainer
      px={2}
      data-test-id={'sidebar-title:' + data.title.label}
      {...disableProps(data.title.route)}
      isActive={isTitleActive}
      as="button"
      align="center"
      onClick={() => push(blockRoute)}
    >
      <data.title.Icon
        width={14}
        height={14}
        style={{ stroke: appColors.red[500], marginBottom: 2 }}
      />
      <P ml={1} fontSize={appFontSizes.small2}>
        {data.title.label}
      </P>
    </ItemContainer>
  );

  return (
    <FlexCol mb={2}>
      {title}
      <FlexCol ml={3} mt={0}>
        {sidebarItemsContent}
      </FlexCol>
    </FlexCol>
  );
};

export const GroupBlock: React.FC<{
  variantcase: string;
  setOpen: Function;
  isOpen: string;
}> = ({ isOpen, setOpen, children, variantcase }) => {
  const open = isOpen === variantcase;

  return (
    <Box>
      <Divider />
      <FlexRow
        mt={2}
        as="a"
        onClick={() => (open ? setOpen('') : setOpen(variantcase))}
        cursor="pointer"
        px={2}
        justify="space-between"
        flex="1"
      >
        <P
          fontWeight={open ? 700 : 100}
          fontSize={open ? appFontSizes.body : appFontSizes.small}
        >

          {useVariantName(variantcase, 25)}

        </P>
        <ArrowDownIcon />
      </FlexRow>

      <OpenListAnimation isOpen={open}>
        <Box mt={2}>{children}</Box>
      </OpenListAnimation>
    </Box>
  );
};


const AddNewBlock: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  const {
    ArrayOfVariantsIndex,
    setVariantsLength,
    variantsLength,
    addNewVariant,
    copyBaselineVariant,
  } = useVariantsLength();

  function handleNewVariant() {
    addNewVariant();
    onToggle();
  }

  function handleBaselineVariant() {
    copyBaselineVariant();
    onToggle();
  }

  return (
    <>
      <P
        color={appColors.primary.main}
        textDecoration="underline"
        as="button"
        pl={0}
        ml={-9}
        onClick={onToggle}
      >
        {isOpen ? i18n.t(`add.focuscase`) : i18n.t(`add.case`)}
      </P>

      <Collapse in={isOpen} animateOpacity>

        <P
          color={appColors.primary.main}
          textDecoration="underline"
          as="button"
          pl={4}
          pr={15}
          mt={2}
          ml={0}
          onClick={() => handleNewVariant()}
        >
          {i18n.t(`add.newcase`)}
        </P>

        {/* <ButtonBase
              {...commonButtonProps(appColors.primary.main)}
              onClick={copyBaselineVariant}
            >  */}
        <P
          color={appColors.primary.main}
          textDecoration="underline"
          as="button"
          pl={4}
          pr={11}
          my={1}
          ml={0}
          onClick={() => handleBaselineVariant()}
        >
          {i18n.t(`add.copybaseline`)}
        </P>

      </Collapse>
    </>
  );
};



export const Sidebar = () => {
  const [isOpen, setOpen] = useState<string>('baseline');
  const {
    ArrayOfVariantsIndex,
    setVariantsLength,
    variantsLength,
    addNewVariant,
    copyBaselineVariant,
  } = useVariantsLength();

  const { pathname } = useLocation();

  const qualitative = pathname.includes('qualitative') ? true : false;

  return (
    <FlexCol flex={1} maxHeight="100vh">
      <Box pl={3} pt={3}>
        <Logo />
      </Box>
      <Box mt={5} />

      <SidebarBlock data={projectInfo} />


      <FlexCol overflow="auto" flex={1} >
        <GroupBlock variantcase={'baseline'} setOpen={setOpen} isOpen={isOpen}>
          <SidebarBlock variantcase={'baseline'} data={management} />
          <SidebarBlock variantcase={'baseline'} data={qualitative ? comfortQualitative : comfortQuantitative} />
          <SidebarBlock variantcase={'baseline'} data={qualitative ? energyQualitative : energyQuantitative} />
          <SidebarBlock variantcase={'baseline'} data={qualitative ? environmentQualitative : environmentQuantitative} />
        </GroupBlock>

        {ArrayOfVariantsIndex.map((c) => {
          const variantCase = `variantcase${c + 1}`; // to correct variant case
          return (
            <GroupBlock
              variantcase={variantCase}
              setOpen={setOpen}
              isOpen={isOpen}
            >
              <SidebarBlock variantcase={variantCase} data={management} />
              <SidebarBlock variantcase={variantCase} data={qualitative ? comfortQualitative : comfortQuantitative} />
              <SidebarBlock variantcase={variantCase} data={qualitative ? energyQualitative : energyQuantitative} />
              <SidebarBlock variantcase={variantCase} data={qualitative ? environmentQualitative : environmentQuantitative} />
            </GroupBlock>
          );
        })}

        {/* <VariantQuestion /> */}



      </FlexCol>

      <FlexCol justify="flex-end" flex={1} >

        <Divider my={2} />



        <AddNewBlock />




        <Divider my={2} />
        <SidebarBlock data={results} />
        {/* <Divider my={2} /> */}
        <SidebarBlock data={settings} />
      </FlexCol>
    </FlexCol>
  );
};


export const VariantQuestion = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    ArrayOfVariantsIndex,
    setVariantsLength,
    variantsLength,
    addNewVariant,
  } = useVariantsLength();

  return (
    <>


    </>
  )
}