import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { getRandomInt } from 'src/features/shared/utils';

export const useNumberOfSystems = (varaintCaseIndex: number, facade = globalJsonFacade) => {
  const [systems, setSystems] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const systemDefinitionPath = `lVariant.[${varaintCaseIndex}].HVAC.lSystem`
  const draftJsonForSystems = _.get(globalJson, systemDefinitionPath);

  const numberOfSystems = _.size(draftJsonForSystems);

  const removeSystem = (index: number) => {
    const newDraftJsonForSystems = [...(draftJsonForSystems as any)];

    const filtered = newDraftJsonForSystems.filter(function (value, i, arr) {
      return i !== index;
    });

    const newDraftJson = _.set(
      globalJson as any,
      systemDefinitionPath,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftJsonForSystems = _.get(newDraftJson, systemDefinitionPath);
      const numberOfSystems = _.size(draftJsonForSystems);
      setSystems(numberOfSystems);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addSystem = (typeOfSystem: number) => {
    setSystems(systems + 1);

    const randomIdSystem = _.uniqueId("10") + getRandomInt(50000);   // get a random id
    _.set(
      globalJson,
      `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].id`,
      randomIdSystem
    );

    _.set(
      globalJson,
      `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahtypeDev`,
      typeOfSystem
    );

    _.set(
      globalJson,
      `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahsysused`, 
      true
    );

    const randomId = _.uniqueId("10") + getRandomInt(50000);   // get a random id
    _.set(
      globalJson,
      `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].id`,
      randomId
    );

    if (typeOfSystem == 1) {

      const typeOfheatDevice = 5;
      _.set(
        globalJson,
        `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahheatDev`,
        typeOfheatDevice
      );
    }

    if (typeOfSystem == 2) {
      _.set(
        globalJson,
        `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahelDev`,
        1
      );
    }

    if (typeOfSystem == 3) {
      _.set(
        globalJson,
        `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahdeviceThrenw`,
        2
      );
    }

    if (typeOfSystem == 4) {
      _.set(
        globalJson,
        `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahdeviceTerenw`,
        1
      );
    }

    _.set(
      globalJson,
      `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].eco.lcasysG`,
      4
    );

    _.set(globalJson, `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahpeheat`,"");
    _.set(globalJson, `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahpedhw`,"");
    _.set(globalJson, `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahpecool`,"");
    _.set(globalJson, `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahpeaux`,"");

    _.set(globalJson, `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.aheldempef`,"");
    _.set(globalJson, `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahheatprodpef`,"");
    _.set(globalJson, `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${systems}].lDevice.[0].Active.ahelprodpef`,"");

    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });

  };

  useEffect(() => {
    setSystems(numberOfSystems);
  }, [numberOfSystems, varaintCaseIndex]);

  return {
    systems,
    addSystem,
    removeSystem,
  };
};
