import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { SustainableQualityJsonDefinition } from '../../../data/qualitative/sustainable_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const SustainableQualityPage: React.FC = () => {
  const sustainablejson = useWithVariantCaseIndex(
    SustainableQualityJsonDefinition
  );

  return <FormBuilder jsonKeys={sustainablejson.keys} />;
};

export default SustainableQualityPage;
