import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { EnvironmentQuantityJsonDefinition } from '../../../data/quantitative/environment_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const EnvironmentQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(EnvironmentQuantityJsonDefinition);

  return <FormBuilder jsonKeys={keys} />;
};

export default EnvironmentQuantityPage;
