// import React from 'react';

export const calcRuningMeanTemperature = (outdoorTemperature: any) => {
  // calc runnin mean temperature EN 15251 from hourly outdoor temperature data
  const alpha = 0.8;
  let lastDayMeanExternalTemperature = 4;
  let lastDayRuningMeanTemperature = 4;

  let currentRuningMeanTemperature = 0;

  let dailyMeanExternalTemperature = new Array();

  for (let day = 0; day < 365; day++) {

    let meanExternalDayTemperature = 0;
    let sumDayTemperature = 0
    for (let i = 0; i < 24; i++) {
      sumDayTemperature += outdoorTemperature[(day * 24) + i];
    }
    meanExternalDayTemperature = sumDayTemperature / 24;

    currentRuningMeanTemperature =
      (1 - alpha) * lastDayMeanExternalTemperature +
      alpha * lastDayRuningMeanTemperature;

    for (let i = 0; i < 24; i++) {
      dailyMeanExternalTemperature[(day * 24) + i] = currentRuningMeanTemperature;
    }

    lastDayMeanExternalTemperature = meanExternalDayTemperature;
    lastDayRuningMeanTemperature = currentRuningMeanTemperature;
  }

  return dailyMeanExternalTemperature;
}
