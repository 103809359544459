export class FreshwaterScore {
    val: number;
    constructor({ flowrate }: { flowrate?: number }) {
      if (!flowrate) {
        this.val = 5;
        return;
      }
  
      this.val =
            flowrate <= 0
          ? 5
          :  flowrate <= 1
          ? 1
          : flowrate  <= 2
          ? 2
          : flowrate  <= 3
          ? 3
          : flowrate  <= 4
          ? 4
          : 5;
    }
  }
  