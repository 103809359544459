import { Unit } from "src/features/shared/form/domain/unit";

export const unitConvertToIP = (
  value: number,
  unit: Unit | undefined
  ) => {



  return unit?.currentSystem == 2 ? value / unit.conversionFactor : value;
};



export const unitConvertToSI = (
  value: number,
  unit: Unit | undefined
  ) => {



  return unit?.currentSystem == 2 ? value * unit.conversionFactor : value;
};

