import { JsonDefinition } from 'src/features/shared/form/data/json_definition';

export const AREAS_DEFINITION_PATH = 'lVariant.0.building.lComponent';

export class AreasTitleJsonDefinition implements JsonDefinition {
  keys: any;

  constructor() {
    this.keys = [
      { key: 'ahh_a' },
      // { key: `aht_sccalc` },
      // {
      //   key: `lVariant.${0}.Active.ahudefenvload`,
      //   extraOptions: { gridColSpan: 3 },
      // },
      // {
      //   key: `lVariant.${0}.Active.ahudefsusconst`,
      //   extraOptions: { gridColSpan: 3 },
      // },
    ];
  }
}

export class AreasDividerJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(typeCah: number) {
    switch (typeCah) {
      case 1:
        this.keys = [{ key: 'aht_arow' }];
        break;
      case 2:
        this.keys = [{ key: 'aht_ariw' }];
        break;
      case 3:
        this.keys = [{ key: 'aht_arwd' }];
        break;
      case 4:
        this.keys = [{ key: 'aht_arsb' }];
        break;
      case 5:
        this.keys = [{ key: 'aht_arrf' }];
        break;
      case 6:
        this.keys = [{ key: 'aht_arfo' }];
        break;
      case 20:
        this.keys = [{ key: 'aht_syd' }];
        break;
      case 21:
        this.keys = [{ key: 'aht_op' }];
        break;
    }
  }
}

export class AreasJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(index: number, typeOfArea:number) {

    if (typeOfArea == 3) {
      this.keys = [
        {
          key: `${AREAS_DEFINITION_PATH}.[${index}].n`,
          extraOptions: { gridColSpan: 3 },
        },
        {
          key: `${AREAS_DEFINITION_PATH}.[${index}].countC`,
          extraOptions: { gridColSpan: 3 },
        },
      ];
    } else {
      this.keys = [
        {
          key: `${AREAS_DEFINITION_PATH}.[${index}].n`,
          extraOptions: { gridColSpan: 3 },
        },
        {
          key: `${AREAS_DEFINITION_PATH}.[${index}].areaC`,
          extraOptions: { gridColSpan: 3 },
        },
      ];
    }
  }
}
