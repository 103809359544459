import { unitsJson } from '../../json/data/source/units_json';
import { FieldData } from '../data/json_definition';
import {
  NumberField,
  DropdownField,
  TitleField,
  TitleAndDividerField,
  TextField,
  ButtonField,
  YesNoField,
  JustTextField,
  CurrentStatusField,
} from '../domain/field_definitions';
import { Unit } from '../domain/unit';

import { getDefinitionForValue } from '../utils/get_definition_for_value';

const FieldDefinedWIthInvalidCode = (definition: { n: string; t: string }) =>
  `[${definition.n}]: has the wrong code --------> ${definition?.t}. Check active.def or define a create for this code`;

const definitionToCreate = {
  101: createDividerAndTitleField,
  110: createTitleField,
  102: createJustTextField,
  2: createDropdownField,
  1: createNumberField,
  0: createTextField,
  112: createButtonField,
  3: createYesNoField,
  120: createCurrentStatusField,
} as any;

export abstract class JsonFieldFactory {
  static create(fieldData: FieldData, unitsystem: number) {
    const definition = getDefinitionForValue(fieldData);

    if (!definition) return null;

    const create = definitionToCreate[definition?.t || 0];

    if (!create) throw FieldDefinedWIthInvalidCode(definition);

    let unit = null;
    if (definition.u) {
      const unitFound = unitsJson.u.find((u) => u[0] === definition.u);
      if (unitFound) {
        unit = new Unit({
          id: unitFound[0],
          siUnit: unitFound[1],
          ipUnit: unitFound[2],
          conversionFactor: unitFound[3],
          currentSystem: unitsystem,
        });
      }
    }

    return create(definition, fieldData, unit);
  }
}

function createNumberField(definition: any, fieldData: FieldData, unit?: Unit) {
  return new NumberField({
    label: definition.n,
    maxLength: definition.md,
    min: definition.min,
    max: definition.max,
    fieldData,
    unit,
  });
}

function createDropdownField(definition: any, fieldData: FieldData) {
  return new DropdownField({
    label: definition.n,
    options: definition.sel.map((v: any) => ({
      value: v[0],
      label: v[1],
    })),
    fieldData,
  });
}

function createTitleField(definition: any, fieldData: FieldData) {
  return new TitleField({
    label: definition.n,
    fieldData,
  });
}

function createDividerAndTitleField(definition: any, fieldData: FieldData) {
  return new TitleAndDividerField({
    label: definition.n,
    fieldData,
  });
}

function createJustTextField(definition: any, fieldData: FieldData) {
  return new JustTextField({
    label: definition.n,
    fieldData,
  });
}

function createCurrentStatusField(definition: any, fieldData: FieldData) {
  return new CurrentStatusField({
    label: definition.n,
    fieldData,
  });
}

function createTextField(definition: any, fieldData: FieldData, unit: Unit) {
  return new TextField({
    label: definition.n,
    maxLength: definition.md,
    fieldData,
    unit,
  });
}

function createButtonField(definition: any, fieldData: FieldData) {
  return new ButtonField({
    label: definition.n,
    fieldData,
  });
}

// TBD
function createYesNoField(definition: any, fieldData: FieldData) {
  return new YesNoField({
    label: definition.n,
    fieldData,
  });
}
