
import { Box, Collapse, toast, useDisclosure, useToast } from '@chakra-ui/core';
import _ from 'lodash';
import { array } from 'purify-ts';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { appColors, appFontSizes } from 'src/app/theme';
import { calcHourlyIndoorAirComfort, calcHourlyThermalComfort } from 'src/features/calculation/domain/sub_calculations/hourly_calculations/comfort_conditions';
import { calcRuningMeanTemperature } from 'src/features/calculation/domain/sub_calculations/hourly_calculations/running_mean_temperature';
import { globalButtonProps } from 'src/ui';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { i18n } from 'src/features/shared/translations/translate';
import { ButtonBase, FlexRow, P } from 'src/ui';
import { FileUploader } from 'src/ui/elements/file_upload';
import { useFileData } from '../use_file_data';
import { getVariantCaseNumber } from '../use_with_variantcase_index';
import { updateFileToRoomIndoorAir, updateFileToRoomThermalEnv } from 'src/features/calculation/domain/sub_calculations/hourly_calculations/set_filedata_to_room';



const parseFromCSVtoJson = (csv: any) => {
  const lines = csv.split("\n");

  let aHfileR = new Array();
  let aHextT = new Array();

  const ah_fileheaders = lines[0].split(";");
  const ah_filefirstline = lines[1].split(";");
  const ah_filesecondline = lines[2].split(";");
  const ah_filethirdline = lines[3].split(";");
  const ah_filefourthline = lines[4].split(";");
  const ah_filefifthline = lines[5].split(";");

  const ah_crooms = (ah_fileheaders.length - 4) / 2;
  for (let i = 0; i < ah_crooms; i++) {
    const aHoptemp = new Array();
    const aHco2 = new Array();
    const room = { aHoptemp, aHco2 };
    aHfileR.push(room);
  }

  for (let i = 1; i < lines.length; i++) {
    const currentline = lines[i].split(";");

    aHextT.push(+currentline[3])

    for (let j = 0; j < ah_crooms; j++) {
      aHfileR[j].aHoptemp.push(+currentline[4 + (j * 2)]);
      aHfileR[j].aHco2.push(+currentline[4 + (j * 2) + 1]);
    }
  }

  // return JSON.stringify(result); //JSON
  return { aHfileR, aHextT, ah_crooms, ah_fileheaders, ah_filefirstline, ah_filesecondline, ah_filethirdline, ah_filefourthline, ah_filefifthline };
}



export const DynamicSimulationFileUplad: React.FC<any> = ({ variantcase }) => {
  const { globalJson, onMergeJsonWithGlobalJson } = useContext(JsonContext);

  const { isDynamicThermal, isDynamicIndoorAir } = useFileData(variantcase);

  const toast = useToast();

  return (
    <>
      {/* <FlexRow alignItems="center"> */}
      <FileUploader
        isFileSet={_.get(globalJson, `lVariant.${variantcase}.Active.aHfiledata.aHfileOk`)}
        onUpload={(ReadFile) => {

          const j = {};

          var reader = new FileReader();
          reader.onloadend = function () {

            // _.set(j, `lVariant.${variantcase}.Active.ahfileheader`, "test file data");

            const parsedFileData = parseFromCSVtoJson(reader.result);

            const draftForRooms = _.get(globalJson, `lVariant[${variantcase}].building.lZone.[0].lRoom`);

            const runningMeanTemperature = calcRuningMeanTemperature(parsedFileData.aHextT);

            const checkFileAndRoomLenght = draftForRooms.length == parsedFileData.ah_crooms;

            _.set(j, `lVariant.${variantcase}.Active.aHfiledata`, parsedFileData.ah_fileheaders);
            _.set(j, `lVariant.${variantcase}.Active.aHfiledata`, parsedFileData.ah_filefirstline);
            _.set(j, `lVariant.${variantcase}.Active.aHfiledata`, parsedFileData.ah_filesecondline);
            _.set(j, `lVariant.${variantcase}.Active.aHfiledata`, parsedFileData.ah_filethirdline);
            _.set(j, `lVariant.${variantcase}.Active.aHfiledata`, parsedFileData.ah_filefourthline);
            _.set(j, `lVariant.${variantcase}.Active.aHfiledata`, parsedFileData.ah_filefifthline);
            _.set(j, `lVariant[${variantcase}].building.lZone.[0].lRoom`, draftForRooms);
            _.set(j, `lVariant.${variantcase}.Active.aHfiledata`, parsedFileData);

            for (let i = 0; i < parsedFileData.ah_crooms; i++) {

              updateFileToRoomThermalEnv(j, variantcase, i, i);
              updateFileToRoomIndoorAir(j, variantcase, i, i);
            }

            _.set(j, `lVariant.${variantcase}.Active.ahthufile`, true);
            _.set(j, `lVariant.${variantcase}.Active.ahco2ufile`, true);
            _.set(j, `lVariant.${variantcase}.Active.aHfiledata.aHfileOk`, true);

            if (j) {
              onMergeJsonWithGlobalJson.callback(j);
            }
          }

          if (ReadFile[0] != null) {
            reader.readAsText(ReadFile[0]);
          }
          else {

          }

        }}
        onUploadFailed={() => {
          toast({
            // title: "Reject file",
            // description: "Sorry, wrong file type, or file size",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
            render: () => (
              <Box color="white" p={3} bg="red.500">
                <P>{i18n.t(`form.filereject`)}</P>
              </Box>
            ),
          })
        }}

      />

      <br></br>
      <FileHeaderColumn variantcase={variantcase} />


      {/* </FlexRow> */}
    </>
  );
};


// const fileheaders = _.get(globalJson, `lVariant.${variantcase}.Active.ahfileheader`);

// fileheaders.forEach((i: number) => {

// });

export const FileHeaderColumn: React.FC<any> = ({ }) => {
  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { globalJson } = useContext(JsonContext);
  const fileheaders = _.get(globalJson, `lVariant.${variantcaseIndex}.Active.aHfiledata.ah_fileheaders`);
  const filefirstline = _.get(globalJson, `lVariant.${variantcaseIndex}.Active.aHfiledata.ah_filefirstline`);
  const filesecondline = _.get(globalJson, `lVariant.${variantcaseIndex}.Active.aHfiledata.ah_filesecondline`);
  const filethirdline = _.get(globalJson, `lVariant.${variantcaseIndex}.Active.aHfiledata.ah_filethirdline`);
  const filefourthline = _.get(globalJson, `lVariant.${variantcaseIndex}.Active.aHfiledata.ah_filefourthline`);
  const filefifthline = _.get(globalJson, `lVariant.${variantcaseIndex}.Active.aHfiledata.ah_filefifthline`);

  const { isOpen, onToggle } = useDisclosure()

  return (
    <>
      <FlexRow alignItems="center">
        <ButtonBase
          mt={1}
          ml={2}
          {...globalButtonProps(appColors.primary.main, isOpen)}
          onClick={onToggle}>
          <P>{i18n.t(`pages.morefiledata`)}</P>
        </ButtonBase>
      </FlexRow>
      <FlexRow alignItems="center">
        <Collapse in={isOpen} animateOpacity>
          <Box mt={3}>
            {(() => {
              const headers = [];
              let i = 0;
              if (fileheaders && filefirstline && filesecondline && filethirdline && filefourthline && filefifthline) {
                for (const header of fileheaders) {
                  headers.push(<P fontSize={appFontSizes.small}>Column {i + 1}: {header} = {filefirstline[i]}, {filesecondline[i]}, {filethirdline[i]}, {filefourthline[i]}, {filefifthline[i]}... </P>);
                  i++;
                }
              }
              return headers;
            })()}
          </Box>
        </Collapse>
      </FlexRow>
    </>
  );
};
