import { Link } from '@chakra-ui/core';
import React from 'react';
import { appColors } from 'src/app/theme';
import { Box, P } from 'src/ui';
import { MinusIcon, PlusIcon } from 'src/ui/elements/icons';
import { RevealIcon } from './animations';

export const AnotherTabLink: React.FC<{ label: string; link: string }> = (
  props
) => (
  <P
    pt={1}
    as="a"
    color={appColors.green[50]}
    target="_blank"
    href={props.link}  
  >
    {props.label}
  </P>
);

export const CloseOpenIcons = (props: { isOpen: boolean }) => (
  <Box>
    <RevealIcon isOpen={props.isOpen}>
      <PlusIcon />
    </RevealIcon>
    <RevealIcon isOpen={!props.isOpen}>
      <MinusIcon />
    </RevealIcon>
  </Box>
);
