import React, { useContext } from 'react';
import { FormContainer, TitleAndDivider } from 'src/ui/form_elements';
import { JsonFieldFactory } from '../infrastructure/form_field_factory';

import { Title } from 'src/ui/form_elements/title';
import {
  DropdownField,
  FieldDefinition,
  NumberField,
  TextField,
  TitleAndDividerField,
  TitleField,
  ButtonField,
  YesNoField,
  JustTextField,
  CurrentStatusField
} from '../domain/field_definitions';
import { createTextField } from './createTextField';
import { JsonContext } from '../../json/application/use_application_json';
import { createDropdownField } from './createDropdownField';
import { YesNoQuestion } from 'src/ui/form_elements/question_fields';
import _ from 'lodash';
import { JustText, JustNothing } from 'src/ui/form_elements/simpleitems';
import { emotionStyled } from 'src/ui/shared';
import { CurrentStatus } from 'src/ui/form_elements/currentstatus';

export const Reveal = emotionStyled.div`
  display: contents;

  & > * {
    transition: all ease 300ms;
        transform: ${(props: { isOpen: boolean }) =>
    !props.isOpen ? 'scale(0.0)' : 'scale(1)'};
    opacity: ${(props: { isOpen: boolean; isDisabled: boolean }) =>
    !props.isOpen || props.isDisabled ? 0.42 : 1};
    cursor:  ${(props: { isOpen: boolean; isDisabled: boolean }) =>
    props.isDisabled ? 'not-allowed' : 'initial'};
    pointer-events: ${(props: { isOpen: boolean; isDisabled: boolean }) =>
    props.isDisabled ? 'none' : 'initial'};
  }
`;

export const FormBuilder = ({
  jsonKeys,
  withoutBottomSpacing = false,
}: {
  jsonKeys: any;
  withoutBottomSpacing?: boolean;
}) => {
  // creates a draft from the current global json, gives back an updateField and a onSave, and give out errors for the fields
  const {
    globalJson,
    onSaveDraftJsonToGlobal,
    onSaveAndCalcDraftJsonToGlobal,
    onSaveAndCalcWithoutEnvironmentDraftJsonToGlobal,
    onUpdateFieldToDraftJson,
    onSaveFieldToGlobalDirectly,
    errors,
    onUpdateFieldToDraftJsonDebounced,
    setThisDraftJson
  } = useContext(JsonContext);

  const unitsystem = _.get(globalJson, 'ahsettings.ah_unit');

  return (
    <FormContainer pb={withoutBottomSpacing ? 0 : 4}>
      {jsonKeys.map((fieldData: any) => {
        const fieldDefinition: FieldDefinition = JsonFieldFactory.create(
          fieldData, unitsystem
        );

        const shouldHide =
          fieldData?.shouldHide && fieldData?.shouldHide(globalJson);

        const shouldHideLine =
          fieldData?.shouldHideLine && fieldData?.shouldHideLine(globalJson);

        const isDisabled =
          fieldData?.shouldDisable && fieldData?.shouldDisable(globalJson);

        const userDefinedUnitJsonKey = fieldDefinition?.fieldData?.extraOptions?.userDefUnit;
        const userDefinedUnit = !userDefinedUnitJsonKey ? null : _.get(globalJson, userDefinedUnitJsonKey);

        const gridColSpan = fieldDefinition?.fieldData?.extraOptions?.gridColSpan;

        let Element = null;

        if (fieldDefinition instanceof TextField) {
          Element = createTextField({
            type: 'text',
            fieldDefinition,
            json: globalJson,
            onUpdate: onUpdateFieldToDraftJsonDebounced,
            onSave: onSaveDraftJsonToGlobal,
            errors: errors,
            userDefinedUnit: userDefinedUnit,
          });
        }

        if (fieldDefinition instanceof NumberField) {
          Element = createTextField({
            type: 'number',
            fieldDefinition,
            json: globalJson,
            onUpdate: onUpdateFieldToDraftJsonDebounced,
            onSave: onSaveAndCalcDraftJsonToGlobal,
            errors: errors,
            userDefinedUnit: userDefinedUnit,
          });
        }

        if (fieldDefinition instanceof TitleAndDividerField) {
          Element = (
            <TitleAndDivider
              title={fieldDefinition.label}
              gridColSpan={gridColSpan}
              noLabel={fieldDefinition?.fieldData?.extraOptions?.noLabel}
              styleType={fieldDefinition?.fieldData?.extraOptions?.styleType}
              preLabel={fieldDefinition?.fieldData?.extraOptions?.preLabel}
            />
          );
        }

        if (fieldDefinition instanceof TitleField) {
          Element = (
            <Title
              IconComponent={
                fieldDefinition?.fieldData?.extraOptions?.IconComponent
              }
              title={fieldDefinition.label}
              isAbsoluteTitle={
                fieldDefinition?.fieldData?.extraOptions?.isAbsoluteTitle
              }
              styleType={fieldDefinition?.fieldData?.extraOptions?.styleType}
              gridColSpan={gridColSpan}
              titleValue={fieldDefinition?.fieldData?.extraOptions?.titleValue}
            />
          );
        }

        if (fieldDefinition instanceof JustTextField) {
          Element = (
            <JustText
              title={fieldDefinition.label}
              gridColSpan={
                gridColSpan
              }
            />
          );
        }

        if (fieldDefinition instanceof CurrentStatusField) {
          Element = (
            <CurrentStatus
              label={fieldDefinition.label}
              value={_.get(globalJson, fieldDefinition?.fieldData?.key as any)}
              gridColSpan={
                gridColSpan
              }
            />
          );
        }

        if (fieldDefinition instanceof DropdownField) {
          Element = createDropdownField({
            type: 'number',
            fieldDefinition,
            json: globalJson,
            onUpdate: onUpdateFieldToDraftJsonDebounced, //onUpdateFieldToDraftJson,
            onSave: onSaveAndCalcDraftJsonToGlobal,
            errors: errors,
          });
        }

        if (fieldDefinition instanceof YesNoField) {
          Element = (
            <YesNoQuestion
              gridColSpan={gridColSpan}
              label={fieldDefinition.label}
              value={_.get(globalJson, fieldDefinition?.fieldData?.key as any)}
              noLabel={fieldDefinition?.fieldData?.extraOptions?.noLabel}
              preLabel={fieldDefinition?.fieldData?.extraOptions?.preLabel}
              onChange={(isYes: boolean) => {
                onUpdateFieldToDraftJsonDebounced.callback(isYes, fieldDefinition);
              }}
            />
          );
        }

        if (fieldDefinition instanceof ButtonField) {
          Element = <button>{fieldDefinition.label}</button>;
        }

        let noElement = (
          <JustNothing
            gridColSpan={gridColSpan}
          />
        );

        let noGridElemet = (
          <> </>
        )

        if (shouldHideLine) {
          return (
            <Reveal isDisabled={isDisabled} isOpen={!shouldHide}>
              {noElement}
            </Reveal>
          );
        } else {
          if (shouldHide && fieldDefinition?.fieldData?.extraOptions?.noGridIfHide) {
            return (
              <Reveal isDisabled={true} isOpen={!shouldHide}>
                {noGridElemet}
              </Reveal>
            );
          }
          else {
            return (
              <Reveal isDisabled={isDisabled} isOpen={!shouldHide}>
                {Element}
              </Reveal>
            );
          }
        }
      })}
    </FormContainer>
  );
};
