import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';

export const useFileData = (indexVariant: number) => {
  const { globalJson } = useContext(JsonContext);

  const isDynamicThermal = _.get(globalJson, `lVariant.${indexVariant}.Active.ahtevalidation`) > 1 ? false : true;
  const isDynamicIndoorAir = _.get(globalJson, `lVariant.${indexVariant}.Active.ahiavalidation`) > 1 ? false : true;

  return {
    isDynamicThermal,
    isDynamicIndoorAir
  };
};

export const useFileTemperature = (indexVariant: number, index: number) => {
  const { globalJson } = useContext(JsonContext);

  // may change ROOM_DEFINITION_PATH to the current variantCase. but then, the rooms have to be set for all variant cases before.
  const operativetemperature = _.get(globalJson, `lVariant.${indexVariant}.Active.aHfiledata.aHfileR.${index}.aHoptemp`);

  // const numberOfColumn = _.size(draftJsonForAreas);

  // useEffect(() => {
  //   setAreas(numberOfAreas);
  // }, [numberOfAreas]);

  return {
    operativetemperature,
  };
};
