import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { ThermalEnvironmentIcon } from 'src/ui/elements/icons';

export class ThermalQuantityTitleDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_te`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: ThermalEnvironmentIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_tesc`,
        },
      },
      // { key: `lVariant.${variantcaseIndex}.Active.ahdlvalidation` }, // Dropdown, or buttons yes/no
      { key: `lVariant.${variantcaseIndex}.Active.ahtevalidation` }, // Dropdown, or buttons yes/no
    ];
  }
}

export class ThermalQuantityUserInputDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahthufile`,
        shouldDisable: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHfiledata.aHfileOk`,
              value: !true,
            }
          ]),
        extraOptions: { gridColSpan: 6 },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 2 } },
    
    ];
  }
}

export class ThermalRoomsHeaderJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.n`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
          placeholderkey: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahndef`,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_tesc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
    ];
  }
}


export class ThermalRoomsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.n`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
          placeholderkey: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahndef`,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_tesc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 1,
          roundDecimal: 1,
          score:true ,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahtecool`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahcolumnte`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          {
            key: `lVariant.${variantcaseIndex}.Active.ahthufile`,
            value: false,
          }
        ]),
        extraOptions: { gridColSpan: 1, disabled: true },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahteopmaxs`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.ahthufile`,
              value: true,
            }
          ]),
        extraOptions: { gridColSpan: 1, noGridIfHide:true },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_teopmaxs`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.ahthufile`,
              value: false,
            }
          ]),
        extraOptions: { disabled: true, gridColSpan: 1, noGridIfHide:true },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahteopmins`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          {
            key: `lVariant.${variantcaseIndex}.Active.ahthufile`,
            value: true,
          }
        ]),
        extraOptions: { gridColSpan: 1, noGridIfHide:true },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_teopmins`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          {
            key: `lVariant.${variantcaseIndex}.Active.ahthufile`,
            value: false,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, noGridIfHide:true },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_maxtop`,
        extraOptions: { disabled: true, gridColSpan: 1, styleType: 2, roundDecimal: 1, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_mintop`,
        extraOptions: { disabled: true, gridColSpan: 1, styleType: 2, roundDecimal: 1, score: true, },
      },
    ];
  }
}
