import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { PrimaryEnergyQualityJsonDefinition } from '../../../data/qualitative/primaryenergy_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const PrimaryEnergyQualityPage: React.FC = () => {
  const primaryenergyjson = useWithVariantCaseIndex(
    PrimaryEnergyQualityJsonDefinition
  );

  return <FormBuilder jsonKeys={primaryenergyjson.keys} />;
};

export default PrimaryEnergyQualityPage;
