import _ from 'lodash';

import { getNameOfDefinitionEnum } from 'src/features/shared/form/utils/get_name_for_definition_enum';
import { getRandomInt } from 'src/features/shared/utils';
import { Calculation } from '../calculation';
import { calculationErrors } from '../calculation_errors';

export class GlobalCalculations implements Calculation {
  i: number;
  draftJson: any;

  public constructor({ i, draftJson }: any) {
    this.i = i;
    this.draftJson = { ...draftJson };
  }

  public calculate() {
    const { draftJson, i } = this;
    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;

    const globalroomdefinitinoCasePath = `lVariant[0].building.lZone.[0].lRoom`;
    const globaldraftJsonForRooms = _.get(
      draftJson,
      globalroomdefinitinoCasePath
    );

    //  ################## for all variants
    const electricityPeFactor = _.get(draftJson, `lVariant[0].Active.ahdefpe`);
    _.set(
      draftJson,
      `lVariant[${i}].Active.ahdefpe`,
      electricityPeFactor
    );

    const daylightMethod = _.get(draftJson, `lVariant[${i}].Active.ahdlopt`);
    if (!daylightMethod) _.set(draftJson, `lVariant[${i}].Active.ahdlopt`, 1);

    // ###### for all rooms
    var index = 0;
    if (globaldraftJsonForRooms) {
      for (var dJFR of globaldraftJsonForRooms) {   // dJFR is only i = 0 ! global

        const roomName = getNameOfDefinitionEnum({
          partJson: draftJson,
          key: `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahroomtype`,
        });
        // _.set(dJFR, `n`, roomName);

        _.set(
          draftJson,
          `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahndef`,
          roomName
        );


        if (i === 0) {
          // for first global room

          const roomType = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahroomtype`);

          if (roomType == 1) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdlhdef`,
              2.5
            );
          }
          if (roomType == 2) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdlhdef`,
              3
            );
          }
          if (roomType == 3) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdlhdef`,
              0.5
            );
          }
          if (roomType == 4) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdlhdef`,
              1.5
            );
          }
          if (roomType == 5) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdlhdef`,
              ""
            );
          }

          if (roomType == 1) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdhdef`,
              3.5
            );
          }

          if (roomType == 2) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdhdef`,
              5
            );
          }
          if (roomType == 3) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdhdef`,
              8.5
            );
          }
          if (roomType == 4) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdhdef`,
              11
            );
          }
          if (roomType == 5) {
            _.set(
              draftJson,
              `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdhdef`,
              ""
            );
          }
        }
        else {
          const rName = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].n`);     // globaly
          const currentId = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].id`);     // globaly
          const daylighthours = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdlhours`);     // globaly
          const hours = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdhours`);     // globaly
          const daylighthoursdef = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdlhdef`);     // globaly
          const hoursdef = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahdhdef`);     // globaly
          const countOfPeople = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahocu`); // globaly
          const roomtype = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.ahroomtype`); // globaly
          const area = _.get(draftJson, `lVariant[0].building.lZone.[0].lRoom[${index}].Active.aharea`); // globaly
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].n`, rName);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].id`, currentId);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdlhours`, daylighthours);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdhours`, hours);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdlhdef`, daylighthoursdef);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdhdef`, hoursdef);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahocu`, countOfPeople);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahroomtype`, roomtype);
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.aharea`, area);
        }


        // old df factor
        const DaylightFactorSelect = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdfs`);
        if (!DaylightFactorSelect) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdfs`, 5);
        }
        const DaylightFactorSelecta = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdfsa`);
        if (!DaylightFactorSelecta) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahdfsa`, 5);
        }

        // default room settings
        const AirConditioning = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahtecool`);
        if (!AirConditioning) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahtecool`, 2);
        }
        const CO2level = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahco2lvl`);
        if (!CO2level) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahco2lvl`, 5);
        }
        const acousticinside = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahaqindb`);
        if (!acousticinside) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahaqindb`, 5);
        }
        const acousticoutside = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahaqoutdb`);
        if (!acousticoutside) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahaqoutdb`, 5);
        }
        const acousticprivate = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahaqprdb`);
        if (!acousticprivate) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahaqprdb`, 5);
        }

        const ahrod = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahrod`);
        if (ahrod == undefined) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahrod`, true);
        }
        const ahrot = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahrot`);
        if (ahrot == undefined) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahrot`, true);
        }
        const ahroi = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahroi`);
        if (ahroi == undefined) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahroi`, true);
        }
        const ahroa = _.get(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahroa`);
        if (ahroa == undefined) {
          _.set(draftJson, `lVariant[${i}].building.lZone.[0].lRoom[${index}].Active.ahroa`, true);
        }


        index = index + 1;
      }
    }
    // else {
    //   _.set(draftJson, `ahstate.ahr_pjstate`, 'missing rooms');
    // }

    // ##################################### check system for removed fractional data
    const systemdefinitinoCasePath = `lVariant[${i}].HVAC.lSystem`;
    const draftJsonForSystemsAll = _.get(draftJson, systemdefinitinoCasePath);
    let i_devclear = 0;
    if (draftJsonForSystemsAll) {
      let filtersystem = false;
      for (const dJFS of draftJsonForSystemsAll) {

        const id = _.get(dJFS, `lDevice.[0].id`);
        if (!id) {  // remove this system
          _.set(
            draftJson,
            `lVariant[${i}].HVAC.lSystem.[${i_devclear}].checkedForDelete`,
            true
          );
          filtersystem = true;
        }
        i_devclear++;
      }
      if (filtersystem)
      {
        const draftJsonForSystemsClean = _.get(draftJson, systemdefinitinoCasePath);
        const newDraftJsonForSystems = [...(draftJsonForSystemsClean as any)];
        
        const filtered = newDraftJsonForSystems.filter(
          function (v) 
          {
            return !v.checkedForDelete ;
          }
        );
        _.set(
          draftJson,
          systemdefinitinoCasePath,
          filtered
        );
      }
    }

    // ##################################### add system for heat pump renewable part 

    if (i == 0) {
      let i_dev = 0;
      const systemdefinitinoCasePath = `lVariant[${i}].HVAC.lSystem`;
      const draftJsonForSystems = _.get(draftJson, systemdefinitinoCasePath);

      if (draftJsonForSystems) {
        for (const dJFS of draftJsonForSystems) {

          const deviceTypeRenewHeat = _.get(dJFS, `lDevice.0.Active.ahdeviceThrenw`);
          if (deviceTypeRenewHeat == 6) {  // heat pump renewable part
            let foundcoupledHeatpump = false;
            const coupledHeatPump = _.get(dJFS, `lDevice.[0].Active.ahcoupdevid`);
            for (const dJFSi of draftJsonForSystems) {
              const thisid = _.get(dJFSi, `lDevice.[0].id`);
              if (coupledHeatPump == thisid) {
                foundcoupledHeatpump = true;
              }
            }
            if (!foundcoupledHeatpump) {
              // console.log("not found id= " + coupledHeatPump + "index = " + i_dev);
              delete draftJson.lVariant[i].HVAC.lSystem[i_dev];
              
            }

          }
          i_dev++;
        }
      }
    }


    if (i == 0) {
      let i_dev = 0;
      const systemdefinitinoCasePath = `lVariant[${i}].HVAC.lSystem`;
      const draftJsonForSystems = _.get(draftJson, systemdefinitinoCasePath);

      if (draftJsonForSystems) {
        const newsystemi = +_.size(draftJsonForSystems);
        for (const dJFS of draftJsonForSystems) {

          const deviceTypeHeatDemand = _.get(dJFS, `lDevice.[0].Active.ahheatDev`);
          if (deviceTypeHeatDemand == 5) {  // heat pump
            let foundcoupledHeatpump = false;
            const coupledHeatPump = _.get(dJFS, `lDevice.[0].Active.ahcoupdevid`);
            for (const dJFSi of draftJsonForSystems) {
              const thisid = _.get(dJFSi, `lDevice.[0].id`);
              if ((coupledHeatPump == thisid) && (coupledHeatPump)) {
                foundcoupledHeatpump = true;
              }
            }
            if (!foundcoupledHeatpump) {
              const heatpumpid = _.get(draftJson, `lVariant.[${i}].HVAC.lSystem.[${i_dev}].lDevice.[0].id`);
              const randomId = _.uniqueId("10") + getRandomInt(50000);   // get a random id
              _.set(
                draftJson,
                `lVariant.[${i}].HVAC.lSystem.[${i_dev}].lDevice.[0].Active.ahcoupdevid`,
                randomId
              );
              _.set(
                draftJson,
                `lVariant.[${i}].HVAC.lSystem.[${newsystemi}].lDevice.[0].Active.ahcoupdevid`,
                heatpumpid
              );
              _.set(
                draftJson,
                `lVariant.[${i}].HVAC.lSystem.[${newsystemi}].lDevice.[0].id`,
                randomId
              );
              _.set(
                draftJson,
                `lVariant.[${i}].HVAC.lSystem.[${newsystemi}].lDevice.[0].Active.ahautoset`,
                true
              );
              _.set(
                draftJson,
                `lVariant.[${i}].HVAC.lSystem.[${newsystemi}].lDevice.[0].Active.ahtypeDev`,
                3
              );
              _.set(
                draftJson,
                `lVariant.[${i}].HVAC.lSystem.[${newsystemi}].lDevice.[0].Active.ahdeviceThrenw`,
                6
              );
            }
          }
          i_dev++;
        }
      }
    }





    // ###############################  Systems global
    const globalsystemdefinitionCasePath = `lVariant[0].HVAC.lSystem`;   // global system  (all systems!)
    const globaldraftJsonForSystems = _.get(draftJson, globalsystemdefinitionCasePath);

    const systemdefinitionCasePath = `lVariant[${i}].HVAC.lSystem`;
    const draftJsonForSystems = _.get(draftJson, systemdefinitionCasePath);

    let indexSystem = 0;
    if (globaldraftJsonForSystems) {

      for (var dJFgS of globaldraftJsonForSystems) {
        const systemtype = _.get(dJFgS, `lDevice.[0].Active.ahtypeDev`);
        const systemId = _.get(dJFgS, `id`);

        if (i > 0) {

          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].id`, systemId);

          const deviceGlobaldefinitionCasePath = `lDevice`
          const deviceGlobaldefinitionCase = _.get(dJFgS, deviceGlobaldefinitionCasePath);

          if (deviceGlobaldefinitionCase) {
            let indexDevice = 0;
            for (var dJFcD of deviceGlobaldefinitionCase) {

              const deviceId = _.get(dJFcD, `id`);
              const SystemType = _.get(dJFcD, `Active.ahtypeDev`);
              const heatingType = _.get(dJFcD, `Active.ahheatDev`);
              // console.log( _.get(dJFcD, `Active.ahpeheat`));

              const heatingPE = _.get(dJFcD, `Active.ahpeheat`);
              const coolingPE = _.get(dJFcD, `Active.ahpecool`);
              const dhwPE = _.get(dJFcD, `Active.ahpedhw`);
              const auxPE = _.get(dJFcD, `Active.ahpeaux`);
              const heatingCOP = _.get(dJFcD, `Active.ahCOP`);
              const coolingCOP = _.get(dJFcD, `Active.ahcoolCOP`);
              const dhwCOP = _.get(dJFcD, `Active.ahdwhCOP`);

              // parameters to set for all heating devices
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].id`, deviceId);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahtypeDev`, SystemType);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahheatDev`, heatingType);

              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahpeheat`, heatingPE);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahpecool`, coolingPE);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahpedhw`, dhwPE);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahpeaux`, auxPE);

              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahCOP`, heatingCOP);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahcoolCOP`, coolingCOP);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahdwhCOP`, dhwCOP);

              // parameters to set for all devices
              const elDev = _.get(dJFcD, `Active.ahelDev`);
              const eldempef = _.get(dJFcD, `Active.aheldempef`);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahelDev`, elDev);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.aheldempef`, eldempef);

              const deviceThrenw = _.get(dJFcD, `Active.ahdeviceThrenw`);
              const heatprodpef = _.get(dJFcD, `Active.ahheatprodpef`);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahdeviceThrenw`, deviceThrenw);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahheatprodpef`, heatprodpef);

              const deviceTerenw = _.get(dJFcD, `Active.ahdeviceTerenw`);
              const elprodpef = _.get(dJFcD, `Active.ahelprodpef`);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahdeviceTerenw`, deviceTerenw);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahelprodpef`, elprodpef);

              // additional LCA devices
              const lcaSysteGroup = _.get(dJFcD, `eco.lcasysG`);
              const lcaMaterial = _.get(dJFcD, `eco.lcaMaterial`);
              const countPerUnit = _.get(dJFcD, `eco.countS`);
              const systemLifetime = _.get(dJFcD, `eco.sus.aHlifetime`);

              if (indexDevice > 0) {
                _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].Active.ahtypeDev`, 5);
              }
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcasysG`, lcaSysteGroup);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaMaterial`, lcaMaterial);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.countS`, countPerUnit);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.sus.aHlifetime`, systemLifetime);
              indexDevice++;
            }
          }

          const operationGlobaldefinitionCasePath = `lOperation`
          const operationBlobaldefinitionCase = _.get(dJFgS, operationGlobaldefinitionCasePath);

          if (operationBlobaldefinitionCase) {
            let indexOperation = 0;
            for (var dJFcO of operationBlobaldefinitionCase) {
              const operationId = _.get(dJFcO, `id`);
              const lcaSysteGroup = _.get(dJFcO, `eco.lcasysO`);
              const lcaMaterial = _.get(dJFcO, `eco.lcaMaterial`);
              const lcashare = _.get(dJFcO, `eco.lcashare`);

              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].id`, operationId);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcasysO`, lcaSysteGroup);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaMaterial`, lcaMaterial);
              _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcashare`, lcashare);
              indexOperation++;
            }
          }
        }
        indexSystem++;
      }
    }


    // #################### for all constructions
    const globalconstructionsdefinitionCasePath = `lAssembly`;
    const globaldraftJsonForConstructions = _.get(
      draftJson,
      globalconstructionsdefinitionCasePath
    );

    let indexConstructions = 0;

    for (var dJFC of globaldraftJsonForConstructions) {

      const constructionid = _.get(dJFC, `id`);

      if (constructionid == null || +constructionid == -1) {
        _.set(
          draftJson,
          `lAssembly[${indexConstructions}].id`,
          _.uniqueId("10") + getRandomInt(50000)   // get a random id
        );
      }


      indexConstructions = indexConstructions + 1;
    }

    // #################### for all Areas globally  get

    if (i > 0) {
      const globalareadefinitionCasePath = `lVariant[0].building.lComponent`;
      const globaldraftJsonForAreas = _.get(
        draftJson,
        globalareadefinitionCasePath
      );
      let indexArea = 0;
      for (var dJFC of globaldraftJsonForAreas) {  // globaly
        const currentId = _.get(dJFC, `id`);
        const name = _.get(dJFC, `n`);
        const areaValue = _.get(dJFC, `areaC`);
        const quantityWindows = _.get(dJFC, `countC`);
        const typeCah = _.get(dJFC, `eco.typeCah`);

        _.set(draftJson, `lVariant.[${i}].building.lComponent.[${indexArea}].eco.typeCah`, typeCah);
        _.set(draftJson, `lVariant[${i}].building.lComponent[${indexArea}].id`, currentId);
        _.set(draftJson, `lVariant[${i}].building.lComponent[${indexArea}].n`, name);
        _.set(draftJson, `lVariant[${i}].building.lComponent[${indexArea}].areaC`, areaValue);
        _.set(draftJson, `lVariant[${i}].building.lComponent[${indexArea}].countC`, quantityWindows);

        indexArea++;
      }
    }

    return draftJson;
  }
}
