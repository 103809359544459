import React from 'react';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, Divider, FlexRow, P } from 'src/ui';
import { TrashIcon } from 'src/ui/elements/icons';
import { ContactJsonDefinition, ContactsTitleJsonDefinition } from '../../data/contacts_json_definition';
import { useNumberOfContacts } from '../applications/use_number_of_contacts';

import { useNumberOfRooms } from '../applications/use_number_of_rooms';

const titleDefinition = new ContactsTitleJsonDefinition();

export const Contact: React.FC<any> = ({ index, onRemoveContact, isLast }) => {
  const Json = new ContactJsonDefinition(index);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        <FlexRow
          mt={4}
          ml={1}
          py={2}
          alignSelf="self-start"
          as="button"
          onClick={() => onRemoveContact(index)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexRow>
      </FlexRow>

      {!isLast ? <Divider my={2} /> : null}
    </>
  );
};

export const ContactsPage: React.FC = () => {
  const { contacts, addContact, removeContact } = useNumberOfContacts();
  const mappingContacts = [...(Array(contacts).keys() as any)];
  return (
    <>
      <FormBuilder jsonKeys={titleDefinition.keys} />
      <FormBuilder withoutBottomSpacing jsonKeys={titleDefinition.keys} />
      {mappingContacts.map((a, i) => (
        <AnimateList key={i}>
          <Contact
            key={contacts}
            index={a}
            onRemoveContact={() => removeContact(i)}
            isLast={i === mappingContacts.length - 1}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} mb={5} onClick={() => addContact()}>
          <P style={{ textDecoration: 'underline' }} color="green.50">
          {i18n.t(`add.contact`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </>
  );
};
