import { DividerProps, TextProps } from '@chakra-ui/core';
import React from 'react';
import { Label } from 'recharts';
import { appColors, appFontSizes } from 'src/app/theme';
import { P } from '..';
import { Divider } from '../structure';
import { FormGridItem } from './grid_items';


export const TitleAndDivider = ({
  title,
  noLabel,
  gridColSpan,
  textProps,
  dividerProps,
  styleType,
  preLabel,
}: {
  fontSize?: typeof appFontSizes;
  textProps?: TextProps;
  dividerProps?: DividerProps;
  title: string;
  noLabel: boolean;
  gridColSpan: number;
  styleType?: number;
  preLabel?: string;
}) => {
  if (noLabel) {
    return (
      <FormGridItem colSpan={gridColSpan ? gridColSpan : 6} mt={-2}>
        <Divider pt={2} color={dividerProps?.color || appColors.gray.light} />
      </FormGridItem>
    );
  } else {
    return (
      <FormGridItem colSpan={gridColSpan ? gridColSpan : 6} mb={gridColSpan ? 0 : 1} mt={gridColSpan ? 2.5 : 5}>
        {styleType == 4 ? (
          <P
            style={{ whiteSpace: 'nowrap' }}
            fontSize={appFontSizes.small}
            textAlign="left"
            mb="4px"
          >
            {preLabel} 
          </P>
        ) : (null)}
        <P
          color={styleType == 4 ? 'black' : textProps?.color || appColors.primary.dark}
          fontSize={styleType == 4 ? appFontSizes.body : textProps?.fontSize || appFontSizes.body}
          fontWeight={styleType == 4 ? 'normal' : textProps?.fontWeight || 'bold'}
          px={styleType == 4 ? '13px' : ''}
        >
          {title}
        </P>
        {gridColSpan ? (
          null
        ) :
          (
            <Divider pt={1.5} color={dividerProps?.color || appColors.gray.light} />
          )}
      </FormGridItem>
    );
  }
};
