import _ from 'lodash';
import { i18n } from 'src/features/shared/translations/translate';
import { Calculation } from '../calculation';
import { calculationErrors } from '../calculation_errors';
import { MeanScore } from './final_calculations/mean_score';

export class FinalCalculations implements Calculation {
  i: number;
  draftJson: any;

  public constructor({ i, draftJson, finalCalculations }: any) {
    this.i = i;
    this.draftJson = { ...draftJson };
  }

  public calculate() {
    const { draftJson, i } = this;
    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;


    const caseerror = _.get(draftJson, `lVariant[${i}].Active.ahstate.ahr_caseerror`);
    if (caseerror === 0) {
      _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_casemsg`, "");
    } else {
      const error_msg =
        caseerror === 1 ? _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_casemsg`, i18n.t(`error.room`)) :
          caseerror === 2 ? _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_casemsg`, i18n.t(`error.room`)) :
            caseerror === 3 ? _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_casemsg`, i18n.t(`error.room`)) :
              caseerror === 4 ? _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_casemsg`, i18n.t(`error.room`)) :
                "";
    }

    const meanTotalScore = new MeanScore({
      daylightScore: _.get(draftJson, `lVariant[${i}].Active.ahr_dlsc`),
      thermalScore: _.get(draftJson, `lVariant[${i}].Active.ahr_tesc`),
      indoorairScore: _.get(draftJson, `lVariant[${i}].Active.ahr_iasc`),
      aqusticScore: _.get(draftJson, `lVariant[${i}].Active.ahr_aqsc`),
      demandScore: _.get(draftJson, `lVariant[${i}].Active.ahr_edsc`),
      primaryScore: _.get(draftJson, `lVariant[${i}].Active.ahr_pesc`),
      supplyScore: _.get(draftJson, `lVariant[${i}].Active.ahr_essc`),
      sustainableScore: _.get(draftJson, `lVariant[${i}].Active.ahr_scsc`),
      freshwaterScore: _.get(draftJson, `lVariant[${i}].Active.ahr_fwsc`),
    });

    _.set(draftJson, `lVariant[${i}].Active.ahr_sc`, meanTotalScore.val);

    return draftJson;
  }



}


