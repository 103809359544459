import { i18n } from '../../translations/translate';
import { FieldData } from '../data/json_definition';
import { Unit } from './unit';

export class FieldDefinitionInterface {
  fieldData?: FieldData;

  constructor({ fieldData }: { fieldData?: FieldData }) {
    this.fieldData = fieldData;
  }
}

export class FieldDefinition {
  fieldData?: FieldData;

  public hasErrors(val?: any): false | string {
    return false;
  }

  constructor({ fieldData }: { fieldData?: FieldData }) {
    this.fieldData = fieldData;
  }
}

interface TextFieldInteface extends FieldDefinitionInterface {
  label: string;
  maxLength?: string;
  unit?: Unit;
}

interface TitleAndDividerInterface extends FieldDefinitionInterface {
  label: string;
}

interface TitleFieldInterface extends FieldDefinitionInterface {
  label: string;
}

interface JustTextFieldInterface extends FieldDefinitionInterface {
  label: string;
}

interface DrodpownFieldInterface extends FieldDefinitionInterface {
  label: string;
  options: { value: any; label: string }[];
}

interface ButtonFieldInterface extends FieldDefinitionInterface {
  label: string;
}

interface YesNoInterface extends FieldDefinitionInterface {
  label: string;
}

export class TextField extends FieldDefinition {
  label: string;
  maxLength?: string;
  unit?: Unit;
  constructor({ label, maxLength, unit, ...props }: TextFieldInteface) {
    super(props);
    this.label = label;
    this.maxLength = maxLength;
    this.unit = unit;
  }
}

export class TitleAndDividerField extends FieldDefinition {
  label: string;
  constructor({ label, ...props }: TitleAndDividerInterface) {
    super(props);
    this.label = label;
  }
}

export class TitleField extends FieldDefinition {
  label: string;
  constructor({ label, ...props }: TitleFieldInterface) {
    super(props);
    this.label = label;
  }
}

export class JustTextField extends FieldDefinition {
  label: string;
  constructor({ label, ...props }: JustTextFieldInterface) {
    super(props);
    this.label = label;
  }
}

export class CurrentStatusField extends FieldDefinition {
  label: string;
  constructor({ label, ...props }: JustTextFieldInterface) {
    super(props);
    this.label = label;
  }
}

export class DropdownField extends FieldDefinition {
  label: string;
  options: { value: any; label: string }[];

  constructor({ label, options, ...props }: DrodpownFieldInterface) {
    super(props);
    this.label = label;
    this.options = options;
  }
}

export class NumberField extends FieldDefinition {
  max: number;
  min: number;
  label: string;
  maxLength?: string;
  unit?: Unit;

  public hasErrors(val: number): false | string {
    if (!val) return false;
    const tb = 'form.number_field';

    let error = '';

    if (this.max && val > this.max) {
      error = error + i18n.t(`${tb}.max_validation`, { max: this.max });
    }

    if (this.min && val < this.min) {
      const separator = error !== '' ? ',' : '';
      error =
        error + separator + i18n.t(`${tb}.min_validation`, { min: this.min });
    }

    if (error === '') return false;

    return error;
  }

  constructor({
    max,
    label,
    maxLength,
    unit,
    min,
    ...props
  }: {
    min: number;
    max: number;
    label: string;
    maxLength: string;
    unit?: Unit;
  } & TextFieldInteface) {
    super(props);

    this.min = min;
    this.max = max;
    this.label = label;
    this.maxLength = maxLength;
    this.unit = unit;
  }
}

export class ButtonField extends FieldDefinition {
  label: string;
  constructor({ label, ...props }: ButtonFieldInterface) {
    super(props);
    this.label = label;
  }
}

export class YesNoField extends FieldDefinition {
  label: string;
  constructor({ label, ...props }: YesNoInterface) {
    super(props);
    this.label = label;
  }
}
