
export class GWPScore {
    score: number;
  constructor({ GWP }: { GWP?: number }) {
    if (GWP == undefined) {
      this.score = 5;
      return;
    }
    this.score =
            GWP <= -30
          ? 1
          : GWP  <= 10
          ? 1+((1)/(10+30))*(GWP+30)
          : GWP  <= 40
          ? 2+((1)/(40-10))*(GWP-10)
          : GWP  <= 50
          ? 3+((1)/(50-40))*(GWP-40)
          : 5;
  }
}

export class ODPScore {
    score: number;
    constructor({ ODP }: { ODP?: number }) {
      if (!ODP) {
        this.score = 5;
        return;
      }
    
      this.score =
            ODP <= 2.25E-7
            ? 1
            : ODP  <= 5.3E-7
            ? 1+((1)/((5.3E-7)-(2.25E-7)))*(ODP-(2.25E-7))
            : ODP  <= 3.7E-6
            ? 2+((1)/((3.7E-6)-(5.3E-7)))*(ODP-(5.3E-7))
            : ODP  <= 6.7E-6
            ? 3+((1)/((6.7E-6)-(3.7E-6)))*(ODP-(3.7E-6))
            : 5;
    }
  }
  

  export class POCPScore {
    score: number;
    constructor({ POCP }: { POCP?: number }) {
      if (!POCP) {
        this.score = 5;
        return;
      }
    
      this.score =
              POCP <= 0.0025
            ? 1
            : POCP  <= 0.0040
            ? 1+((1)/(0.0040-0.0025))*(POCP-0.0025)
            : POCP  <= 0.0070
            ? 2+((1)/(0.0070-0.0040))*(POCP-0.0040)
            : POCP  <= 0.0085
            ? 3+((1)/(0.0085-0.0070))*(POCP-0.0070)
            : 5;
    }
  }
  

  export class APScore {
    score: number;
    constructor({ AP }: { AP?: number }) {
      if (!AP) {
        this.score = 5;
        return;
      }
    
      this.score =
              AP <= 0.010
            ? 1
            : AP  <= 0.075
            ? 1+((1)/(0.075-0.010))*(AP-0.010)
            : AP  <= 0.100
            ? 2+((1)/(0.100-0.075))*(AP-0.075)
            : AP  <= 0.125
            ? 3+((1)/(0.125-0.100))*(AP-0.100)
            : 5;
    }
  }
  

  export class EPScore {
    score: number;
    constructor({ EP }: { EP?: number }) {
      if (!EP) {
        this.score = 5;
        return;
      }
    
      this.score =
              EP <= 0.0040
            ? 1
            : EP  <= 0.0055
            ? 1+((1)/(0.0055-0.0040))*(EP-0.0040)
            : EP  <= 0.0085
            ? 2+((1)/(0.0085-0.0055))*(EP-0.0055)
            : EP  <= 0.0105
            ? 3+((1)/(0.0105-0.0085))*(EP-0.0085)
            : 5;
    }
  }

  export class EnvironmentLoadScore {
    score:number;
    constructor({ GWPscore, OPDscore, POCPscore, APscore, EPscore }: { 
            GWPscore?: number, 
            OPDscore?: number, 
            POCPscore?: number, 
            APscore?: number, 
            EPscore?: number, 
        }) {
        if (!GWPscore || !OPDscore || !POCPscore || !APscore || !EPscore) {
          this.score = 5;
          return;
        }
        if (GWPscore > 4 || OPDscore > 4 || POCPscore > 4|| APscore > 4 || EPscore > 4) {
          this.score = 5;
          return;
        }
        this.score = 
        (
            GWPscore + 
            OPDscore + 
            POCPscore +
            APscore +
            EPscore 
            ) / 5.0 ;
      }
  }
  