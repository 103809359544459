import React from "react";
import { appTheme } from "src/app/theme";
import { i18n } from "src/features/shared/translations/translate";
import { radarTheme200, radarTheme400 } from "./theme";

const polarToX = (angle: number, distance: number) =>
  Math.cos(angle - Math.PI / 2) * distance;

const polarToY = (angle: number, distance: number) =>
  Math.sin(angle - Math.PI / 2) * distance;

const points = (pts: number[][]) => {
  return pts
    .map((point) => point[0].toFixed(4) + "," + point[1].toFixed(4))
    .join(" ");
};

// To add the axis correctly, we have to manualy set this parameters.
// The bigger the number, it goes to clockwise direction.

const ENVIRONMENT_CONFORT_AXIS = 4.88;
const COMFORT_ENERGY_AXIS = 1.39;
const ENERGY_ENVIRONMENT_AXIS = 3.49;

// To grow in the correct size to reach the circle, this requires some manual tweaks as well.

const ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE_200 = {
  [ENVIRONMENT_CONFORT_AXIS]: radarTheme200.ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE,
  [COMFORT_ENERGY_AXIS]: radarTheme200.ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE,
  [ENERGY_ENVIRONMENT_AXIS]: radarTheme200.ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE,
} as any;

const ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE_400 = {
  [ENVIRONMENT_CONFORT_AXIS]: radarTheme400.ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE,
  [COMFORT_ENERGY_AXIS]: radarTheme400.ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE,
  [ENERGY_ENVIRONMENT_AXIS]: radarTheme400.ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE,
} as any;

// Given the numbers should be align in the axis, according to the angle that its there, we can use
// this adjusts to put the number in a better position

const NUMBERS_ADJUST_200 = {
  [ENVIRONMENT_CONFORT_AXIS]: [
    { x: 1, y: -2 },
    { x: -11, y: -4 },
    { x: -7, y: -3 },
    { x: 2, y: -1 },
    { x: 15, y: 2 },
  ],
  [COMFORT_ENERGY_AXIS]: [
    { x: -6, y: -1 },
    { x: 5, y: -3 },
    { x: 2, y: -3 },
    { x: -8, y: -1 },
    { x: -21, y: 2 },
  ],
  [ENERGY_ENVIRONMENT_AXIS]: [
    { x: 4, y: 3 },
    { x: 0, y: 12 },
    { x: 1, y: 9 },
    { x: 3, y: 0 },
    { x: 8, y: -13 },
  ],
} as any;

const NUMBERS_ADJUST_400 = {
  [ENVIRONMENT_CONFORT_AXIS]: [
    { x: -25, y: -8 },
    { x:-37, y: -10 },
    { x: -26, y: -9 },
    { x: -6, y: -5 },
    { x: 17, y: 0 },
  ],
  [COMFORT_ENERGY_AXIS]: [
    { x: 20, y: -8 },
    { x: 30, y: -11 },
    { x: 20, y: -10 },
    { x: 0, y: -5 },
    { x: -23, y: 0 },
  ],
  [ENERGY_ENVIRONMENT_AXIS]: [
    { x: -4, y: 28 },
    { x: -8, y: 38 },
    { x: -5, y: 28 },
    { x: 1, y: 9 },
    { x: 9, y: -13 },
  ],
} as any;

export const appLineAxis = (options: any) => (
  <>
    {LineAxis(options)({ angle: ENERGY_ENVIRONMENT_AXIS }, "axis-1")}
    {LineAxis(options)({ angle: ENVIRONMENT_CONFORT_AXIS }, "axis-2")}
    {LineAxis(options)({ angle: COMFORT_ENERGY_AXIS }, "axis-3")}
  </>
);

const LineAxis = (options: any) => (col: any, i: any) => {
  const size = options.chartSize <= 200 ?
      options.chartSize / 2 + ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE_200[col.angle]
    : options.chartSize / 2 + ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE_400[col.angle];
  const numbersAdjust = options.chartSize <= 200 ?
      NUMBERS_ADJUST_200[col.angle]
    : NUMBERS_ADJUST_400[col.angle];

  return (
    <>
      <polyline
        key={`poly-axis-big-${i}`}
        points={points([
          [0, 0],
          [polarToX(col.angle, size), polarToY(col.angle, size)],
        ])}
        {...options.axisProps(col)}
        className="axis-big"
      />
      <text
        className="caption line-number"
        x={polarToX(col.angle, size / 2) + numbersAdjust[0].x}
        y={polarToY(col.angle, size / 2) + numbersAdjust[0].y}
      >
         {i18n.t(`radar.score.1`)}
      </text>

      <text
        className="caption line-number"
        x={polarToX(col.angle, size / 3) + numbersAdjust[1].x}
        y={polarToY(col.angle, size / 3) + numbersAdjust[1].y}
      >
        {i18n.t(`radar.score.2`)}
      </text>

      <text
        className="caption line-number"
        x={polarToX(col.angle, size / 4) + numbersAdjust[2].x}
        y={polarToY(col.angle, size / 4) + numbersAdjust[2].y}
      >
        {i18n.t(`radar.score.3`)}
      </text>
      <text
        className="caption line-number"
        x={polarToX(col.angle, size / 5) + numbersAdjust[3].x}
        y={polarToY(col.angle, size / 5) + numbersAdjust[3].y}
      >
        {i18n.t(`radar.score.4`)}
      </text>
      <text
        className="caption line-number"
        x={polarToX(col.angle, size / 6) + numbersAdjust[4].x}
        y={polarToY(col.angle, size / 6) + numbersAdjust[4].y}
      >
        {/* {i18n.t(`radar.score.5`)} */}
      </text>
    </>
  );
};
