export class Unit {
  
  id: string | number;
  siUnit: string | number;
  ipUnit: number | string;
  conversionFactor: number;
  currentSystem: string | number; 

  constructor({
    id,
    siUnit,
    ipUnit,
    conversionFactor,
    currentSystem,
  }: {
    id: string | number;
    siUnit: string | number;
    ipUnit: string | number;
    conversionFactor: string | number;
    currentSystem: string | number;
  }) {
    this.id = id;
    this.siUnit = siUnit;
    this.ipUnit = ipUnit;
    if (typeof conversionFactor === "string") {
      this.conversionFactor = parseFloat(conversionFactor);
    } else {
      this.conversionFactor = conversionFactor;
    }
    this.currentSystem = currentSystem;
  }
}
