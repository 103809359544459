import React from 'react';
import { useParams } from 'react-router';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { Box, Divider, FlexRow, P } from 'src/ui';
import {
  DaylightQuantityJsonDefinition,
  DaylightRoomsJsonDefinition,
  NotUsedRoomsJsonDefinition,
} from '../../../data/quantitative/daylight_json_definition';
import {
  getVariantCaseNumber,
  useWithVariantCaseIndex,
} from '../use_with_variantcase_index';

import { useListOfRooms } from '../use_rooms_list';
import { ReUseIcon, TrashIcon } from 'src/ui/elements/icons';
import { appColors } from 'src/app/theme';

export const RoomDaylight: React.FC<any> = ({ variantcase, index, remove, roomIsUsed }) => {
  const Json = new DaylightRoomsJsonDefinition(variantcase, index);

  if (!roomIsUsed(index,1)) return <> </>;

  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        <FlexRow
          mt={4}
          ml={1}
          py={2}
          alignSelf="self-start"
          as="button"
          onClick={() => remove(index, 1)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexRow>
      </FlexRow>
    </>
  );
};


export const NotUsedRoom: React.FC<any> = ({ variantcase, index, bringback, usedfortype, getRoomName, roomIsUsed }) => {

  if (roomIsUsed(index, usedfortype)) return <> </>;

  return (
    <>
     
        <P
          mr="20px"
          as="button"
          color={appColors.primary.main}
          onClick={() => bringback(index, usedfortype)}
        >
            
            {getRoomName(index)}
            {/* <ReUseIcon /> */}
        </P>

    </>
  );
};

export const DaylightQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(DaylightQuantityJsonDefinition);
  
  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { rooms, removeThisRoom, bringBackThisRoom, getRoomName, roomIsUsed, notUsedRoomsDefined } = useListOfRooms(variantcaseIndex);
  const mappingRooms = [...(Array(rooms).keys() as any)];

  const deviderDefinition = new NotUsedRoomsJsonDefinition();

  return (
    <>
      <FormBuilder jsonKeys={keys} />
      {/* <Divider my={2} /> */}
      <Box my={-2}>
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <RoomDaylight key={rooms} variantcase={variantcaseIndex} index={a} remove={removeThisRoom} roomIsUsed={roomIsUsed}/>
          </AnimateList>
        ))}
      </Box>

      {notUsedRoomsDefined(1) ? (
        <>
        <FormBuilder jsonKeys={deviderDefinition.keys} />
        <Box my={-2}>
          <P color={appColors.gray.light}>Bring back room:</P>
          {mappingRooms.map((a, i) => (
              <NotUsedRoom key={rooms} variantcase={variantcaseIndex} index={a} bringback={bringBackThisRoom} usedfortype={1} getRoomName={getRoomName} roomIsUsed={roomIsUsed}/>
          ))}
        </Box>
        </>
      ) : (
        <></>
      )} 
    </>
  );
};

export default DaylightQuantityPage;
