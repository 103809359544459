import _ from 'lodash';
import { TextProps } from '@chakra-ui/core';
import React, { useContext } from 'react';
import { appColors, appFontSizes } from 'src/app/theme';
import { FlexCol, FlexRow, P } from '..';
import { Icon } from '../elements/icons';
import { FormGridItem } from './grid_items';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { roundDecimals } from 'src/features/calculation/domain/sub_calculations/helper_calculations';
import { i18n } from 'src/features/shared/translations/translate';

export const Title = ({
  title,
  textProps,
  styleType,
  gridColSpan,
  isAbsoluteTitle = false,
  IconComponent,
  titleValue
}: {
  fontSize?: typeof appFontSizes;
  textProps?: TextProps;
  title: string;
  styleType?: number;
  gridColSpan?: number;
  isAbsoluteTitle?: boolean;
  titleValue?: string;
  IconComponent: React.FC<any>;
}) => {
  const {
    globalJson,
  } = useContext(JsonContext);
  const absoluteProps = isAbsoluteTitle
    ? { position: 'absolute', top: 4 }
    : ({} as any);
  const stylemt = styleType === 1 ? 5 : styleType === 2 ? 0 : 3;
  const stylemb = styleType === 1 ? 1.5 : styleType === 2 ? 0 : 4;

  const titelkey = titleValue;
  const additionalValue = !titelkey ? null : _.get(globalJson, titelkey);


  return (
    <FormGridItem {...absoluteProps} colSpan={gridColSpan || 6} mt={stylemt} mb={stylemb} >
      <FlexRow alignItems="center">
        {IconComponent ? (
          <FlexCol mr={2}>
            <Icon stroke={textProps?.color || (appColors.primary.dark as any)}>
              <IconComponent width={24} height={24} />
            </Icon>
          </FlexCol>
        ) : null}
        <P
          fontWeight={styleType === 1 ? 'bold' : styleType === 2 ? 'bold' : 'bold'}
          color={
            textProps?.color ||
              styleType === 1
              ? appColors.primary.dark
              : styleType === 2
                ? appColors.primary.main
                : appColors.primary.dark
          }
          fontSize={textProps?.fontSize || styleType === 1 ? appFontSizes.body : styleType === 2 ? appFontSizes.h4 : appFontSizes.h2}
        >
          {title}
        </P>


        {additionalValue ? (
          <FlexCol ml={5} textAlign={'right'}>
            <P
              fontSize={appFontSizes.h3}
              fontWeight={'bold'}
              color={appColors.primary.main}
            >
              {additionalValue >= 5 ? i18n.t(`radar.out`) : roundDecimals(additionalValue, 1)}
            </P>

          </FlexCol>
        ) : null}
      </FlexRow>
    </FormGridItem>
  );

};
