import {
  Input as BaseTextInput,
  Input as BaseNumberInput,
  InputGroup,
  InputProps,
  NumberInputProps,
  InputRightElement,
  Textarea as BaseTextarea,
  TextareaProps,
} from '@chakra-ui/core';

import { Select as BaseSelect, SelectProps } from '@chakra-ui/core';
import React from 'react';
import { Unit } from 'src/features/shared/form/domain/unit';
import { appFontSizes, appColors } from '../../app/theme';
import { unitConvertToIP } from '../calc/unitconverter';
import { Box, FlexCol } from '../structure';
import { SelectIcon } from './icons';
import { P } from './typography';

export type SelectWithLabelProps = {
  label: string;
  placeholder: string;
  value: string;
  onChange: SelectProps['onChange'];
  hasError?: boolean;
  errorText?: string;
  options: {
    value: any;
    label: string;
  }[];
  valuetext: boolean
} & SelectProps;

export const SelectWithLabel: React.FC<SelectWithLabelProps> = ({
  placeholder,
  label,
  value,
  onChange,
  hasError,
  errorText,
  options,
  ...selectProps
}) => (
  <FlexCol flex={1}>
    <P
      style={{ whiteSpace: 'nowrap' }}
      fontSize={appFontSizes.small}
      textAlign="left"
      mb="4px"
    >
      {label}
    </P>
    <InputGroup>
      <BaseSelect
        icon={<div />}
        iconSize="80px!important"
        css={{ width: '100%', paddingLeft: 8 }}
        backgroundColor={appColors.white}
        borderRadius="8px"
        focusBorderColor="primary.500"
        focusBorderWidth="1px"
        errorBorderColor="shinyRed.500"
        // _hover={{ borderColor: 'primary.500' }}
        fontWeight={'Regular'}
        fontSize={appFontSizes.body}
        borderColor="gray.500"
        height="42px"
        borderWidth="1px"
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        isinvalid={hasError}
        {...selectProps}
      >
        {options.map((option) => (
          <option value={option.value} selected={value == option.value ? true : false}>
            {option.label}
          </option>
        ))}
      </BaseSelect>

      <InputRightElement
        pointerEvents="none"
        width={12}
        children={
          <P mt="22px" l="4px" color={appColors.gray.light2}>
            <SelectIcon />
          </P>
        }
      />
    </InputGroup>

    {hasError && errorText && <P color="shinyRed.500">{errorText}</P>}
  </FlexCol>
);

export type TextInputWithLabelProps = {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: InputProps['onChange'];
  hasError?: boolean;
  errorText?: string;
  insideLabel?: string;
  styleType?: number;
  preLabel?: string;
} & InputProps;

export const TextInputWithLabel: React.FC<TextInputWithLabelProps> = ({
  placeholder,
  label,
  value,
  type,
  onChange,
  hasError,
  errorText,
  insideLabel,
  styleType,
  preLabel,
  ...inputProps
}) => (
  <FlexCol flex={1} position="relative">
    <P
      style={{ whiteSpace: 'nowrap' }}
      fontSize={appFontSizes.small}
      textAlign="left"
      mb="4px"
    >
      {preLabel} {label}
    </P>
    <InputGroup justify="center">
      <BaseTextInput
        textAlign={styleType === 1 ? 'left' : styleType === 2 ? 'right' : 'left'}
        backgroundColor={appColors.white}
        borderRadius="8px"
        focusBorderColor={appColors.primary.main}
        focusBorderWidth="1px"
        errorBorderColor="red.500"
        fontSize={styleType === 1 ? appFontSizes.body : styleType === 2 ? appFontSizes.h4 : appFontSizes.body}
        fontWeight={styleType === 1 ? 'bold' : styleType === 2 ? 'bold' : ''}
        color={
          styleType === 1
            ? appColors.primary.dark
            : styleType === 2
              ? appColors.primary.main
              : ''
        }
        borderColor="gray.500"
        px={styleType === 1 ? '0px' : styleType === 2 ? '0px' : '13px'}
        height="42px"
        borderWidth="1px"
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        isinvalid={hasError}
        {...inputProps}
      ></BaseTextInput>
      {insideLabel ? (
        <InputRightElement
          pointerEvents="none"
          width="auto"
          children={
            <P mt="22px" mr="-18px" color={appColors.gray.light}>
              {insideLabel}
            </P>
          }
        />
      ) : null}
    </InputGroup>

    {hasError && errorText && (
      <Box mt={1}>
        <P color="red.500">{errorText}</P>
      </Box>
    )}
  </FlexCol>
);


export type NumberInputWithLabelProps = {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: InputProps['onChange'];
  hasError?: boolean;
  errorText?: string;
  insideLabel?: string;
  styleType?: number;
  preLabel?: string;
  unit?: Unit;
} & InputProps;

export const NumberInputWithLabel: React.FC<NumberInputWithLabelProps> = ({
  placeholder,
  label,
  value,
  type,
  onChange,
  hasError,
  errorText,
  insideLabel,
  styleType,
  preLabel,
  unit,
  ...inputProps
}) => (
  <FlexCol flex={1} position="relative">
    <P
      style={{ whiteSpace: 'nowrap' }}
      fontSize={appFontSizes.small}
      textAlign="left"
      mb="4px"
    >
      {preLabel} {label}
    </P>
    
    <InputGroup justify="center">
      <BaseNumberInput
        textAlign={styleType === 1 ? 'left' : styleType === 2 ? 'right' : 'left'}
        backgroundColor={appColors.white}
        borderRadius="8px"
        focusBorderColor={appColors.primary.main}
        focusBorderWidth="1px"
        errorBorderColor="red.500"
        fontSize={styleType === 1 ? appFontSizes.body : styleType === 2 ? appFontSizes.h4 : appFontSizes.body}
        fontWeight={styleType === 1 ? 'bold' : styleType === 2 ? 'bold' : ''}
        color={
          styleType === 1
            ? appColors.primary.dark
            : styleType === 2
              ? appColors.primary.main
              : ''
        }
        borderColor="gray.500"
        px={styleType === 1 ? '0px' : styleType === 2 ? '0px' : '13px'}
        height="42px"
        borderWidth="1px"
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        isinvalid={hasError}
        {...inputProps}
      > 
      </BaseNumberInput>
      {insideLabel ? (
        <InputRightElement
          pointerEvents="none"
          width="auto"
          children={
            <P mt="22px" mr="-18px" color={appColors.gray.light}>
              {insideLabel}
            </P>
          }
        />
      ) : null}
    </InputGroup>

    {hasError && errorText && (
      <Box mt={1}>
        <P color="red.500">{errorText}</P>
      </Box>
    )}
  </FlexCol>
);




export type TextAreaInputWithLabelProps = {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: TextareaProps['onChange'];
  hasError?: boolean;
  errorText?: string;
  insideLabel?: string;
  styleType?: number;
  preLabel?: string;
} & TextareaProps;

export const TextAreaInputWithLabel: React.FC<TextAreaInputWithLabelProps> = ({
  placeholder,
  label,
  value,
  type,
  onChange,
  hasError,
  errorText,
  insideLabel,
  styleType,
  preLabel,
  ...inputProps
}) => (
  <FlexCol flex={1} position="relative">
    <P
      style={{ whiteSpace: 'nowrap' }}
      fontSize={appFontSizes.small}
      textAlign="left"
      mb="4px"
    >
      {preLabel} {label}
    </P>
    <InputGroup justify="center">
      <BaseTextarea
        backgroundColor={appColors.white}
        borderRadius="8px"
        focusBorderColor={appColors.primary.main}
        focusBorderWidth="1px"
        errorBorderColor="red.500"
        fontSize={styleType === 1 ? appFontSizes.body : styleType === 2 ? appFontSizes.h4 : appFontSizes.body}
        fontWeight={styleType === 1 ? 'bold' : styleType === 2 ? 'bold' : ''}
        color={
          styleType === 1
            ? appColors.primary.dark
            : styleType === 2
              ? appColors.primary.main
              : ''
        }
        borderColor="gray.500"
        px={styleType === 1 ? '0px' : styleType === 2 ? '0px' : '13px'}
        height="42px"
        borderWidth="1px"
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        isinvalid={hasError}
        {...inputProps}
      >
      </BaseTextarea>
      {insideLabel ? (
        <InputRightElement
          pointerEvents="none"
          width="auto"
          children={
            <P mt="25px" mr="-15px" color={appColors.gray.light2}>
              {insideLabel}
            </P>
          }
        />
      ) : null}
    </InputGroup>

    {hasError && errorText && (
      <Box mt={1}>
        <P color="red.500">{errorText}</P>
      </Box>
    )}
  </FlexCol>
);
