import { theme as baseTheme } from '@chakra-ui/core';

export const appColors = {
  ...baseTheme.colors,
  primary: {
    main: '#88B854',
    light: '#5B9045',
    dark: '#346634',
  },
  borders: {
    100: '#EEEEEE',
  },
  secondary: {
    dark: '#2D342E',
    light: '#E3E6DD',
    500: 'blue',
    50: 'blue',
  },
  gray: {
    50: '#363E36',
    500: '#718096',
    light: '#A1A4AB',
    light2: '#A1A4AB',
    lightest: '#E9EAEC',
    dark: '#7C7F87',
  },
  white: '#FFFFFF',
  black: '#2D342E',
  success: '#C0D05A',
  green: {
    ...baseTheme.colors.green,
    50: '#88B854',
  },
  red: {
    ...baseTheme.colors.red,
    50: '#CE0057',
  },
  mainschema: {
    ...baseTheme.colors.green,
    50: '#88B854',
    100: '#88B854',
    200: '#88B854',
    300: '#88B854',
    400: '#88B854',
    500: '#88B854',  // used in checkbox
    600: '#88B854',
    700: '#88B854',
    800: '#88B854',
    900: '#88B854',
  },
  secondaryschema: {
    ...baseTheme.colors.green,
    50: '#346634',
    100: '#346634',
    200: '#346634',
    300: '#346634',
    400: '#346634',
    500: '#346634',  // used in checkbox
    600: '#346634',
    700: '#346634',
    800: '#346634',
    900: '#346634',
  },
};
