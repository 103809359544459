import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { FreshwaterConsumptionIcon } from 'src/ui/elements/icons';

export class FreshwaterQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_fw`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: FreshwaterConsumptionIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_fwsc`,
        },
      },

      { key: `aht_qfw1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqfw1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqfw1c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qfw2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqfw2` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqfw2c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qfw3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqfw3` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqfw3c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
