export class WeightedRoomScore {
  weightedScore: number;
  subTotal: number;
  sectionOk: boolean;
  roomOk: boolean;

  constructor({ roomScore, hours, defhours, countOfPeople, roomUsed }: { roomScore?: number, hours?: number, defhours?: number, countOfPeople?: number, roomUsed?: boolean }) {
    if (!roomScore || !countOfPeople) {
      this.weightedScore = NaN;
      this.subTotal = NaN;
      this.sectionOk = false;
      this.roomOk = !countOfPeople ? false : true;
      return;
    }
    const usedhours = hours ? hours : defhours ? defhours : NaN;
    
    if (roomUsed) {
      this.weightedScore = roomScore * usedhours * countOfPeople;
      this.subTotal = usedhours * countOfPeople;
      this.sectionOk = true;
    } else {
      this.weightedScore = 0;
      this.subTotal = 0;
      this.sectionOk = true;
    }

    this.roomOk = hours ? true : defhours ? true : false;
    this.sectionOk = hours ? true : defhours ? true : false;
  }
}


