import _ from 'lodash';
import { ComfortCalculations } from './sub_calculations/comfort_calculations';
import { Calculation } from './calculation';
import { calculationErrors } from './calculation_errors';
import { GlobalCalculations } from './sub_calculations/global_project_update';
import { EnvironmentCalculations } from './sub_calculations/environment_calculations';
import { EnergyCalculations } from './sub_calculations/energy_calculations';
import { GlobalRemoveObjects } from './sub_calculations/filter_removed_objects';
import { FinalCalculations } from './sub_calculations/final_calculations';
import { showWidgetLauncher } from 'src/features/help/application/freshworks_widget';

export class ActiveHouseCalculation implements Calculation {
  draftJson: any;
  globalRemove: GlobalRemoveObjects;
  globalCalculations: GlobalCalculations;
  comfortCalculations: ComfortCalculations;
  environmentCalculations: EnvironmentCalculations;
  energyCalculations: EnergyCalculations;
  finalCalculations: FinalCalculations;

  public constructor({
    draftJson,
    globalRemove,
    globalCalculations,
    comfortCalculations,
    environmentCalculations,
    energyCalculations,
    finalCalculations,
  }: any) {
    this.draftJson = draftJson;

    if (globalRemove) {
      this.globalRemove = globalRemove;
    } else {
      this.globalRemove = new GlobalRemoveObjects({
        i: undefined,
        draftJson,
      });
    }

    if (globalCalculations) {
      this.globalCalculations = globalCalculations;
    } else {
      this.globalCalculations = new GlobalCalculations({
        i: undefined,
        draftJson,
      });
    }

    if (comfortCalculations) {
      this.comfortCalculations = comfortCalculations;
    } else {
      this.comfortCalculations = new ComfortCalculations({
        i: undefined,
        draftJson,
      });
    }

    if (environmentCalculations) {
      this.environmentCalculations = environmentCalculations;
    } else {
      this.environmentCalculations = new EnvironmentCalculations({
        i: undefined,
        draftJson,
      });
    }

    if (energyCalculations) {
      this.energyCalculations = energyCalculations;
    } else {
      this.energyCalculations = new EnergyCalculations({
        i: undefined,
        draftJson,
      });
    }

    if (finalCalculations) {
      this.finalCalculations = finalCalculations;
    } else {
      this.finalCalculations = new FinalCalculations({
        i: undefined,
        draftJson,
      });
    }
  }

  defineProgVersion() {
    this.draftJson.progVers = 'ActiceHouseCalculation';
  }

  updateProjdState() {
    _.set(this.draftJson, 'projD.tbState', 0);
    _.set(this.draftJson, 'ahstate.ahr_pjstate', 'success');
  }

  calculateRemove(i: number) {
    const { draftJson } = this;

    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;
    this.globalRemove.i = i;
    this.globalRemove.draftJson = { ...draftJson };
    this.draftJson = this.globalRemove.calculate();
  }

  calculaleGlobal(i: number) {
    const { draftJson } = this;

    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;
    this.globalCalculations.i = i;
    this.globalCalculations.draftJson = { ...draftJson };
    this.draftJson = this.globalCalculations.calculate();
  }

  calculaleComfort(i: number) {
    const { draftJson } = this;

    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;
    this.comfortCalculations.i = i;
    this.comfortCalculations.draftJson = { ...draftJson };
    this.draftJson = this.comfortCalculations.calculate();
  }

  calculateEnergy(i: number) {
    const { draftJson } = this;

    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;
    this.energyCalculations.i = i;
    this.energyCalculations.draftJson = { ...draftJson };
    this.draftJson = this.energyCalculations.calculate();
  }

  calculateEnvironment(i: number) {
    const { draftJson } = this;

    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;
    this.environmentCalculations.i = i;
    this.environmentCalculations.draftJson = { ...draftJson };
    this.draftJson = this.environmentCalculations.calculate();
  }

  calculateFinal(i:number) {
    const { draftJson } = this;

    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;
    this.finalCalculations.i = i;
    this.finalCalculations.draftJson = { ...draftJson };
    this.draftJson = this.finalCalculations.calculate();
  }

  calculate() {
    const { draftJson } = this;

    this.defineProgVersion();
    this.updateProjdState();

    const showHelpRightBottom = _.get(draftJson, 'ahsettings.ahhlp'); //
    showWidgetLauncher(!showHelpRightBottom);

    const lVariantNumber = _.size(_.get(draftJson, 'lVariant')); //
    const mapping = [...(Array(lVariantNumber).keys() as any)];

    try {
      mapping.forEach((i: number) => {
        this.calculateRemove(i);
        this.calculaleGlobal(i);
        this.calculaleComfort(i);
        this.calculateEnergy(i);  // energy must before environment
        this.calculateEnvironment(i);
        this.calculateFinal(i);
      });

      return this.draftJson;
    } catch (error) {
      throw error;
    }
  }

  onlySetGlobal() {
    const { draftJson } = this;

    this.defineProgVersion();
    this.updateProjdState();

    const showHelpRightBottom = _.get(draftJson, 'ahsettings.ahhlp'); //
    showWidgetLauncher(!showHelpRightBottom);

    const lVariantNumber = _.size(_.get(draftJson, 'lVariant')); //
    const mapping = [...(Array(lVariantNumber).keys() as any)];

    try {
      mapping.forEach((i: number) => {
        // this.calculateRemove(i);
        this.calculaleGlobal(i);
        // this.calculaleComfort(i);
        // this.calculateEnergy(i);  // energy must before environment
        // this.calculateEnvironment(i);
        this.calculateFinal(i);
      });

      return this.draftJson;
    } catch (error) {
      throw error;
    }
  }

  calculateWithoutEnvironment() {
    const { draftJson } = this;

    this.defineProgVersion();
    this.updateProjdState();

    const showHelpRightBottom = _.get(draftJson, 'ahsettings.ahhlp'); //
    showWidgetLauncher(!showHelpRightBottom);

    const lVariantNumber = _.size(_.get(draftJson, 'lVariant')); //
    const mapping = [...(Array(lVariantNumber).keys() as any)];

    try {
      mapping.forEach((i: number) => {
        this.calculateRemove(i);
        this.calculaleGlobal(i);
        this.calculaleComfort(i);
        this.calculateEnergy(i);  // energy must before environment
        // this.calculateEnvironment(i);
        this.calculateFinal(i);
      });

      return this.draftJson;
    } catch (error) {
      throw error;
    }
  }

}
