import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { i18n } from 'src/features/shared/translations/translate';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from 'src/ui/tabs';
import { ContactsPage } from './contacts';
import { ProjectInformationPage } from './project_information';

export const ProjectInformationTabsPage = ({
  tabsi,
}: any) => {

  const { replace } = useHistory();
  const { pathname } = useLocation();

  const onChange = (index: number) => {
    if (index === 0) {
      replace(pathname.replace('contacts', 'main'));
    } else {
      replace(pathname.replace('main', 'contacts'));
    } 
  };

  const defaultIndex = pathname.includes('general') ? 0 : 1;


  return (
    <Tabs
      pt={12}
      isLazy
      defaultIndex={tabsi}
      onChange={(index: number) => onChange(index)}
    >
      <TabList>
        <Tab>{i18n.t(`tabs.projectmain`)}</Tab>
        <Tab>{i18n.t(`tabs.contacts`)}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0} py={0}>
          <ProjectInformationPage />
        </TabPanel>
        <TabPanel px={0} py={0}>
          <ContactsPage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};