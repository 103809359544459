import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';

export const useNumberOfConstructionLayers = (
  indexConstruction: number,
  facade = globalJsonFacade
) => {
  const [layers, setLayers] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForConstructions = _.get(
    globalJson,
    `lAssembly`
  );

  const draftJsonForLayer = draftJsonForConstructions
    ? _.get(draftJsonForConstructions[indexConstruction], `lLayer`)
    : null;

  const numberOfLayer = _.size(draftJsonForLayer);

  const removeLayer = (indexConstruction: number, indexLayer: number) => {
    const newDraftJsonForLayer = [...(draftJsonForLayer as any)];

    const filtered = newDraftJsonForLayer.filter(function (value, i, arr) {
      return i !== indexLayer;
    });

    const newDraftJson = _.set(
      globalJson,
      `lAssembly.[${indexConstruction}].lLayer`,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftForLayers = _.get(newDraftJson, `lAssembly`);
      const numberOfLayer = _.size(draftForLayers);
      setLayers(numberOfLayer);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addLayer = () => {
    setLayers(layers + 1);

    _.set(
      globalJson,
      `lAssembly.[${indexConstruction}].lLayer.[${layers}].eco.aHshare`,
      "100"
    );

    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  useEffect(() => {
    setLayers(numberOfLayer);
  }, [numberOfLayer]);

  return {
    layers,
    addLayer: addLayer,
    removeLayer,
  };
};
