import { JsonDefinition } from 'src/features/shared/form/data/json_definition';

export class SystemsElectricitySupplyTitleJsonDefinition implements JsonDefinition {
    keys: any;
  
    constructor() {
      this.keys = [
        { 
          key: 'ahh_sy',
          extraOptions: { 
            isAbsoluteTitle: true, 
            // IconComponent: DaylightIcon,
           }, 
      }];
    }
  }

  export class SystemsElectricitySupplyJsonDefinition implements JsonDefinition {
    keys: any;
  
    constructor(variantcaseIndex = 0, index: number) {
      this.keys = [
        {
          key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceTerenw`,
          extraOptions: { gridColSpan: 2 },
        },
        {
          key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahelprodpef`,
          extraOptions: { gridColSpan: 2, 
            placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe` },
        },
      ];
    }
  }
  