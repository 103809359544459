import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';

export const useListOfAreas = (indexVariant: number) => {
  const [areas, setAreas] = useState(0);
  const { globalJson } = useContext(JsonContext);

  // may change ROOM_DEFINITION_PATH to the current variantCase. but then, the rooms have to be set for all variant cases before.
  const draftJsonForAreas = _.get(globalJson, `lVariant.[${indexVariant}].building.lComponent`);

  const numberOfAreas = _.size(draftJsonForAreas);

  useEffect(() => {
    setAreas(numberOfAreas);
  }, [numberOfAreas]);

  return {
    areas,
  };
};
