import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { ProjectIcon } from 'src/ui/elements/icons';

export class SettingsJsonDefinition implements JsonDefinition {
  keys = [
    {
      key: 'ahh_set',
      // extraOptions: {
      //   IconComponent: ProjectIcon,
      //   isAbsoluteTitle: true,
      // }
    },
    { key: `aht_intf` },

    { key: `ahsettings.ahhlp` },
    { key: 'ahsettings.ah_lang' },
    { key: 'ahsettings.ah_unit' },

    { key: `aht_nation` },
    
    { key: `ahsettings.ahnat` },
    // { key: `empty`, extraOptions: { gridColSpan: 3 } },
    {
      key: 'ahsettings.ActiveNation.ahr_dlsc',
      extraOptions: {
        gridColSpan: 2
      } },
    { key: 'ahsettings.ActiveNation.ahr_tesc',
    extraOptions: {
      gridColSpan: 2
    } },
    { key: 'ahsettings.ActiveNation.ahr_iasc',
    extraOptions: {
      gridColSpan: 2
    } },
    { key: 'ahsettings.ActiveNation.ahr_aqsc',
    extraOptions: {
      gridColSpan: 2
    } },
    { key: 'ahsettings.ActiveNation.ahr_edsc',
    extraOptions: {
      gridColSpan: 2
    } },
    { key: 'ahsettings.ActiveNation.ahr_essc',
    extraOptions: {
      gridColSpan: 2
    } },
    { key: 'ahsettings.ActiveNation.ahr_pesc',
    extraOptions: {
      gridColSpan: 2
    } },
    { key: 'ahsettings.ActiveNation.ahr_fwsc',
    extraOptions: {
      gridColSpan: 2
    } },
    { key: 'ahsettings.ActiveNation.ahr_scsc',
    extraOptions: {
      gridColSpan: 2
    } },

  ];
}


