import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { EnergyDemandIcon } from 'src/ui/elements/icons';

export class ElectricityDemandQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_ecd`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: EnergyDemandIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_edsc`,
        },
      },

      {
        key: `aht_auxen`,
        extraOptions: {
          styleType: 1,
          gridColSpan: 2,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edsaux`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edpesaux`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 2 } },
      // {
      //   key: `lVariant.${variantcaseIndex}.Active.ahr_edsaux`,
      //   extraOptions: { disabled:true, gridColSpan: 2, roundDecimal: 2 },
      // },
      // {
      //   key: `lVariant.${variantcaseIndex}.Active.ahr_edpefsaux`,
      //   extraOptions: { disabled:true, gridColSpan: 1, roundDecimal: 2 },
      // },
      // {
      //   key: `lVariant.${variantcaseIndex}.Active.ahr_edpesaux`,
      //   extraOptions: { disabled:true, gridColSpan: 1, roundDecimal: 2 },
      // },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `aht_devi`,
        extraOptions: {
          styleType: 1,
          gridColSpan: 2,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_eddev`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edpedev`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
    ];
  }
}

export class ElectricityDemandSytemsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahelDev`,
        extraOptions: { gridColSpan: 2, disabled: true },
      },

      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.aheldem`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.aheldempef`,
        extraOptions: { gridColSpan: 1, disabled: true, 
          placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe` },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_pesum`,
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },
    ];
  }
}
