import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { EnergyDemandQualityJsonDefinition } from '../../../data/qualitative/energydemand_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const EnergyDemandQualityPage: React.FC = () => {
  const energydemandjson = useWithVariantCaseIndex(
    EnergyDemandQualityJsonDefinition
  );

  return <FormBuilder jsonKeys={energydemandjson.keys} />;
};

export default EnergyDemandQualityPage;
