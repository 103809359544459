import _ from 'lodash';
import { Box, Checkbox, useToast } from '@chakra-ui/core';
import React, { useContext, useEffect } from 'react';
import { appColors, appFontSizes } from 'src/app/theme';
import { UnderlineOnHover } from 'src/features/help/presentation/animations';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { ButtonBase, FlexCol, FlexRow, globalButtonProps, P } from 'src/ui';
import { ProjectUploader } from 'src/ui/elements/file_upload';
import { ExternalLink, ResultsIcon } from 'src/ui/elements/icons';
import { Title } from 'src/ui/form_elements/title';
import { Radar } from './Radar';
import { definitionsJson } from 'src/features/shared/json/data/source/active.def';
import { i18n } from 'src/features/shared/translations/translate';
import { onPrintButton } from '../application/print_report';
import { useRadarData } from '../application/use_radar_data';
import { ProjectInformationPage } from 'src/features/project_information/presentation/pages/project_information';


export const RadarLegend = ({ radarData, visibleData, setVisibleData }: any) => {
  const { globalJson, onNewJson, onLoadJson, onMergeJsonWithGlobalJson, onSaveKeyToGlobalDirectly } = useContext(JsonContext);
  const toast = useToast();

  return (
    <FlexRow
      width="100%"
      mt={12}
      alignSelf="flex-start"
      justifyContent="space-around"
      px={0}
    >
      <div id="noprint">
        {drawLegend(radarData, globalJson, onSaveKeyToGlobalDirectly)}
      </div>
      <FlexCol id="noprint">
        <P mb={1} fontWeight="bold" color={appColors.primary.dark}>
          {i18n.t(`pages.project`)}
        </P>
        <UnderlineOnHover borderColor={appColors.primary.main}>
          <P
            fontSize={appFontSizes.small}
            color={appColors.primary.main}
            as="a"
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify(globalJson, 0 as any, 4)
            )}`}
            download="activeHouse.json"
          >
            <FlexRow alignItems="center">
              <ExternalLink style={{ marginRight: 8, height: 30, width: 20 }} />
              {i18n.t(`pages.exportproject`)}
            </FlexRow>
          </P>
        </UnderlineOnHover>
        <UnderlineOnHover borderColor={appColors.primary.main}>
          <P
            fontSize={appFontSizes.small}
            color={appColors.primary.main}
            as="a"
            href=""
            onClick={() => {
              if (window.confirm(i18n.t(`pages.confirmnewproject`))) {
                onNewJson();
              }
            }
            }
          >
            <FlexRow alignItems="center">
              <ExternalLink style={{ marginRight: 8, height: 30, width: 20 }} />
              {i18n.t(`pages.newproject`)}
            </FlexRow>
          </P>
        </UnderlineOnHover>

        <ProjectUploader
          onUpload={(ReadFile) => {
            const reader = new FileReader()

            if (ReadFile[0] != null) {
              reader.readAsText(ReadFile[0]);
            }
            else {

            }

            reader.onload = () => {
              const textFromFile = reader.result;
              if ("string" == typeof textFromFile) {
                const obj = JSON.parse(textFromFile);
                if (obj) {
                  onNewJson();
                  onLoadJson(obj);
                }
                else {
                  alert(i18n.t(`pages.notvaliedproject`));
                }
              }
              else {
                alert(i18n.t(`pages.notvaliedproject`));
              }
            }
            reader.onerror = function () {
              alert(i18n.t(`pages.notvaliedproject`));
            };
          }}
          onUploadFailed={() => {
            toast({
              // title: "Reject file",
              // description: "Sorry, wrong file type, or file size",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
              render: () => (
                <Box color="white" p={3} bg="red.500">
                  <P>{i18n.t(`form.filereject`)}</P>
                </Box>
              ),
            })
          }}
        />
      </FlexCol>
    </FlexRow>
  );
};




export function drawLegend(radarData: any, globalJson: any, onSaveKeyToGlobalDirectly: any) {
  return <FlexCol maxWidth="160px">
    <P fontWeight="bold" color={appColors.primary.dark}>
      {i18n.t(`pages.radarcases`)}
    </P>
    {radarData.map((r: any, i: number) => (
      <P><Checkbox
        iconSize="1rem"
        spacing="1rem"
        size="lg"
        colorScheme={i > 0 ? 'secondaryschema' : 'mainschema'}
        color={appColors.gray.dark}
        mt={1}
        mx={1}
        // defaultIsChecked
        // isChecked={visibleData[r.name]}
        isChecked={_.get(globalJson, `lVariant[${i}].Active.ahshowradar`) > 0 ? true : false}
        onChange={() => {
          const showInRadar = _.get(globalJson, `lVariant[${i}].Active.ahshowradar`);
          onSaveKeyToGlobalDirectly.callback(showInRadar > 0 ? 0 : 1, `lVariant[${i}].Active.ahshowradar`);
        }}
      >
        <P fontSize={appFontSizes.body}>{checkboxVariantName(globalJson, i, r, 20)}</P>
      </Checkbox></P>
    ))}
  </FlexCol>;
}

export function checkboxVariantName(globalJson: any, i: number, r: any, maxlength: number): React.ReactNode {
  const namefromproject = _.get(globalJson, `lVariant[${i}].n`);
  const usedname = namefromproject ? namefromproject : i > 0 ? "Case " + (i + 1) : r.name;
  const breakname = usedname.length > maxlength ? usedname.slice(0, maxlength).concat('...') : usedname;
  // var breakname = "";
  // if (usedname.lenght > maxlength)
  // {
  //   var array = usedname.split('');
  //   var newtext = '';
  //   for(var i=0;i<array.length;i++) {
  //     breakname +=array[i];
  //   if (i % maxlength == 0 && i>1) {
  //     breakname += '\n';//or \n\r
  //       }
  //   }
  // }
  // else
  // {
  //   breakname = usedname;
  // }
  return breakname;
}

