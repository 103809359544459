import React from "react";
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { TargetsJsonDefinition } from "../data/Target_json";


// export const Target = () => <p>Target!</p>;


const targetsJson = new TargetsJsonDefinition();

export const Target: React.FC = () => {
  return <FormBuilder jsonKeys={targetsJson.keys} />;
};
