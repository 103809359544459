import React from 'react';
import { FormSelect } from 'src/ui/form_elements';
import _ from 'lodash';
import { DropdownField, FieldDefinition } from '../domain/field_definitions';
import { getNameOfDefinitionEnum } from '../utils/get_name_for_definition_enum';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';

export function createDropdownField({
  fieldDefinition,
  json,
  onUpdate,
  onSave,
  errors,
  type,
}: {
  fieldDefinition: DropdownField;
  json: any;
  onUpdate: DebouncedState<(value: any, field: any) => void>;
  onSave: (fieldDefinition: FieldDefinition) => void;
  errors: any;
  type: any;
}) {
  const key = fieldDefinition?.fieldData?.key;
  if (!key) return null;

  const value = _.get(json, key);

  let valueEnumText = undefined;

  const indexVar =
    fieldDefinition?.fieldData?.extraOptions?.indexVar || 0;
  const indexOne =
    fieldDefinition?.fieldData?.extraOptions?.indexOne;
  const indexTwo =
    fieldDefinition?.fieldData?.extraOptions?.indexTwo;

  const dropdownOptions =
    fieldDefinition?.fieldData?.dropdownOptionsFromJson &&
    fieldDefinition?.fieldData?.dropdownOptionsFromJson(json, indexVar, indexOne, indexTwo);

  if (dropdownOptions && fieldDefinition?.fieldData?.extraOptions?.disabled) {
    if (dropdownOptions.length > 0) {
      if (value == undefined ||value == null) {
        valueEnumText = "not assigned"; //"not assigned"
      } else {
        const filtered = dropdownOptions.filter(function (a, i) {
          if (a.value == value) {
            return a;
          }
        });
        valueEnumText = filtered[0].label;
      }
    }
  }
  else {
    if (fieldDefinition?.fieldData?.extraOptions?.disabled) {
      const name = getNameOfDefinitionEnum({
        partJson: json,
        key: key,
      });
      valueEnumText = name;
    }
  }

  return (
    <FormSelect
      ml={0}
      options={dropdownOptions || fieldDefinition.options}
      placeholder=""
      gridColSpan={fieldDefinition?.fieldData?.extraOptions?.gridColSpan}
      label={fieldDefinition.label}
      onChange={(e: any) => {
        onUpdate.callback(e.target.value, fieldDefinition);
      }}
      onBlur={() => onSave(fieldDefinition)}
      value={value}
      hasError={errors[key]}
      valuetext={valueEnumText}
      errorText={fieldDefinition?.fieldData?.error || 'error'}
    />
  );
}
