import _ from 'lodash';
import { Calculation } from '../calculation';
import { calculationErrors } from '../calculation_errors';

export class GlobalRemoveObjects implements Calculation {
  i: number;
  draftJson: any;

  public constructor({ i, draftJson }: any) {
    this.i = i;
    this.draftJson = { ...draftJson };
  }

  private filterIdRemovedVariant(filterJson: string) {
    const { draftJson, i } = this;
    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;

    // #################### filter areas 
    let currentIds = new Array();
    if (i > 0) {
      const globaldraftJsonForFilter = _.get(draftJson, `lVariant[0].${filterJson}`);
      if (globaldraftJsonForFilter) {
        for (var dJFF of globaldraftJsonForFilter) {  // globaly
          currentIds.push(_.get(dJFF, `id`));
        }
      }
    }
    // remove deleted areas for a case
    if (currentIds.length > 0 ) {
      const olddraftJsonNotFiltered = _.get(draftJson, `lVariant[${i}].${filterJson}`);
      if (olddraftJsonNotFiltered) {
        const filtered = olddraftJsonNotFiltered.filter((item: { id: any; }) => currentIds.includes(item.id));
        _.set(draftJson, `lVariant[${i}].${filterJson}`, filtered);
      }
    }
  }

  public calculate() {
    const { draftJson, i } = this;
    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;

    this.filterIdRemovedVariant('building.lComponent');
    this.filterIdRemovedVariant('building.lZone.[0].lRoom');
    this.filterIdRemovedVariant('HVAC.lSystem');

    const olddraftJsonFilteredSystems = _.get(draftJson, `lVariant[0].HVAC.lSystem`);
    for (let index in olddraftJsonFilteredSystems) {  // all systems
      this.filterIdRemovedVariant(`HVAC.lSystem.[${index}].lDevice`);
      this.filterIdRemovedVariant(`HVAC.lSystem.[${index}].lOperation`);
    }


    return draftJson;
  }




}
