import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from 'src/ui/tabs';

export const QualitatiteTabs = ({
  Qualitative,
}: any) => {
  const { pathname } = useLocation();

  const onChange = (index: number) => {
    // if (index === 0) {
    //   replace(pathname.replace('quantitative', 'qualitative'));
    // } else {
    //   replace(pathname.replace('qualitative', 'quantitative'));
    // }
  };

  const defaultIndex = pathname.includes('qualitative') ? 0 : 0;

  return (
    <Tabs
      isLazy
      pt={12}
      defaultIndex={defaultIndex}
      onChange={(index: number) => onChange(index)}
    >
      <TabList>
        <Tab>Qualitative</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>{Qualitative}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const QualitativePage = ({ name }: { name: string }) => {
  const Quality = useMemo(() => require(`./qualitative/${name}`).default, [
    name,
  ]);
  

  if (!Quality) return null;

  return (
    <>
      <QualitatiteTabs
        Qualitative={<Quality />}
      />
    </>
  );
};



export const Management = () => {
  return <QualitativePage name="management" />;
};

