import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { PrimaryEnergyDemandIcon } from 'src/ui/elements/icons';




export class PrimaryThermalDemandHeadlineJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, typeOfSystem: number) {
    switch (typeOfSystem) {
      case 1:
        this.keys = [
          {
            key: `aht_edspe`,
            extraOptions: {
              styleType: 1,
              gridColSpan: 4,
            },
          },
          {
            key: `lVariant.${variantcaseIndex}.Active.ahr_pedemtsum`,
            extraOptions: {
              disabled: true,
              noLabel: true,
              styleType: 2,
              gridColSpan: 2,
              roundDecimal: 2
            },
          },
          { key: `emptydivider`, extraOptions: { noLabel: true } },
        ];
        break;
      case 2:
        this.keys = [
         
          {
            key: `aht_ecdspe`,
            extraOptions: {
              styleType: 1,
              gridColSpan: 4,
            },
          },
          {
            key: `lVariant.${variantcaseIndex}.Active.ahr_edpetot`,
            extraOptions: {
              disabled: true,
              noLabel: true,
              styleType: 2,
              gridColSpan: 2,
              roundDecimal: 2
            },
          },
          { key: `emptydivider`, extraOptions: { noLabel: true } },
          {
            key: `aht_auxen`,
            extraOptions: {
              styleType: 4,
              gridColSpan: 2,
              preLabel: ""
            },
          },
          {
            key: `lVariant.${variantcaseIndex}.Active.ahr_edpesaux`,
            extraOptions: {
              disabled: true,
              gridColSpan: 2,
              roundDecimal: 2
            },
          },
        ];
        break;
        case 3:
        this.keys = [
          {
            key: `aht_esspe`,
            extraOptions: {
              styleType: 1,
              gridColSpan: 4,
            },
          },
          {
            key: `lVariant.${variantcaseIndex}.Active.ahr_espehd`,
            extraOptions: {
              disabled: true,
              noLabel: true,
              styleType: 2,
              gridColSpan: 2,
              roundDecimal: 2
            },
          },
          { key: `emptydivider`, extraOptions: { noLabel: true } },
        ];
        break;
        case 4:
        this.keys = [
          {
            key: `aht_esespe`,
            extraOptions: {
              styleType: 1,
              gridColSpan: 4,
            },
          },
          {
            key: `lVariant.${variantcaseIndex}.Active.ahr_espeel`,
            extraOptions: {
              disabled: true,
              noLabel: true,
              styleType: 2,
              gridColSpan: 2,
              roundDecimal: 2
            },
          },
          { key: `emptydivider`, extraOptions: { noLabel: true } },
        ];
        break;
    }
  }
}

export class PrimaryThermalDemandJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.n`,
        extraOptions: {
          disabled: true,
          gridColSpan: 2,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_pesum`,
        extraOptions: {
          disabled: true,
          gridColSpan: 1,
          roundDecimal: 2
        },
      },

    ];
  }
}


export class PrimaryElectricityDemandJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahelDev`,
        extraOptions: { gridColSpan: 2, disabled: true },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_pesum`,
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },
    ];
  }
}


export class PrimaryThermalSupplyJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceThrenw`,
        extraOptions: { gridColSpan: 2, disabled: true },
      },
      {
        key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahr_pesum`,
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },
    ];
  }
}


export class PrimaryElectricitySupplyJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceTerenw`,
        extraOptions: { gridColSpan: 2, disabled: true },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_pesum`,
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },
    ];
  }
}





export class PrimaryEnergyQuantityJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_pe`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: PrimaryEnergyDemandIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_pesc`,
        },
      },
      {
        key: `aht_tped`,
        extraOptions: {
          styleType: 1,
          gridColSpan: 4,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_pesum`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_pedemsum`,
        extraOptions: { disabled: true, gridColSpan: 6, roundDecimal: 2 },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_pesupsum`,
        extraOptions: { disabled: true, gridColSpan: 6, roundDecimal: 2 },
      },
    ];
  }
}


