import _ from 'lodash';


export const constructionsFromJson = (currentJson: any) => {
  // const { globalJSON } = useContext(JsonContext);

  const draftJsonForConstructions = _.get(
    currentJson,
    `lAssembly`
  );
  const emptymap = [
   { value: -1, label: 'not assigned' },
  ];

  if (!draftJsonForConstructions) 
  {
    return emptymap;
  }

  const returna = draftJsonForConstructions.map((constructions: { id: any; n: string; }) => {
    return {
      value: constructions.id,
      label: constructions.n,
    };
  });

  const returnall = emptymap.concat(returna); 

  return returnall;
};


export const windowsdoorsFromJson = (currentJson: any) => {
  // const { globalJSON } = useContext(JsonContext);

  const draftJsonForWindowsDoors = _.get(
    currentJson,
    `lWindow`
  );
  const emptymap = [
   { value: -1, label: 'not assigned' },
  ];

  if (!draftJsonForWindowsDoors) 
  {
    return emptymap;
  }

  const returna = draftJsonForWindowsDoors.map((windowsdoors: { id: any; n: string; }) => {
    return {
      value: windowsdoors.id,
      label: windowsdoors.n,
    };
  });

  const returnall = emptymap.concat(returna); 

  return returnall;
};