import _ from 'lodash';
import { definitionsJson } from 'src/features/shared/json/data/source/active.def';
import { i18n } from '../../translations/translate';

const getLastKeyForDeepObject = (key: string) => key.split('.').reverse()[0];

export const getDefinitionsForEnum = (value: { key: string }) => {
  const key = getLastKeyForDeepObject(value.key);
  const definition = _.get(definitionsJson, key);

  if (!definition) throw Error('Not definition found for ' + key);
  return definition;
};

export const getNameOfDefinitionEnum = (value: {
  partJson: any;
  key: string;
}) => {
  const ArrayKey = _.get(value.partJson, value.key);
  const EnumSel = getDefinitionsForEnum({ key: value.key });
  if (!EnumSel) return i18n.t(`pages.nan`);

  //for (const sel  of EnumSel.sel)
  for (var iii in EnumSel.sel) {
    if (EnumSel.sel[iii][0] == ArrayKey) {
      return EnumSel.sel[iii][1];
    }
  }
  return i18n.t(`pages.nan`);
};
