import React from 'react';
import { Route } from 'react-router';

import { qualitativeRoutePaths, quantitativeRoutePaths } from './routes_path';
import {
  Acoustic,
  Comfort,
  Daylight,
  Energy,
  Environment,
  Freshwater,
  Indoorair,
  PrimaryEnergy,
  Sustainable,
  Thermal,
} from '../pages/tabs';
import { EnergyDemand, EnergySupply } from '../pages/tabs_energy';
import { Management } from '../pages/tabs_no';

export const BaselineRoutes = [
  <Route exact path={quantitativeRoutePaths.comfort()} component={Comfort} />,
  <Route exact path={quantitativeRoutePaths.daylight()} component={Daylight} />,
  <Route exact path={quantitativeRoutePaths.thermal()} component={Thermal} />,
  <Route
    exact
    path={quantitativeRoutePaths.indoorair()}
    component={Indoorair}
  />,
  <Route exact path={quantitativeRoutePaths.acoustic()} component={Acoustic} />,

  <Route exact path={quantitativeRoutePaths.energy()} component={Energy} />,
  <Route
    exact
    path={quantitativeRoutePaths.energydemand()}
    component={EnergyDemand}
  />,
  <Route
    exact
    path={quantitativeRoutePaths.electricitydemand()}
    component={EnergyDemand}
  />,
  <Route
    exact
    path={quantitativeRoutePaths.energysupply()}
    component={EnergySupply}
  />,
  <Route
    exact
    path={quantitativeRoutePaths.electricitysupply()}
    component={EnergySupply}
  />,
  <Route
    exact
    path={quantitativeRoutePaths.primaryenergy()}
    component={PrimaryEnergy}
  />,

  <Route
    exact
    path={quantitativeRoutePaths.environment()}
    component={Environment}
  />,
  <Route
    exact
    path={quantitativeRoutePaths.sustainable()}
    component={Sustainable}
  />,
  <Route
    exact
    path={quantitativeRoutePaths.freshwater()}
    component={Freshwater}
  />,

  <Route exact path={qualitativeRoutePaths.comfort()} component={Comfort} />,
  <Route exact path={qualitativeRoutePaths.daylight()} component={Daylight} />,
  <Route exact path={qualitativeRoutePaths.thermal()} component={Thermal} />,
  <Route
    exact
    path={qualitativeRoutePaths.indoorair()}
    component={Indoorair}
  />,
  <Route exact path={qualitativeRoutePaths.acoustic()} component={Acoustic} />,

  <Route exact path={qualitativeRoutePaths.energy()} component={Energy} />,
  <Route
    exact
    path={qualitativeRoutePaths.energydemand()}
    component={EnergyDemand}
  />,
  <Route
    exact
    path={qualitativeRoutePaths.energysupply()}
    component={EnergySupply}
  />,
  <Route
    exact
    path={qualitativeRoutePaths.primaryenergy()}
    component={PrimaryEnergy}
  />,

  <Route
    exact
    path={qualitativeRoutePaths.environment()}
    component={Environment}
  />,
  <Route
    exact
    path={qualitativeRoutePaths.sustainable()}
    component={Sustainable}
  />,
  <Route
    exact
    path={qualitativeRoutePaths.freshwater()}
    component={Freshwater}
  />,

  <Route
    exact
    path={qualitativeRoutePaths.management()}
    component={Management}
  />,
];
