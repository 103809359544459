
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { Box, Divider, FlexRow, P } from 'src/ui';
import {
  ThermalQuantityTitleDefinition,
  ThermalRoomsJsonDefinition,
  ThermalQuantityUserInputDefinition,
  ThermalRoomsHeaderJsonDefinition
} from '../../../data/quantitative/thermal_json_definition';
import { getVariantCaseNumber, useWithVariantCaseIndex } from '../use_with_variantcase_index';

import { useListOfRooms } from '../use_rooms_list';
import { DynamicSimulationFileUplad } from '../auxiliary_pages/dynamic_file_upload';
import { useFileData, useFileTemperature } from '../use_file_data';
import { appColors } from 'src/app/theme';
import { NotUsedRoomsJsonDefinition } from 'src/features/baseline/data/quantitative/daylight_json_definition';
import { NotUsedRoom } from './daylight';
import { TrashIcon } from 'src/ui/elements/icons';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Flex } from '@chakra-ui/layout';
import { ConstructionsTitleJsonDefinition } from 'src/features/project_information/data/constructions_json_definitions';


export const RoomThermal: React.FC<any> = ({ variantcase, index, remove, roomIsUsed }) => {
  const JsonHeader = new ThermalRoomsHeaderJsonDefinition(variantcase, index);
  const Json = new ThermalRoomsJsonDefinition(variantcase, index);

  if (!roomIsUsed(index, 2)) return <> </>;

  // const { operativetemperature } = useFileTemperature(variantcase, index);


  // var rdata = [];
  // // for (let element in operativetemperature) {  
  // for (var i = 0; i < 3000; i++) {
  //   rdata.push({
  //     name: i,
  //     op: operativetemperature[i]
  //   });
  // }

  return (
    <>
      <FlexRow alignItems="center">
        {/* <FormBuilder jsonKeys={JsonHeader.keys} /> */}
        <FormBuilder jsonKeys={Json.keys} />
        <FlexRow
          mt={4}
          ml={1}
          py={2}
          alignSelf="self-start"
          as="button"
          onClick={() => remove(index, 2)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexRow>
      </FlexRow>
      {/* <FlexRow>
        <LineChart
          width={800}
          height={150}
          data={rdata}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={['auto', 'auto']} />
          <Tooltip active={false} />
          <Legend />
          <Line type="monotone" dataKey="op" stroke="#8884d8" dot={false}/>
        </LineChart>
      </FlexRow> */}
    </>
  );
};


export const ThermalQuantityUserDefined: React.FC<any> = ({ variantcase }) => {
  const Json = new ThermalQuantityUserInputDefinition(variantcase);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};



export const ThermalQuantityPage: React.FC = () => {

  const { keys } = useWithVariantCaseIndex(ThermalQuantityTitleDefinition);
  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { isDynamicThermal } = useFileData(variantcaseIndex);
  const { rooms, removeThisRoom, bringBackThisRoom, getRoomName, roomIsUsed, notUsedRoomsDefined } = useListOfRooms(variantcaseIndex);
  const mappingRooms = [...(Array(rooms).keys() as any)];

  const deviderDefinition = new NotUsedRoomsJsonDefinition();

  return (
    <>
      <FormBuilder jsonKeys={keys} />
      {isDynamicThermal ? <>
        <DynamicSimulationFileUplad variantcase={variantcaseIndex} />
        <ThermalQuantityUserDefined variantcase={variantcaseIndex} />
      </> : null}
      <Box my={-2}>
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <RoomThermal key={rooms} variantcase={variantcaseIndex} index={a} remove={removeThisRoom} roomIsUsed={roomIsUsed} />
          </AnimateList>
        ))}
      </Box>

      {notUsedRoomsDefined(2) ? (
        <>
          <FormBuilder jsonKeys={deviderDefinition.keys} />
          <Box my={-2}>
            <P color={appColors.gray.light}>Bring back room:</P>
            {mappingRooms.map((a, i) => (
              <NotUsedRoom key={rooms} variantcase={variantcaseIndex} index={a} bringback={bringBackThisRoom} usedfortype={2} getRoomName={getRoomName} roomIsUsed={roomIsUsed} />
            ))}
          </Box>
        </>
      ) : (
        <></>
      )}

    </>
  );
};


export default ThermalQuantityPage;