import React from "react";
import { radarTheme200, radarTheme400 } from "../components/theme";

const polarToX = (angle, distance) => Math.cos(angle - Math.PI / 2) * distance;

const polarToY = (angle, distance) => Math.sin(angle - Math.PI / 2) * distance;

const points = (points) => {
  return points
    .map((point) => point[0].toFixed(4) + "," + point[1].toFixed(4))
    .join(" ");
};

const Axis = (options) => (col, i) => (
  <polyline
    key={`poly-axis-${i}`}
    points={points([
      [0, 0],
      [
        polarToX(col.angle, options.chartSize / 2),
        polarToY(col.angle, options.chartSize / 2),
      ],
    ])}
    {...options.axisProps(col)}
  />
);

const dot = (columns, options) => (chartData, i) => {
  const data = chartData.data;
  const meta = chartData.meta || {};
  const extraProps = options.dotProps(meta);
  let extraPropsSvg = {};
  let mouseEnter = () => {};
  let mouseLeave = () => {};
  if (extraProps.mouseEnter) {
    mouseEnter = extraProps.mouseEnter;
  }
  if (extraProps.mouseLeave) {
    mouseLeave = extraProps.mouseLeave;
  }
  if (extraProps.r) {
    extraPropsSvg.r = extraProps.r;
  }
  if (extraProps.fill) {
    extraPropsSvg.fill = extraProps.fill;
  }
  if (extraProps.stoke) {
    extraPropsSvg.stroke = extraProps.stroke;
  }
  if (extraProps.stokeWidth) {
    extraPropsSvg.strokeWidth = extraProps.strokeWidth;
  }

  return columns.map((col) => {
    const val = data[col.key];
    if ("number" !== typeof val) {
      throw new Error(`Data set ${i} is invalid.`);
    }
    return (
      <circle
         key={`dot-${col.key}-${val}`}
        cx={polarToX(col.angle, (val * options.chartSize) / 2)}
        cy={polarToY(col.angle, (val * options.chartSize) / 2)}
        className={[extraProps.className, meta.class].join(" ")}
        onMouseEnter={() => mouseEnter({ key: col.key, value: val, idx: i })}
        onMouseLeave={() => mouseLeave({})}
          {...extraPropsSvg}
      />
    );
  });
};

const shape = (columns, options) => (chartData, i) => {
  const data = chartData.data;
  const meta = chartData.meta || {};
  const extraProps = options.shapeProps(meta);
  let extraPropsSvg = {};
  if (!meta.fill) {
    meta.fill = meta.color;
  }
  if (meta.strokeWidth) {
    extraPropsSvg.strokeWidth = meta.strokeWidth;
  }
  if (meta.strokeDasharray) {
    extraPropsSvg.strokeDasharray = meta.strokeDasharray;
  }
  if (meta.strokeLinecap) {
    extraPropsSvg.strokeLinecap = meta.strokeLinecap;
  }
  // if (!meta.strokeDasharray) {
  //   meta.strokeDasharray = "none";
  // }

  if (meta.isDot) {
    return (
      <>
        {Object.keys(data).map((d) => {
          const col = columns.find((c) => c.key === d);

          return (
            <circle
              r={5}
              className="dot app-dot"
              cx={polarToX(col.angle, (data[d] * options.chartSize) / 2)}
              cy={polarToY(col.angle, (data[d] * options.chartSize) / 2)}
            />
          );
        })}
      </>
    );
  }
  return (
    <path
      key={`shape-${i}`}
      d={options.smoothing(
        columns.map((col) => {
          const val = data[col.key] <= 1 ? data[col.key] : 0;
          if ("number" !== typeof val) {
            throw new Error(`Data set ${i} is invalid.`);
          }

          return [
            polarToX(col.angle, (val * options.chartSize) / 2),
            polarToY(col.angle, (val * options.chartSize) / 2),
          ];
        })
      )}
      {...extraProps}
      {...extraPropsSvg}
      stroke={meta.color}
      fill={meta.fill}
      className={[extraProps.className, meta.class].join(" ")}
      stroke-dasharray={meta.strokeDasharray}
    />
  );
};

const scale = (options, value) => (
  <circle
    key={`circle-${value}`}
    cx={0}
    cy={0}
    r={(value * options.chartSize) / 2}
    {...options.scaleProps(value)}
  />
);

const caption = (options) => (col) => {
  const Icon = options.captionProps(col).Icon;

  const x = polarToX(col.angle, (options.size < 300 ? options.size / radarTheme200.captionPositionX : options.size / radarTheme400.captionPositionX) * 1.0).toFixed(4);
  const y = polarToY(col.angle, (options.size < 300 ? options.size / radarTheme200.captionPositionY : options.size / radarTheme400.captionPositionX) * 1.0).toFixed(4);

  let usedY = y;
  let usedX = x;

  if (y > 0) {
    usedY = Number(y) + 22.0;
  }

  if (x > 0) {
    usedX = Number(x) + 10.0;
  } else {
    usedX = Number(x) - 6.0;
  }

  return (
    <g className={"caption-group"} onClick={options.captionProps(col).onClick}>
      {Icon && (
        <Icon
          fill="white"
          onClick={options.captionProps(col).onClick}
          width={24}
          height={24}
          x={usedX - 10}
          y={usedY - 32}
        />
      )}
      <text
        key={`caption-of-${col.key}`}
        x={usedX}
        dy={(options.captionProps(col).fontSize || 10) / 2}
        {...options.captionProps(col)}
        y={usedY}
        className={"caption " + options.captionProps(col).className}
      >
        {col.caption}
      </text>
    </g>
  );
};

const render = (captions, chartData, options = {}) => {
  if ("object" !== typeof captions || Array.isArray(captions)) {
    throw new Error("caption must be an object");
  }
  if (!Array.isArray(chartData)) {
    throw new Error("data must be an array");
  }
  options.chartSize = options.size / options.zoomDistance;

  

  const columns = Object.keys(captions).map((key, i, all) => {
    return {
      key,
      caption: captions[key],
      angle: (Math.PI * 2 * (i-0.5)) / all.length,   // 9 directions
    };
  });
  const groups = [
    <g key={`g-groups`}>{chartData.map(shape(columns, options))}</g>,
  ];
  if (options.captions) {
    groups.push(<g key={`poly-captions`}>{columns.map(caption(options))}</g>);
  }
  if (options.dots) {
    groups.push(<g key={`g-dots`}>{chartData.map(dot(columns, options))}</g>);
  }
  if (options.axes) {
    groups.unshift(
      <g key={`group-axes`}>
        {columns.map(Axis(options))}
        {options.lineAxis(options)}
      </g>
    );
  }
  if (options.scales > 0) {
    const scales = [];
    for (let i = options.scales; i > 0; i--) {
      scales.push(scale(options, i / options.scales));
    }
    groups.unshift(<g key={`poly-scales`}>{scales}</g>);
  }
  const delta = (options.size / 2).toFixed(4);
  return (
    <>
      <g transform={`translate(${delta},${delta})`}>{groups}</g>
    </>
  );
};

export default render;
