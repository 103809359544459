import React from 'react';
import { useParams } from 'react-router';
import { appColors } from 'src/app/theme';
import { EnvironmentalLoadUserDefinedJsonDefinition, SustainableAreasJsonDefinition, SustainableConstructionUserDefinedJsonDefinition, SustainableQuantityInputJsonDefinition, SustainableQuantityJsonDefinition, SustainableSystemDeviceJsonDefinition, SustainableSystemOperationJsonDefinition } from 'src/features/baseline/data/quantitative/sustainable_json_definition';
import { AreasDividerJsonDefinition } from 'src/features/project_information/data/areas_json_definition';
import { useAreadTypeWithIndex } from 'src/features/project_information/presentation/applications/check_type_of_area';
import { useSustainableConstructionCalc } from 'src/features/project_information/presentation/applications/use_number_of_areas';
import { useNumberOfSystemDevices } from 'src/features/project_information/presentation/applications/use_number_of_systems_devices';
import { projectInformationRoutePaths } from 'src/features/project_information/presentation/routes/routes_path';
import { AnimateList } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, FlexRow, P } from 'src/ui';
import { useListOfAreas } from '../use_areas_list';
import { useListOfDevices, useListOfOperations, useListOfSystems } from '../use_systems_list';
import {
  getVariantCaseNumber,
  useWithVariantCaseIndex,
} from '../use_with_variantcase_index';

export const AreaSustainable: React.FC<any> = ({
  variantcase,
  index,
  typeOfComponent,
  onRemoveArea,
}) => {

  const typeOfArea = useAreadTypeWithIndex(variantcase, index);

  if (typeOfComponent === typeOfArea) {
    const Json = new SustainableAreasJsonDefinition(variantcase, index, typeOfComponent);
    return (
      <>
        <FlexRow alignItems="center">
          <FormBuilder jsonKeys={Json.keys} />
        </FlexRow>
      </>
    );
  } else {
    return <></>;
  }
};

export const AreaDivider: React.FC<any> = ({ typeArea }) => {
  const Json = new AreasDividerJsonDefinition(typeArea);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};

const ListingOfSystemDevices: React.FC<any> = ({
  indexVariant }) => {
  const { systems } = useListOfSystems(indexVariant);
  const mappingSystems = [...(Array(systems).keys() as any)];
  return (
    <>
      {/* <FlexRow alignItems="center"> */}
      <AreaDivider typeArea={20} />
      {mappingSystems.map((a, i) => (
        <>
          <AnimateList key={i}>
            <ListingOfDevices
              key={systems}
              indexVariant={indexVariant}
              indexSystem={i}
            />
          </AnimateList>
        </>
      ))}
      {/* </FlexRow> */}
    </>
  );
};

const ListingOfDevices: React.FC<any> = ({
  indexVariant,
  indexSystem }) => {

  const { devices } = useListOfDevices(indexVariant, indexSystem);
  const { isHeatPumpRenewablepart } = useNumberOfSystemDevices(indexVariant,indexSystem);
  const mappingDevices = [...(Array(devices).keys() as any)];
  if (isHeatPumpRenewablepart()) {
    return (
      <>
        {/* <FlexRow alignItems="center"> */}
        {mappingDevices.map((a, i) => (
          <AnimateList key={i}>
            <ListOneDevices
              key={devices}
              indexVariant={indexVariant}
              indexSystem={indexSystem}
              indexDevice={i}
            />
          </AnimateList>
        ))}
        {/* </FlexRow> */}
      </>
    );
  }
  else
  {
    return (
      <>
      </>
    );
  }
};

const ListOneDevices: React.FC<any> = ({
  indexVariant,
  indexSystem,
  indexDevice }) => {
  const Json = new SustainableSystemDeviceJsonDefinition(indexVariant, indexSystem, indexDevice);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};


const ListingOfSystemOperations: React.FC<any> = ({
  indexVariant }) => {
  const { systems } = useListOfSystems(indexVariant);
  const mappingSystems = [...(Array(systems).keys() as any)];
  return (
    <>
      {/* <FlexRow alignItems="center"> */}
      <AreaDivider typeArea={21} />
      {mappingSystems.map((a, i) => (
        <AnimateList key={i}>
          <ListingOfOperations
            key={systems}
            indexVariant={indexVariant}
            indexSystem={i}
          />
        </AnimateList>
      ))}
      {/* </FlexRow> */}

    </>
  );
};

const ListingOfOperations: React.FC<any> = ({
  indexVariant,
  indexSystem }) => {

  const { operations } = useListOfOperations(indexVariant, indexSystem);

  const mappingOperations = [...(Array(operations).keys() as any)];

  return (
    <>
      {/* <FlexRow alignItems="center"> */}
      {mappingOperations.map((a, i) => (
        <AnimateList key={i}>
          <ListOperation
            key={operations}
            indexVariant={indexVariant}
            indexSystem={indexSystem}
            indexOperation={i}
          />
        </AnimateList>
      ))}
      {/* </FlexRow> */}
    </>
  );

};

const ListOperation: React.FC<any> = ({
  indexVariant,
  indexSystem,
  indexOperation }) => {
  const Json = new SustainableSystemOperationJsonDefinition(indexVariant, indexSystem, indexOperation);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};

export const SustainableQuantityPage: React.FC = () => {

  const { keys } = useWithVariantCaseIndex(SustainableQuantityJsonDefinition);

  const { useEnvironmentLoads, useSustainableConstruction } = useSustainableConstructionCalc();

  if (useEnvironmentLoads || useSustainableConstruction) {
    return (
      <>
        <FormBuilder jsonKeys={keys} />

        <P display="inline">{i18n.t(`links.change`)} </P>
        <P
          as="button"
          onClick={() => window.location.replace(projectInformationRoutePaths.main)}
          color={appColors.green[50]}
          _hover={{ bg: appColors.secondary.light }}
          display="inline"
        >
          {i18n.t(`links.projectinfo`)}
        </P>

        <EnvironemtalLoadUserDefinedQuantityPage />
        <SustainableConstructionUserDefinedQuantityPage />
        <SustainableQuantityInputPage />
      </>
    );
  } else {
    return (
      <>
        <FormBuilder jsonKeys={keys} />

        <P display="inline">{i18n.t(`links.change`)} </P>
        <P
          as="button"
          onClick={() => window.location.replace(projectInformationRoutePaths.main)}
          color={appColors.green[50]}
          _hover={{ bg: appColors.secondary.light }}
          display="inline"
        >
          {i18n.t(`links.projectinfo`)}
        </P>

        <EnvironemtalLoadUserDefinedQuantityPage />
        <SustainableConstructionUserDefinedQuantityPage />

      </>
    );
  }
};

export const EnvironemtalLoadUserDefinedQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(EnvironmentalLoadUserDefinedJsonDefinition);
  const { useEnvironmentLoads } = useSustainableConstructionCalc();

  if (!useEnvironmentLoads) {
    return (
      <>
        <FormBuilder jsonKeys={keys} />
      </>
    );
  } else {
    return (
      <>
      </>
    );
  }

};

export const SustainableConstructionUserDefinedQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(SustainableConstructionUserDefinedJsonDefinition);
  const { useSustainableConstruction } = useSustainableConstructionCalc();

  if (!useSustainableConstruction) {
    return (
      <>
        <FormBuilder jsonKeys={keys} />
      </>
    );
  } else {
    return (
      <>
      </>
    );
  }

};


export const SustainableQuantityInputPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(SustainableQuantityInputJsonDefinition);

  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { areas } = useListOfAreas(variantcaseIndex);
  const mappingRooms = [...(Array(areas).keys() as any)];

  const outsidewalls = 1;
  const insidewalls = 2;
  const windowsdoors = 3;
  const slabs = 4;
  const roofs = 5;
  const foundations = 6;

  return (
    <>
      <FormBuilder jsonKeys={keys} />
      {/* <Divider my={2} /> */}
      <Box my={-2}>

        <AreaDivider typeArea={outsidewalls} />
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <AreaSustainable key={areas} variantcase={variantcaseIndex} index={a} typeOfComponent={outsidewalls} />
          </AnimateList>
        ))}

        <AreaDivider typeArea={insidewalls} />
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <AreaSustainable key={areas} variantcase={variantcaseIndex} index={a} typeOfComponent={insidewalls} />
          </AnimateList>
        ))}

        <AreaDivider typeArea={windowsdoors} />
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <AreaSustainable key={areas} variantcase={variantcaseIndex} index={a} typeOfComponent={windowsdoors} />
          </AnimateList>
        ))}

        <AreaDivider typeArea={slabs} />
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <AreaSustainable key={areas} variantcase={variantcaseIndex} index={a} typeOfComponent={slabs} />
          </AnimateList>
        ))}

        <AreaDivider typeArea={roofs} />
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <AreaSustainable key={areas} variantcase={variantcaseIndex} index={a} typeOfComponent={roofs} />
          </AnimateList>
        ))}

        <AreaDivider typeArea={foundations} />
        {mappingRooms.map((a, i) => (
          <AnimateList key={i}>
            <AreaSustainable key={areas} variantcase={variantcaseIndex} index={a} typeOfComponent={foundations} />
          </AnimateList>
        ))}
      </Box>
      <Box>
        <ListingOfSystemDevices
          indexVariant={variantcaseIndex}
        />
        <ListingOfSystemOperations
          indexVariant={variantcaseIndex}
        />

      </Box>
    </>
  );
};

export default SustainableQuantityPage;


function indexVariant(indexVariant: any, indexSystem: any): { isHeatPumpRenewablepart: any; } {
  throw new Error('Function not implemented.');
}

