import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { FreshwaterConsumptionIcon } from 'src/ui/elements/icons';

export class FreshwaterQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_fw`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: FreshwaterConsumptionIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_fwsc`,
        },
      },
      { key: `aht_fwtoi`, extraOptions: { disabled: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahfwtoi`,
        extraOptions: { gridColSpan: 3 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 2 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_fwtoi`,
        extraOptions: { disabled: true, gridColSpan: 1, styleType: 2, score: true, },
      },

      { key: `aht_fwshw`, extraOptions: { disabled: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahfwshw`,
        extraOptions: { gridColSpan: 3 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 2 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_fwshw`,
        extraOptions: { disabled: true, gridColSpan: 1, styleType: 2, score: true, },
      },

      { key: `aht_fwtap`, extraOptions: { disabled: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahfwtap`,
        extraOptions: { gridColSpan: 3 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 2 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_fwtap`,
        extraOptions: { disabled: true, gridColSpan: 1, styleType: 2, score: true, },
      },
    ];
  }
}
