import { Button } from '@chakra-ui/core';
import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { SettingsJsonDefinition } from '../data/settings_json_definition';

const settingsJson = new SettingsJsonDefinition();


export const SettingsPage: React.FC = () => {
  return (
    <>
      <FormBuilder jsonKeys={settingsJson.keys} />
    </>
  );
};
