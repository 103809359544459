import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { getRandomInt } from 'src/features/shared/utils';


export const useNumberOfSystemOperations = (
  indexVariant: number,
  indexSystem: number,
  facade = globalJsonFacade
) => {
  const [operations, setOperations] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForSystems = _.get(globalJson, `lVariant.[${indexVariant}].HVAC.lSystem`);

  const draftJsonForOperations = draftJsonForSystems
    ? _.get(draftJsonForSystems[indexSystem], `lOperation`)
    : null;

  const numberOfOperations = _.size(draftJsonForOperations);

  const removeOperation = (indexVariant: number, indexSystem: number, indexOperation: number) => {
    const newDraftJsonForOperation = [...(draftJsonForOperations as any)];

    const filtered = newDraftJsonForOperation.filter(function (value, i, arr) {
      return i !== indexOperation;
    });

    const newDraftJson = _.set(
      globalJson,
      `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation`,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftForOperations = _.get(newDraftJson, `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation`);
      const numberOfOperations = _.size(draftForOperations);
      setOperations(numberOfOperations);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addOperation = () => {
    setOperations(operations + 1);

    const randomId = _.uniqueId("10") + getRandomInt(50000);   // get a random id
    _.set(
      globalJson,
      `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${operations}].id`,
      randomId
    );

    _.set(
      globalJson,
      `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${operations}].eco.lcasysO`,
      16
    );

    _.set(
        globalJson,
        `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${operations}].eco.lcashare`,
        100
      );

    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  useEffect(() => {
    setOperations(numberOfOperations);
  }, [numberOfOperations]);

  return {
    operations,
    addOperation: addOperation,
    removeOperation,
  };
};
