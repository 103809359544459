import { HTMLVisualElement } from 'framer-motion';
import localForage from 'localforage';
import _ from 'lodash';
import { ActiveHouseCalculation } from 'src/features/calculation/domain/active_house_calculation';
import { JsonRepository } from '../../json/data/repository/json_repository';
import { globalJSON } from '../../json/data/source/global.activejob';

export class GlobalJsonFacade {
  jsonRepository: JsonRepository;

  constructor(jsonRepository: JsonRepository) {
    this.jsonRepository = jsonRepository;

    this.init();
  }

  async init() {
    const savedJson = await this.jsonRepository.get();

    if (!savedJson) {
      this.save(globalJSON);
    }
  }

  async save(draftJson: {}) {
    const currentJson = await this.get();

    // draftJson is just the new input, need to get the whole "currentJson"

    return this.jsonRepository.set(
      new ActiveHouseCalculation({
        i: 0,
        draftJson: _.merge(currentJson, draftJson),
      }).calculate()
    );
  }

  async saveWithoutMerge(draftJson: {}) {
    return this.jsonRepository.set(
      new ActiveHouseCalculation({
        i: 0,
        draftJson: draftJson,
      }).calculate()
    );
  }

  async saveWithoutCalc(draftJson: {}) {
    const currentJson = await this.get();
    return this.jsonRepository.set(
      new ActiveHouseCalculation({
        i: 0,
        draftJson: _.merge(currentJson, draftJson),
      }).onlySetGlobal()
    );
  }

  async setWithoutMerge(draftJson: {}) {
    return this.jsonRepository.set(draftJson);
  }

  async get() {
    const result = await this.jsonRepository.get();

    if (!result) return globalJSON;

    return result as typeof globalJSON;
  }

  clear() {
    // window.location.reload();
    this.jsonRepository.set(
      globalJSON
    );
   this.jsonRepository.clear();
  }

  async new() {
    // return this.jsonRepository;
    return this.jsonRepository.set(
      globalJSON
    );
  }
}



export const globalJsonFacade = new GlobalJsonFacade(
  new JsonRepository(localForage)
);
