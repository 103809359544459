
import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { EnergyDemandIcon } from 'src/ui/elements/icons';

export class EnergyDemandQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_ed`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: EnergyDemandIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_edsc`,
        },
      },
    ];
  }
}

export class EnergyDemandSytemsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.n`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 2,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_edsum`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_pesum`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahheatdem`,
        extraOptions: { gridColSpan: 2 },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahCOP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { disabled: true, gridColSpan: 1, noGridIfHide:true }
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_heateldem`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { disabled: true, gridColSpan: 1, noGridIfHide:true, roundDecimal: 2 }
      },
      {
        key: `empty`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 5 },
          ]),
        extraOptions: { disabled: true, gridColSpan: 2, noGridIfHide:true }
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahpeheat`,
        extraOptions: {
          disabled: true, gridColSpan: 1,
          placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe`
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_heatpe`,
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahdhwdem`,
        extraOptions: { gridColSpan: 2 },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahdwhCOP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { disabled:true, gridColSpan: 1, noGridIfHide:true }
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_dhweldem`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { disabled:true, gridColSpan: 1, noGridIfHide:true, roundDecimal: 2 }
      },
      {
        key: `empty`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 5 },
          ]),
        extraOptions: { disabled: true, gridColSpan: 2, noGridIfHide:true }
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahpedhw`,
        extraOptions: {
          disabled: true, gridColSpan: 1,
          placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe`
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_dhwpe`,
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },

      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahhcooldem`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { gridColSpan: 2 },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahcoolCOP`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { disabled: true, gridColSpan: 1 }
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_cooleldem`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 }
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahpecool`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: {
          disabled: true, gridColSpan: 1,
          placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe`
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_coolpe`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },


      
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahhauxen`,
        extraOptions: { gridColSpan: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 2 } },
      {
        key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahpeaux`,
        extraOptions: {
          disabled: true, gridColSpan: 1,
          placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe`
        },
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      // {
      //   key: `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.0.Active.ahr_auxpe`,
      //   extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      // },
    ];
  }
}


export class NotUsedSystemsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor() {
    this.keys = [{ key: 'aht_nus' }];
  }
}