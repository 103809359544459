
  export class TotalSustainableScore {
    val:number;
    constructor({ Loads, SustainableConstruction }: { 
      Loads?: number, 
      SustainableConstruction?: number
        }) {
        if (!Loads || !SustainableConstruction ) {
          this.val = 5;
          return;
        }
        if (Loads > 4 || SustainableConstruction > 4 ) {
          this.val = 5;
          return;
        }
      
        this.val = 
        (
          Loads + 
          SustainableConstruction
        ) / 2.0 ;
      }
  }