import _ from 'lodash';
import { Calculation } from '../calculation';
import { calculationErrors } from '../calculation_errors';
import { AcousticScore } from './comfort_calculations/acoustic_score';
import { DailightScore } from './comfort_calculations/daylight_score';
import { WeightedRoomScore } from './comfort_calculations/weighted_room_score';
import { IndoorairScore } from './comfort_calculations/indoorair_score';
import { ThermalScore } from './comfort_calculations/thermal_score';
import { checkFileToRoomChangesIndoorAir, checkFileToRoomChangesThermalEnv } from './hourly_calculations/set_filedata_to_room';
import { calculationValueToSelect } from './helper_calculations';
import { ScoreTotal } from './final_calculations/weighted_total_score';

export class ComfortCalculations implements Calculation {
  i: number;
  draftJson: any;

  public constructor({ i, draftJson, comfortCalculations }: any) {
    this.i = i;
    this.draftJson = { ...draftJson };
  }

  public calculate() {
    const { draftJson, i } = this;
    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;

    this.updatesDailightScore();
    this.updatesThermalAndIndoorAirScore();
    this.updateAcousticScore();

    return draftJson;

    // // energy
    // // energy demand
    // draftJson.lVariant[i].Active.ahr_edsc = 1;
    // // energy supply
    // draftJson.lVariant[i].Active.ahr_essc = 1;
    // // primary energy
    // draftJson.lVariant[i].Active.ahr_pesc = 1;
    // // sustainable construction
    // // environmental load
    // draftJson.lVariant[i].Active.ahr_elsc = 1;
    // // sustainable construction
    // draftJson.lVariant[i].Active.ahr_scsc = 1;
    // // freshwater
    // draftJson.lVariant[i].Active.ahr_fwsc = 1;

    // return draftJson;
  }

  private updatesDailightScore() {
    const { draftJson, i } = this;

    const roomdefinitinoCasePath = `lVariant[${i}].building.lZone.[0].lRoom`;
    const draftJsonForRooms = _.get(draftJson, roomdefinitinoCasePath);

    const dayLightScoreTotal = new ScoreTotal();

    let weightedDayFactorTotal = 0;

    // get daylight option
    const daylightMethod = _.get(draftJson, `lVariant.${i}.Active.ahdlopt`);
    // ###### for all rooms
    if (draftJsonForRooms) {
      _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_caseerror`, 0);
      for (const dJFR of draftJsonForRooms) {
        // old factor input
        // const roomDaylightFactor = daylightMethod == 1 ? _.get(dJFR, `Active.ahdf`) : _.get(dJFR, `Active.ahtdl`) ;
        // const dailightRoomScore = new DailightScore({
        //   averageDailightFactor: roomDaylightFactor,
        // });

        const dailightRoomScore = daylightMethod == 1 ? _.get(dJFR, `Active.ahdfs`) : _.get(dJFR, `Active.ahdfsa`);

        const roomUsed = _.get(dJFR, `Active.ahrod`);

        const weightedDaylightScoreRoom = new WeightedRoomScore({
          roomScore: dailightRoomScore,
          hours: _.get(dJFR, `Active.ahdlhours`),
          defhours: _.get(dJFR, `Active.ahdlhdef`),
          countOfPeople: _.get(dJFR, `Active.ahocu`),
          roomUsed: roomUsed,
        });

        if (roomUsed && !weightedDaylightScoreRoom.roomOk) {
          _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_caseerror`, 1);
        }

        _.set(dJFR, `Active.ahr_dlsc`, weightedDaylightScoreRoom.sectionOk ? dailightRoomScore : NaN);

        const SubTotal = roomUsed ? weightedDaylightScoreRoom.subTotal : 0;
        const ScoreTotal = roomUsed ? weightedDaylightScoreRoom.weightedScore : 0;
        dayLightScoreTotal.add(ScoreTotal, SubTotal, dailightRoomScore);

        const weightedDaylightFactorRoom = new WeightedRoomScore({
          roomScore: dailightRoomScore,// roomDaylightFactor,
          hours: _.get(dJFR, `Active.ahdlhours`),
          defhours: _.get(dJFR, `Active.ahdlhdef`),
          countOfPeople: _.get(dJFR, `Active.ahocu`),
          roomUsed: roomUsed,
        });
        weightedDayFactorTotal += roomUsed ? weightedDaylightFactorRoom.weightedScore : 0;

      }
    }


    const dayLightFactorAverage = weightedDayFactorTotal / dayLightScoreTotal.weightSubTotal;

    // _.set(draftJson, `lVariant[${i}].Active.ahr_dfAvg`, dayLightFactorAverage);

    _.set(draftJson, `lVariant[${i}].Active.ahr_dfsAvg`, calculationValueToSelect(dayLightFactorAverage));

    _.set(draftJson, `lVariant[${i}].Active.ahr_dlsc`, dayLightScoreTotal.val());

  }

  private updatesThermalAndIndoorAirScore() {
    const { draftJson, i } = this;


    const roomdefinitinoCasePath = `lVariant[${i}].building.lZone.[0].lRoom`;
    const draftJsonForRooms = _.get(draftJson, roomdefinitinoCasePath);


    const thermalScoreTotal = new ScoreTotal();

    const indoorairScoreTotal = new ScoreTotal();

    let weightedThermalCategoryTotal = 0;

    const co2ScoreTotal = new ScoreTotal();

    // ###### for all rooms
    if (draftJsonForRooms) {
      let indexRoom = 0;
      let indexToFileThermalEnv = -1;
      let indexToFileIndoorAir = -1;
      for (const dJFR of draftJsonForRooms) {

        if (_.get(draftJson, `lVariant[${i}].Active.ahtevalidation`) > 1) {
          _.set(draftJson, `lVariant[${i}].Active.ahthufile`, false);
        }

        if (_.get(draftJson, `lVariant[${i}].Active.ahiavalidation`) > 1) {
          _.set(draftJson, `lVariant[${i}].Active.ahco2ufile`, false);
        }

        const useFileThermalData = _.get(draftJson, `lVariant[${i}].Active.ahthufile`);
        const useFileIndoorAirData = _.get(draftJson, `lVariant[${i}].Active.ahco2ufile`);
        const fileDataOk = _.get(draftJson, `lVariant[${i}].Active.aHfiledata.aHfileOk`);

        if (!fileDataOk) {
          _.set(draftJson, `lVariant[${i}].Active.ahthufile`, false);
          _.set(draftJson, `lVariant[${i}].Active.ahco2ufile`, false);
        }

        const roomUsedtherm = _.get(dJFR, `Active.ahrot`);

        let indexFromFileThermalEnv = -1;
        if (roomUsedtherm) {
          indexToFileThermalEnv++;
          if ((useFileThermalData) && fileDataOk) {
            indexFromFileThermalEnv = checkFileToRoomChangesThermalEnv(draftJson, i, indexRoom, indexToFileThermalEnv);
          }
        }


        const maxScoreFromFile = indexFromFileThermalEnv >= 0 ? _.get(draftJson, `lVariant[${i}].Active.aHfiledata.aHfileR.[${indexFromFileThermalEnv}].aHRth.ahr_ftss.ahr_ffs`) : 5;
        const minScoreFromFile = indexFromFileThermalEnv >= 0 ? _.get(draftJson, `lVariant[${i}].Active.aHfiledata.aHfileR.[${indexFromFileThermalEnv}].aHRth.ahr_ftws.ahr_ffs`) : 5;

        const MaximumOperativeScore = useFileThermalData ? maxScoreFromFile : _.get(dJFR, `Active.ahteopmaxs`);
        const MinimumOperativeScore = useFileThermalData ? minScoreFromFile : _.get(dJFR, `Active.ahteopmins`);

        // thermal specific
        const ThermalRoomScore = new ThermalScore({
          MaximumOperativeScore: MaximumOperativeScore,
          MinimumOperativeScore: MinimumOperativeScore
        });


        const weightedThermalScoreRoom = new WeightedRoomScore({
          roomScore: ThermalRoomScore.val,
          hours: _.get(dJFR, `Active.ahdhours`),
          defhours: _.get(dJFR, `Active.ahdhdef`),
          countOfPeople: _.get(dJFR, `Active.ahocu`),
          roomUsed: roomUsedtherm,
        });
        const SubThermalTotal = roomUsedtherm ? weightedThermalScoreRoom.subTotal : 0;
        const ScoreThermalTotal = roomUsedtherm ? weightedThermalScoreRoom.weightedScore : 0;
        thermalScoreTotal.add(ScoreThermalTotal,SubThermalTotal, ThermalRoomScore.val);

        if (roomUsedtherm && !weightedThermalScoreRoom.roomOk) {
          _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_caseerror`, 2);
        }

        _.set(dJFR, `Active.ahr_tesc`, weightedThermalScoreRoom.sectionOk ? ThermalRoomScore.val : NaN);
        _.set(dJFR, `Active.ahr_maxtop`, ThermalRoomScore.maxopscore);
        _.set(dJFR, `Active.ahr_mintop`, ThermalRoomScore.minopscore);

        _.set(dJFR, `Active.ahr_teopmaxs`, calculationValueToSelect(ThermalRoomScore.maxopscore));
        _.set(dJFR, `Active.ahr_teopmins`, calculationValueToSelect(ThermalRoomScore.minopscore));


        // co2 specific
        const roomUsedtco2 = _.get(dJFR, `Active.ahroi`);
        const roomCO2lvl = _.get(dJFR, `Active.ahco2lvl`);

        let indexFromFileIndoorAir = -1;
        if (roomUsedtco2) {
          indexToFileIndoorAir++;
          if ((useFileIndoorAirData) && fileDataOk) {
            indexFromFileIndoorAir = checkFileToRoomChangesIndoorAir(draftJson, i, indexRoom, indexToFileIndoorAir);
          }
        }

        const co2FromFile = indexFromFileIndoorAir >= 0 ? _.get(draftJson, `lVariant[${i}].Active.aHfiledata.aHfileR.[${indexFromFileIndoorAir}].aHRina.ahr_tco2s.ahr_ffs`) : 5;

        const co2 = useFileIndoorAirData ? co2FromFile : roomCO2lvl;



        const indoorairRoomScore = new IndoorairScore({
          co2aboveoutdoor: co2,
        });

        const weightedIndoorairScoreRoom = new WeightedRoomScore({
          roomScore: indoorairRoomScore.val,
          hours: _.get(dJFR, `Active.ahdhours`),
          defhours: _.get(dJFR, `Active.ahdhdef`),
          countOfPeople: _.get(dJFR, `Active.ahocu`),
          roomUsed: roomUsedtco2,
        });
        const SubIndoorAirTotal = roomUsedtco2 ? weightedIndoorairScoreRoom.subTotal : 0;
        const ScoreIndoorAirTotal = roomUsedtco2 ? weightedIndoorairScoreRoom.weightedScore : 0;
        indoorairScoreTotal.add(ScoreIndoorAirTotal, SubIndoorAirTotal, indoorairRoomScore.val);

/*         console.log("roomUsedtco2: " + roomUsedtco2
        + " | ScoreIndoorAirTotal:" + ScoreIndoorAirTotal
        + " | SubIndoorAirTotal:" + SubIndoorAirTotal
        + " | indoorairRoomScore:" + indoorairRoomScore.val
      ); */


        const weightedCO2lvlRoom = new WeightedRoomScore({
          roomScore: roomCO2lvl,
          hours: _.get(dJFR, `Active.ahdhours`),
          defhours: _.get(dJFR, `Active.ahdhdef`),
          countOfPeople: _.get(dJFR, `Active.ahocu`),
          roomUsed: roomUsedtco2,
        });

        co2ScoreTotal.add(roomUsedtco2 ? weightedCO2lvlRoom.weightedScore : 0, 
          roomUsedtco2 ? weightedCO2lvlRoom.weightedScore : 0,
          roomCO2lvl)

        if (roomUsedtco2 && !weightedIndoorairScoreRoom.roomOk) {
          _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_caseerror`, 3);
        }

        _.set(dJFR, `Active.ahr_co2lvl`, co2);
        _.set(dJFR, `Active.ahr_iasc`, weightedIndoorairScoreRoom.sectionOk ? indoorairRoomScore.val : NaN);


        indexRoom++;
      }
    }

    //themal
    weightedThermalCategoryTotal = calculationValueToSelect(thermalScoreTotal.val());

    _.set(draftJson, `lVariant[${i}].Active.ahr_tesc`, thermalScoreTotal.val());
    _.set(draftJson, `lVariant[${i}].Active.ahr_telvl`, weightedThermalCategoryTotal);



    // indoor air
    _.set(draftJson, `lVariant[${i}].Active.ahr_iasc`, indoorairScoreTotal.val());

    

    const indoorCO2Total = calculationValueToSelect(co2ScoreTotal.val());
    _.set(draftJson, `lVariant[${i}].Active.ahr_co2lvl`, indoorCO2Total);

  }




  private updateAcousticScore() {
    const { draftJson, i } = this;

    const withWithoutAcousticPrivacy = _.get(draftJson, `lVariant.${i}.Active.ahaqoutdbuse`);

    const roomdefinitinoCasePath = `lVariant[${i}].building.lZone.[0].lRoom`;
    const draftJsonForRooms = _.get(draftJson, roomdefinitinoCasePath);


    const acouticScoreTotal = new ScoreTotal();

    let weightedAcousticCategoryTotal = 0;
    // ###### for all rooms
    if (draftJsonForRooms) {
      for (const dJFR of draftJsonForRooms) {
        const roomInsideSystemNoise = _.get(dJFR, `Active.ahaqindb`);
        const roomOutsideSystemNoise = _.get(dJFR, `Active.ahaqoutdb`);
        const roomAcousticPrivacy = _.get(dJFR, `Active.ahaqprdb`);
        const AcoustivRoomScore = new AcousticScore({
          insideSystemNoise: roomInsideSystemNoise,
          outsideSystemNoise: roomOutsideSystemNoise,
          acousticPrivacy: roomAcousticPrivacy,
          useAcousticPrivacy: withWithoutAcousticPrivacy
        });


        const roomUsed = _.get(dJFR, `Active.ahroa`);

        const weightedAcousticScoreRoom = new WeightedRoomScore({
          roomScore: AcoustivRoomScore.val,
          hours: _.get(dJFR, `Active.ahdhours`),
          defhours: _.get(dJFR, `Active.ahdhdef`),
          countOfPeople: _.get(dJFR, `Active.ahocu`),
          roomUsed: roomUsed,
        });

        if (roomUsed && !weightedAcousticScoreRoom.roomOk) {
          _.set(draftJson, `lVariant[${i}].Active.ahstate.ahr_caseerror`, 4);
        }

        _.set(dJFR, `Active.ahr_aqsc`, weightedAcousticScoreRoom.sectionOk ? AcoustivRoomScore.val : NaN);

        const weightedScore = roomUsed ? weightedAcousticScoreRoom.weightedScore : 0;
        const weightSub = roomUsed ? weightedAcousticScoreRoom.subTotal : 0;

        acouticScoreTotal.add(weightedScore, weightSub, AcoustivRoomScore.val);
      }
    }


    weightedAcousticCategoryTotal = calculationValueToSelect(acouticScoreTotal.val());

    _.set(
      draftJson,
      `lVariant[${i}].Active.ahr_aqlvl`,
      weightedAcousticCategoryTotal
    );

    _.set(draftJson, `lVariant[${i}].Active.ahr_aqsc`, acouticScoreTotal.val());


    // const acousticqualitylvl = _.get(
    //   draftJson,
    //   `lVariant[${i}].Active.ahr_aqlvl`
    // );

    // const acousticScore = new AcousticScore({
    //   acousticqualitylvl: acousticqualitylvl,
    // });

    // _.set(draftJson, `lVariant[${i}].Active.ahr_aqsc`, acousticScore.val);
  }

}


