import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { CloudIcon } from 'src/ui/elements/icons';

export class EnvironmentQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      { key: `ahh_ev`, extraOptions: { isAbsoluteTitle: true, IconComponent: CloudIcon } },

      { key: `aht_qev1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqev1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqev1c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
