import { convertCompilerOptionsFromJson } from "typescript";

export class ThermalScore {
  val: number;
  maxopscore: number;
  minopscore: number;
  constructor({ 
      MaximumOperativeScore, 
      MinimumOperativeScore, 
    }: 
    { 
      MaximumOperativeScore?: number,
      MinimumOperativeScore?: number, 
    }) {
      
    if (
        !MaximumOperativeScore || 
        !MinimumOperativeScore 
      )  {
      this.val = 5;
      this.minopscore = 5;
      this.maxopscore = 5;
      return;
    }

    this.maxopscore = MaximumOperativeScore;
    this.minopscore = MinimumOperativeScore;

    this.val = (+this.maxopscore + +this.minopscore) / 2;

    if (this.val > 4 || MaximumOperativeScore > 4 || MinimumOperativeScore > 4)
    {
      this.val = 5;
    }

  }
}
