import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';

export class SystemsHeatSupplyTitleJsonDefinition implements JsonDefinition {
    keys: any;
  
    constructor() {
      this.keys = [
        { 
          key: 'ahh_sy',
          extraOptions: { 
            isAbsoluteTitle: true, 
            // IconComponent: DaylightIcon,
           }, 
      }];
    }
  }


  export class SystemsHeatSupplyJsonDefinition implements JsonDefinition {
    keys: any;
  
    constructor(variantcaseIndex = 0, index: number) {
      this.keys = [
        {
          key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceThrenw`,
          shouldDisable: (json: typeof globalJSON) =>
            jsonValuesUtils.or(json)([
            { key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceThrenw`, value: 6 },
          ]),
          extraOptions: { gridColSpan: 2 },
        },
        {
          key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahheatprodpef`,
          shouldDisable: (json: typeof globalJSON) =>
            jsonValuesUtils.or(json)([
            { key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceThrenw`, value: 6 },
          ]),
          extraOptions: { gridColSpan: 2, 
            placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe` },
        },
      ];
    }
  }
  