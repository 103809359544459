import _ from 'lodash';
import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { getNameOfDefinitionEnum } from 'src/features/shared/form/utils/get_name_for_definition_enum';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { oekobauJson } from 'src/features/shared/json/data/source/oekobau.data';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';

export class SystemsTitleJsonDefinition implements JsonDefinition {
  keys: any;

  constructor() {
    this.keys = [
      { 
        key: 'ahh_sy',
        extraOptions: { 
          isAbsoluteTitle: true, 
          // IconComponent: DaylightIcon,
         }, 
    }];
  }
}

const ahsystemFromJson = (currentJson: any, indexVar:number, indexOne: number, indexTwo:number) => {
  const draftJsonForMaterials = _.get(oekobauJson, 'oekobau');
  const thisdevicepath = `lVariant.[[${indexVar}].HVAC.lSystem.[${indexOne}].lDevice.[${indexTwo}].eco.lcasysG`;
  const LayerGroup = getNameOfDefinitionEnum({
    partJson: currentJson,
    key: thisdevicepath,
  });
  const filtered = draftJsonForMaterials.filter(function (value, i, arr) {
    return value.grp == LayerGroup;
  });
  const emptymap = [
    { value: -1, label: 'not assigned' },
  ];
  const returna = filtered.map((material: { id: any; n: string; }) => {
    return {
      value: material.id,
      label: material.n
    }
  });
  const returnall = emptymap.concat(returna);
  return returnall;
};

const ahoperationFromJson = (currentJson: any, indexVar:number, indexOne: number, indexTwo:number) => {
  const draftJsonForMaterials = _.get(oekobauJson, 'oekobau');
  const thisdevicepath = `lVariant.[${indexVar}].HVAC.lSystem.[${indexOne}].lOperation.[${indexTwo}].eco.lcasysO`;
  const LayerGroup = getNameOfDefinitionEnum({
    partJson: currentJson,
    key: thisdevicepath,
  });
  const filtered = draftJsonForMaterials.filter(function (value, i, arr) {
    return value.grp == LayerGroup;
  });
  const emptymap = [
    { value: -1, label: 'not assigned' },
  ];
  const returna = filtered.map((material: { id: any; n: string; }) => {
    return {
      value: material.id,
      label: material.n
    }
  });
  const returnall = emptymap.concat(returna);
  return returnall;
};


export class SystemAddExpandJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexVariant: number, indexSystem: number) {
    this.keys = [
      {
        key: `empty`,
        extraOptions: { gridColSpan: 1 },
      },
    ]
  }
}


export class DeviceJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexVariant: number, indexSystem: number, indexDevice: number) {
    this.keys = [
      {
        key: `ahs_lca`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcasysG`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexVar: indexVariant, indexOne: indexSystem, indexTwo: indexDevice },
        dropdownOptionsFromJson: ahsystemFromJson,
      },
       {
        key: `empty`,
        extraOptions: { gridColSpan: 2 },
      },     
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.countS`,
        extraOptions: { 
          gridColSpan: 2, 
          userDefUnit: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcau` 
        },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.sus.aHlifetime`,
        extraOptions: { gridColSpan: 2 },
      },

    ]
  }
}



export class OperationJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexVariant:number, indexSystem: number, indexOperation: number) {
    this.keys = [
      {
        key: `ahs_ope`,
        extraOptions: { gridColSpan: 2 },
      },
      // {
      //   key: `lVariant.[0].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcasysO`,
      //   extraOptions: { gridColSpan: 1 },
      // },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexVar: indexVariant, indexOne: indexSystem, indexTwo: indexOperation },
        dropdownOptionsFromJson: ahoperationFromJson,
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcashare`,
        extraOptions: { gridColSpan: 2 },
      },
    ]
  }
}


export class SystemsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(index: number) {
    this.keys = [
      {
        key: `aht_sy`,
        //extraOptions: { gridColSpan: 3 },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`,
        extraOptions: { gridColSpan: 6 },
      },
      {
        key: `ahs_heating`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahpeheat`,
        extraOptions: { gridColSpan: 2, 
          placeholderkey: `lVariant[0].Active.ahdefpe` },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahCOP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 3 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 4 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 8 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 7 },
        ]),
        extraOptions: { gridColSpan: 2 },
      },

      {
        key: `ahs_dhw`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahpedhw`,
        extraOptions: { gridColSpan: 2, 
          placeholderkey: `lVariant[0].Active.ahdefpe` },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahdwhCOP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { gridColSpan: 2 },
      },

      {
        key: `ahs_cool`,
        shouldHideLine: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 3 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 4 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 8 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 7 },
        ]),
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahpecool`,
        shouldHideLine: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 3 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 4 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 8 },
          { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 7 },
        ]),
        extraOptions: { gridColSpan: 2, 
          placeholderkey: `lVariant[0].Active.ahdefpe` },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahcoolCOP`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 3 },
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 4 },
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 8 },
            { key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahheatDev`, value: 7 },
          ]),
        extraOptions: { gridColSpan: 2 },
      },


      {
        key: `ahs_auxen`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.[0].HVAC.lSystem.[${index}].lDevice.[0].Active.ahpeaux`,
        extraOptions: { gridColSpan: 2, 
          placeholderkey: `lVariant[0].Active.ahdefpe` },
      },
      {
        key: `empty`,
        extraOptions: { gridColSpan: 2 },
      },
     
    ];
  }
}
