
import React from 'react';
import { appColors } from 'src/app/theme';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, P } from 'src/ui';
import { SystemsElectricityDemandTitleJsonDefinition } from '../../data/systems_electricitydemand_json';
import { SystemsHeatSupplyTitleJsonDefinition } from '../../data/systems_heatsupply_json';
import { useNumberOfSystems } from '../applications/use_number_of_systems';
import { System } from './systems';



export const SystemElectricityDemandPage: React.FC = () => {
  const { systems, addSystem, removeSystem } = useNumberOfSystems(0);
  const mappingSystems = [...(Array(systems).keys() as any)];
  const electricityDemandType = 2;
  const titleDefinition = new SystemsElectricityDemandTitleJsonDefinition();
  return (
    <>
      <FormBuilder jsonKeys={titleDefinition.keys} />
      {mappingSystems.map((a, i) => (
        <AnimateList key={i}>
          <System
            key={systems}
            indexVariant={0}
            indexSystem={a}
            typeOfSystem={electricityDemandType}
            onRemoveSystem={() => removeSystem(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} mb={5} onClick={() => addSystem(electricityDemandType)}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
          {i18n.t(`add.system`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </>
  );
};

export const SystemHeatSupplyPage: React.FC = () => {
  const { systems, addSystem, removeSystem } = useNumberOfSystems(0);
  const mappingSystems = [...(Array(systems).keys() as any)];
  const heatSupplyType = 3;
  const titleDefinition = new SystemsHeatSupplyTitleJsonDefinition();
  return (
    <>
      <FormBuilder jsonKeys={titleDefinition.keys} />
      {mappingSystems.map((a, i) => (
        <AnimateList key={i}>
          <System
            key={systems}
            indexVariant={0}
            indexSystem={a}
            typeOfSystem={heatSupplyType}
            onRemoveSystem={() => removeSystem(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} mb={5} onClick={() => addSystem(heatSupplyType)}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.system`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </>
  );
};

export const SystemElectricitySupplyPage: React.FC = () => {
  const { systems, addSystem, removeSystem } = useNumberOfSystems(0);
  const mappingSystems = [...(Array(systems).keys() as any)];
  const electricitySupplyType = 4;
  const titleDefinition = new SystemsHeatSupplyTitleJsonDefinition();
  return (
    <>
      <FormBuilder jsonKeys={titleDefinition.keys} />
      {mappingSystems.map((a, i) => (
        <AnimateList key={i}>
          <System
            key={systems}
            indexVariant={0}
            indexSystem={a}
            typeOfSystem={electricitySupplyType}
            onRemoveSystem={() => removeSystem(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} mb={5} onClick={() => addSystem(electricitySupplyType)}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.system`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </>
  );
};

