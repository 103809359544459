import { useLocation, useParams } from 'react-router';
import { appColors } from 'src/app/theme';
import { useWithVariantCaseIndex } from 'src/features/baseline/presentation/pages/use_with_variantcase_index';
import { quantitativeRoutePaths } from 'src/features/baseline/presentation/routes/routes_path';
import { projectInformationRoutePaths } from 'src/features/project_information/presentation/routes/routes_path';
import { i18n } from 'src/features/shared/translations/translate';
import {
  AcousticQualityIcon,
  DaylightIcon,
  IndoorAirQualityIcon,
  PrimaryEnergyDemandIcon,
  ThermalEnvironmentIcon,
  EnergySupplyIcon,
  EnergyDemandIcon,
  FreshwaterConsumptionIcon,
  SustainableConstructionIcon,
} from 'src/ui/elements/icons';
import { appLineAxis } from './components/lineAxis';

export const SCALE = 5;

const captions = {
  thermalEnvironment: i18n.t(`radar.thermal`),
  indoorAirQuality: i18n.t(`radar.indoorair`),
  acousticQuality: i18n.t(`radar.acoustic`),
  energyDemand: i18n.t(`radar.energydemand`),
  primaryEnergyPerformance: i18n.t(`radar.primaryenergy`),
  energySupply: i18n.t(`radar.energysupply`),
  sustainableConstruction: i18n.t(`radar.sustainable`),
  freshwater: i18n.t(`radar.freshwater`),
  daylight: i18n.t(`radar.daylight`),
};

const routeToNavigate = {
  freshwater: quantitativeRoutePaths.freshwater() ,
  daylight: quantitativeRoutePaths.daylight(),
  thermalEnvironment: quantitativeRoutePaths.thermal(),
  indoorAirQuality: quantitativeRoutePaths.indoorair(),
  acousticQuality: quantitativeRoutePaths.acoustic(),
  energyDemand: quantitativeRoutePaths.energydemand(),
  primaryEnergyPerformance: quantitativeRoutePaths.primaryenergy(),
  energySupply: quantitativeRoutePaths.energysupply(),
  sustainableConstruction: quantitativeRoutePaths.sustainable(),
} as any;

const icons = {
  daylight: DaylightIcon,
  thermalEnvironment: ThermalEnvironmentIcon,
  indoorAirQuality: IndoorAirQualityIcon,
  acousticQuality: AcousticQualityIcon,
  energyDemand: EnergyDemandIcon,
  primaryEnergyPerformance: PrimaryEnergyDemandIcon,
  energySupply: EnergySupplyIcon,
  sustainableConstruction: SustainableConstructionIcon,
  freshwater: FreshwaterConsumptionIcon,
} as any;

// const { pathname } = useLocation();
// const variantcase = pathname.includes('baseline') ? 'baseline' : 'variantcase';

// const { variantcase } = useParams() as { variantcase: string };

const captionProps = (col: any) => ({
  className: 'wrap',
  Icon: icons[col.key],
  textAnchor: 'middle',
  fontFamily: 'Regular',
  overflow: 'visible',
  cursor: 'pointer',
  fill: appColors.primary.dark,
  onClick: () => {
    const routepath = routeToNavigate[col.key].replace(':variantcase', 'baseline');
    window.location.replace(routepath);
  },
});

const options = { captionProps, scales: SCALE, lineAxis: appLineAxis };

export const radarConfig = { options, captions, SCALE };
