export const definitionsJson = {
  "empty": {
    "n": "",
    "t": 102
  },
  "emptydivider": {
    "n": "",
    "t": 101
  },
  "ah_lang": {
    "n": "Language",
    "sel": [
      [
        1,
        "English"
      ],
      [
        2,
        "German"
      ],
    ],
    "t": 2
  },
  "ah_unit": {
    "n": "Unit system",
    "sel": [
      [
        1,
        "Scientific units (SI)"
      ],
      [
        2,
        "Imperial units (IP)"
      ],
    ],
    "t": 2
  },
  "ahr_pjstate": {
    "n": "Project state",
    "t": 120
  },
  "ahr_caseerror": {
    "n": "Case error",
    "t": 120
  },
  "ahr_casemsg": {
    "n": "Case error message",
    "t": 120
  },
  "n": {
    "n": "Name",
    "t": 0
  },
  "ahndef": {
    "n": "Name",
    "t": 0
  },
  "ahh_pj": {
    "n": "Project Info",
    "t": 110
  },
  "ahh_cf": {
    "n": "Comfort",
    "t": 110
  },
  "ahh_eg": {
    "n": "Energy",
    "t": 110
  },
  "ahh_ev": {
    "n": "Environment",
    "t": 110
  },
  "ahh_a": {
    "n": "Envelope geometry",
    "t": 110
  },
  "ahh_c": {
    "n": "Envelope structure",
    "t": 110
  },
  "ahh_sy": {
    "n": "Installations",
    "t": 110
  },
  "ahh_res": {
    "n": "Results",
    "t": 110
  },
  "ahh_cores": {
    "n": "Comfort Results",
    "t": 101
  },
  "ahh_set": {
    "n": "Settings",
    "t": 110
  },
  "aht_syd": {
    "n": "System devices",
    "t": 101
  },
  "aht_op": {
    "n": "Operations",
    "t": 101
  },
  "aht_sy": {
    "n": "System",
    "t": 101
  },
  "aht_c": {
    "n": "Project settings",
    "t": 101
  },
  "aht_b": {
    "n": "Building",
    "t": 101
  },
  "aht_r": {
    "n": "Rooms",
    "t": 110
  },
  "aht_mgn": {
    "n": "Management",
    "t": 110
  },
  "aht_dl": {
    "n": "Daylight",
    "t": 110
  },
  "aht_te": {
    "n": "Thermal environment",
    "t": 110
  },
  "aht_ia": {
    "n": "Indoor air quality",
    "t": 110
  },
  "aht_aq": {
    "n": "Acoustic quality",
    "t": 110
  },
  "aht_ed": {
    "n": "Energy demand",
    "t": 110
  },
  "aht_ecd": {
    "n": "Electricity demand",
    "t": 110
  },
  "aht_es": {
    "n": "Energy supply",
    "t": 110
  },
  "aht_tped": {
    "n": "Total primary energy",
    "t": 101
  },
  "aht_edspe": {
    "n": "Total primary thermal energy demand",
    "t": 101
  },
  "aht_ecdspe": {
    "n": "Total primary electricity demand",
    "t": 101
  },
  "aht_esspe": {
    "n": "Total primary thermal energy supply",
    "t": 101
  },
  "aht_esespe": {
    "n": "Total primary electricity supply",
    "t": 101
  },
  "aht_pe": {
    "n": "Primary energy",
    "t": 110
  },
  "aht_el": {
    "n": "Environmental loads",
    "t": 110
  },
  "aht_udel": {
    "n": "User defined environmental loads",
    "t": 101
  },
  "aht_fw": {
    "n": "Freshwater",
    "t": 110
  },
  "aht_sc": {
    "n": "Sustainable construction",
    "t": 110
  },
  "aht_udsc": {
    "n": "User defined sustainable construction",
    "t": 101
  },
  "aht_arow": {
    "n": "Outside Walls",
    "t": 101
  },
  "aht_ariw": {
    "n": "Inside Walls",
    "t": 101
  },
  "aht_arwd": {
    "n": "Windows and Doors",
    "t": 101
  },
  "aht_arsb": {
    "n": "Slabs",
    "t": 101
  },
  "aht_arrf": {
    "n": "Roofs",
    "t": 101
  },
  "aht_arfo": {
    "n": "Foundations",
    "t": 101
  },
  "aht_stru": {
    "n": "Structure",
    "t": 101
  },
  "aht_fwtoi": {
    "n": "Toilet water usage",
    "t": 101
  },
  "aht_fwshw": {
    "n": "Shower water use",
    "t": 101
  },
  "aht_fwtap": {
    "n": "Tap water use",
    "t": 101
  },
  "ahs_heating": {
    "n": "Heating",
    "t": 102
  },
  "ahs_dhw": {
    "n": "DHW",
    "t": 102
  },
  "ahs_cool": {
    "n": "Cooling",
    "t": 102
  },
  "ahs_auxen": {
    "n": "Auxilliary Energy",
    "t": 102
  },
  "ahs_lca": {
    "n": "Device Environment load",
    "t": 102
  },
  "ahs_ope": {
    "n": "Operation Environment load",
    "t": 102
  },
  "aht_auxen": {
    "n": "Auxiliary electriciy from thermal systems",
    "t": 101
  },
  "aht_devi": {
    "n": "Devices",
    "t": 101
  },
  "ahs_fr": {
    "n": "Frame",
    "t": 102
  },
  "ahs_ca": {
    "n": "Casement",
    "t": 102
  },
  "ahs_pa": {
    "n": "Pane",
    "t": 102
  },
  "ahs_ha": {
    "n": "Handles",
    "t": 102
  },
  "ahs_iw": {
    "n": "Iron work",
    "t": 102
  },
  "ahs_jt": {
    "n": "Joint tape",
    "t": 102
  },
  "aht_intf": {
    "n": "Interface",
    "t": 101
  },
  "aht_def": {
    "n": "Defaults",
    "t": 101
  },
  "aht_nation": {
    "n": "National legislation",
    "t": 101
  },
  "aht_nur": {
    "n": "Not used rooms",
    "t": 101
  },
  "aht_nus": {
    "n": "Not used systems",
    "t": 101
  },
  "ahbtype": {
    "n": "Building type",
    "sel": [
      [
        1,
        "Residential"
      ],
      [
        2,
        "Office"
      ],
      [
        3,
        "School"
      ],
      [
        4,
        "Other"
      ]
    ],
    "t": 2
  },
  "aHCrole": {
    "n": "Contact",
    "sel": [
      [
        1,
        "Client / Owner"
      ],
      [
        2,
        "Active House Commissoner"
      ],
      [
        3,
        "Active House Certifier"
      ],
      [
        4,
        "Investor"
      ],
      [
        5,
        "Architect"
      ],
      [
        6,
        "Constructor"
      ],
      [
        7,
        "Mechanical engineer"
      ],
      [
        99,
        "User defined"
      ],
    ],
    "t": 2
  },
  "aht_oc": {
    "n": "Owner / Client information",
    "t": 101
  },
  "aht_ai": {
    "n": "Architect information",
    "t": 101
  },
  "aht_mi": {
    "n": "Mechanical engineer information",
    "t": 101
  },
  "mN": {
    "n": "Name",
    "md": 120,
    "o": "+o"
  },
  "mC": {
    "n": "Company",
    "md": 120,
    "o": "+o"
  },
  "mTel": {
    "n": "Phone",
    "md": 50,
    "o": "+o"
  },
  "mEmail": {
    "n": "E-mail",
    "md": 50,
    "o": "+o"
  },
  "undef": {
    "n": "",
    "t": 9999
  },
  "dummyText": {
    "n": "",
    "t": 0,
    "o": "+ro"
  },
  "dummyNumber": {
    "n": "",
    "t": 1,
    "o": "+ro"
  },
  "calcScope": {
    "n": "Calculation scope",
    "t": 1
  },
  "lcau": {
    "n": "Unit",
    "t": 1
  },
  "cN": {
    "n": "Name",
    "md": 120,
    "o": "+o"
  },
  "cTel": {
    "n": "Phone",
    "md": 50,
    "o": "+o"
  },
  "cEmail": {
    "n": "E-mail",
    "md": 50,
    "o": "+o"
  },
  "bN": {
    "n": "Name/Type",
    "md": 120,
    "o": "+o"
  },
  "bYCon": {
    "n": "Year of construction",
    "md": 4,
    "o": "+o"
  },
  "bLoc": {
    "n": "Locality",
    "md": 120,
    "o": "+o"
  },
  "bPostC": {
    "n": "Postal code",
    "md": 20,
    "o": "+o"
  },
  "bStr": {
    "n": "Street",
    "md": 120,
    "o": "+o"
  },
  "aHCroleN": {
    "n": "Role",
    "md": 50,
    "o": "+o"
  },
  "rN": {
    "n": "Name",
    "md": 50,
    "o": "+o"
  },
  "rTel": {
    "n": "Phone",
    "md": 50,
    "o": "+o"
  },
  "rC": {
    "n": "Company",
    "md": 120,
    "o": "+o"
  },
  "rEmail": {
    "n": "E-mail",
    "md": 50,
    "o": "+o"
  },
  "date": {
    "n": "Date",
    "t": 5,
    "o": "+o"
  },
  "latB": {
    "n": "Latitude",
    "u": 43,
    "min": -90.0,
    "max": 90.0,
    "t": 1
  },
  "longB": {
    "n": "Longitude",
    "u": 43,
    "min": -180.0,
    "max": 180.0,
    "t": 1
  },
  "densB": {
    "n": "Bulk density",
    "u": 1,
    "min": 0.00001,
    "max": 100000000000000000000,
    "t": 1
  },
  "ahdefpe": {
    "n": "Default primary energy factor",
    "u": 0,
    "min": 0,
    "max": 10,
    "t": 1
  },
  "ahhlp": {
    "n": "Hide right bottom help button",
    "t": 3,
  },
  "ahnat": {
    "n": "Show in radar",
    "t": 3,
  },
  "lcaMaterial": {
    "n": "Material",
    "sel": [
      [
        -1,
        "not assigned"
      ]
    ],
    "t": 2
  },
  "lcaGroup": {
    "n": "Group",
    "sel": [
      [
        -1,
        "not assigned"
      ],
      [
        1,
        "Casement frame"
      ],
      [
        2,
        "Distribution"
      ],
      [
        3,
        "End of Life"
      ],
      [
        4,
        "Heat generator"
      ],
      [
        5,
        "Joint tape"
      ],
      [
        6,
        "Metal"
      ],
      [
        7,
        "Mineral building materials"
      ],
      [
        8,
        "Other"
      ],
      [
        9,
        "Output"
      ],
      [
        10,
        "Plastic"
      ],
      [
        11,
        "Small iron work"
      ],
      [
        12,
        "Storage"
      ],
      [
        13,
        "Window frame"
      ],
      [
        14,
        "Window pane"
      ],
      [
        15,
        "Wood"
      ],
      [
        16,
        "Operation"
      ]
    ],
    "t": 2
  },
  "lcamatG": {
    "n": "Group",
    "sel": [
      [
        -1,
        "not assigned"
      ],
      [
        6,
        "Metal"
      ],
      [
        7,
        "Mineral building materials"
      ],
      [
        10,
        "Plastic"
      ],
      [
        15,
        "Wood"
      ]
    ],
    "t": 2
  },
  "lcasysG": {
    "n": "Group",
    "sel": [
      [
        -1,
        "not assigned"
      ],
      [
        2,
        "Distribution"
      ],
      [
        4,
        "Heat generator"
      ],
      [
        9,
        "Output"
      ],
      [
        12,
        "Storage"
      ]
    ],
    "t": 2
  },
  "lcasysO": {
    "n": "Group",
    "sel": [
      [
        -1,
        "not assigned"
      ],
      [
        16,
        "Operation"
      ]
    ],
    "t": 2
  },
  "aht_sccalc": {
    "n": "Sustainable construction input method",
    "t": 101
  },
  "aht_evset": {
    "n": "Envelope structure definition",
    "t": 101
  },
  "ahudefenvload": {
    "n": "Environmental loads setting",
    "sel": [
      [
        1,
        "Build-in life cycle assessment calculation"
      ],
      [
        2,
        "User defined"
      ]
    ],
    "t": 2
  },
  "ahudefsusconst": {
    "n": "Sustainable construction setting",
    "sel": [
      [
        1,
        "Build-in life cycle assessment calculation"
      ],
      [
        2,
        "User defined"
      ]
    ],
    "t": 2
  },
  "aHshnload": {
    "n": "Shown environmental load",
    "sel": [
      [
        1,
        "Global warming potential (GWP)"
      ],
      [
        2,
        "Ozone depletion potential (ODP)"
      ],
      [
        3,
        "Acidfication potential (AP)"
      ],
      [
        4,
        "Eutrophication potential (EP)"
      ],
      [
        5,
        "Photochemical ozone creation potential (POCP)"
      ]
    ],
    "t": 2
  },
  "lcaGWP": {
    "n": "Global warming potential (GWP)",
    "u": 156,
    "min": -10000000,
    "max": 10000000,
    "t": 1
  },
  "lcaODP": {
    "n": "Ozone depletion potential (ODP)",
    "u": 157,
    "min": -1000000,
    "max": 1000000,
    "t": 1
  },
  "lcaAP": {
    "n": "Acidfication potential (AP)",
    "u": 158,
    "min": -1000000,
    "max": 1000000,
    "t": 1
  },
  "lcaEP": {
    "n": "Eutrophication potential (EP)",
    "u": 159,
    "min": -1000000,
    "max": 1000000,
    "t": 1
  },
  "lcaPOCP": {
    "n": "Photochemical ozone creation potential (POCP)",
    "u": 160,
    "min": -1000000,
    "max": 1000000,
    "t": 1
  },
  "lcaPEnonrenw": {
    "n": "PE (non renewable)",
    "u": 161,
    "min": -1000000,
    "max": 1000000,
    "t": 1
  },
  "lcaPRErenw": {
    "n": "PE (renewable)",
    "u": 161,
    "min": -100000,
    "max": 100000,
    "t": 1
  },
  "lcaWtPUnit": {
    "n": "Weight (if unit not kg)",
    "u": 5,
    "min": -100000,
    "max": 100000,
    "t": 1
  },
  "lcaMatGroup": {
    "n": "End of life",
    "sel": [],
    "t": 2
  },
  "aHlifetime": {
    "n": "Lifetime",
    "u": 116,
    "min": 0,
    "max": 100000,
    "t": 1
  },
  "aHspace": {
    "n": "Useable space",
    "u": 38,
    "min": 0,
    "max": 100000,
    "t": 1
  },
  "aHrecycled": {
    "n": "Recycled content",
    "t": 3
  },
  "aHrecyclable": {
    "n": "Recyclable or reusable virgin content",
    "t": 3
  },
  "aHcertifiedwood": {
    "n": "Certified wood (FSC/PEFC)",
    "t": 3
  },
  "aHverifiedepd": {
    "n": "Verified EPD",
    "t": 3
  },
  "quantity": {
    "n": "Quantity",
    "t": 1
  },
  "aHcpane": {
    "n": "Layers in pane",
    "u": 0,
    "min": 0,
    "max": 10,
    "t": 1
  },
  "caHchand": {
    "n": "Number of handles",
    "u": 0,
    "min": 0,
    "max": 10,
    "t": 1
  },
  "aHciw": {
    "n": "Number of small iron work",
    "u": 0,
    "min": 0,
    "max": 10,
    "t": 1
  },
  "aHthick": {
    "n": "Thickness",
    "u": 16,
    "min": 1e-7,
    "max": 100,
    "t": 1
  },
  "aHshare": {
    "n": "Share of Layer",
    "u": 22,
    "min": 1e-7,
    "max": 100,
    "t": 1
  },
  "aHlrtbW": {
    "n": "Width",
    "u": 18,
    "min": 0,
    "max": 999,
    "t": 1
  },
  "aHlrtbL": {
    "n": "Height",
    "u": 18,
    "min": 0,
    "max": 999,
    "t": 1
  },
  "remarks": {
    "n": "Remarks",
    "md": 200,
    "t": 0
  },
  "ahType": {
    "n": "Calculation Type",
    "sel": [
      [
        1,
        "Main calculation"
      ],
      [
        2,
        "Data from measurements"
      ],
      [
        3,
        "Data from leagal requirement"
      ],
      [
        4,
        "Data from original building (before renovation)"
      ],
      [
        5,
        "None"
      ]
    ],
    "t": 2
  },
  "bStatus": {
    "n": "Building status",
    "sel": [
      [
        1,
        "In planning"
      ],
      [
        2,
        "Under construction"
      ],
      [
        3,
        "Completed"
      ]
    ],
    "t": 2
  },
  "tBState": {
    "n": "Project type",
    "sel": [
      [
        1,
        "New construction"
      ],
      [
        2,
        "Retrofit"
      ],
      [
        3,
        "Mixed - new construction/retrofit"
      ]
    ],
    "t": 2
  },
  "ahdlvalidation": {
    "n": "Have you made daylight factor calculation with a validated simulation program?",
    "sel": [
      [
        1,
        "Yes"
      ],
      [
        2,
        "No"
      ]
    ],
    "t": 2
  },
  "ahdlopt": {
    "n": "Used method",
    "sel": [
      [
        1,
        "Option 1: daylight factor"
      ],
      [
        2,
        "Option 2: daylight autonomy"
      ]
    ],
    "t": 2
  },
  "ahtevalidation": {
    "n": "Has a dynamic simulation been performed to evaluate the thermal environment for different rooms?",
    "sel": [
      [
        1,
        "Yes"
      ],
      [
        2,
        "No"
      ]
    ],
    "t": 2
  },
  "ahiavalidation": {
    "n": "Has a dynamic simulation been performed to evaluate the CO2 concentration for different rooms?",
    "sel": [
      [
        1,
        "Yes"
      ],
      [
        2,
        "No"
      ]
    ],
    "t": 2
  },
  "HaveUsedActiveHouseLCATool": {
    "n": "Have you used the active house LCA tool",
    "t": 3
  },
  "flAreaZ": {
    "n": "Floor area",
    "u": 38,
    "min": 0.001,
    "max": 10000000000,
    "t": 1,
    "ud": 6,
    "o": "+oW",
    "psel": {
      "n": "Setting way",
      "sel": [
        [
          2,
          "From visualized geometry"
        ],
        [
          4,
          "Estimated from gross volume"
        ],
        [
          6,
          "User defined"
        ]
      ],
      "t": 2
    }
  },
  "ahroomtype": {
    "n": "Type",
    "ud": 5,
    "sel": [
      [
        1,
        "Kitchen",
        2.5,
        3.5
      ],
      [
        2,
        "Living room",
        3.0,
        5.0
      ],
      [
        3,
        "Bedroom parents",
        0.5,
        8.5
      ],
      [
        4,
        "Bedroom child",
        1.5,
        11
      ],
      [
        5,
        "User defined",
        0,
        0
      ]
    ],
    "t": 2
  },
  "aharea": {
    "n": "Area",
    "u": 38,
    "min": 0.001,
    "max": 10000000000,
    "t": 1,
    "o": "+o"
  },
  "ahdhours": {
    "n": "Daily use hours",
    "u": 30,
    "min": 0,
    "max": 24,
    "t": 1
  },
  "ahdlhours": {
    "n": "Daily use hours with daylight",
    "u": 30,
    "min": 0,
    "max": 24,
    "t": 1
  },
  "ahdhdef": {
    "n": "Default daily use hours",
    "u": 30,
    "min": 0,
    "max": 24,
    "t": 1
  },
  "ahdlhdef": {
    "n": "Default daily use hours with daylight",
    "u": 30,
    "min": 0,
    "max": 24,
    "t": 1
  },
  "ahocu": {
    "n": "Number of people",
    "u": 5,
    "min": 0,
    "max": 10000,
    "t": 1
  },
  "ahdfs": {
    "n": "Fraction with daylight factor higher than the target value",
    "sel": [
      [
        1,
        "> 70% of occupied space"
      ],
      [
        2,
        "> 60% of occupied space"
      ],
      [
        3,
        "> 50% of occupied space"
      ],
      [
        4,
        "> 40% of occupied space"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahdfsa": {
    "n": "Amount of daylight above target illuminance",
    "sel": [
      [
        1,
        "> 70% of occupied space"
      ],
      [
        2,
        "> 60% of occupied space"
      ],
      [
        3,
        "> 50% of occupied space"
      ],
      [
        4,
        "> 40% of occupied space"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahr_dfsAvg": {
    "n": "Average fraction higher than the target value",
    "sel": [
      [
        1,
        "> 70% of occupied space"
      ],
      [
        2,
        "> 60% of occupied space"
      ],
      [
        3,
        "> 50% of occupied space"
      ],
      [
        4,
        "> 40% of occupied space"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahdf": {
    "n": "Daylight factor",
    "u": 22,
    "min": 0,
    "max": 100,
    "t": 1
  },
  "ahtdl": {
    "n": "Target daylight level",
    "u": 22,
    "min": 0,
    "max": 100,
    "t": 1
  },
  "ahr_dfAvg": {
    "n": "Average daylight factor",
    "u": 22,
    "min": 0,
    "max": 100,
    "t": 1
  },
  "ahr_nrooms": {
    "n": "Number of rooms",
    "u": 0,
    "min": 0,
    "max": 1000,
    "t": 8
  },
  "aHfiledata": {
    "n": "File data",
    "t": 0
  },
  "ahfileheader": {
    "n": "Headline",
    "t": 0
  },
  "ahthufile": {
    "n": "Use data from file",
    "t": 3
  },
  "ahco2ufile": {
    "n": "Use data from file",
    "t": 3
  },
  "ahr_telvl": {
    "n": "Indoor environment category",
    "sel": [
      [
        1,
        "Best level"
      ],
      [
        2,
        "Better level"
      ],
      [
        3,
        "Good level"
      ],
      [
        4,
        "Lowest level"
      ]
    ],
    "t": 2
  },
  "ahtecool": {
    "n": "Room with air conditioning?",
    "sel": [
      [
        1,
        "yes"
      ],
      [
        2,
        "no"
      ]
    ],
    "t": 2
  },
  "ahtemeanout": {
    "n": "Running Mean outdoor temperature",
    "u": 14,
    "min": -20,
    "max": 60,
    "t": 1
  },
  "ahteopmaxs": {
    "n": "Summer category",
    "sel": [
      [
        1,
        "Best level"
      ],
      [
        2,
        "Better level"
      ],
      [
        3,
        "Good level"
      ],
      [
        4,
        "Lowest level"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahteopmins": {
    "n": "Winter category",
    "sel": [
      [
        1,
        "Best level"
      ],
      [
        2,
        "Better level"
      ],
      [
        3,
        "Good level"
      ],
      [
        4,
        "Lowest level"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahr_teopmaxs": {
    "n": "Summer category",
    "sel": [
      [
        1,
        "Best level"
      ],
      [
        2,
        "Better level"
      ],
      [
        3,
        "Good level"
      ],
      [
        4,
        "Lowest level"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahr_teopmins": {
    "n": "Winter category",
    "sel": [
      [
        1,
        "Best level"
      ],
      [
        2,
        "Better level"
      ],
      [
        3,
        "Good level"
      ],
      [
        4,
        "Lowest level"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahr_maxtop": {
    "n": "Summer score",
    "u": 0,
    "min": 0,
    "max": 5,
    "t": 1
  },
  "ahr_mintop": {
    "n": "Winter score",
    "u": 0,
    "min": 0,
    "max": 5,
    "t": 1
  },
  "ahcolumnte": {
    "n": "File column",
    "t": 0
  },
  "ahcolumnia": {
    "n": "File column",
    "t": 0
  },
  "ahr_aqlvl": {
    "n": "Acoustic quality category",
    "sel": [
      [
        1,
        "Best level"
      ],
      [
        2,
        "Better level"
      ],
      [
        3,
        "Good level"
      ],
      [
        4,
        "Lowest level"
      ]
    ],
    "t": 2
  },
  "ahaqindb": {
    "n": "Inside system noise",
    "sel": [
      [
        1,
        "< 25 dB, or below background noise"
      ],
      [
        2,
        "< 30 dB"
      ],
      [
        3,
        "< 35 dB"
      ],
      [
        4,
        "< 40 dB"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahaqoutdbuse": {
    "n": "Acoustic privacy",
    "sel": [
      [
        1,
        "With acoustic privacy"
      ],
      [
        2,
        "Without acoustic privacy"
      ],
    ],
    "t": 2
  },
  "ahaqoutdb": {
    "n": "Outside noise",
    "sel": [
      [
        1,
        "< 25 dB"
      ],
      [
        2,
        "< 30 dB"
      ],
      [
        3,
        "< 35 dB"
      ],
      [
        4,
        "< 40 dB"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "ahaqprdb": {
    "n": "Acoustic privacy (airborne ; contact sound)",
    "sel": [
      [
        1,
        "> 62 dB ; < 43 dB"
      ],
      [
        2,
        "> 57 dB ; < 48 dB"
      ],
      [
        3,
        "> 52 dB ; < 53 dB"
      ],
      [
        4,
        "> 47 dB ; < 58 dB"
      ],
      [
        5,
        "Out of AH category"
      ]
    ],
    "t": 2
  },
  "typeC": {
    "n": "Type",
    "sel": [
      [
        1,
        "Opaque"
      ],
      [
        2,
        "Transparent"
      ],
      [
        3,
        "Opening"
      ]
    ],
    "t": 2
  },
  "typeCah": {
    "n": "Type",
    "sel": [
      [
        1,
        "Outside Wall"
      ],
      [
        2,
        "Inside Wall"
      ],
      [
        3,
        "Windows and Doors"
      ],
      [
        4,
        "Slabs"
      ],
      [
        5,
        "Roofs"
      ],
      [
        6,
        "Foundations"
      ]
    ],
    "t": 2
  },
  "areaC": {
    "n": "Area",
    "u": 38,
    "min": 0.01,
    "max": 9999999,
    "t": 1
  },
  "idAssC": {
    "n": "Assembly",
    "sel": [
      [
        -1,
        "Not assigned"
      ]
    ],
    "t": 2
  },
  "idWtC": {
    "n": "Window type",
    "sel": [
      [
        -1,
        "Not assigned"
      ]
    ],
    "t": 2
  },
  "countC": {
    "n": "Quantity",
    "min": 1,
    "max": 2147483647,
    "t": 1
  },
  "widthWC": {
    "n": "Window width",
    "u": 18,
    "min": 0.01,
    "max": 100,
    "t": 1
  },
  "countS": {
    "n": "Quantity",
    "U": 0,
    "min": 0,
    "max": 2147483647,
    "t": 1
  },
  "ahsysused": {
    "n": "System used in case",
    "t": 3
  },
  "typeDev": {
    "n": "Type",
    "ud": 7,
    "sel": [
      [
        1,
        "Mechanical ventilation"
      ],
      [
        2,
        "Electric resistance heater"
      ],
      [
        3,
        "Boiler"
      ],
      [
        4,
        "District heating"
      ],
      [
        5,
        "Heat pump"
      ],
      [
        6,
        "Solar collector"
      ],
      [
        8,
        "Water storage"
      ],
      [
        13,
        "Drain water heat recovery"
      ],
      [
        10,
        "Photovoltaic / RES"
      ],
      [
        11,
        "Evaporative cooler"
      ],
      [
        12,
        "Split unit heat pump"
      ],
      [
        7,
        "User defined"
      ]
    ],
    "t": 2
  },
  "ahheatDev": {
    "n": "Type",
    "ud": 7,
    "sel": [
      [
        3,
        "Boiler"
      ],
      [
        4,
        "District heating"
      ],
      [
        5,
        "Heat pump"
      ],
      [
        8,
        "Electric heating"
      ],
      [
        7,
        "User defined"
      ]
    ],
    "t": 2
  },
  "ahelDev": {
    "n": "Device",
    "ud": 7,
    "sel": [
      [
        1,
        "Mechanical ventilation"
      ],
      [
        2,
        "Control system"
      ],
      [
        3,
        "Lighting"
      ],
      [
        4,
        "Pumps"
      ],
      [
        5,
        "User defined"
      ]
    ],
    "t": 2
  },
  "lcashare": {
    "n": "Share",
    "u": 22,
    "min": 0.0,
    "max": 100,
    "t": 1
  },
  "aheldem": {
    "n": "Energy demand",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "aheldempef": {
    "n": "PE factor",
    "u": 0,
    "min": -100,
    "max": 100,
    "t": 1
  },
  "aheldempe": {
    "n": "Primary Energy",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahpeheat": {
    "n": "PE factor",
    "u": 0,
    "min": -10.0,
    "max": 100,
    "t": 1
  },
  "ahpedhw": {
    "n": "PE factor",
    "u": 0,
    "min": -10.0,
    "max": 100,
    "t": 1
  },
  "ahpecool": {
    "n": "PE factor",
    "u": 0,
    "min": -10.0,
    "max": 100,
    "t": 1
  },
  "ahpeaux": {
    "n": "PE factor",
    "u": 0,
    "min": -10.0,
    "max": 100,
    "t": 1
  },
  "ahheatdem": {
    "n": "Heating demand",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahdhwdem": {
    "n": "Domestic hot water demand",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahhcooldem": {
    "n": "Cooling demand",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahr_heateldem": {
    "n": "El. demand",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahr_dhweldem": {
    "n": "El. demand",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahr_cooleldem": {
    "n": "El. demand",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahhauxen": {
    "n": "Auxiliary Energy (Electricity)",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahCOP": {
    "n": "COP",
    "u": 0,
    "min": 1,
    "max": 10000,
    "t": 1
  },
  "ahdwhCOP": {
    "n": "COP",
    "u": 0,
    "min": 1,
    "max": 100,
    "t": 1
  },
  "ahcoolCOP": {
    "n": "COP",
    "u": 0,
    "min": 1,
    "max": 100,
    "t": 1
  },
  "ahr_heatpe": {
    "n": "Primary energy",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahr_dhwpe": {
    "n": "Primary energy",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahr_coolpe": {
    "n": "Primary energy",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahr_auxpe": {
    "n": "Primary energy",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahheatprod": {
    "n": "Renewable heat",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahheatprodpef": {
    "n": "PE factor",
    "u": 0,
    "min": -100,
    "max": 100,
    "t": 1
  },
  "ahheatprodpe": {
    "n": "PE supply",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahelprod": {
    "n": "Renewable heat",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "ahelprodpef": {
    "n": "PE factor",
    "u": 0,
    "min": -100,
    "max": 100,
    "t": 1
  },
  "ahelprodpe": {
    "n": "PE supply",
    "u": 96,
    "min": 0.0,
    "max": 9999999,
    "t": 1
  },
  "aH_LightingValidation": {
    "n": "Is lighting part of the energy demand according to the national calculation methodology for domestic heating?",
    "t": 3
  },
  "ahdeviceTerenw": {
    "n": "Device",
    "sel": [
      [
        1,
        "PV Cells(on site)"
      ],
      [
        2,
        "Windmills and others (on site)"
      ],
      [
        3,
        "Other off-site renewable electricity production (nearby)"
      ],
      [
        4,
        "Electricity grid"
      ],
      [
        99,
        "User defined"
      ]
    ],
    "t": 2
  },
  "ahdeviceThrenw": {
    "n": "Device",
    "sel": [
      [
        1,
        "Solar panel (space heating)"
      ],
      [
        2,
        "Solar panel (domestic hot water)"
      ],
      [
        3,
        "District heating (Renewable part)"
      ],
      [
        4,
        "Boiler (Renewable part)"
      ],
      [
        5,
        "Electric heating (Renewable part)"
      ],
      [
        6,
        "Heat Pump (Renewable part)"
      ],
      [
        7,
        "Biofuels"
      ],
      [
        8,
        "Biogas"
      ],
      [
        99,
        "User defined"
      ]
    ],
    "t": 2
  },

  "aH_nationalvalue": {
    "n": "Calculation based on national value",
    "u": 0,
    "t": 3
  },
  "aH_fwconsumption": {
    "n": "Consumption based on national average",
    "u": 89,
    "t": 1
  },
  "aH_fwcfixed": {
    "n": "Consumption based on fixed value",
    "u": 89,
    "t": 1,
    "o": "+ro"
  },
  "aH_reusegrainw": {
    "n": "Reuse of rain / grey water",
    "u": 89,
    "t": 1,
    "o": "+o"
  },
  "ahr_co2lvl": {
    "n": "CO2 concentration above outdoor",
    "sel": [
      [
        1,
        "< 400 ppm"
      ],
      [
        2,
        "< 550 ppm"
      ],
      [
        3,
        "< 800 ppm"
      ],
      [
        4,
        "< 1100 ppm"
      ],
      [
        5,
        "> 1100 ppm"
      ]
    ],
    "t": 2
  },
  "ahco2lvl": {
    "n": "CO2 concentration above outdoor",
    "sel": [
      [
        1,
        "< 400 ppm"
      ],
      [
        2,
        "< 550 ppm"
      ],
      [
        3,
        "< 800 ppm"
      ],
      [
        4,
        "< 1100 ppm"
      ],
      [
        5,
        "> 1100 ppm"
      ]
    ],
    "t": 2
  },
  "ahr_dlsc": {
    "n": "Daylight score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_tesc": {
    "n": "Thermal environment score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_iasc": {
    "n": "Indoor air quality score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_aqsc": {
    "n": "Acoustic quality score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_edsc": {
    "n": "Energy demand score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_essc": {
    "n": "Energy supply score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_pesc": {
    "n": "Primary energy score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_elsc": {
    "n": "Environment load score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_fwsc": {
    "n": "Freshwater score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_scsc": {
    "n": "Sustainable construction score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_ssc": {
    "n": "Sustainable score",
    "u": "0",
    "min": 1,
    "max": 5,
    "t": 1
  },
  "ahr_gwpsc": {
    "n": "GWP score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_opdsc": {
    "n": "ODP score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_pocpsc": {
    "n": "POCP score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_apsc": {
    "n": "AP score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_epsc": {
    "n": "EP score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_rcsc": {
    "n": "Recycled content score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_rvsc": {
    "n": "Recycable virgin content score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_cwsc": {
    "n": "Responsibly sourced wood score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_vesc": {
    "n": "Declared origin score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_sc": {
    "n": "Mean active score",
    "u": "0",
    "t": 1,
    "o": "+ro"
  },
  "ahr_edsh": {
    "n": "Space heating",
    "u": 96,
    "t": 1
  },
  "ahr_eddhw": {
    "n": "Domestic hot water",
    "u": 96,
    "t": 1
  },
  "ahr_edmv": {
    "n": "Mechanical ventilation",
    "u": 96,
    "t": 1
  },
  "ahr_edcool": {
    "n": "Cooling",
    "u": 96,
    "t": 1
  },
  "ahr_edsys": {
    "n": "Auxiliary electricity",
    "u": 96,
    "t": 1
  },
  "ahr_edsaux": {
    "n": "Auxiliary System electricity",
    "u": 96,
    "t": 1
  },
  "ahr_edl": {
    "n": "Lighting",
    "u": 96,
    "t": 1
  },
  "ahr_eddev": {
    "n": "Electricity demand devices",
    "u": 96,
    "t": 1
  },
  "ahr_edsum": {
    "n": "Total energy demand",
    "u": 96,
    "t": 1
  },
  "ahr_esel": {
    "n": "Electricity produced by renewables",
    "u": 96,
    "t": 1
  },
  "ahr_eshd": {
    "n": "Heat produced by renewables",
    "u": 96,
    "t": 1
  },
  "ahr_espeel": {
    "n": "PE Electricity produced by renewables",
    "u": 96,
    "t": 1
  },
  "ahr_espehd": {
    "n": "PE Heat produced by renewables",
    "u": 96,
    "t": 1
  },
  "ahr_esperc": {
    "n": "Covered percentage of energy demand",
    "u": 22,
    "t": 1
  },
  "ahr_essum": {
    "n": "Total energy supply",
    "u": 96,
    "t": 1
  },
  "ahr_pedemsum": {
    "n": "Total Primary energy demand",
    "u": 96,
    "t": 1
  },
  "ahr_pedemtsum": {
    "n": "Total Primary thermal energy demand",
    "u": 96,
    "t": 1
  },
  "ahr_pesupsum": {
    "n": "Total Primary energy supply",
    "u": 96,
    "t": 1
  },
  "ahr_pesum": {
    "n": "Total primary energy",
    "u": 96,
    "t": 1
  },
  "ahr_edpesaux": {
    "n": "Total primary energy",
    "u": 96,
    "t": 1
  },
  "ahr_edpedev": {
    "n": "PE Electricity demand devices",
    "u": 96,
    "t": 1
  },
  "ahr_edpetot": {
    "n": "PE Electricity demand",
    "u": 96,
    "t": 1
  },
  "ahr_edpefsaux": {
    "n": "PE factor",
    "u": 0,
    "t": 1
  },
  "ah_elpe": {
    "n": "Primary energy consumption",
    "sel": [
      [
        1,
        "<-150"
      ],
      [
        2,
        "<15"
      ],
      [
        3,
        "<150"
      ],
      [
        4,
        "<200"
      ],
      [
        5,
        ">200"
      ]
    ],
    "t": 2
  },
  "ah_elgwp": {
    "n": "Global warming potential (GWP)",
    "sel": [
      [
        1,
        "<-30 kg CO2-eq."
      ],
      [
        2,
        "<10 kg CO2-eq."
      ],
      [
        3,
        "<40 kg CO2-eq."
      ],
      [
        4,
        "<50 kg CO2-eq."
      ],
      [
        5,
        ">50 kg CO2-eq."
      ]
    ],
    "t": 2
  },
  "ah_elopd": {
    "n": "Ozone depletion potential (ODP)",
    "sel": [
      [
        1,
        "<2.25E-07 kg R11-eq."
      ],
      [
        2,
        "<5.30E-07 kg R11-eq."
      ],
      [
        3,
        "<3.70E-06 kg R11-eq."
      ],
      [
        4,
        "<6.70E-06 kg R11-eq."
      ],
      [
        5,
        ">6.70E-06 kg R11-eq."
      ]
    ],
    "t": 2
  },
  "ah_elpocp": {
    "n": "Photochemical ozone creation potential (POCP)",
    "sel": [
      [
        1,
        "<0.0025 kg C2H4-eq."
      ],
      [
        2,
        "<0.0040 kg C2H4-eq."
      ],
      [
        3,
        "<0.0070 kg C2H4-eq."
      ],
      [
        4,
        "<0.0085 kg C2H4-eq."
      ],
      [
        5,
        ">0.0085 kg C2H4-eq."
      ]
    ],
    "t": 2
  },
  "ah_elap": {
    "n": "Acidfication potential (AP)",
    "sel": [
      [
        1,
        "<0.010 kg SO2-eq."
      ],
      [
        2,
        "<0.075 kg SO2-eq."
      ],
      [
        3,
        "<0.100 kg SO2-eq."
      ],
      [
        4,
        "<0.125 kg SO2-eq."
      ],
      [
        5,
        ">0.125 kg SO2-eq."
      ]
    ],
    "t": 2
  },
  "ah_elep": {
    "n": "Eutrophication potential (EP)",
    "sel": [
      [
        1,
        "<0.0040 kg PO4-eq."
      ],
      [
        2,
        "<0.0055 kg PO4-eq."
      ],
      [
        3,
        "<0.0085 kg PO4-eq."
      ],
      [
        4,
        "<0.0105 kg PO4-eq."
      ],
      [
        5,
        ">0.0105 kg PO4-eq."
      ]
    ],
    "t": 2
  },
  "ahr_fwred": {
    "n": "Minimisation of fresh water consumpton",
    "u": 22,
    "t": 1
  },
  "ahfwtoi": {
    "n": "Toilet water usage",
    "sel": [
      [
        -1,
        "not selected"
      ],
      [
        1,
        "< 4 litre per flush"
      ],
      [
        2,
        "< 6 litre per flush"
      ],
      [
        3,
        "< 9 litre per flush"
      ],
      [
        4,
        "< 12 litre per flush"
      ],
      [
        5,
        "> 12 litre per flush"
      ]
    ],
    "t": 2
  },
  "ahfwshw": {
    "n": "Flowrate of showerhead",
    "sel": [
      [
        -1,
        "not selected"
      ],
      [
        1,
        "< 6 litre per minute"
      ],
      [
        2,
        "< 8 litre per minute"
      ],
      [
        3,
        "< 10 litre per minute"
      ],
      [
        4,
        "< 12 litre per minute"
      ],
      [
        5,
        "> 12 litre per minute"
      ]
    ],
    "t": 2
  },
  "ahfwtap": {
    "n": "Tap flow rate",
    "sel": [
      [
        -1,
        "not selected"
      ],
      [
        1,
        "< 3 litre per minute"
      ],
      [
        2,
        "< 5 litre per minute"
      ],
      [
        3,
        "< 7 litre per minute"
      ],
      [
        4,
        "< 9 litre per minute"
      ],
      [
        5,
        "> 9 litre per minute"
      ]
    ],
    "t": 2
  },
  "ahr_fwtoi": {
    "n": "Score",
    "u": "0",
    "t": 1
  },
  "ahr_fwshw": {
    "n": "Score",
    "u": "0",
    "t": 1
  },
  "ahr_fwtap": {
    "n": "Score",
    "u": "0",
    "t": 1
  },
  "ah_scrc": {
    "n": "Recycled content",
    "u": 22,
    "t": 1
  },
  "ah_scrv": {
    "n": "Recycable or reuseable virgin content",
    "u": 22,
    "t": 1
  },
  "ah_sccw": {
    "n": "Responsibly sourced wood",
    "u": 22,
    "t": 1
  },
  "ah_scve": {
    "n": "Declared origin",
    "u": 22,
    "t": 1
  },
  "ahr_scrc": {
    "n": "Recycled content",
    "u": 22,
    "t": 1
  },
  "ahr_scrv": {
    "n": "Recycable or reuseable virgin content",
    "u": 22,
    "t": 1
  },
  "ahr_sccw": {
    "n": "Responsibly sourced wood",
    "u": 22,
    "t": 1
  },
  "ahr_scve": {
    "n": "Declared origin",
    "u": 22,
    "t": 1
  },
  "aH_flow": {
    "n": "flow",
    "u": 52,
    "t": 1
  },
  "aht_qm1": {
    "n": "Management of indoor climate",
    "t": 101
  },
  "ahqm11": {
    "n": "• Training of building users in efficient behaviour. Yearly performance check of building and systems (service contract).\n• User guidelines on operation of building and technical systems Continuous (hourly) monitoring and display of performance and indoor air quality.\n• Commissioning of building, building services, shading and ventilation systems during the first year of operation.",
    "t": 3
  },
  "ahqm1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qm2": {
    "n": "Management of energy",
    "t": 101
  },
  "ahqm21": {
    "n": "• Training of building users in energy-efficient behaviour.\n• Yearly performance check of building and systems (service contract).\n• User guidelines on energy-efficient operation of building and technical systems.\n• Continuous (hourly) monitoring and display of energy use and production.\n• Commissioning of building, building services and renewable energy systems during the first year of operation.",
    "t": 3
  },
  "ahqm2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qm3": {
    "n": "Management of environment",
    "t": 101
  },
  "ahqm31": {
    "n": "• Training of building users in resource-efficient and responsible environmental behaviour.\n• Yearly performance check of building and systems (service contract).\n• User guidelines on optimal maintenance and water-efficient operation of building and technical systems.\n• Continuous monitoring and display of water use.\n• Commissioning of building, building services and water systems during the first year of operation.",
    "t": 3
  },
  "ahqm3c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qcf1": {
    "n": "Space for comments",
    "t": 101
  },
  "ahqcf1": {
    "n": "Do you have comments?",
    "t": 3
  },
  "ahqcf1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qdl1": {
    "n": "View",
    "t": 101
  },
  "aht_qdl2": {
    "n": "Visual transmittance",
    "t": 101
  },
  "aht_qdl3": {
    "n": "Glare management",
    "t": 101
  },
  "aht_qdl4": {
    "n": "Daylight in secondary rooms",
    "t": 101
  },
  "aht_qdl5": {
    "n": "Blinding of bedrooms",
    "t": 101
  },
  "aht_qdl6": {
    "n": "Room reflectance",
    "t": 101
  },
  "aht_qdl7": {
    "n": "Single or multiple openings",
    "t": 101
  },
  "aht_qdl8": {
    "n": "Simulation method",
    "t": 101
  },
  "ahqdl1": {
    "n": "Are windows located to offer the best possible views to the exterior environment (sky and surroundings)?",
    "t": 3
  },
  "ahqdl2": {
    "n": "Are windows that provide a view to the outdoors selected to have the highest possible visible transmittance?",
    "t": 3
  },
  "ahqdl3": {
    "n": "Is dynamic shading present to avoid risk of glare?",
    "t": 3
  },
  "ahqdl4": {
    "n": "Have circulation zones and bathrooms access to daylight?",
    "t": 3
  },
  "ahqdl5": {
    "n": "Do bedrooms have the possibility to block out all light coming from windows to create a full dark environment to sleep in?",
    "t": 3
  },
  "ahqdl6": {
    "n": "Have surface reflectances been considered during the design stage?\n If so, state the values in the arguments.\nIt is recommended to use the following values (typical ranges in brackets) \nCeiling: 0.7 (0.7 to 0.9) \nWalls: 0.5 (0.5 to 0.8) \nFloor: 0.2 (0.2 to 0.4)",
    "t": 3
  },
  "ahqdl7": {
    "n": "Do the most important rooms have access to daylight from more than one orientation?",
    "t": 3
  },
  "ahqdl8": {
    "n": "Has dynamic model simulation been used to determine the DA, rather than determining the DF?",
    "t": 3
  },
  "ahqdl1c": {
    "n": "Comment",
    "t": 0
  },
  "ahqdl2c": {
    "n": "Comment",
    "t": 0
  },
  "ahqdl3c": {
    "n": "Comment",
    "t": 0
  },
  "ahqdl4c": {
    "n": "Comment",
    "t": 0
  },
  "ahqdl5c": {
    "n": "Comment",
    "t": 0
  },
  "ahqdl6c": {
    "n": "Comment",
    "t": 0
  },
  "ahqdl7c": {
    "n": "Comment",
    "t": 0
  },
  "ahqdl8c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qte1": {
    "n": "Individual control, winter",
    "t": 101
  },
  "ahqte1": {
    "n": "Is it possible to adjust the temperature at room level according to momentary needs, e.g. with adjustable thermostats?",
    "t": 3
  },
  "ahqte1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qte2": {
    "n": "Individual control, summer",
    "t": 101
  },
  "ahqte2": {
    "n": "Is it possible to manually influence the thermal conditions in each room, e.g. by opening windows or adjusting solar shading? In the case of mechanical cooling systems, is it possible to adjust the temperature at room level, e.g. with adjustable thermostats?",
    "t": 3
  },
  "ahqte2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qte3": {
    "n": "Night cooling",
    "t": 101
  },
  "ahqte3": {
    "n": "Is it possible to remove excess heat that has built up during the day, through high volume night-time ventilation with cool outdoor air?",
    "t": 3
  },
  "ahqte3c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qte4": {
    "n": "Overheating, winter",
    "t": 101
  },
  "ahqte4": {
    "n": "Is it possible to remove unwanted excess heat in winter, e.g. on sunny days, without creating uncomfortable draughts?",
    "t": 3
  },
  "ahqte4c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qte5": {
    "n": "System interface",
    "t": 101
  },
  "ahqte5": {
    "n": "Have the climate system interfaces (e.g. wall thermostats) been selected to be as intuitive and simple as possible?",
    "t": 3
  },
  "ahqte5c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qte6": {
    "n": "Draught",
    "t": 101
  },
  "ahqte6": {
    "n": "Have ventilation openings (including windows, ventilation grilles and mechanical ventilation devices) been located and detailed so that discomfort caused by draught is minimised? \n Typical airspeeds within the living zone should remain below 0.2 m/s in winter and 0.5 m/s in summer \n Note: Adjustability (e.g. of operable windows and ventilation grilles) is an important issue to take into account in this context.",
    "t": 3
  },
  "ahqte6c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qia1": {
    "n": "Individual control",
    "t": 101
  },
  "ahqia1": {
    "n": "Is it possible to manually influence the air exchange rate in the rooms (especially living room, kitchen and bedrooms), e.g. by opening windows, temporarily closing air grills, or if mechanical ventilation is installed, is it possible to adjust the airflow rate at three or more levels?",
    "t": 3
  },
  "ahqia1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qia2": {
    "n": "Dampness",
    "t": 101
  },
  "ahqia2": {
    "n": "Is it guaranteed that there is sufficient extraction in rooms with periodic moisture-production peaks (esp. kitchens, bathrooms and toilets)? \n Note: The minimum exhaust air flow for toilets, bathrooms and kitchens should be 35, 50 and 70 m3/h, according to category II of EN16798-1.",
    "t": 3
  },
  "ahqia2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qia3": {
    "n": "Low-emitting building materials",
    "t": 101
  },
  "ahqia3": {
    "n": "Have indoor climate-labelled materials been used? \n Note: many labels exist, for example, Danish Indoor Climate label, M1 label, AgBB, GUT label, Blue Angel, GreenGuard Gold label.",
    "t": 3
  },
  "ahqia3c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qia4": {
    "n": "Kitchen",
    "t": 101
  },
  "ahqia4": {
    "n": "Is a kitchen hood present with a capacity of at least 300 m3/h with the exhaust directly to the outside?",
    "t": 3
  },
  "ahqia4c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qia5": {
    "n": "Outdoor air filtration",
    "t": 101
  },
  "ahqia5": {
    "n": "In case the building is situated at a location with poor outdoor air quality, is filtration present in the fresh air supply?",
    "t": 3
  },
  "ahqia5c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qaq1": {
    "n": "Inside system noise",
    "t": 101
  },
  "ahqaq1": {
    "n": "Has extra attention been given to rooms that require extra quietness, such as bedrooms and study rooms?",
    "t": 3
  },
  "ahqaq1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qaq2": {
    "n": "Acoustic privacy",
    "t": 101
  },
  "ahqaq2": {
    "n": "Are inner walls and floor divisions designed to reduce noise transmission between rooms?",
    "t": 3
  },
  "ahqaq2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qaq3": {
    "n": "External spaces",
    "t": 101
  },
  "ahqaq3": {
    "n": "In case external spaces are present, such as a garden or balcony, have measures been taken to create a quiet environment?",
    "t": 3
  },
  "ahqaq3c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qaq4": {
    "n": "Outdoor noise",
    "t": 101
  },
  "ahqaq4": {
    "n": "Does at least one facade with operable windows have an external sound level of maximum 50 dB?",
    "t": 3
  },
  "ahqaq4c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qeg1": {
    "n": "Onsite control of solutions and products",
    "t": 101
  },
  "ahqeg1": {
    "n": "Has onsite control focused on proving that the energy solutions and products meet the designed level?",
    "t": 3
  },
  "ahqeg1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qeg2": {
    "n": "Air permeability of the building is maximum 1 ACH at 50 Pa", "t": 101
  },
  "ahqeg2": {
    "n": "Has the air permeability of the building been tested?",
    "t": 3
  },
  "ahqeg2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qeg3": {
    "n": "Thermal bridges",
    "t": 101
  },
  "ahqeg3": {
    "n": "Have the thermal bridges been evaluated during the construction phase?",
    "t": 3
  },
  "ahqeg3c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qeg4": {
    "n": "Qualification of the controller",
    "t": 101
  },
  "ahqeg4": {
    "n": "Has the control been established by a certified expert?",
    "t": 3
  },
  "ahqeg4c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qeg5": {
    "n": "Real world validation after one year",
    "t": 101
  },
  "ahqeg5": {
    "n": "After completion of the building, does the real world energy performance meet the designed performance?",
    "t": 3
  },
  "ahqeg5c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qed1": {
    "n": "Demand on individual products and construction elements",
    "t": 101
  },
  "ahqed1": {
    "n": "Have the chosen products and construction solutions been evaluated from a cost-efficient, life-cost perspective and maintenance view?",
    "t": 3
  },
  "ahqed1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qed2": {
    "n": "Architectural design solutions",
    "t": 101
  },
  "ahqed2": {
    "n": "Have architectural design solutions been used to reach a holistic approach of the building, as well as to reach a low energy demand?",
    "t": 3
  },
  "ahqed2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qed3": {
    "n": "Passive cooling solutions",
    "t": 101
  },
  "ahqed3": {
    "n": "Have solutions been included in the design to minimise the need for active cooling through air conditioning?",
    "t": 3
  },
  "ahqed3c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qed4": {
    "n": "Simulation method",
    "t": 101
  },
  "ahqed4": {
    "n": "Has dynamic model simulation been used to gain deeper insight into the energy performance?",
    "t": 3
  },
  "ahqed4c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qes1": {
    "n": "Design",
    "t": 101
  },
  "ahqes1": {
    "n": "Has integration of renewable energy been worked with as a part of the building design and typology of the building and the plot?",
    "t": 3
  },
  "ahqes1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qes2": {
    "n": "Origin of energy supply",
    "t": 101
  },
  "ahqes2": {
    "n": "Has the energy supply been evaluated from a cost perspective, and how was the decision about the origin of the energy supply made?",
    "t": 3
  },
  "ahqes2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qpe1": {
    "n": "Energy use and CO2 emissions",
    "t": 101
  },
  "ahqpe1": {
    "n": "Has the non-renewable energy source been optimised such as to have the lowest primary energy factor and lowest emissions?",
    "t": 3
  },
  "ahqpe1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qev1": {
    "n": "Space for comments",
    "t": 101
  },
  "ahqev1": {
    "n": "Do youhave comments?",
    "t": 3
  },
  "ahqev1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qsc1": {
    "n": "LCA of the building",
    "t": 101
  },
  "ahqsc1": {
    "n": "Were the results of an LCA used to optimise the design?",
    "t": 3
  },
  "ahqsc1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qsc2": {
    "n": "Responsible sourced wood",
    "t": 101
  },
  "ahqsc2": {
    "n": "Has effort been made to use as much certified wood as possible (FSC, PEFC)?",
    "t": 3
  },
  "ahqsc2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qsc3": {
    "n": "Responsible sourced building materials",
    "t": 101
  },
  "ahqsc3": {
    "n": "Has effort been made to use as many building materials as possible with a label indicating responsible origin or production?",
    "t": 3
  },
  "ahqsc3c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qsc4": {
    "n": "Job site management",
    "t": 101
  },
  "ahqsc4": {
    "n": "Were the following items considered to minimise the impact of the job site?\n • Optimise the management of construction waste\n • Limit nuisance and pollution\n • Minimise resource consumption\n • Respect workers",
    "t": 3
  },
  "ahqsc4c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qsc5": {
    "n": "Disassembly",
    "t": 101
  },
  "ahqsc5": {
    "n": "Has the building been designed so that 70% by weight of the building will be able to be reused, recycled or backfilled at its end of life? This will meet and anticipate the European Directive on construction waste.\n (European Directive from 2008 (2008/98/EC)\n • Waste hierarchy\n • Construction and demolition waste: 70% by weight by 2020 (re-use, recycling and backfilling of 70%).",
    "t": 3
  },
  "ahqsc5c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qsc6": {
    "n": "Biodiversity",
    "t": 101
  },
  "ahqsc6": {
    "n": "Has respect for fauna, flora and the environment been considered, in order to provide nests for birds, create green vegetation, minimise chemical treatment, etc.?",
    "t": 3
  },
  "ahqsc6c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qfw1": {
    "n": "Appliances",
    "t": 101
  },
  "ahqfw1": {
    "n": "Have water saving appliances been installed?",
    "t": 3
  },
  "ahqfw1c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qfw2": {
    "n": "Use of grey or rain water",
    "t": 101
  },
  "ahqfw2": {
    "n": "Is grey or rain water used to reduce the water consumption in garden, toilets and for washing?",
    "t": 3
  },
  "ahqfw2c": {
    "n": "Comment",
    "t": 0
  },
  "aht_qfw3": {
    "n": "Storm water runoff",
    "t": 101
  },
  "ahqfw3": {
    "n": "Are measures in place to prevent rainwater from entering the sewerage system?",
    "t": 3
  },
  "ahqfw3c": {
    "n": "Comment",
    "t": 0
  }
};
