import { motion } from 'framer-motion';
import React from 'react';

export const OpenListAnimation: React.FC<{ isOpen: boolean }> = ({
  children,
  isOpen,
}) => (
  <>
    <motion.div
      initial={false}
      transition={{ duration: 0.3, delay: 0 }}
      animate={{
        height: isOpen ? 'auto' : 0,
        opacity: isOpen ? 1 : 0,
        y: isOpen ? 0 : -40,
        x: isOpen ? 0 : -40,
        pointerEvents: !isOpen ? 'none' : 'initial',
      }}
    >
      <motion.div transition={{ duration: 0.9, delay: 1 }}></motion.div>
      {children}
    </motion.div>
  </>
);
