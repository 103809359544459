import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { DaylightIcon } from 'src/ui/elements/icons';

export class DaylightQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_dl`,
        extraOptions: { 
          isAbsoluteTitle: true, 
          IconComponent: DaylightIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_dlsc`,
         },
      },

      { key: `lVariant.${variantcaseIndex}.Active.ahdlvalidation` }, 

      { key: `lVariant.${variantcaseIndex}.Active.ahdlopt` }, 

      // lVariant[current]  foreach lRoom[0..n]

      // end foreach
    ];
  }
}

export class DaylightRoomsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.n`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
          placeholderkey: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahndef`,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_dlsc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahdfs`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          {
            key: `lVariant.${variantcaseIndex}.Active.ahdlopt`,
            value: 2,
          }
        ]),
        extraOptions: { gridColSpan: 2, noGridIfHide: true  },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahdfsa`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          {
            key: `lVariant.${variantcaseIndex}.Active.ahdlopt`,
            value: 1,
          }
        ]),
        extraOptions: { gridColSpan: 2, noGridIfHide: true },
      },
      // {
      //   key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahdf`,
      //   shouldHide: (json: typeof globalJSON) =>
      //   jsonValuesUtils.or(json)([
      //     {
      //       key: `lVariant.${variantcaseIndex}.Active.ahdlopt`,
      //       value: 2,
      //     }
      //   ]),
      //   extraOptions: { gridColSpan: 2, noGridIfHide: true  },
      // },
      // {
      //   key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahtdl`,
      //   shouldHide: (json: typeof globalJSON) =>
      //   jsonValuesUtils.or(json)([
      //     {
      //       key: `lVariant.${variantcaseIndex}.Active.ahdlopt`,
      //       value: 1,
      //     }
      //   ]),
      //   extraOptions: { gridColSpan: 2, noGridIfHide: true },
      // },
    ];
  }
}






export class NotUsedRoomsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor() {
    this.keys = [{ key: 'aht_nur' }];
  }
}