import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { IndoorAirQualityIcon } from 'src/ui/elements/icons';

export class IndoorAirQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_ia`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: IndoorAirQualityIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_iasc`,
        },
      },
      { key: `lVariant.${variantcaseIndex}.Active.ahiavalidation` }, // Dropdown, or buttons yes/no
    ];
  }
}

export class IndoorAirUserInputDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      // { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahco2ufile`,
        shouldDisable: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHfiledata.aHfileOk`,
              value: !true,
            }
          ]),
        extraOptions: { gridColSpan: 6 },
      },
      // { key: `empty`, extraOptions: { gridColSpan: 2 } },

    ];
  }
}

export class IndoorAirRoomsJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.n`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
          placeholderkey: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahndef`,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_iasc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 1,
          roundDecimal: 1,
          score:true ,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahco2lvl`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.ahco2ufile`,
              value: true,
            }
          ]),
        extraOptions: { gridColSpan: 2, noGridIfHide:true },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_co2lvl`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.ahco2ufile`,
              value: false,
            }
          ]),
        extraOptions: { disabled:true, gridColSpan: 2, noGridIfHide:true },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahcolumnia`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          {
            key: `lVariant.${variantcaseIndex}.Active.ahco2ufile`,
            value: false,
          }
        ]),
        extraOptions: { gridColSpan: 1, disabled: true },
      },
    ];
  }
}
