import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { EnergySupplyIcon } from 'src/ui/elements/icons';

export class EnergySupplyQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_es`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: EnergySupplyIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_essc`,
        },
      },

      { key: `aht_qes1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqes1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqes1c`,
        extraOptions: { multiLine: true, },
      },
      { key: `aht_qes2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqes2` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqes2c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
