import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { CloudIcon } from 'src/ui/elements/icons';

export class EnvironmentQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      { key: `ahh_ev`, extraOptions: { isAbsoluteTitle: true, IconComponent: CloudIcon } },

      {
        key: `aht_el`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_elsc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elgwp`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 1 },
          ]),
        extraOptions: { gridColSpan: 3, roundDecimal: 3, disabled: true, noGridIfHide: true },
      }, // Dropdown (with selected item from dropdown)
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.lcaGWP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 2 },
          ]),
        extraOptions: { gridColSpan: 3, roundDecimal: 3, disabled: true, noGridIfHide: true },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_gwpsc`,
        extraOptions: { gridColSpan: 3, roundDecimal: 1, disabled: true, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elopd`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 1 },
          ]),
        extraOptions: { gridColSpan: 3, disabled: true, noGridIfHide: true },
      }, // Dropdown (with selected item from dropdown)
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.lcaODP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 2 },
          ]),
        extraOptions: { gridColSpan: 3, roundDecimal: 43, disabled: true, noGridIfHide: true },  // , roundDecimal: 2
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_opdsc`,
        extraOptions: { gridColSpan: 3, roundDecimal: 1, disabled: true, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elpocp`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 1 },
          ]),
        extraOptions: { gridColSpan: 3, disabled: true, noGridIfHide: true },
      }, // Dropdown (with selected item from dropdown)
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.lcaPOCP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 2 },
          ]),
        extraOptions: { gridColSpan: 3, roundDecimal: 43, disabled: true, noGridIfHide: true },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_pocpsc`,
        extraOptions: { gridColSpan: 3, roundDecimal: 1, disabled: true, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elap`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 1 },
          ]),
        extraOptions: { gridColSpan: 3, disabled: true, noGridIfHide: true },
      }, // Dropdown (with selected item from dropdown)
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.lcaAP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 2 },
          ]),
        extraOptions: { gridColSpan: 3, roundDecimal: 43, disabled: true, noGridIfHide: true },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_apsc`,
        extraOptions: { gridColSpan: 3, roundDecimal: 1, disabled: true, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elep`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 1 },
          ]),
        extraOptions: { gridColSpan: 3, disabled: true, noGridIfHide: true },
      }, // Dropdown (with selected item from dropdown)
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.lcaEP`,
        shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.or(json)([
            { key: `lVariant.${0}.Active.ahudefenvload`, value: 2 },
          ]),
        extraOptions: { gridColSpan: 3, roundDecimal: 43, disabled: true, noGridIfHide: true },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_epsc`,
        extraOptions: { gridColSpan: 3, roundDecimal: 1, disabled: true, score: true, },
      },

      { key: `empty`, extraOptions: { gridColSpan: 6 } },
      {
        key: `aht_sc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_ssc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ahr_scrc`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 2, },
      }, // calculated or maybe dropdown
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_rcsc`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 1, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ahr_scrv`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 2, },
      }, // calculated or maybe dropdown
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_rvsc`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 1, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ahr_sccw`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 2 },
      }, // calculated or maybe dropdown
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_cwsc`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 1, score: true, },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ahr_scve`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 2 },
      }, // calculated or maybe dropdown
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.ahr_vesc`,
        extraOptions: { gridColSpan: 3, disabled: true, roundDecimal: 1, score: true, },
      },

      { key: `empty`, extraOptions: { gridColSpan: 6 } },
      {
        key: `aht_fw`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_fwsc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      // {
      //   key: `lVariant.${variantcaseIndex}.Active.ahr_fwred`,
      //   extraOptions: { gridColSpan: 6 },
      // }, // b
    ];
  }
}
