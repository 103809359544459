export class IndoorairScore {
  val: number;
  constructor({ co2aboveoutdoor }: { co2aboveoutdoor?: number }) {
    if (!co2aboveoutdoor) {
      this.val = 5;
      return;
    }

    this.val =
      co2aboveoutdoor == 1
        ? 1
        : co2aboveoutdoor == 2
        ? 2
        : co2aboveoutdoor == 3
        ? 3
        : co2aboveoutdoor == 4
        ? 4
        : 5;
  }
}
