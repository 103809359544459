import { Box as BoxBase, Flex as FlexBase, FlexProps } from '@chakra-ui/core';
import styled from '@emotion/styled';
import React from 'react';

export const Box = BoxBase;

export const FlexRow = styled<React.FC<FlexProps>>(FlexBase)`
  flex-direction: row;
`;

export const FlexCol = styled<React.FC<FlexProps>>(FlexBase)`
  flex-direction: column;
`;

export const FlexAllCenter = styled<React.FC<FlexProps>>(FlexBase)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

export const FlexCenterPage: React.FC<{ maxWidth?: string | number }> = ({
  children,
  maxWidth,
}) => {
  return (
    <FlexAllCenter>
      <FlexAllCenter maxWidth={maxWidth} textAlign="center">
        {children}
      </FlexAllCenter>
    </FlexAllCenter>
  );
};
