import _ from 'lodash';
import { useContext, useEffect, useState } from "react";
import { JsonContext } from "src/features/shared/json/application/use_application_json";
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';



export const useVariantsLength = () => {
    const [variantsLength, setVariantsLength] = useState<number>(0);
    const { globalJson, onSaveKeyToGlobalDirectly, onMergeJsonWithGlobalJson } = useContext(JsonContext);
    const lVariantNumber = _.size(_.get(globalJson, 'lVariant')) - 1; // - 1 to ignore baseline
    const ArrayOfVariantsIndex = [...(Array(variantsLength).keys() as any)];

    const addNewVariant = () => {

        setVariantsLength(variantsLength + 1);

        initializeNewVariant(variantsLength + 1, onSaveKeyToGlobalDirectly);


    }

    const copyBaselineVariant = () => {

        setVariantsLength(variantsLength + 1);

        initializeBaselineVariant(globalJson, variantsLength + 1, onSaveKeyToGlobalDirectly);

    }


    useEffect(() => {

        setVariantsLength(lVariantNumber);

        // initializeBaselineVariant(globalJson, lVariantNumber, onSaveKeyToGlobalDirectly);

        // initializeNewVariant(lVariantNumber, onSaveKeyToGlobalDirectly);   // value, key
    }, [lVariantNumber]);

    return { ArrayOfVariantsIndex, setVariantsLength, variantsLength, addNewVariant, copyBaselineVariant };
};


function initializeBaselineVariant(globalJson: any, lVariantNumber: number, onSaveKeyToGlobalDirectly: any) {
    const baselinevariant = _.get(globalJson, 'lVariant.[0]');
    const neuvariantkey = `lVariant.[${lVariantNumber}]`;
    onSaveKeyToGlobalDirectly.callback(baselinevariant, neuvariantkey);

}

function initializeNewVariant(lVariantNumber: number, onSaveKeyToGlobalDirectly: any) {
    const baselinevariant = _.get(globalJSON, 'lVariant.[0]');
    const neuvariantkey = `lVariant.[${lVariantNumber}]`;
    onSaveKeyToGlobalDirectly.callback(baselinevariant, neuvariantkey);
    
}

