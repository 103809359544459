import { emotionStyled } from './shared';
import {
  Tab as TabChakra,
  TabList as TabsListChakra,
  Tabs as TabsChakra,
} from '@chakra-ui/core';
import { appColors, appFontSizes } from 'src/app/theme';

export { TabPanels, TabPanel } from '@chakra-ui/core';

export const Tabs = TabsChakra;
// export const Tabs ;

// Tabs.defaultProps = {
//   variantcase: 'unstyled',
//   colorScheme: 'green',
// };

export const TabList = TabsListChakra;

TabList.defaultProps = {
  borderBottomColor: appColors.gray.lightest,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
};

export const Tab = TabChakra;

Tab.defaultProps = {
  pl: 2,
  pr: 2,
  pb: 1,
  fontSize: appFontSizes.body,
  color: appColors.gray.dark,
  _selected: {
    color: appColors.primary.main,
    borderBottomColor: appColors.primary.main,
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
  },
  _focus: {
    boxShadow: 'none',
  },
};
