import { GridItem } from '@chakra-ui/core';
import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { appColors, appFontSizes } from 'src/app/theme';
import { i18n } from 'src/features/shared/translations/translate';
import { ButtonBase, P } from '..';
import { Box, FlexCol } from '../structure';
import { FormGridItem } from './grid_items';

const commonButtonProps = (
  bg: string,
  isActive: boolean,
  isLoading: boolean
) => ({
  mw: '72px',
  py: 2,
  _hover: {
    bg,
  },
  _active: {
    bg,
    transform: 'scale(0.90)',
  },
  _focus: {
    boxShadow: '0 0 1px 2px rgba(244, 183, 64, .75), 0 1px 1px rgba(0, 0, 0, .15)',
  },
  bg: isActive && !isLoading ? bg : appColors.secondary.light,
});


const smallButtonProps = (
  isActive: boolean,
  isLoading: boolean
) => ({
  mw: '72px',
  py: 2,
  border: '1px',
  h: '42px',
  borderColor: appColors.gray[500],
  _hover: {
    borderColor: isActive ? appColors.primary.main : appColors.red[500],
  },
  _active: {
    bg: appColors.white
    // transform: 'scale(0.90)',
  },
  bg: isActive && !isLoading ? appColors.white : appColors.white,
});


let alreadyRan = false;

export const NewLineLable: React.FC<any> = ({ text }) => {
  return (
    <>
      <P fontWeight="400" color={appColors.gray.dark}>
        {text}
      </P>
    </>
  );
};

export const YesNoQuestion = ({
  onChange,
  value,
  label,
  gridColSpan,
  noLabel,
  preLabel,
}: {
  onChange: (value: boolean) => any;
  value: boolean;
  label: string;
  gridColSpan?: number;
  noLabel: boolean;
  preLabel: string;
}) => {
  const time = new Date();
  time.setSeconds(time.getMilliseconds() + 200);

  const { isRunning, start } = useTimer({
    expiryTimestamp: 1000,
    onExpire: () => {
      alreadyRan = true;
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => start(), []);

  const isLoading = !alreadyRan && isRunning;

  if (gridColSpan == 1) {
    return (
      <>
        <GridItem mt={2} colSpan={gridColSpan} >
        <FlexCol flex={1}>
            <P
              style={{ whiteSpace: 'nowrap' }}
              fontSize={appFontSizes.small}
              textAlign="left"
              mb="4px"
            >
              {preLabel}{noLabel ? null : label}
            </P>
            <ButtonBase
              mx={0}
              mt={0}
              w="100%"
              isLoading={isLoading}
              {...smallButtonProps(value, isLoading)}
              onClick={() => onChange(!value)}
            >
              <P color={value ? appColors.primary.main : appColors.red[500] }>{value ? i18n.t(`pages.yes`) : i18n.t(`pages.no`)}</P>
            </ButtonBase>
          </FlexCol>
        </GridItem>
      </>
    );


  } else {
    const thisLabeGridColSpan = !gridColSpan ? 4 : gridColSpan - 2;
    const lablesplit = label.split(/\r?\n/);
    return (
      <>
        <GridItem mt={3} colSpan={thisLabeGridColSpan} >
          {lablesplit.map((a, i) => (
            <NewLineLable text={a} />
          ))}
        </GridItem>
        <GridItem mt={3} colSpan={2}>
          <Box
            textAlign="right"
          >
            <ButtonBase
              mx={1}
              mb={1}
              isLoading={isLoading}
              {...commonButtonProps(appColors.primary.main, value, isLoading)}
              onClick={() => onChange(true)}
            >
              <P color="white">{i18n.t(`pages.yes`)}</P>
            </ButtonBase>
            <ButtonBase
              mx={1}
              mb={1}
              isLoading={isLoading}
              {...commonButtonProps(appColors.red[500], !value, isLoading)}

              onClick={() => onChange(false)}
            >
              <P color="white">{i18n.t(`pages.no`)}</P>
            </ButtonBase>
          </Box>
        </GridItem>
      </>
    );
  }
};

