import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { PrimaryEnergyDemandIcon } from 'src/ui/elements/icons';

export class PrimaryEnergyQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_pe`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: PrimaryEnergyDemandIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_pesc`,
        },
      },

      { key: `aht_qpe1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqpe1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqpe1c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
