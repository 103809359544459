import React, { } from 'react';
import { FormTextInput, FormSimpleOutput, FormTextAreaInput, FormNumberInput } from 'src/ui/form_elements';
import _ from 'lodash';
import { NumberField, TextField } from '../domain/field_definitions';
import { roundDecimals } from 'src/features/calculation/domain/sub_calculations/helper_calculations';

import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';
import { i18n } from '../../translations/translate';
import { unitConvertToIP, unitConvertToSI } from 'src/ui/calc/unitconverter';


export function createTextField({
  fieldDefinition,
  json,
  onUpdate,
  onSave,
  errors,
  type,
  userDefinedUnit,
}: {
  fieldDefinition: TextField | NumberField;
  json: any;
  // onUpdate: (value: any, field: any) => void;
  onUpdate: DebouncedState<(value: any, field: any, unit: any) => void>;
  onSave: (fieldDefinition: TextField | NumberField) => void;
  errors: any;
  type: any;
  userDefinedUnit: string;
}) {
  // const { globalJson } = useContext(JsonContext)
  const key = fieldDefinition?.fieldData?.key;
  if (!key) return null;

  const roundDecimal = fieldDefinition?.fieldData?.extraOptions?.roundDecimal;

  const isOutOfScore = fieldDefinition?.fieldData?.extraOptions?.score && _.get(json, key) >= 5;
  const unit = fieldDefinition.unit;
  const isDisabled = fieldDefinition?.fieldData?.extraOptions?.disabled;

  // unit converstion, if IP units used, isDisabled indicates output (SI->IP), else input(IP->SI)
/*   const convertedValue = //unitConvertToIP(_.get(json, key), unit);
    isDisabled ?
      unitConvertToIP(_.get(json, key), unit) :
      unitConvertToSI(_.get(json, key), unit); */
  const convertedValue =
    unitConvertToIP(_.get(json, key), unit);
  

  const value = isOutOfScore ?
  fieldDefinition?.fieldData?.extraOptions?.score == 2 ? i18n.t(`radar.outsmall`) : i18n.t(`radar.out`) :
    roundDecimal ? roundDecimals(convertedValue, roundDecimal) : 
    !isNaN(+convertedValue) ? convertedValue : _.get(json, key);

  const placeholderkey = fieldDefinition?.fieldData?.extraOptions?.placeholderkey;
  const placeholdervalue = placeholderkey ? _.get(json, placeholderkey) : "";

  // console.log(unit);
  let unitLabel = null;

  if (unit) {
    unitLabel = unit.currentSystem == 1 ? unit.siUnit : unit.ipUnit;
  }
  if (userDefinedUnit) {
    unitLabel = userDefinedUnit;
  }



  const label = fieldDefinition?.fieldData?.extraOptions?.noLabel
    ? ` `
    : fieldDefinition.label;

  let allErrors = { ...errors };

  const hasError = fieldDefinition.hasErrors(value);
  let errorText = '';
  if (!!hasError) {
    allErrors[key] = hasError;
    errorText = hasError;
  }


  if (fieldDefinition?.fieldData?.extraOptions?.multiLine) {
    return (
      <FormTextAreaInput
        placeholder={placeholdervalue}
        isDisabled={isDisabled}
        borderWidth={isDisabled ? 0 : 1}
        _disabled={{
          opacity: 1,
        }}
        gridColSpan={fieldDefinition?.fieldData?.extraOptions?.gridColSpan}
        maxLength={Number(fieldDefinition?.maxLength) ? Number(fieldDefinition?.maxLength) : 255}
        type={type}
        label={label}
        onChange={(e: any) => {
          onUpdate.callback(e.target.value, fieldDefinition, unit);
        }}
        onBlur={() => {
          if (!allErrors[key]) onSave(fieldDefinition);
        }}
        value={value}
        hasError={allErrors[key]}
        errorText={fieldDefinition?.fieldData?.error || errorText || 'error'}
        insideLabel={unitLabel as any}
        styleType={fieldDefinition?.fieldData?.extraOptions?.styleType}
        preLabel={fieldDefinition?.fieldData?.extraOptions?.preLabel}
      />
    )

  } else if (fieldDefinition?.fieldData?.extraOptions?.inline) {
    return (
      <FormSimpleOutput
        placeholder={placeholdervalue}
        isDisabled={isDisabled}
        borderWidth={isDisabled ? 0 : 1}
        _disabled={{
          opacity: 1,
        }}
        gridColSpan={fieldDefinition?.fieldData?.extraOptions?.gridColSpan}
        maxLength={Number(fieldDefinition?.maxLength) ? Number(fieldDefinition?.maxLength) : 255}
        type={type}
        label={label}
        onChange={(e: any) => {
          onUpdate.callback(e.target.value, fieldDefinition, unit);
        }}
        onBlur={() => {
          if (!allErrors[key]) onSave(fieldDefinition);
        }}
        value={value}
        hasError={allErrors[key]}
        errorText={fieldDefinition?.fieldData?.error || errorText || 'error'}
        insideLabel={unitLabel as any}
        styleType={fieldDefinition?.fieldData?.extraOptions?.styleType}
        preLabel={fieldDefinition?.fieldData?.extraOptions?.preLabel}
      />
    );
  } else if (type === 'text') {
    return (
      <FormTextInput
        placeholder={placeholdervalue}
        isDisabled={isDisabled}
        borderWidth={isDisabled ? 0 : 1}
        _disabled={{
          opacity: 1,
        }}
        gridColSpan={fieldDefinition?.fieldData?.extraOptions?.gridColSpan}
        maxLength={Number(fieldDefinition?.maxLength) ? Number(fieldDefinition?.maxLength) : 255}
        type={type}
        label={label}
        onChange={(e: any) => {
          onUpdate.callback(e.target.value, fieldDefinition, unit);
        }}
        onBlur={() => {
          if (!allErrors[key]) onSave(fieldDefinition);
        }}
        value={value}
        hasError={allErrors[key]}
        errorText={fieldDefinition?.fieldData?.error || errorText || 'error'}
        insideLabel={unitLabel as any}
        styleType={fieldDefinition?.fieldData?.extraOptions?.styleType}
        preLabel={fieldDefinition?.fieldData?.extraOptions?.preLabel}
      />
    );
  } else {
    return (
      <FormNumberInput
        placeholder={placeholdervalue}
        isDisabled={isDisabled}
        borderWidth={isDisabled ? 0 : 1}
        _disabled={{
          opacity: 1,
        }}
        gridColSpan={fieldDefinition?.fieldData?.extraOptions?.gridColSpan}
        maxLength={Number(fieldDefinition?.maxLength) ? Number(fieldDefinition?.maxLength) : 255}
        type={type}
        label={label}
        onChange={(e: any) => {
          onUpdate.callback(e.target.value, fieldDefinition, unit);
        }}
        onBlur={() => {
          if (!allErrors[key]) onSave(fieldDefinition);
        }}
        value={value}
        hasError={allErrors[key]}
        errorText={fieldDefinition?.fieldData?.error || errorText || 'error'}
        insideLabel={unitLabel as any}
        styleType={fieldDefinition?.fieldData?.extraOptions?.styleType}
        preLabel={fieldDefinition?.fieldData?.extraOptions?.preLabel}
        unit={unit}
      />
    );
  }
}
