import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

export async function createPdf() {
  const pdfDoc = await PDFDocument.create()
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

  const page = pdfDoc.addPage()
  const { width, height } = page.getSize()
  const fontSize = 30
  page.drawText('Creating PDFs in JavaScript is awesome!', {
    x: 50,
    y: height - 4 * fontSize,
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0.53, 0.71),
  })

  const pdfBytes = await pdfDoc.save()


    // download
  var blob = new Blob([pdfBytes], {type: "application/pdf"});
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = 'file.pdf';
  link.download = fileName;
  link.click();

}

export async function modifyPdf() {
  const url = 'DaylightScoreCertificat.pdf'
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]
  const { width, height } = firstPage.getSize()
  firstPage.drawText('This text was added', {
    x: 110,
    y: 608,
    size: 26,
    font: helveticaFont,
    color: rgb(0.95, 0.1, 0.1),
    // rotate: degrees(-45),
  })

  const pdfBytes = await pdfDoc.save()

  // download
  var blob = new Blob([pdfBytes], {type: "application/pdf"});
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = 'file.pdf';
  link.download = fileName;
  link.click();
}