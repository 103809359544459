import React from 'react';
import { useSystemTypeWithIndex } from 'src/features/project_information/presentation/applications/check_type_of_systems';
import {
  HeatSupplyQuantityJsonDefinition,
  HeatSupplySytemsJsonDefinition,
} from '../../../data/quantitative/energysupply_json_definition';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { Box, Divider, FlexRow, P } from 'src/ui';
import { getVariantCaseNumber, useWithVariantCaseIndex } from '../use_with_variantcase_index';
import { useNumberOfSystems } from 'src/features/project_information/presentation/applications/use_number_of_systems';
import { useParams } from 'react-router';
import { i18n } from 'src/features/shared/translations/translate';
import { useListOfSystems } from '../use_systems_list';
import { NotUsedSystemsJsonDefinition } from 'src/features/baseline/data/quantitative/energydemand_json_definition';
import { appColors } from 'src/app/theme';
import { NotUsedSystem } from './energydemand';
import { TrashIcon } from 'src/ui/elements/icons';

export const SystemHeatSupply: React.FC<any> = ({
  variantcase,
  index,
  typeOfSystem,
  remove,
  systemIsUsed
}) => {
  const Json = new HeatSupplySytemsJsonDefinition(variantcase, index);
  if (typeOfSystem === useSystemTypeWithIndex(index, variantcase) && systemIsUsed(index)) {
    return (
      <>
        <FlexRow alignItems="center" mb="4">
          <FormBuilder jsonKeys={Json.keys} />
          <FlexRow
            mt={4}
            ml={1}
            py={2}
            alignSelf="self-start"
            as="button"
            onClick={() => remove(index, 3)}
          >
            <AnimatePageEnter>
              <TrashIcon />
            </AnimatePageEnter>
          </FlexRow>
        </FlexRow>

        <Divider my={2} />
      </>
    );
  } else {
    return <></>;
  }
};

export const EnergySupplyQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(HeatSupplyQuantityJsonDefinition);

  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;

  const { systems, removeThisSystem, bringBackThisSystem, getSystemName, systemIsUsed, notUsedSystemsDefined } = useListOfSystems(variantcaseIndex);
  const mappingSystems = [...(Array(systems).keys() as any)];

  const deviderDefinition = new NotUsedSystemsJsonDefinition();

  return (
    <>
      <FormBuilder jsonKeys={keys} />
      <Box my={-2}>
        {mappingSystems.length > 0 ? null : <P>{i18n.t(`pages.NoSystemdefined`)}</P> }
        {mappingSystems.map((a, i) => (
          <AnimateList key={i}>
            <SystemHeatSupply
              key={systems}
              variantcase={variantcaseIndex}
              index={a}
              typeOfSystem={3}
              remove={removeThisSystem} 
              systemIsUsed={systemIsUsed}
            />
          </AnimateList>
        ))}
      </Box>
      {notUsedSystemsDefined(3) ? (
        <>
        <FormBuilder jsonKeys={deviderDefinition.keys} />
        <Box my={-2}>
          <P color={appColors.gray.light}>Bring back system:</P>
          {mappingSystems.map((a, i) => (
              <NotUsedSystem key={systems} variantcase={variantcaseIndex} index={a} bringback={bringBackThisSystem} usedfortype={3} getSystemName={getSystemName} systemIsUsed={systemIsUsed}/>
          ))}
        </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EnergySupplyQuantityPage;
