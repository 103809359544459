import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { HouseIcon } from 'src/ui/elements/icons';

export class ComfortQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `ahh_cf`,
        extraOptions: { isAbsoluteTitle: true, IconComponent: HouseIcon },
      },
      { key: `aht_qcf1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqcf1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqcf1c`,
        extraOptions: {multiLine: true,}, 
      },
    ];
  }
}
