import React from 'react';
import { useParams } from 'react-router';
import { appColors } from 'src/app/theme';
import {
  EnergyDemandQuantityJsonDefinition,
  EnergyDemandSytemsJsonDefinition,
  NotUsedSystemsJsonDefinition,
} from 'src/features/baseline/data/quantitative/energydemand_json_definition';
import { useSystemTypeWithIndex } from 'src/features/project_information/presentation/applications/check_type_of_systems';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, Divider, FlexRow, P } from 'src/ui';
import { TrashIcon } from 'src/ui/elements/icons';
import { useListOfSystems } from '../use_systems_list';
import {
  getVariantCaseNumber,
  useWithVariantCaseIndex,
} from '../use_with_variantcase_index';

export const NotUsedSystem: React.FC<any> = ({ variantcase, index, bringback, usedfortype, getSystemName, systemIsUsed }) => {

  if (!(usedfortype === useSystemTypeWithIndex(index, variantcase)))
  {
    return <> </>;
  }
  else
  {

  
  if (systemIsUsed(index))
  {
    return <> </>;
  }
    else
    {
      return (
        <>
        
            <P
              mr="20px"
              as="button"
              color={appColors.primary.main}
              onClick={() => bringback(index)}
            >
                {getSystemName(index)}
                {/* <ReUseIcon /> */}
            </P>

        </>
      );    
    }
  }


};

export const SystemEnergy: React.FC<any> = ({
  variantcase,
  index,
  typeOfSystem,
  remove,
  systemIsUsed
}) => {
  const Json = new EnergyDemandSytemsJsonDefinition(variantcase, index);
  if (typeOfSystem === useSystemTypeWithIndex(index, variantcase) && systemIsUsed(index)) {
    return (
      <>
        <FlexRow alignItems="center">
          <FormBuilder jsonKeys={Json.keys} />
          <FlexRow
            mt={4}
            ml={1}
            py={2}
            alignSelf="self-start"
            as="button"
            onClick={() => remove(index, 1)}
          >
            <AnimatePageEnter>
              <TrashIcon />
            </AnimatePageEnter>
          </FlexRow>
        </FlexRow>

        {/* <Divider my={2} /> */}
      </>
    );
  } else {
    return <></>;
  }
};

export const EnergyDemandQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(EnergyDemandQuantityJsonDefinition);

  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { systems, removeThisSystem, bringBackThisSystem, getSystemName, systemIsUsed, notUsedSystemsDefined } = useListOfSystems(variantcaseIndex);
  const mappingSystems = [...(Array(systems).keys() as any)];

  const deviderDefinition = new NotUsedSystemsJsonDefinition();

  return (
    <>
      <FormBuilder jsonKeys={keys} />
      {/* <Divider my={2} /> */}
      <Box my={-2}>
      {mappingSystems.length > 0 ? null : <P>{i18n.t(`pages.NoSystemdefined`)}</P> }
      {mappingSystems.map((a, i) => (
        <AnimateList key={i}>
          <SystemEnergy
            key={systems}
            variantcase={variantcaseIndex}
            index={a}
            typeOfSystem={1}
            remove={removeThisSystem} 
            systemIsUsed={systemIsUsed}
          />
        </AnimateList>
      ))}
      </Box>

      {notUsedSystemsDefined(1) ? (
        <>
        <FormBuilder jsonKeys={deviderDefinition.keys} />
        <Box my={-2}>
          <P color={appColors.gray.light}>Bring back system:</P>
          {mappingSystems.map((a, i) => (
              <NotUsedSystem key={systems} variantcase={variantcaseIndex} index={a} bringback={bringBackThisSystem} usedfortype={1} getSystemName={getSystemName} systemIsUsed={systemIsUsed}/>
          ))}
        </Box>
        </>
      ) : (
        <></>
      )}


    </>
  );
};

export default EnergyDemandQuantityPage;


