import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { i18n } from 'src/features/shared/translations/translate';
import { ClipIcon } from 'src/ui/elements/icons';

export class ManagementQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_mgn`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: ClipIcon,
          // titleValue: `lVariant.${variantcaseIndex}.Active.ahr_pesc`,
        },
      },

      { key: `lVariant.${variantcaseIndex}.n`,
        extraOptions: { preLabel: i18n.t(`prelabel.case`) }, 
      },

      { key: `aht_qm1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqm11` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqm1c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qm2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqm21` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqm2c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qm3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqm31` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqm3c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
