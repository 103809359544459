import React from 'react';
import { useParams } from 'react-router';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { Box, FlexRow, P } from 'src/ui';
import {
  IndoorAirQuantityJsonDefinition,
  IndoorAirRoomsJsonDefinition,
  IndoorAirUserInputDefinition,
} from '../../../data/quantitative/indoorair_json_definition';
import {
  getVariantCaseNumber,
  useWithVariantCaseIndex,
} from '../use_with_variantcase_index';

import { useListOfRooms } from '../use_rooms_list';
import { DynamicSimulationFileUplad } from '../auxiliary_pages/dynamic_file_upload';
import { useFileData } from '../use_file_data';
import { NotUsedRoomsJsonDefinition } from 'src/features/baseline/data/quantitative/daylight_json_definition';
import { appColors } from 'src/app/theme';
import { NotUsedRoom } from './daylight';
import { TrashIcon } from 'src/ui/elements/icons';

export const RoomIndoorAir: React.FC<any> = ({ variantcase, index, remove, roomIsUsed }) => {
  const Json = new IndoorAirRoomsJsonDefinition(variantcase, index);

  if (!roomIsUsed(index,3)) return <> </>;

  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        <FlexRow
          mt={4}
          ml={1}
          py={2}
          alignSelf="self-start"
          as="button"
          onClick={() => remove(index, 3)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexRow>
      </FlexRow>
    </>
  );
};

export const IndoorAirUserDefined: React.FC<any> = ({ variantcase }) => {
  const Json = new IndoorAirUserInputDefinition(variantcase);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};


export const IndoorAirQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(IndoorAirQuantityJsonDefinition);

  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { isDynamicIndoorAir } = useFileData(variantcaseIndex);
  const { rooms, removeThisRoom, bringBackThisRoom, getRoomName, roomIsUsed, notUsedRoomsDefined } = useListOfRooms(variantcaseIndex);
  const mappingRooms = [...(Array(rooms).keys() as any)];

  const deviderDefinition = new NotUsedRoomsJsonDefinition();

  return (
    <>
      <FormBuilder jsonKeys={keys} />
      {isDynamicIndoorAir ? <>
      <DynamicSimulationFileUplad variantcase={variantcaseIndex} />
      <IndoorAirUserDefined variantcase={variantcaseIndex} />
      </> : null}
      <Box my={0}> 
      {/* <Divider my={-1.5} size={5}/> */}
      {mappingRooms.map((a, i) => (
        <AnimateList key={i}>
          <RoomIndoorAir key={rooms} variantcase={variantcaseIndex} index={a} remove={removeThisRoom} roomIsUsed={roomIsUsed} />
        </AnimateList>
      ))}
      </Box>

      {notUsedRoomsDefined(3) ? (
        <>
        <FormBuilder jsonKeys={deviderDefinition.keys} />
        <Box my={-2}>
          <P color={appColors.gray.light}>Bring back room:</P>
          {mappingRooms.map((a, i) => (
              <NotUsedRoom key={rooms} variantcase={variantcaseIndex} index={a} bringback={bringBackThisRoom} usedfortype={3} getRoomName={getRoomName} roomIsUsed={roomIsUsed}/>
          ))}
        </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default IndoorAirQuantityPage;
