import { TextProps } from '@chakra-ui/core';
import React from 'react';
import { appFontSizes } from 'src/app/theme';
import { P } from '..';
import { FormGridItem } from './grid_items';

export const CurrentStatus = ({
  label,
  value,
  gridColSpan,
}: {
  fontSize?: typeof appFontSizes;
  textProps?: TextProps;
  label: string;
  value: string;
  gridColSpan: number;
}) => {
  return (
    <FormGridItem colSpan={gridColSpan} mt={0} mx={0}>
      <P
      //color={textProps?.color || appColors.primary.dark}
      //fontSize={textProps?.fontSize || appFontSizes.body}
      //fontWeight="bold"
      >
          
        {label} : {value}
      </P>
    </FormGridItem>
  );
};
