import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { AcousticQualityIcon } from 'src/ui/elements/icons';

export class AcousticQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_aq`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: AcousticQualityIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_aqsc`,
        },
      },

      { key: `aht_qaq1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq1c`,
        extraOptions: {multiLine: true,}, 
      },
      { key: `aht_qaq2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq2c`,
        extraOptions: {multiLine: true,}, 
      },
      { key: `aht_qaq3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq3c`,
        extraOptions: {multiLine: true,}, 
      },
      { key: `aht_qaq4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqaq4c`,
        extraOptions: {multiLine: true,}, 
      },
    ];
  }
}
