import _ from 'lodash';
import { useContext } from 'react';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';

export const useAreadTypeWithIndex = (variantcase: number, index: number) => {
  const { globalJson } = useContext(JsonContext);

  const typeOfAreaPath = `lVariant.[${variantcase}].building.lComponent.[${index}].eco.typeCah`;
  const typeOfArea = _.get(globalJson, typeOfAreaPath);

  return typeOfArea;
};
