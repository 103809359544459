import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { AcousticQualityIcon } from 'src/ui/elements/icons';

export class AcousticQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_aq`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: AcousticQualityIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_aqsc`,
        },
      },
      // { key: `lVariant.${variantcaseIndex}.Active.ahdlvalidation` }, // Dropdown, or buttons yes/no
      { key: `lVariant.${variantcaseIndex}.Active.ahaqoutdbuse` }, 
    ];
  }
}

export class AcousticRoomsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.n`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
          placeholderkey: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahndef`,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahr_aqsc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahaqindb`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahaqoutdb`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lVariant.${variantcaseIndex}.building.lZone.0.lRoom.${index}.Active.ahaqprdb`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
          { key: `lVariant.${variantcaseIndex}.Active.ahaqoutdbuse`, value: 2 },
        ]),
        extraOptions: { gridColSpan: 2 },
      },
    ];
  }
}
