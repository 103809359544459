import { APP_FONT_SIZE_BASE } from "./spaces";

export const getSpaces = (base: number) => {
  const Spacing = {
    value: (v: number) => v * APP_FONT_SIZE_BASE + "px",
  };
  const spaces = [];
  let i = 0;
  while (i < 40) {
    spaces.push(i);
    i = i + 0.5;
  }
  const appSpaces: any = {};
  spaces.forEach((a) => {
    appSpaces[a] = Spacing.value(a);
  });
  return appSpaces;
};
