import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { getRandomInt } from 'src/features/shared/utils';


export const useNumberOfSystemDevices = (
  indexVariant:number,
  indexSystem: number,
  facade = globalJsonFacade
) => {
  const [devices, setDevices] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForSystems = _.get(globalJson, `lVariant.[${indexVariant}].HVAC.lSystem`);

  const draftJsonForDevices = draftJsonForSystems
    ? _.get(draftJsonForSystems[indexSystem], `lDevice`)
    : null;

  const numberOfDevices = _.size(draftJsonForDevices);

  const removeDevice = (indexVariant: number, indexSystem: number, indexDevice: number) => {
    const newDraftJsonForDevice = [...(draftJsonForDevices as any)];

    const filtered = newDraftJsonForDevice.filter(function (value, i, arr) {
      return i !== indexDevice;
    });

    const newDraftJson = _.set(
      globalJson,
      `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice`,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftForDevices = _.get(newDraftJson, `lVariant.[${indexVariant}].HVAC.lSystem[${indexSystem}].lDevice`);
      const numberOfDevices = _.size(draftForDevices);
      setDevices(numberOfDevices);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addDevice = () => {
    setDevices(devices + 1);

    const randomId = _.uniqueId("10") + getRandomInt(50000);   // get a random id
    _.set(
      globalJson,
      `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${devices}].id`,
      randomId
    );
    
    _.set(
      globalJson,
      `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${devices}].Active.ahheatDev`,
      5
    );

    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const isHeatPumpRenewablepart = () => {
    const DeviceRenewableType = _.get(
      globalJson,
      `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[0].Active.ahdeviceThrenw`
    );
    return DeviceRenewableType != 6;
  }

  useEffect(() => {
    setDevices(numberOfDevices);
  }, [numberOfDevices]);

  return {
    devices,
    addDevice: addDevice,
    removeDevice,
    isHeatPumpRenewablepart,
  };
};
