import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { i18n } from 'src/features/shared/translations/translate';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from 'src/ui/tabs';

export const QuantitativeHeatingElectricityTabs = ({
  Qualitative,
  SpaceHeating,
  Electricity,
}: any) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const onChange = (index: number) => {
    if (index === 0) {
      replace(pathname.replace(/thermal|electric/g, 'qualitative'));
    } else if (index === 1 ) {
      replace(pathname.replace(/qualitative|electric/g, 'thermal'));
    } else {  // index === 2
      replace(pathname.replace(/qualitative|thermal/g, 'electric'));
    }
  };

  const defaultIndex = 
    pathname.includes('qualitative') ? 0 
    : pathname.includes('thermal') ? 1 
    : 2

  return (
    <Tabs
      pt={12}
      defaultIndex={defaultIndex}
      onChange={(index: number) => onChange(index)}
    >
      <TabList>
        <Tab>{i18n.t(`tabs.qualitative`)}</Tab>
        <Tab>{i18n.t(`tabs.thermal`)}</Tab>
        <Tab>{i18n.t(`tabs.electric`)}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>{Qualitative}</TabPanel>
        <TabPanel px={0}>{SpaceHeating}</TabPanel>
        <TabPanel px={0}>{Electricity}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const QuantitativeHeatingElectricityPage = ({
  name,
}: {
  name: string;
}) => {
  const Quality = useMemo(() => require(`./qualitative/${name}`).default, [
    name,
  ]);
  const SpaceHeating = useMemo(
    () => require(`./thermal/${name}`).default,
    [name]
  );
  const Electricity = useMemo(
    () => require(`./electric/${name}`).default,
    [name]
  );

  if (!Quality || !SpaceHeating || !Electricity) return null;

  return (
    <>
      <QuantitativeHeatingElectricityTabs
        Qualitative={<Quality />}
        SpaceHeating={<SpaceHeating />}
        Electricity={<Electricity />}
      />
    </>
  );
};

export const EnergyDemand = () => {
  return <QuantitativeHeatingElectricityPage name="energydemand" />;
};

export const EnergySupply = () => {
  return <QuantitativeHeatingElectricityPage name="energysupply" />;
};
