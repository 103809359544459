import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { ComfortQualityJsonDefinition } from '../../../data/qualitative/comfort_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const ComfortQualityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(ComfortQualityJsonDefinition);

  return <FormBuilder jsonKeys={keys} />;
};

export default ComfortQualityPage;
