import _ from 'lodash';
import { Unit } from 'src/features/shared/form/domain/unit';
import { getNameOfDefinitionEnum } from 'src/features/shared/form/utils/get_name_for_definition_enum';
import { oekobauJson } from 'src/features/shared/json/data/source/oekobau.data';
import { unitsJson } from 'src/features/shared/json/data/source/units_json';
import { Calculation } from "../calculation";
import { calculationErrors } from "../calculation_errors";
import { FreshwaterScore } from './environment_calculations/freshwater_score';
import { APScore, EnvironmentLoadScore, EPScore, GWPScore, ODPScore, POCPScore } from './environment_calculations/loads_score';
import { DeclaredOriginScore, RecyclableScore, RecycledScore, ResponsibleWoodScore, SustainableScore } from './environment_calculations/sustainable_score';
import { TotalSustainableScore } from './environment_calculations/total_sustainable_score';

export class EnvironmentCalculations implements Calculation {
  i: number;
  draftJson: any;

  public constructor({ i, draftJson, comfortCalculations }: any) {
    this.i = i;
    this.draftJson = { ...draftJson };
  }

  public calculate() {
    const { draftJson, i } = this;
    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;

    this.updatesFreshwaterScore();

    const buildingLifetime = _.get(draftJson, `lVariant.${i}.Active.aHlifetime`);

    this.calculateConstructions(buildingLifetime);
    this.calculateWindowsDoors(buildingLifetime);
    this.calculateSystems(buildingLifetime);
    this.calculateOperations(buildingLifetime);


    this.calculateBuildingEnvironment();

    return draftJson;

  }



  private getOekobauData = (id: string) => {
    const draftJsonForOekobau = _.get(oekobauJson, 'oekobau');
    const selectedMaterialData = draftJsonForOekobau.filter(function (value, i, arr) {
      return value.id == id;
    });
    return selectedMaterialData[0];
  };

  private getOekobauDataByName = (name: string) => {
    const draftJsonForOekobau = _.get(oekobauJson, 'oekobau');
    const selectedMaterialData = draftJsonForOekobau.filter(function (value, i, arr) {
      return value.n == name;
    });
    return selectedMaterialData[0];
  };

  private getConstructionLayers = (idConstruction: number) => {
    const { draftJson, i } = this;
    const draftJsonForConstructions = _.get(draftJson, `lAssembly`);
    const newDraftJsonForConstruction = [...(draftJsonForConstructions as any)];
    const selectedConstruction = newDraftJsonForConstruction.filter(function (value, i, arr) {
      return value.id == idConstruction;
    });
    const layerData = _.get(selectedConstruction[0], `lLayer`);  // hopefully only one construction for that id.
    return layerData;
  };

  private getConstruction = (idConstruction: number) => {
    const { draftJson, i } = this;
    const draftJsonForConstructions = _.get(draftJson, `lAssembly`);
    const newDraftJsonForConstruction = [...(draftJsonForConstructions as any)];
    const selectedConstruction = newDraftJsonForConstruction.filter(function (value, i, arr) {
      return value.id == idConstruction;
    });
    return selectedConstruction[0];
  };

  private getWindow = (idWindow: number) => {
    const { draftJson, i } = this;
    const draftJsonForWindows = _.get(draftJson, `lWindow`);
    const newDraftJsonForWindow = [...(draftJsonForWindows as any)];
    const selectedWindow = newDraftJsonForWindow.filter(function (value, i, arr) {
      return value.id == idWindow;
    });
    return selectedWindow[0];
  };


  private calculateConstructions(lifetimeBuilding: number) {
    const { draftJson, i } = this;
    const draftJsonForConstructions = _.get(draftJson, `lAssembly`);
    for (const dJFC of draftJsonForConstructions) {

      let sumweightTotal = 0;
      let sumweightWood = 0;
      let sumweightRecycled = 0;
      let sumweightRecyclable = 0;
      let sumweightCertWood = 0;
      let sumweightEPD = 0;

      let sumprodGWP = 0;
      let sumprodODP = 0;
      let sumprodPOCP = 0;
      let sumprodAP = 0;
      let sumprodEP = 0;
      let sumprodPEnon = 0;
      let sumprodPErenew = 0;

      let sumeolGWP = 0;
      let sumeolODP = 0;
      let sumeolPOCP = 0;
      let sumeolAP = 0;
      let sumeolEP = 0;
      let sumeolPEnon = 0;
      let sumeolPErenew = 0;

      let summaintenanceGWP = 0;
      let summaintenanceODP = 0;
      let summaintenancePOCP = 0;
      let summaintenanceAP = 0;
      let summaintenanceEP = 0;
      let summaintenancePEnon = 0;
      let summaintenancePErenew = 0;


      const draftJsonForLayers = _.get(dJFC, `lLayer`);

      if (draftJsonForLayers) {
        for (const dJFL of draftJsonForLayers) {
          const thickness = _.get(dJFL, `eco.aHthick`);
          const shareOfLayer = _.get(dJFL, `eco.aHshare`);
          const idOekobau = _.get(dJFL, `eco.lcaMaterial`);

          const recycled: boolean = _.get(dJFL, `eco.sus.aHrecycled`) || false;
          const recyclable: boolean = _.get(dJFL, `eco.sus.aHrecyclable`) || false;
          const certifiedwood: boolean = _.get(dJFL, `eco.sus.aHcertifiedwood`) || false;
          const verifiedepd: boolean = _.get(dJFL, `eco.sus.aHverifiedepd`) || false;
          const materialLifetime = _.get(dJFL, `eco.sus.aHlifetime`);

          const material = this.getOekobauData(idOekobau);

          if (material) {

            const density = material.densB ? material.densB : 0;
            const normvolume = thickness * (shareOfLayer * 0.01) * 0.01;
            _.set(dJFL, `eco.normvol`, normvolume);

            // weight
            const weightTotal = normvolume * +density;
            const weightWood = material.grp == "Wood" ? weightTotal : 0;
            const weightRecycled = recycled ? weightTotal : 0;
            const weightRecyclable = recyclable ? weightTotal : 0;
            const weightCertWood = certifiedwood ? weightWood : 0;
            const weightEPD = verifiedepd ? weightTotal : 0;

            _.set(dJFL, `eco.lcawtot`, weightTotal);
            _.set(dJFL, `eco.lcawwood`, weightWood);
            _.set(dJFL, `eco.lcawrec`, weightRecycled);
            _.set(dJFL, `eco.lcawrecab`, weightRecyclable);
            _.set(dJFL, `eco.lcawcert`, weightCertWood);
            _.set(dJFL, `eco.lcawepd`, weightEPD);

            sumweightTotal += weightTotal;
            sumweightWood += weightWood;
            sumweightRecycled += weightRecycled;
            sumweightRecyclable += weightRecyclable;
            sumweightCertWood += weightCertWood;
            sumweightEPD += weightEPD;

            // Material "u": xxx
            // 39 : kg
            // 38 : m²
            // 26 : m³

            // 6, 18 : m
            // 36 : kWh
            // 162 : Piece

            const Amount = material.u == "38" ? 1 :
              material.u == "26" ? normvolume :
                material.u == "39" ? +density * normvolume : 0;



            const prodGWP = Amount * +material.GWP;
            const prodODP = Amount * +material.ODP;
            const prodPOCP = Amount * +material.POCP;
            const prodAP = Amount * +material.AP;
            const prodEP = Amount * +material.EP;
            const prodPEnon = Amount * +material.PEnr;
            const prodPErenew = Amount * +material.PE;

            _.set(dJFL, `eco.lcaProd.lcaGWP`, prodGWP);
            _.set(dJFL, `eco.lcaProd.lcaODP`, prodODP);
            _.set(dJFL, `eco.lcaProd.lcaPOCP`, prodPOCP);
            _.set(dJFL, `eco.lcaProd.lcaAP`, prodAP);
            _.set(dJFL, `eco.lcaProd.lcaEP`, prodEP);
            _.set(dJFL, `eco.lcaProd.lcaPEnonrenw`, prodPEnon);
            _.set(dJFL, `eco.lcaProd.lcaPRErenw`, prodPErenew);

            sumprodGWP += prodGWP;
            sumprodODP += prodODP;
            sumprodPOCP += prodPOCP;
            sumprodAP += prodAP;
            sumprodEP += prodEP;
            sumprodPEnon += prodPEnon;
            sumprodPErenew += prodPErenew;

            // End of life

            const materialEOL = this.getOekobauDataByName(material.eol);

            const eolGWP = materialEOL ? Amount * +materialEOL.GWP : 0;
            const eolODP = materialEOL ? Amount * +materialEOL.ODP : 0;
            const eolPOCP = materialEOL ? Amount * +materialEOL.POCP : 0;
            const eolAP = materialEOL ? Amount * +materialEOL.AP : 0;
            const eolEP = materialEOL ? Amount * +materialEOL.EP : 0;
            const eolPEnon = materialEOL ? Amount * +materialEOL.PEnr : 0;
            const eolPErenew = materialEOL ? Amount * +materialEOL.PE : 0;

            _.set(dJFL, `eco.lcaEol.lcaGWP`, eolGWP);
            _.set(dJFL, `eco.lcaEol.lcaODP`, eolODP);
            _.set(dJFL, `eco.lcaEol.lcaPOCP`, eolPOCP);
            _.set(dJFL, `eco.lcaEol.lcaAP`, eolAP);
            _.set(dJFL, `eco.lcaEol.lcaEP`, eolEP);
            _.set(dJFL, `eco.lcaEol.lcaPEnonrenw`, eolPEnon);
            _.set(dJFL, `eco.lcaEol.lcaPRErenw`, eolPErenew);

            sumeolGWP += eolGWP;
            sumeolODP += eolODP;
            sumeolPOCP += eolPOCP;
            sumeolAP += eolAP;
            sumeolEP += eolEP;
            sumeolPEnon += eolPEnon;
            sumeolPErenew += eolPErenew;

            // Maintenance  Lifetime (case) dependend! 
            const AmountRenew = Math.floor(lifetimeBuilding / (+materialLifetime + 1));

            const newprodGWP = Amount * AmountRenew * +material.GWP;
            const newprodODP = Amount * AmountRenew * +material.ODP;
            const newprodPOCP = Amount * AmountRenew * +material.POCP;
            const newprodAP = Amount * AmountRenew * +material.AP;
            const newprodEP = Amount * AmountRenew * +material.EP;
            const newprodPEnon = Amount * AmountRenew * +material.PEnr;
            const newprodPErenew = Amount * AmountRenew * +material.PE;

            const neweolGWP = materialEOL ? Amount * AmountRenew * +materialEOL.GWP : 0;
            const neweolODP = materialEOL ? Amount * AmountRenew * +materialEOL.ODP : 0;
            const neweolPOCP = materialEOL ? Amount * AmountRenew * +materialEOL.POCP : 0;
            const neweolAP = materialEOL ? Amount * AmountRenew * +materialEOL.AP : 0;
            const neweolEP = materialEOL ? Amount * AmountRenew * +materialEOL.EP : 0;
            const neweolPEnon = materialEOL ? Amount * AmountRenew * +materialEOL.PEnr : 0;
            const neweolPErenew = materialEOL ? Amount * AmountRenew * +materialEOL.PE : 0;

            const maintenanceGWP = newprodGWP + neweolGWP;
            const maintenanceODP = newprodODP + neweolODP;
            const maintenancePOCP = newprodPOCP + neweolPOCP;
            const maintenanceAP = newprodAP + neweolAP;
            const maintenanceEP = newprodEP + neweolEP;
            const maintenancePEnon = newprodPEnon + neweolPEnon;
            const maintenancePErenew = newprodPErenew + neweolPErenew;

            _.set(dJFL, `eco.lcaMaintain.lcaGWP`, maintenanceGWP);
            _.set(dJFL, `eco.lcaMaintain.lcaODP`, maintenanceODP);
            _.set(dJFL, `eco.lcaMaintain.lcaPOCP`, maintenancePOCP);
            _.set(dJFL, `eco.lcaMaintain.lcaAP`, maintenanceAP);
            _.set(dJFL, `eco.lcaMaintain.lcaEP`, maintenanceEP);
            _.set(dJFL, `eco.lcaMaintain.lcaPEnonrenw`, maintenancePEnon);
            _.set(dJFL, `eco.lcaMaintain.lcaPRErenw`, maintenancePErenew);

            summaintenanceGWP += maintenanceGWP;
            summaintenanceODP += maintenanceODP;
            summaintenancePOCP += maintenancePOCP;
            summaintenanceAP += maintenanceAP;
            summaintenanceEP += maintenanceEP;
            summaintenancePEnon += maintenancePEnon;
            summaintenancePErenew += maintenancePErenew;
          } // end if material
          else // if no material choosen
          {
            _.set(dJFL, `eco.normvol`, NaN);

            _.set(dJFL, `eco.lcawtot`, NaN);
            _.set(dJFL, `eco.lcawwood`, NaN);
            _.set(dJFL, `eco.lcawrec`, NaN);
            _.set(dJFL, `eco.lcawrecab`, NaN);
            _.set(dJFL, `eco.lcawcert`, NaN);
            _.set(dJFL, `eco.lcawepd`, NaN);

            _.set(dJFL, `eco.lcaProd.lcaGWP`, NaN);
            _.set(dJFL, `eco.lcaProd.lcaODP`, NaN);
            _.set(dJFL, `eco.lcaProd.lcaPOCP`, NaN);
            _.set(dJFL, `eco.lcaProd.lcaAP`, NaN);
            _.set(dJFL, `eco.lcaProd.lcaEP`, NaN);
            _.set(dJFL, `eco.lcaProd.lcaPEnonrenw`, NaN);
            _.set(dJFL, `eco.lcaProd.lcaPRErenw`, NaN);

      
            _.set(dJFL, `eco.lcaEol.lcaGWP`, NaN);
            _.set(dJFL, `eco.lcaEol.lcaODP`, NaN);
            _.set(dJFL, `eco.lcaEol.lcaPOCP`, NaN);
            _.set(dJFL, `eco.lcaEol.lcaAP`, NaN);
            _.set(dJFL, `eco.lcaEol.lcaEP`, NaN);
            _.set(dJFL, `eco.lcaEol.lcaPEnonrenw`, NaN);
            _.set(dJFL, `eco.lcaEol.lcaPRErenw`, NaN);


            _.set(dJFL, `eco.lcaMaintain.lcaGWP`, NaN);
            _.set(dJFL, `eco.lcaMaintain.lcaODP`, NaN);
            _.set(dJFL, `eco.lcaMaintain.lcaPOCP`, NaN);
            _.set(dJFL, `eco.lcaMaintain.lcaAP`, NaN);
            _.set(dJFL, `eco.lcaMaintain.lcaEP`, NaN);
            _.set(dJFL, `eco.lcaMaintain.lcaPEnonrenw`, NaN);
            _.set(dJFL, `eco.lcaMaintain.lcaPRErenw`, NaN);
          }
        } // end layer for
      }

      _.set(dJFC, `eco.lcawtot`, sumweightTotal);
      _.set(dJFC, `eco.lcawwood`, sumweightWood);
      _.set(dJFC, `eco.lcawrec`, sumweightRecycled);
      _.set(dJFC, `eco.lcawrecab`, sumweightRecyclable);
      _.set(dJFC, `eco.lcawcert`, sumweightCertWood);
      _.set(dJFC, `eco.lcawepd`, sumweightEPD);

      _.set(dJFC, `eco.lcaProd.lcaGWP`, sumprodGWP);
      _.set(dJFC, `eco.lcaProd.lcaODP`, sumprodODP);
      _.set(dJFC, `eco.lcaProd.lcaPOCP`, sumprodPOCP);
      _.set(dJFC, `eco.lcaProd.lcaAP`, sumprodAP);
      _.set(dJFC, `eco.lcaProd.lcaEP`, sumprodEP);
      _.set(dJFC, `eco.lcaProd.lcaPEnonrenw`, sumprodPEnon);
      _.set(dJFC, `eco.lcaProd.lcaPRErenw`, sumprodPErenew);

      _.set(dJFC, `eco.lcaEol.lcaGWP`, sumeolGWP);
      _.set(dJFC, `eco.lcaEol.lcaODP`, sumeolODP);
      _.set(dJFC, `eco.lcaEol.lcaPOCP`, sumeolPOCP);
      _.set(dJFC, `eco.lcaEol.lcaAP`, sumeolAP);
      _.set(dJFC, `eco.lcaEol.lcaEP`, sumeolEP);
      _.set(dJFC, `eco.lcaEol.lcaPEnonrenw`, sumeolPEnon);
      _.set(dJFC, `eco.lcaEol.lcaPRErenw`, sumeolPErenew);

      _.set(dJFC, `eco.lcaMaintain.lcaGWP`, summaintenanceGWP);
      _.set(dJFC, `eco.lcaMaintain.lcaODP`, summaintenanceODP);
      _.set(dJFC, `eco.lcaMaintain.lcaPOCP`, summaintenancePOCP);
      _.set(dJFC, `eco.lcaMaintain.lcaAP`, summaintenanceAP);
      _.set(dJFC, `eco.lcaMaintain.lcaEP`, summaintenanceEP);
      _.set(dJFC, `eco.lcaMaintain.lcaPEnonrenw`, summaintenancePEnon);
      _.set(dJFC, `eco.lcaMaintain.lcaPRErenw`, summaintenancePErenew);

    }
  }


  private calculateWindowsDoors(lifetimeBuilding: number) {
    const { draftJson, i } = this;
    const draftJsonForWindows = _.get(draftJson, `lWindow`);
    for (const dJFW of draftJsonForWindows) {

      let sumweightTotal = 0;
      let sumweightWood = 0;
      let sumweightRecycled = 0;
      let sumweightRecyclable = 0;
      let sumweightCertWood = 0;
      let sumweightEPD = 0;

      let sumprodGWP = 0;
      let sumprodODP = 0;
      let sumprodPOCP = 0;
      let sumprodAP = 0;
      let sumprodEP = 0;
      let sumprodPEnon = 0;
      let sumprodPErenew = 0;

      let sumeolGWP = 0;
      let sumeolODP = 0;
      let sumeolPOCP = 0;
      let sumeolAP = 0;
      let sumeolEP = 0;
      let sumeolPEnon = 0;
      let sumeolPErenew = 0;

      let summaintenanceGWP = 0;
      let summaintenanceODP = 0;
      let summaintenancePOCP = 0;
      let summaintenanceAP = 0;
      let summaintenanceEP = 0;
      let summaintenancePEnon = 0;
      let summaintenancePErenew = 0;


      const windowKeyArray: string[] = ['frame', 'casement', 'pane', 'handl', 'ironw', 'tape'];

      let casementLength = 0;

      for (const windowKey of windowKeyArray) {

        const idOekobau = _.get(dJFW, `eco.${windowKey}.lcaMaterial`);
        const material = this.getOekobauData(idOekobau);

        if (material) {
          const length: number = windowKey == 'frame' || 'casement' || 'pane' ? +_.get(dJFW, `eco.${windowKey}.aHlrtbW`) : 0;
          const width: number = windowKey == 'frame' || 'casement' || 'pane' ? +_.get(dJFW, `eco.${windowKey}.aHlrtbL`) : 0;
          const countPanes: number = windowKey == 'pane' ? +_.get(dJFW, `eco.${windowKey}.aHcpane`) : 0;
          const countHandler: number = windowKey == 'handl' ? +_.get(dJFW, `eco.${windowKey}.caHchand`) : 0;
          const countIronWork: number = windowKey == 'ironw' ? +_.get(dJFW, `eco.${windowKey}.aHciw`) : 0;

          const recycled: boolean = _.get(dJFW, `eco.${windowKey}.sus.aHrecycled`) || false;
          const recyclable: boolean = _.get(dJFW, `eco.${windowKey}.sus.aHrecyclable`) || false;
          const certifiedwood: boolean = _.get(dJFW, `eco.${windowKey}.sus.aHcertifiedwood`) || false;
          const verifiedepd: boolean = _.get(dJFW, `eco.${windowKey}.sus.aHverifiedepd`) || false;
          const materialLifetime = _.get(dJFW, `eco.${windowKey}.sus.aHlifetime`);

          // const density = material.densB ? material.densB : 0 ;
          const weightPerUnit = material.w ? material.w : 0;

          const Amount =
            windowKey === 'frame' ? (length + width) * 2
              : windowKey === 'casement' ? (length + width) * 2
                : windowKey === 'pane' ? countPanes === 1 ? length * width : (countPanes / 2) * length * width
                  : windowKey === 'handl' ? countHandler
                    : windowKey === 'ironw' ? countIronWork
                      : windowKey === 'tape' ? (casementLength) * 0.01 * 0.01 * 1100
                        : 0;

          if (windowKey === 'casement') casementLength = Amount;

          const weightTotal = windowKey === 'tape' ? Amount : Amount * +weightPerUnit;
          const weightWood = +material.id == 211 || +material.id == 1 ? weightTotal : 0;
          const weightRecycled = recycled ? weightTotal : 0;
          const weightRecyclable = recyclable ? weightTotal : 0;
          const weightCertWood = certifiedwood ? weightWood : 0;
          const weightEPD = verifiedepd ? weightTotal : 0;

          // _.set(dJFW, `eco.${windowKey}.lcawtot`, weightTotal);
          // _.set(dJFW, `eco.${windowKey}.lcawwood`, weightWood);
          // _.set(dJFW, `eco.${windowKey}.lcawrec`, weightRecycled);
          // _.set(dJFW, `eco.${windowKey}.lcawrecab`, weightRecyclable);
          // _.set(dJFW, `eco.${windowKey}.lcawcert`, weightCertWood);
          // _.set(dJFW, `eco.${windowKey}.lcawepd`, weightEPD);

          sumweightTotal += weightTotal;
          sumweightWood += weightWood;
          sumweightRecycled += weightRecycled;
          sumweightRecyclable += weightRecyclable;
          sumweightCertWood += weightCertWood;
          sumweightEPD += weightEPD;

          const prodGWP = Amount * +material.GWP;
          const prodODP = Amount * +material.ODP;
          const prodPOCP = Amount * +material.POCP;
          const prodAP = Amount * +material.AP;
          const prodEP = Amount * +material.EP;
          const prodPEnon = Amount * +material.PEnr;
          const prodPErenew = Amount * +material.PE;

          // _.set(dJFW, `eco.${windowKey}.lcaProd.lcaGWP`, prodGWP);
          // _.set(dJFW, `eco.${windowKey}.lcaProd.lcaODP`, prodODP);
          // _.set(dJFW, `eco.${windowKey}.lcaProd.lcaPOCP`, prodPOCP);
          // _.set(dJFW, `eco.${windowKey}.lcaProd.lcaAP`, prodAP);
          // _.set(dJFW, `eco.${windowKey}.lcaProd.lcaEP`, prodEP);
          // _.set(dJFW, `eco.${windowKey}.lcaProd.lcaPEnonrenw`, prodPEnon);
          // _.set(dJFW, `eco.${windowKey}.lcaProd.lcaPRErenw`, prodPErenew);

          sumprodGWP += prodGWP;
          sumprodODP += prodODP;
          sumprodPOCP += prodPOCP;
          sumprodAP += prodAP;
          sumprodEP += prodEP;
          sumprodPEnon += prodPEnon;
          sumprodPErenew += prodPErenew;

          // End of life

          const materialEOL = this.getOekobauDataByName(material.eol);

          const eolGWP = materialEOL ? Amount * +materialEOL.GWP : 0;
          const eolODP = materialEOL ? Amount * +materialEOL.ODP : 0;
          const eolPOCP = materialEOL ? Amount * +materialEOL.POCP : 0;
          const eolAP = materialEOL ? Amount * +materialEOL.AP : 0;
          const eolEP = materialEOL ? Amount * +materialEOL.EP : 0;
          const eolPEnon = materialEOL ? Amount * +materialEOL.PEnr : 0;
          const eolPErenew = materialEOL ? Amount * +materialEOL.PE : 0;

          // _.set(dJFW, `eco.${windowKey}.lcaEol.lcaGWP`, eolGWP);
          // _.set(dJFW, `eco.${windowKey}.lcaEol.lcaODP`, eolODP);
          // _.set(dJFW, `eco.${windowKey}.lcaEol.lcaPOCP`, eolPOCP);
          // _.set(dJFW, `eco.${windowKey}.lcaEol.lcaAP`, eolAP);
          // _.set(dJFW, `eco.${windowKey}.lcaEol.lcaEP`, eolEP);
          // _.set(dJFW, `eco.${windowKey}.lcaEol.lcaPEnonrenw`, eolPEnon);
          // _.set(dJFW, `eco.${windowKey}.lcaEol.lcaPRErenw`, eolPErenew);

          sumeolGWP += eolGWP;
          sumeolODP += eolODP;
          sumeolPOCP += eolPOCP;
          sumeolAP += eolAP;
          sumeolEP += eolEP;
          sumeolPEnon += eolPEnon;
          sumeolPErenew += eolPErenew;

          // Maintenance  Lifetime (case) dependend! 
          const AmountRenew = Math.floor(lifetimeBuilding / (+materialLifetime + 1));

          const newprodGWP = Amount * AmountRenew * +material.GWP;
          const newprodODP = Amount * AmountRenew * +material.ODP;
          const newprodPOCP = Amount * AmountRenew * +material.POCP;
          const newprodAP = Amount * AmountRenew * +material.AP;
          const newprodEP = Amount * AmountRenew * +material.EP;
          const newprodPEnon = Amount * AmountRenew * +material.PEnr;
          const newprodPErenew = Amount * AmountRenew * +material.PE;

          const neweolGWP = materialEOL ? Amount * AmountRenew * +materialEOL.GWP : 0;
          const neweolODP = materialEOL ? Amount * AmountRenew * +materialEOL.ODP : 0;
          const neweolPOCP = materialEOL ? Amount * AmountRenew * +materialEOL.POCP : 0;
          const neweolAP = materialEOL ? Amount * AmountRenew * +materialEOL.AP : 0;
          const neweolEP = materialEOL ? Amount * AmountRenew * +materialEOL.EP : 0;
          const neweolPEnon = materialEOL ? Amount * AmountRenew * +materialEOL.PEnr : 0;
          const neweolPErenew = materialEOL ? Amount * AmountRenew * +materialEOL.PE : 0;

          const maintenanceGWP = newprodGWP + neweolGWP;
          const maintenanceODP = newprodODP + neweolODP;
          const maintenancePOCP = newprodPOCP + neweolPOCP;
          const maintenanceAP = newprodAP + neweolAP;
          const maintenanceEP = newprodEP + neweolEP;
          const maintenancePEnon = newprodPEnon + neweolPEnon;
          const maintenancePErenew = newprodPErenew + neweolPErenew;

          // _.set(dJFW, `eco.${windowKey}.lcaMaintain.lcaGWP`, maintenanceGWP);
          // _.set(dJFW, `eco.${windowKey}.lcaMaintain.lcaODP`, maintenanceODP);
          // _.set(dJFW, `eco.${windowKey}.lcaMaintain.lcaPOCP`, maintenancePOCP);
          // _.set(dJFW, `eco.${windowKey}.lcaMaintain.lcaAP`, maintenanceAP);
          // _.set(dJFW, `eco.${windowKey}.lcaMaintain.lcaEP`, maintenanceEP);
          // _.set(dJFW, `eco.${windowKey}.lcaMaintain.lcaPEnonrenw`, maintenancePEnon);
          // _.set(dJFW, `eco.${windowKey}.lcaMaintain.lcaPRErenw`, maintenancePErenew);

          summaintenanceGWP += maintenanceGWP;
          summaintenanceODP += maintenanceODP;
          summaintenancePOCP += maintenancePOCP;
          summaintenanceAP += maintenanceAP;
          summaintenanceEP += maintenanceEP;
          summaintenancePEnon += maintenancePEnon;
          summaintenancePErenew += maintenancePErenew;

        }
      }

      _.set(dJFW, `eco.lcawtot`, sumweightTotal);
      _.set(dJFW, `eco.lcawwood`, sumweightWood);
      _.set(dJFW, `eco.lcawrec`, sumweightRecycled);
      _.set(dJFW, `eco.lcawrecab`, sumweightRecyclable);
      _.set(dJFW, `eco.lcawcert`, sumweightCertWood);
      _.set(dJFW, `eco.lcawepd`, sumweightEPD);

      _.set(dJFW, `eco.lcaProd.lcaGWP`, sumprodGWP);
      _.set(dJFW, `eco.lcaProd.lcaODP`, sumprodODP);
      _.set(dJFW, `eco.lcaProd.lcaPOCP`, sumprodPOCP);
      _.set(dJFW, `eco.lcaProd.lcaAP`, sumprodAP);
      _.set(dJFW, `eco.lcaProd.lcaEP`, sumprodEP);
      _.set(dJFW, `eco.lcaProd.lcaPEnonrenw`, sumprodPEnon);
      _.set(dJFW, `eco.lcaProd.lcaPRErenw`, sumprodPErenew);

      _.set(dJFW, `eco.lcaEol.lcaGWP`, sumeolGWP);
      _.set(dJFW, `eco.lcaEol.lcaODP`, sumeolODP);
      _.set(dJFW, `eco.lcaEol.lcaPOCP`, sumeolPOCP);
      _.set(dJFW, `eco.lcaEol.lcaAP`, sumeolAP);
      _.set(dJFW, `eco.lcaEol.lcaEP`, sumeolEP);
      _.set(dJFW, `eco.lcaEol.lcaPEnonrenw`, sumeolPEnon);
      _.set(dJFW, `eco.lcaEol.lcaPRErenw`, sumeolPErenew);

      _.set(dJFW, `eco.lcaMaintain.lcaGWP`, summaintenanceGWP);
      _.set(dJFW, `eco.lcaMaintain.lcaODP`, summaintenanceODP);
      _.set(dJFW, `eco.lcaMaintain.lcaPOCP`, summaintenancePOCP);
      _.set(dJFW, `eco.lcaMaintain.lcaAP`, summaintenanceAP);
      _.set(dJFW, `eco.lcaMaintain.lcaEP`, summaintenanceEP);
      _.set(dJFW, `eco.lcaMaintain.lcaPEnonrenw`, summaintenancePEnon);
      _.set(dJFW, `eco.lcaMaintain.lcaPRErenw`, summaintenancePErenew);


      // // DEBUG
      // const GWP = sumprodGWP + sumeolGWP + summaintenanceGWP;
      // const ODP = sumprodODP + sumeolODP + summaintenanceODP;
      // const POCP = sumprodPOCP + sumeolPOCP + summaintenancePOCP;
      // const AP = sumprodAP + sumeolAP + summaintenanceAP;
      // const EP = sumprodEP + sumeolEP + summaintenanceEP;
      // const PEnon = sumprodPEnon + sumeolPEnon + summaintenancePEnon;
      // const PErenew = sumprodPErenew + sumeolPErenew + summaintenancePErenew;

      // console.log("GWP:" + GWP + "___ODP:" + ODP +
      //   "___POCP:" + POCP +
      //   "___AP:" + AP +
      //   "___EP:" + EP);

    }
  }


  private calculateSystems(lifetimeBuilding: number) {
    const { draftJson, i } = this;
    const draftJsonForSystems = _.get(draftJson, `lVariant.[${i}].HVAC.lSystem`);
    for (const dJFS of draftJsonForSystems) {

      let sumprodGWP = 0;
      let sumprodODP = 0;
      let sumprodPOCP = 0;
      let sumprodAP = 0;
      let sumprodEP = 0;
      let sumprodPEnon = 0;
      let sumprodPErenew = 0;

      let sumeolGWP = 0;
      let sumeolODP = 0;
      let sumeolPOCP = 0;
      let sumeolAP = 0;
      let sumeolEP = 0;
      let sumeolPEnon = 0;
      let sumeolPErenew = 0;

      let summaintenanceGWP = 0;
      let summaintenanceODP = 0;
      let summaintenancePOCP = 0;
      let summaintenanceAP = 0;
      let summaintenanceEP = 0;
      let summaintenancePEnon = 0;
      let summaintenancePErenew = 0;


      const draftJsonForDevices = _.get(dJFS, `lDevice`);

      if (draftJsonForDevices) {
        for (const dJFD of draftJsonForDevices) {

          const typeOfDevice = _.get(dJFD, `Active.ahtypeDev`);
          if (typeOfDevice == 1) {
            const deviceName = getNameOfDefinitionEnum({
              partJson: dJFD,
              key: `Active.ahheatDev`,
            });
            _.set(dJFD, `n`, deviceName);
          } else if (typeOfDevice == 2) {
            const deviceName = getNameOfDefinitionEnum({
              partJson: dJFD,
              key: `Active.ahelDev`,
            });
            _.set(dJFD, `n`, deviceName);
          } else if (typeOfDevice == 3) {
            const deviceName = getNameOfDefinitionEnum({
              partJson: dJFD,
              key: `Active.ahdeviceThrenw`,
            });
            _.set(dJFD, `n`, deviceName);
          } else if (typeOfDevice == 4) {
            const deviceName = getNameOfDefinitionEnum({
              partJson: dJFD,
              key: `Active.ahdeviceTerenw`,
            });
            _.set(dJFD, `n`, deviceName);
          }

          const inputAmount = _.get(dJFD, `eco.countS`);

          // const usedUnit = _.get(dJFD, `eco.u`);
          const idOekobau = _.get(dJFD, `eco.lcaMaterial`);
          const deviceLifetime = _.get(dJFD, `eco.sus.aHlifetime`);
          const material = this.getOekobauData(idOekobau);
          if (material) {
            let unit = null;
            if (material.u) {
              const unitFound = unitsJson.u.find((u) => u[0] == material.u);
              if (unitFound) {
                unit = new Unit({
                  id: unitFound[0],
                  siUnit: unitFound[1],
                  ipUnit: unitFound[2],
                  conversionFactor: unitFound[3],
                  currentSystem: 1                // here only SI units in oebobaudata
                });
              }
            }
            _.set(dJFD, `eco.lcau`, unit ? unit.siUnit : "");

            const usedDevice: boolean = _.get(dJFS, `lDevice.[0].Active.ahr_edsum`) > 0 && _.get(dJFS, `lDevice.[0].Active.ahsysused`);
            
            const Amount = usedDevice ? inputAmount : 0;

            const prodGWP = Amount * +material.GWP;
            const prodODP = Amount * +material.ODP;
            const prodPOCP = Amount * +material.POCP;
            const prodAP = Amount * +material.AP;
            const prodEP = Amount * +material.EP;
            const prodPEnon = Amount * +material.PEnr;
            const prodPErenew = Amount * +material.PE;

            _.set(dJFD, `eco.lcaProd.lcaGWP`, prodGWP);
            _.set(dJFD, `eco.lcaProd.lcaODP`, prodODP);
            _.set(dJFD, `eco.lcaProd.lcaPOCP`, prodPOCP);
            _.set(dJFD, `eco.lcaProd.lcaAP`, prodAP);
            _.set(dJFD, `eco.lcaProd.lcaEP`, prodEP);
            _.set(dJFD, `eco.lcaProd.lcaPEnonrenw`, prodPEnon);
            _.set(dJFD, `eco.lcaProd.lcaPRErenw`, prodPErenew);

            sumprodGWP += prodGWP;
            sumprodODP += prodODP;
            sumprodPOCP += prodPOCP;
            sumprodAP += prodAP;
            sumprodEP += prodEP;
            sumprodPEnon += prodPEnon;
            sumprodPErenew += prodPErenew;



            // End of life

            const materialEOL = this.getOekobauDataByName(material.eol);

            const eolGWP = materialEOL ? Amount * +materialEOL.GWP : 0;
            const eolODP = materialEOL ? Amount * +materialEOL.ODP : 0;
            const eolPOCP = materialEOL ? Amount * +materialEOL.POCP : 0;
            const eolAP = materialEOL ? Amount * +materialEOL.AP : 0;
            const eolEP = materialEOL ? Amount * +materialEOL.EP : 0;
            const eolPEnon = materialEOL ? Amount * +materialEOL.PEnr : 0;
            const eolPErenew = materialEOL ? Amount * +materialEOL.PE : 0;

            _.set(dJFD, `eco.lcaEol.lcaGWP`, eolGWP);
            _.set(dJFD, `eco.lcaEol.lcaODP`, eolODP);
            _.set(dJFD, `eco.lcaEol.lcaPOCP`, eolPOCP);
            _.set(dJFD, `eco.lcaEol.lcaAP`, eolAP);
            _.set(dJFD, `eco.lcaEol.lcaEP`, eolEP);
            _.set(dJFD, `eco.lcaEol.lcaPEnonrenw`, eolPEnon);
            _.set(dJFD, `eco.lcaEol.lcaPRErenw`, eolPErenew);

            sumeolGWP += eolGWP;
            sumeolODP += eolODP;
            sumeolPOCP += eolPOCP;
            sumeolAP += eolAP;
            sumeolEP += eolEP;
            sumeolPEnon += eolPEnon;
            sumeolPErenew += eolPErenew;

            // Maintenance  Lifetime (case) dependend! 
            const AmountRenew = Math.floor(lifetimeBuilding / (+deviceLifetime + 1));

            const newprodGWP = Amount * AmountRenew * +material.GWP;
            const newprodODP = Amount * AmountRenew * +material.ODP;
            const newprodPOCP = Amount * AmountRenew * +material.POCP;
            const newprodAP = Amount * AmountRenew * +material.AP;
            const newprodEP = Amount * AmountRenew * +material.EP;
            const newprodPEnon = Amount * AmountRenew * +material.PEnr;
            const newprodPErenew = Amount * AmountRenew * +material.PE;

            const neweolGWP = materialEOL ? Amount * AmountRenew * +materialEOL.GWP : 0;
            const neweolODP = materialEOL ? Amount * AmountRenew * +materialEOL.ODP : 0;
            const neweolPOCP = materialEOL ? Amount * AmountRenew * +materialEOL.POCP : 0;
            const neweolAP = materialEOL ? Amount * AmountRenew * +materialEOL.AP : 0;
            const neweolEP = materialEOL ? Amount * AmountRenew * +materialEOL.EP : 0;
            const neweolPEnon = materialEOL ? Amount * AmountRenew * +materialEOL.PEnr : 0;
            const neweolPErenew = materialEOL ? Amount * AmountRenew * +materialEOL.PE : 0;

            const maintenanceGWP = newprodGWP + neweolGWP;
            const maintenanceODP = newprodODP + neweolODP;
            const maintenancePOCP = newprodPOCP + neweolPOCP;
            const maintenanceAP = newprodAP + neweolAP;
            const maintenanceEP = newprodEP + neweolEP;
            const maintenancePEnon = newprodPEnon + neweolPEnon;
            const maintenancePErenew = newprodPErenew + neweolPErenew;

            _.set(dJFD, `eco.lcaMaintain.lcaGWP`, maintenanceGWP);
            _.set(dJFD, `eco.lcaMaintain.lcaODP`, maintenanceODP);
            _.set(dJFD, `eco.lcaMaintain.lcaPOCP`, maintenancePOCP);
            _.set(dJFD, `eco.lcaMaintain.lcaAP`, maintenanceAP);
            _.set(dJFD, `eco.lcaMaintain.lcaEP`, maintenanceEP);
            _.set(dJFD, `eco.lcaMaintain.lcaPEnonrenw`, maintenancePEnon);
            _.set(dJFD, `eco.lcaMaintain.lcaPRErenw`, maintenancePErenew);

            summaintenanceGWP += maintenanceGWP;
            summaintenanceODP += maintenanceODP;
            summaintenancePOCP += maintenancePOCP;
            summaintenanceAP += maintenanceAP;
            summaintenanceEP += maintenanceEP;
            summaintenancePEnon += maintenancePEnon;
            summaintenancePErenew += maintenancePErenew;
          }
        } // end device for
      }

      _.set(dJFS, `eco.lcaProd.lcaGWP`, sumprodGWP);
      _.set(dJFS, `eco.lcaProd.lcaODP`, sumprodODP);
      _.set(dJFS, `eco.lcaProd.lcaPOCP`, sumprodPOCP);
      _.set(dJFS, `eco.lcaProd.lcaAP`, sumprodAP);
      _.set(dJFS, `eco.lcaProd.lcaEP`, sumprodEP);
      _.set(dJFS, `eco.lcaProd.lcaPEnonrenw`, sumprodPEnon);
      _.set(dJFS, `eco.lcaProd.lcaPRErenw`, sumprodPErenew);

      _.set(dJFS, `eco.lcaEol.lcaGWP`, sumeolGWP);
      _.set(dJFS, `eco.lcaEol.lcaODP`, sumeolODP);
      _.set(dJFS, `eco.lcaEol.lcaPOCP`, sumeolPOCP);
      _.set(dJFS, `eco.lcaEol.lcaAP`, sumeolAP);
      _.set(dJFS, `eco.lcaEol.lcaEP`, sumeolEP);
      _.set(dJFS, `eco.lcaEol.lcaPEnonrenw`, sumeolPEnon);
      _.set(dJFS, `eco.lcaEol.lcaPRErenw`, sumeolPErenew);

      _.set(dJFS, `eco.lcaMaintain.lcaGWP`, summaintenanceGWP);
      _.set(dJFS, `eco.lcaMaintain.lcaODP`, summaintenanceODP);
      _.set(dJFS, `eco.lcaMaintain.lcaPOCP`, summaintenancePOCP);
      _.set(dJFS, `eco.lcaMaintain.lcaAP`, summaintenanceAP);
      _.set(dJFS, `eco.lcaMaintain.lcaEP`, summaintenanceEP);
      _.set(dJFS, `eco.lcaMaintain.lcaPEnonrenw`, summaintenancePEnon);
      _.set(dJFS, `eco.lcaMaintain.lcaPRErenw`, summaintenancePErenew);

    }
  }


  private calculateOperations(lifetimeBuilding: number) {
    const { draftJson, i } = this;

    const BuildingSpace = _.get(draftJson, `lVariant.${i}.Active.aHspace`);

    const draftJsonForSystems = _.get(draftJson, `lVariant.[${i}].HVAC.lSystem`);
    for (const dJFS of draftJsonForSystems) {

      let energyDemandOfSystem = 0;

      // energy demand of the system
      const draftJsonForDevices = _.get(dJFS, `lDevice`);
      if (draftJsonForDevices) {
        for (const dJFD of draftJsonForDevices) {
          energyDemandOfSystem += _.get(dJFD, `Active.ahr_edsum`) || 0;
        }
      }
      energyDemandOfSystem *= BuildingSpace;



      const draftJsonForOperations = _.get(dJFS, `lOperation`);
      if (draftJsonForOperations) {
        for (const dJFO of draftJsonForOperations) {
          const powerForOperation = (_.get(dJFO, `eco.lcashare`) / 100) * energyDemandOfSystem;
          // const usedUnit = _.get(dJFD, `eco.u`);
          const idOekobau = _.get(dJFO, `eco.lcaMaterial`);

          const material = this.getOekobauData(idOekobau);

          if (material) {

            const Amount = powerForOperation;

            _.set(dJFO, `eco.lcar_eng`, powerForOperation);

            const prodGWP = Amount * lifetimeBuilding * +material.GWP;
            const prodODP = Amount * lifetimeBuilding * +material.ODP;
            const prodPOCP = Amount * lifetimeBuilding * +material.POCP;
            const prodAP = Amount * lifetimeBuilding * +material.AP;
            const prodEP = Amount * lifetimeBuilding * +material.EP;
            const prodPEnon = Amount * lifetimeBuilding * +material.PEnr;
            const prodPErenew = Amount * lifetimeBuilding * +material.PE;

            _.set(dJFO, `eco.lcaProd.lcaGWP`, prodGWP);
            _.set(dJFO, `eco.lcaProd.lcaODP`, prodODP);
            _.set(dJFO, `eco.lcaProd.lcaPOCP`, prodPOCP);
            _.set(dJFO, `eco.lcaProd.lcaAP`, prodAP);
            _.set(dJFO, `eco.lcaProd.lcaEP`, prodEP);
            _.set(dJFO, `eco.lcaProd.lcaPEnonrenw`, prodPEnon);
            _.set(dJFO, `eco.lcaProd.lcaPRErenw`, prodPErenew);

          }
        } // end device for
      }
    } // end system for
  }


  private calculateBuildingEnvironment() {
    const { draftJson, i } = this;

    const currentBuildingLifetime = _.get(draftJson, `lVariant.${i}.Active.aHlifetime`);
    const currentBuildingSpace = _.get(draftJson, `lVariant.${i}.Active.aHspace`);

    const draftJsonForAreas = _.get(draftJson, `lVariant[${i}].building.lComponent`);

    // let sumAreaValue = 0;

    let sumweightTotal = 0;
    let sumweightWood = 0;
    let sumweightRecycled = 0;
    let sumweightRecyclable = 0;
    let sumweightCertWood = 0;
    let sumweightEPD = 0;

    let sumGWP = 0;
    let sumODP = 0;
    let sumPOCP = 0;
    let sumAP = 0;
    let sumEP = 0;
    let sumPEnon = 0;
    let sumPErenew = 0;

    // ##################### for areas
    if (draftJsonForAreas) {
      let indexArea = 0;

      for (const dJFA of draftJsonForAreas) {

        const idConstrucion = _.get(dJFA, `idAssC`);
        const AreaValue = _.get(dJFA, `areaC`);

        const idWindow = _.get(dJFA, `idWtC`);
        const CountWindow = _.get(dJFA, `countC`);

        const typeOfArea = _.get(dJFA, `eco.typeCah`);

        // sumAreaValue += +AreaValue;

        const AreaQuantity = typeOfArea == 3 ? +CountWindow : +AreaValue;

        const constructiondata = typeOfArea == 3 ? this.getWindow(idWindow) : this.getConstruction(idConstrucion);

        
        if (constructiondata && constructiondata.eco.lcaProd.lcaGWP != undefined) {

          const weightTotal = AreaQuantity * constructiondata.eco.lcawtot;
          const weightWood = AreaQuantity * constructiondata.eco.lcawwood;
          const weightRecycled = AreaQuantity * constructiondata.eco.lcawrec;
          const weightRecyclable = AreaQuantity * constructiondata.eco.lcawrecab;
          const weightCertWood = AreaQuantity * constructiondata.eco.lcawcert;
          const weightEPD = AreaQuantity * constructiondata.eco.lcawepd;

          const GWP = AreaQuantity * (constructiondata.eco.lcaProd.lcaGWP + constructiondata.eco.lcaEol.lcaGWP + constructiondata.eco.lcaMaintain.lcaGWP);
          const ODP = AreaQuantity * (constructiondata.eco.lcaProd.lcaODP + constructiondata.eco.lcaEol.lcaODP + constructiondata.eco.lcaMaintain.lcaODP);
          const POCP = AreaQuantity * (constructiondata.eco.lcaProd.lcaPOCP + constructiondata.eco.lcaEol.lcaPOCP + constructiondata.eco.lcaMaintain.lcaPOCP);
          const AP = AreaQuantity * (constructiondata.eco.lcaProd.lcaAP + constructiondata.eco.lcaEol.lcaAP + constructiondata.eco.lcaMaintain.lcaAP);
          const EP = AreaQuantity * (constructiondata.eco.lcaProd.lcaEP + constructiondata.eco.lcaEol.lcaEP + constructiondata.eco.lcaMaintain.lcaEP);
          const PEnon = AreaQuantity * (constructiondata.eco.lcaProd.lcaPEnonrenw + constructiondata.eco.lcaEol.lcaPEnonrenw + constructiondata.eco.lcaMaintain.lcaPEnonrenw);
          const PErenew = AreaQuantity * (constructiondata.eco.lcaProd.lcaPRErenw + constructiondata.eco.lcaEol.lcaPRErenw + constructiondata.eco.lcaMaintain.lcaPRErenw);

          _.set(dJFA, `eco.lcawtot`, weightTotal);
          _.set(dJFA, `eco.lcawwood`, weightWood);
          _.set(dJFA, `eco.lcawrec`, weightRecycled);
          _.set(dJFA, `eco.lcawrecab`, weightRecyclable);
          _.set(dJFA, `eco.lcawcert`, weightCertWood);
          _.set(dJFA, `eco.lcawepd`, weightEPD);

          sumweightTotal += weightTotal;
          sumweightWood += weightWood;
          sumweightRecycled += weightRecycled;
          sumweightRecyclable += weightRecyclable;
          sumweightCertWood += weightCertWood;
          sumweightEPD += weightEPD;

          _.set(dJFA, `eco.lca.lcaGWP`, GWP);
          _.set(dJFA, `eco.lca.lcaODP`, ODP);
          _.set(dJFA, `eco.lca.lcaPOCP`, POCP);
          _.set(dJFA, `eco.lca.lcaAP`, AP);
          _.set(dJFA, `eco.lca.lcaEP`, EP);
          _.set(dJFA, `eco.lca.lcaPEnonrenw`, PEnon);
          _.set(dJFA, `eco.lca.lcaPRErenw`, PErenew);

          sumGWP += GWP;
          sumODP += ODP;
          sumPOCP += POCP;
          sumAP += AP;
          sumEP += EP;
          sumPEnon += PEnon;
          sumPErenew += PErenew;

          const GWPscore = new GWPScore({ GWP: GWP / AreaQuantity / currentBuildingLifetime });
          const ODPscore = new ODPScore({ ODP: ODP / AreaQuantity / currentBuildingLifetime });
          const POCPscore = new POCPScore({ POCP: POCP / AreaQuantity / currentBuildingLifetime });
          const APscore = new APScore({ AP: AP / AreaQuantity / currentBuildingLifetime });
          const EPscore = new EPScore({ EP: EP / AreaQuantity / currentBuildingLifetime });

          _.set(dJFA, `eco.ahr_gwpsc`, GWPscore.score);
          _.set(dJFA, `eco.ahr_opdsc`, ODPscore.score);
          _.set(dJFA, `eco.ahr_pocpsc`, POCPscore.score);
          _.set(dJFA, `eco.ahr_apsc`, APscore.score);
          _.set(dJFA, `eco.ahr_epsc`, EPscore.score);

          const environmentalLoadScore = new EnvironmentLoadScore({
            GWPscore: GWPscore.score,
            OPDscore: ODPscore.score,
            POCPscore: POCPscore.score,
            APscore: APscore.score,
            EPscore: EPscore.score
          });

          _.set(dJFA, `eco.ahr_elsc`, environmentalLoadScore.score);

        } // if construciondata
        else
        {
          _.set(dJFA, `eco.lcawtot`, NaN);
          _.set(dJFA, `eco.lcawwood`, NaN);
          _.set(dJFA, `eco.lcawrec`, NaN);
          _.set(dJFA, `eco.lcawrecab`, NaN);
          _.set(dJFA, `eco.lcawcert`, NaN);
          _.set(dJFA, `eco.lcawepd`, NaN);

          _.set(dJFA, `eco.lca.lcaGWP`, NaN);
          _.set(dJFA, `eco.lca.lcaODP`, NaN);
          _.set(dJFA, `eco.lca.lcaPOCP`, NaN);
          _.set(dJFA, `eco.lca.lcaAP`, NaN);
          _.set(dJFA, `eco.lca.lcaEP`, NaN);
          _.set(dJFA, `eco.lca.lcaPEnonrenw`, NaN);
          _.set(dJFA, `eco.lca.lcaPRErenw`, NaN);

          _.set(dJFA, `eco.ahr_gwpsc`, NaN);
          _.set(dJFA, `eco.ahr_opdsc`, NaN);
          _.set(dJFA, `eco.ahr_pocpsc`, NaN);
          _.set(dJFA, `eco.ahr_apsc`, NaN);
          _.set(dJFA, `eco.ahr_epsc`, NaN);

          _.set(dJFA, `eco.ahr_elsc`, NaN);
        }

        indexArea = indexArea + 1;
      } // end for each Area
    } // end if Areas


    let countSystemValue = 0;
    // ##################### for systems
    const draftJsonForSystems = _.get(draftJson, `lVariant.[${i}].HVAC.lSystem`);
    if (draftJsonForSystems) {
      for (const dJFS of draftJsonForSystems) {
        const draftJsonForDevices = _.get(dJFS, `lDevice`);
        if (draftJsonForDevices) {
          for (const dJFD of draftJsonForDevices) {

            _.get(dJFD, `lDevice`);
            const GWP = (_.get(dJFD, `eco.lcaProd.lcaGWP`) + _.get(dJFD, `eco.lcaEol.lcaGWP`) + _.get(dJFD, `eco.lcaMaintain.lcaGWP`));
            const ODP = (_.get(dJFD, `eco.lcaProd.lcaODP`) + _.get(dJFD, `eco.lcaEol.lcaODP`) + _.get(dJFD, `eco.lcaMaintain.lcaODP`));
            const POCP = (_.get(dJFD, `eco.lcaProd.lcaPOCP`) + _.get(dJFD, `eco.lcaEol.lcaPOCP`) + _.get(dJFD, `eco.lcaMaintain.lcaPOCP`));
            const AP = (_.get(dJFD, `eco.lcaProd.lcaAP`) + _.get(dJFD, `eco.lcaEol.lcaAP`) + _.get(dJFD, `eco.lcaMaintain.lcaAP`));
            const EP = (_.get(dJFD, `eco.lcaProd.lcaEP`) + _.get(dJFD, `eco.lcaEol.lcaEP`) + _.get(dJFD, `eco.lcaMaintain.lcaEP`));
            const PEnon = (_.get(dJFD, `eco.lcaProd.lcaPEnonrenw`) + _.get(dJFD, `eco.lcaEol.lcaPEnonrenw`) + _.get(dJFD, `eco.lcaMaintain.lcaPEnonrenw`));
            const PErenew = (_.get(dJFD, `eco.lcaProd.lcaPRErenw`) + _.get(dJFD, `eco.lcaEol.lcaPRErenw`) + _.get(dJFD, `eco.lcaMaintain.lcaPRErenw`));

            const countSystem = _.get(dJFD, `eco.countS`);

            countSystemValue += countSystem;

            if (countSystem > 0) {
              sumGWP += (GWP ? GWP : 0);
              sumODP += (ODP ? ODP : 0);
              sumPOCP += (POCP ? POCP : 0);
              sumAP += (AP ? AP : 0);
              sumEP += (EP ? EP : 0);
              sumPEnon += (PEnon ? PEnon : 0);
              sumPErenew += (PErenew ? PErenew : 0);

              const GWPscore = new GWPScore({ GWP: GWP / currentBuildingSpace / currentBuildingLifetime });
              const ODPscore = new ODPScore({ ODP: ODP / currentBuildingSpace / currentBuildingLifetime });
              const POCPscore = new POCPScore({ POCP: POCP / currentBuildingSpace / currentBuildingLifetime });
              const APscore = new APScore({ AP: AP / currentBuildingSpace / currentBuildingLifetime });
              const EPscore = new EPScore({ EP: EP / currentBuildingSpace / currentBuildingLifetime });

              _.set(dJFD, `eco.ahr_gwpsc`, GWPscore.score);
              _.set(dJFD, `eco.ahr_opdsc`, ODPscore.score);
              _.set(dJFD, `eco.ahr_pocpsc`, POCPscore.score);
              _.set(dJFD, `eco.ahr_apsc`, APscore.score);
              _.set(dJFD, `eco.ahr_epsc`, EPscore.score);

              const environmentalLoadScore = new EnvironmentLoadScore({
                GWPscore: GWPscore.score,
                OPDscore: ODPscore.score,
                POCPscore: POCPscore.score,
                APscore: APscore.score,
                EPscore: EPscore.score
              });

              _.set(dJFD, `eco.ahr_elsc`, environmentalLoadScore.score);
            }

          }
        } // end if Devices

        const draftJsonForOperaions = _.get(dJFS, `lOperation`);
        if (draftJsonForOperaions) {
          for (const dJFO of draftJsonForOperaions) {

            const GWP = _.get(dJFO, `eco.lcaProd.lcaGWP`);
            const ODP = _.get(dJFO, `eco.lcaProd.lcaODP`);
            const POCP = _.get(dJFO, `eco.lcaProd.lcaPOCP`);
            const AP = _.get(dJFO, `eco.lcaProd.lcaAP`);
            const EP = _.get(dJFO, `eco.lcaProd.lcaEP`);
            const PEnon = _.get(dJFO, `eco.lcaProd.lcaPEnonrenw`);
            const PErenew = _.get(dJFO, `eco.lcaProd.lcaPRErenw`);

            const share = _.get(dJFO, `eco.lcar_eng`);

            if (share > 0) {
              sumGWP += (GWP ? GWP : 0);
              sumODP += (ODP ? ODP : 0);
              sumPOCP += (POCP ? POCP : 0);
              sumAP += (AP ? AP : 0);
              sumEP += (EP ? EP : 0);
              sumPEnon += (PEnon ? PEnon : 0);
              sumPErenew += (PErenew ? PErenew : 0);

              const GWPscore = new GWPScore({ GWP: GWP / share / currentBuildingLifetime });
              const ODPscore = new ODPScore({ ODP: ODP / share / currentBuildingLifetime });
              const POCPscore = new POCPScore({ POCP: POCP / share / currentBuildingLifetime });
              const APscore = new APScore({ AP: AP / share / currentBuildingLifetime });
              const EPscore = new EPScore({ EP: EP / share / currentBuildingLifetime });

              _.set(dJFO, `eco.ahr_gwpsc`, GWPscore.score);
              _.set(dJFO, `eco.ahr_opdsc`, ODPscore.score);
              _.set(dJFO, `eco.ahr_pocpsc`, POCPscore.score);
              _.set(dJFO, `eco.ahr_apsc`, APscore.score);
              _.set(dJFO, `eco.ahr_epsc`, EPscore.score);

              const environmentalLoadScore = new EnvironmentLoadScore({
                GWPscore: GWPscore.score,
                OPDscore: ODPscore.score,
                POCPscore: POCPscore.score,
                APscore: APscore.score,
                EPscore: EPscore.score
              });

              _.set(dJFO, `eco.ahr_elsc`, environmentalLoadScore.score);
            }

          }
        } // end if Devices
      }
    } // end if Systems

    sumGWP /= currentBuildingSpace;
    sumODP /= currentBuildingSpace;
    sumPOCP /= currentBuildingSpace;
    sumAP /= currentBuildingSpace;
    sumEP /= currentBuildingSpace;
    sumGWP /= currentBuildingLifetime;
    sumODP /= currentBuildingLifetime;
    sumPOCP /= currentBuildingLifetime;
    sumAP /= currentBuildingLifetime;
    sumEP /= currentBuildingLifetime;

    // environmental loads
    const totalGWP = sumGWP; // + sumGWPtotal;
    const totalODP = sumODP; // + sumODPtotal;
    const totalPOCP = sumPOCP; // + sumPOCPtotal;
    const totalAP = sumAP; // + sumAPtotal;
    const totalEP = sumEP; // + sumEPtotal;

    // console.log("GWP:" + totalGWP + "ODP:" + totalODP + 
    // "POCP:" + totalPOCP + 
    // "AP:" + totalAP +
    // "EP:" + totalEP );

    _.set(draftJson, `lVariant[${i}].Active.eco.lca.lcaGWP`, totalGWP);
    _.set(draftJson, `lVariant[${i}].Active.eco.lca.lcaODP`, totalODP);
    _.set(draftJson, `lVariant[${i}].Active.eco.lca.lcaPOCP`, totalPOCP);
    _.set(draftJson, `lVariant[${i}].Active.eco.lca.lcaAP`, totalAP);
    _.set(draftJson, `lVariant[${i}].Active.eco.lca.lcaEP`, totalEP);

    const tGWPscore = new GWPScore({ GWP: totalGWP });
    const tODPscore = new ODPScore({ ODP: totalODP });
    const tPOCPscore = new POCPScore({ POCP: totalPOCP });
    const tAPscore = new APScore({ AP: totalAP });
    const tEPscore = new EPScore({ EP: totalEP });

    if (!_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elgwp`)) _.set(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elgwp`,5);
    if (!_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elopd`)) _.set(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elopd`,5);
    if (!_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elpocp`)) _.set(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elpocp`,5);
    if (!_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elap`)) _.set(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elap`,5);
    if (!_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elep`)) _.set(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elep`,5);

    const tGWPscoreUserDef = +_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elgwp`);
    const tODPscoreUserDef = +_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elopd`);
    const tPOCPscoreUserDef = +_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elpocp`);
    const tAPscoreUserDef = +_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elap`);
    const tEPscoreUserDef = +_.get(draftJson, `lVariant.[${i}].Active.eco.lca.ah_elep`);

    const useUserdefinedEL = _.get(draftJson, `lVariant.[${0}].Active.ahudefenvload`) > 1 ? true : false;
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_gwpsc`, useUserdefinedEL ? tGWPscoreUserDef : tGWPscore.score);
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_opdsc`, useUserdefinedEL ? tODPscoreUserDef : tODPscore.score);
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_pocpsc`, useUserdefinedEL ? tPOCPscoreUserDef : tPOCPscore.score);
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_apsc`, useUserdefinedEL ? tAPscoreUserDef : tAPscore.score);
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_epsc`, useUserdefinedEL ? tEPscoreUserDef : tEPscore.score);

    const environmentalLoadScore = new EnvironmentLoadScore({
      GWPscore: useUserdefinedEL ? tGWPscoreUserDef : tGWPscore.score,
      OPDscore: useUserdefinedEL ? tODPscoreUserDef : tODPscore.score,
      POCPscore:  useUserdefinedEL ? tPOCPscoreUserDef : tPOCPscore.score,
      APscore:  useUserdefinedEL ? tAPscoreUserDef : tAPscore.score,
      EPscore: useUserdefinedEL ? tEPscoreUserDef : tEPscore.score
    });

    _.set(draftJson, `lVariant[${i}].Active.ahr_elsc`, environmentalLoadScore.score);



    // sustainable construction
    const useUserdefinedSC = _.get(draftJson, `lVariant.[${0}].Active.ahudefsusconst`) > 1 ? true : false;
    const percentageRecycled = useUserdefinedSC ? _.get(draftJson, `lVariant.[${i}].Active.eco.sus.ah_scrc`) : sumweightTotal > 0 ? (sumweightRecycled / sumweightTotal) * 100 : 100;
    const percentageRecyclable = useUserdefinedSC ? _.get(draftJson, `lVariant.[${i}].Active.eco.sus.ah_scrv`) : sumweightTotal > 0 ? (sumweightRecyclable / sumweightTotal) * 100 : 100;
    const percentageRespSourceWood = useUserdefinedSC ? _.get(draftJson, `lVariant.[${i}].Active.eco.sus.ah_sccw`) : sumweightWood > 0 ? (sumweightCertWood / sumweightWood) * 100 : 100;
    const percentageDeclaredOriging = useUserdefinedSC ? _.get(draftJson, `lVariant.[${i}].Active.eco.sus.ah_scve`) : sumweightTotal > 0 ? (sumweightEPD / sumweightTotal) * 100 : 100;

    _.set(draftJson, `lVariant[${i}].Active.eco.sus.ahr_scrc`, percentageRecycled);
    _.set(draftJson, `lVariant[${i}].Active.eco.sus.ahr_scrv`, percentageRecyclable);
    _.set(draftJson, `lVariant[${i}].Active.eco.sus.ahr_sccw`, percentageRespSourceWood);
    _.set(draftJson, `lVariant[${i}].Active.eco.sus.ahr_scve`, percentageDeclaredOriging);

    const recycledScore = new RecycledScore({ percentage: percentageRecycled });
    const recyclableScore = new RecyclableScore({ percentage: percentageRecyclable });
    const responsibleWoodScore = new ResponsibleWoodScore({ percentage: percentageRespSourceWood });
    const declaredOriginScore = new DeclaredOriginScore({ percentage: percentageDeclaredOriging });

    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_rcsc`, recycledScore.val);
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_rvsc`, recyclableScore.val);
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_cwsc`, responsibleWoodScore.val);
    _.set(draftJson, `lVariant[${i}].Active.eco.ahr_vesc`, declaredOriginScore.val);

    const sustainableScore = new SustainableScore({
      Recycled: recycledScore.val,
      Recyclable: recyclableScore.val,
      ResponsWood: responsibleWoodScore.val,
      DeclaredOrigin: declaredOriginScore.val,
    });

    _.set(draftJson, `lVariant[${i}].Active.ahr_ssc`, sustainableScore.val);

    const totalSustainableScore = new TotalSustainableScore({
      Loads: environmentalLoadScore.score,
      SustainableConstruction: sustainableScore.val 
    });

    _.set(draftJson, `lVariant[${i}].Active.ahr_scsc`, totalSustainableScore.val);

  }














  private updatesFreshwaterScore() {
    const { draftJson, i } = this;

    // const indooraircategory = _.get(
    //   draftJson,
    //   `lVariant[${i}].Active.ahr_telvl`
    // );
    const toi = _.get(draftJson, `lVariant[${i}].Active.ahfwtoi`);
    const shw = _.get(draftJson, `lVariant[${i}].Active.ahfwshw`);
    const tap = _.get(draftJson, `lVariant[${i}].Active.ahfwtap`);

    const toiletWaterUsage = new FreshwaterScore({
      flowrate: toi,
    });

    const showerWaterUsage = new FreshwaterScore({
      flowrate: shw,
    });

    const tapWaterUsage = new FreshwaterScore({
      flowrate: tap,
    });


    _.set(draftJson, `lVariant[${i}].Active.ahr_fwtoi`, toiletWaterUsage.val);
    _.set(draftJson, `lVariant[${i}].Active.ahr_fwshw`, showerWaterUsage.val);
    _.set(draftJson, `lVariant[${i}].Active.ahr_fwtap`, tapWaterUsage.val);

    const oneFreshWaterIsOutOfScope = 
      toiletWaterUsage.val > 4 ? true : 
      showerWaterUsage.val > 4 ? true : 
      tapWaterUsage.val > 4 ? true : 
      false;

    const weightedFreshWaterScore = oneFreshWaterIsOutOfScope ? 5 :
      (toiletWaterUsage.val + showerWaterUsage.val + tapWaterUsage.val) / 3.0;


    _.set(draftJson, `lVariant[${i}].Active.ahr_fwsc`, weightedFreshWaterScore);
  }

}
