import React from 'react';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, Divider, FlexRow, P } from 'src/ui';
import { TrashIcon } from 'src/ui/elements/icons';
import {
  RoomsJsonDefinition,
  RoomsTitleJsonDefinition,
} from '../../data/rooms_json_definition';
import { useNumberOfRooms } from '../applications/use_number_of_rooms';

const titleDefinition = new RoomsTitleJsonDefinition();

export const Room: React.FC<any> = ({ index, onRemoveRoom, isLast }) => {
  const Json = new RoomsJsonDefinition(index);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        <FlexRow
          mt={4}
          ml={1}
          py={2}
          alignSelf="self-start"
          as="button"
          onClick={() => onRemoveRoom(index)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexRow>
      </FlexRow>

      {!isLast ? <Divider my={2} /> : null}
    </>
  );
};

export const RoomsPage: React.FC = () => {
  const { rooms, addRoom, removeRoom } = useNumberOfRooms();
  const mappingRooms = [...(Array(rooms).keys() as any)];
  return (
    <>
      <FormBuilder withoutBottomSpacing jsonKeys={titleDefinition.keys} />
      {mappingRooms.map((a, i) => (
        <AnimateList key={i}>
          <Room
            key={rooms}
            index={a}
            onRemoveRoom={() => removeRoom(i)}
            isLast={i === mappingRooms.length - 1}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" onClick={() => addRoom()}>
          <P style={{ textDecoration: 'underline' }} color="green.50">
          {i18n.t(`add.room`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </>
  );
};
