import {
  Input as BaseInput,
  InputGroup,
  InputProps,
  InputRightElement,
  Textarea as BaseTextarea,
  TextareaProps,
} from '@chakra-ui/core';

import { Select as BaseSelect, SelectProps } from '@chakra-ui/core';
import React from 'react';
import { i18n } from 'src/features/shared/translations/translate';
import { appFontSizes, appColors } from '../../app/theme';
import { Box, FlexCol } from '../structure';
import { SelectIcon } from './icons';
import { SelectWithLabelProps } from './inputs';
import { P } from './typography';




export const SelectOutputWithLabel: React.FC<SelectWithLabelProps> = ({
  placeholder,
  label,
  value,
  onChange,
  hasError,
  errorText,
  options,
  valuetext,
  ...selectProps
}) => (
  <FlexCol flex={1}>
    <P
      style={{ whiteSpace: 'nowrap' }}
      fontSize={appFontSizes.small}
      textAlign="left"
      mb="4px"
    >
      {label}
    </P>
    <InputGroup>
      <P
        mt={1}
        px={'13px'}

      >{valuetext}</P>
      {/* <BaseSelect
          icon={<div />}
          iconSize="80px!important"
          css={{ width: '100%', paddingLeft: 8 }}
          backgroundColor={appColors.white}
          borderRadius="8px"
          focusBorderColor="primary.500"
          focusBorderWidth="1px"
          errorBorderColor="shinyRed.500"
          // _hover={{ borderColor: 'primary.500' }}
          fontWeight={'Regular'}
          fontSize={appFontSizes.body}
          borderColor="gray.500"
          height="42px"
          borderWidth="1px"
          value={value}
          // onChange={onChange}
          placeholder={placeholder}
          isinvalid={hasError}
          {...selectProps}
        >
          {options.map((option) => (
            <option value={option.value}>
                {option.label}
            </option>
          ))}
        </BaseSelect> */}
    </InputGroup>

    {hasError && errorText && <P color="shinyRed.500">{errorText}</P>}
  </FlexCol>
);



export type OutputWithLabelProps = {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: InputProps['onChange'];
  hasError?: boolean;
  errorText?: string;
  insideLabel?: string;
  styleType?: number;
  preLabel?: string;
} & InputProps;

export const OutputWithLabel: React.FC<OutputWithLabelProps> = ({
  placeholder,
  label,
  value,
  type,
  onChange,
  hasError,
  errorText,
  insideLabel,
  styleType,
  preLabel,
  ...inputProps
}) => (
  <FlexCol flex={1} position="relative">
    <P
      style={{ whiteSpace: 'nowrap' }}
      fontSize={appFontSizes.small}
      textAlign="left"
      mb="4px"
    >
      {preLabel} {label}
    </P>
    <InputGroup justify="center">
      <P
        width={styleType === 1 ? 'auto' : styleType === 2 ? '100%' : styleType === 3 ? '100%' : 'auto'}
        mt={styleType === 1 ? 0 : styleType === 2 ? 0 : styleType === 3 ? 2 : 1}
        textAlign={styleType === 1 ? 'left' : styleType === 2 ? 'right' : styleType === 3 ? 'right' : 'left'}
        backgroundColor={appColors.white}
        borderRadius="8px"
        focusBorderColor={appColors.primary.main}
        focusBorderWidth="1px"
        errorBorderColor="red.500"
        fontSize={styleType === 1 ? appFontSizes.body : styleType === 2 ? appFontSizes.h4 : styleType === 3 ? appFontSizes.h4 : appFontSizes.body}
        fontWeight={styleType === 1 ? 'bold' : styleType === 2 ? 'bold' : styleType === 3 ? 'bold' : ''}
        color={
          styleType === 1
            ? appColors.primary.dark
            : styleType === 2
              ? appColors.primary.main
              : styleType === 3
                ? appColors.primary.main
                : ''
        }
        borderColor="gray.500"
        px={styleType === 1 ? '0px' : styleType === 2 ? '0px' : styleType === 3 ? '0px' : '13px'}
        height="42px"
        borderWidth="0px"
        whiteSpace={styleType === 1 ? 'nowrap' : styleType === 2 ? 'nowrap' : styleType === 3 ? 'nowrap' : 'normal'}
        isinvalid={hasError}
        {...inputProps}
      >
        {shownValue(value, placeholder)}
      </P>

      <P 
        whiteSpace="nowrap"
        mt={styleType === 1 ? 0 : styleType === 2 ? 0 : styleType === 3 ? 0 : 1}
        ml={styleType === 1 ? 0 : styleType === 2 ? 2 : styleType === 3 ? 2 : 0}
        color={appColors.gray.light}
      >
        {insideLabel}
      </P>


      {/* <BaseInput
        textAlign={styleType === 1 ? 'left' : styleType === 2 ? 'right' : 'left'}
          backgroundColor={appColors.white}
          borderRadius="8px"
          focusBorderColor={appColors.primary.main}
          focusBorderWidth="1px"
          errorBorderColor="red.500"
          fontSize={styleType === 1 ? appFontSizes.body : styleType === 2 ? appFontSizes.h4 : appFontSizes.body}
          fontWeight={styleType === 1 ? 'bold' : styleType === 2 ? 'bold' : ''}
          color={
            styleType === 1
              ? appColors.primary.dark
              : styleType === 2
              ? appColors.primary.main
              : ''
          }
          borderColor="gray.500"
          px={styleType === 1 ? '0px' : styleType === 2 ? '0px' : '13px'}
          height="42px"
          borderWidth="1px"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          isinvalid={hasError}
          {...inputProps}
        ></BaseInput>
        {insideLabel ? (
          <InputRightElement
            pointerEvents="none"
            width="auto"
            children={
              <P mt="22px" mr="-18px" color={appColors.gray.light}>
                {insideLabel}
              </P>
            }
          />
        ) : null} */}
    </InputGroup>

    {hasError && errorText && (
      <Box mt={1}>
        <P color="red.500">{errorText}</P>
      </Box>
    )}
  </FlexCol>
);





export type SimpleOutputProps = {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: InputProps['onChange'];
  hasError?: boolean;
  errorText?: string;
  insideLabel?: string;
  styleType?: number;
  preLabel?: string;
} & InputProps;

export const SimpleOutput: React.FC<SimpleOutputProps> = ({
  placeholder,
  label,
  value,
  type,
  onChange,
  hasError,
  errorText,
  insideLabel,
  styleType,
  preLabel,
  ...inputProps
}) => (
  <FlexCol flex={1} position="relative">
    <InputGroup justify="center">
      <P
        width={'auto'}
        mb={0}
        textAlign={styleType === 1 ? 'left' : styleType === 2 ? 'right' : styleType === 3 ? 'right' : 'left'}
        backgroundColor={appColors.white}
        borderRadius="8px"
        border="0px;"
        focusBorderColor={appColors.primary.main}
        focusBorderWidth="1px"
        errorBorderColor="red.500"
        fontSize={styleType === 1 ? appFontSizes.body : styleType === 2 ? appFontSizes.h4 : styleType === 3 ? appFontSizes.h4 : appFontSizes.body}
        fontWeight={styleType === 1 ? 'bold' : styleType === 2 ? 'bold' : styleType === 3 ? 'bold' : ''}
        color={
          styleType === 1
            ? appColors.primary.dark
            : styleType === 2
              ? appColors.primary.main
              : styleType === 3
                ? appColors.primary.main
                : ''
        }
        borderColor="gray.500"
        px={'0px'}
        isinvalid={hasError}
        {...inputProps}
      >
        {shownValue(value, placeholder)} 
      </P>

      <P
        mt={styleType === 1 ? 0 : styleType === 2 ? 0 : styleType === 3 ? 0 : 1}
        ml={styleType === 1 ? 0 : styleType === 2 ? 2 : styleType === 3 ? 2 : 0}
        color={appColors.gray.light}
      >
        {insideLabel}
      </P>

    </InputGroup>

    {hasError && errorText && (
      <Box mt={0}>
        <P color="red.500">{errorText}</P>
      </Box>
    )}
  </FlexCol>
);


export type SimpleLabelProps = {
  label: string;
  placeholder: string;
  value: string;
  type: string;
  onChange: InputProps['onChange'];
  hasError?: boolean;
  errorText?: string;
  insideLabel?: string;
  styleType?: number;
  preLabel?: string;
} & InputProps;

export const SimpleLabel: React.FC<SimpleLabelProps> = ({
  placeholder,
  label,
  value,
  type,
  onChange,
  hasError,
  errorText,
  insideLabel,
  styleType,
  preLabel,
  ...inputProps
}) => (
  <FlexCol flex={1} position="relative">
    <InputGroup justify="center">
    <P
      style={{ whiteSpace: 'nowrap' }}
      fontSize={appFontSizes.small}
      textAlign="left"
      mt="4px"
      mr={5}
    >
      {preLabel} {label}
    </P>
    </InputGroup>

    {hasError && errorText && (
      <Box mt={0}>
        <P color="red.500">{errorText}</P>
      </Box>
    )}
  </FlexCol>
);

function shownValue(value: string & (string | number | readonly string[] | undefined), placeholder: string): React.ReactNode {

  if (value == undefined) return placeholder;
  if (value == "NaN") return i18n.t(`pages.nan`);
  if (value == "" ) return placeholder;
  return value;

}
