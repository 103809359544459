import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { useOnMount } from 'src/features/shared/hooks/useOnMount';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { getRandomInt } from 'src/features/shared/utils';
import { AREAS_DEFINITION_PATH } from '../../data/areas_json_definition';


export const useSustainableConstructionCalc = (facade = globalJsonFacade) => {
  const { globalJson } = useContext(JsonContext);

  const useEnvironmentLoads = _.get(globalJson,  `lVariant.[0].Active.ahudefenvload`) < 2 ? true : false ;
  const useSustainableConstruction = _.get(globalJson, `lVariant.[0].Active.ahudefsusconst`) < 2 ? true : false ;

  const useSustainable = useEnvironmentLoads || useSustainableConstruction;

  return {
    useEnvironmentLoads,
    useSustainableConstruction,
    useSustainable,
  };
}


export const useNumberOfAreas = (facade = globalJsonFacade) => {
  const [areas, setAreas] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForAreas = _.get(globalJson, AREAS_DEFINITION_PATH);

  useOnMount(() => {
    facade.get().then((v: typeof globalJSON) => setGlobalJson(v));
  });

  const numberOfAreas = _.size(draftJsonForAreas);


  const removeArea = (index: number) => {
    const newDraftJsonForAreas = [...(draftJsonForAreas as any)];

    const filtered = newDraftJsonForAreas.filter(function (value, i, arr) {
      return i !== index;
    });

    const newDraftJson = _.set(
      globalJson as any,
      AREAS_DEFINITION_PATH,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftJsonForRooms = _.get(newDraftJson, AREAS_DEFINITION_PATH);
      const numberOfAreas = _.size(draftJsonForRooms);
      setAreas(numberOfAreas);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addArea = (typeOfArea: number) => {
    setAreas(areas + 1)

    const randomId = _.uniqueId("10") + getRandomInt(50000);   // get a random id
    _.set(
      globalJson,
      `lVariant.[0].building.lComponent.[${areas}].id`,
      randomId
    );    

    _.set(globalJson, `lVariant.[0].building.lComponent.[${areas}].eco.typeCah`, typeOfArea);

    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });

  }


  useEffect(() => {
    setAreas(numberOfAreas);
  }, [numberOfAreas]);

  return {
    areas,
    addArea,
    removeArea,
  };
};


