import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { i18n } from 'src/features/shared/translations/translate';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from 'src/ui/tabs';
import { SystemsPage } from './systems';
import { SystemElectricityDemandPage, SystemElectricitySupplyPage, SystemHeatSupplyPage } from './systems_additional';

export const SystemsTabsPage = ({
  tabsi,
}: any) => {

  const { replace } = useHistory();
  const { pathname } = useLocation();

  const onChange = (index: number) => {
    if (index === 0) {
      replace(pathname.replace(/electricitydemand|thermalproduction|electricityproduction/g, 'thermaldemand'));
    } else if (index === 1 ) {
      replace(pathname.replace(/thermaldemand|atest|thermalproduction|electricityproduction/g, 'electricitydemand'));
    } else if (index === 2 ) {  // index === 2
      replace(pathname.replace(/thermaldemand|electricitydemand|electricityproduction/g, 'thermalproduction'));
    } else {  // index === 3
      replace(pathname.replace(/thermaldemand|electricitydemand|thermalproduction/g, 'electricityproduction'));
    }
  };

  const defaultIndex = 
    pathname.includes('thermaldemand') ? 0 
    : pathname.includes('electricitydemand') ? 1 
    : pathname.includes('thermalproduction') ? 2 
    : 3 


  return (
    <Tabs
      pt={12}
      isLazy
      defaultIndex={tabsi}
      onChange={(index: number) => onChange(index)}
    >
      <TabList>
        <Tab>{i18n.t(`tabs.thermaldemand`)}</Tab>
        <Tab>{i18n.t(`tabs.electricitydemand`)}</Tab>
        <Tab>{i18n.t(`tabs.thermalproduction`)}</Tab>
        <Tab>{i18n.t(`tabs.electricityproduction`)}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0} py={0}>
          <SystemsPage />
        </TabPanel>
        <TabPanel px={0} py={0}>
          <SystemElectricityDemandPage />
        </TabPanel>
        <TabPanel px={0} py={0}>
          <SystemHeatSupplyPage />
        </TabPanel>
        <TabPanel px={0} py={0}>
          <SystemElectricitySupplyPage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};