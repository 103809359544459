import _ from 'lodash';
import { globalJSON } from '../data/source/global.activejob';

const and = (json: typeof globalJSON) => (
  data: {
    key: string;
    value: any;
  }[]
) => {
  return !data.some((d) => _.get(json, d.key) !== String(d.value));
};

const or = (json: typeof globalJSON) => (
  data: {
    key: string;
    value: any;
  }[]
) => {
  // eslint-disable-next-line eqeqeq
  return data.some((d) => _.get(json, d.key) == d.value); 
};

const not = (json: typeof globalJSON) => (
  data: {
    key: string;
    value: any;
  }[]
) => {
  // eslint-disable-next-line eqeqeq
  return data.some((d) => _.get(json, d.key) != d.value); // not type check
};


export const jsonValuesUtils = {
  and,
  or,
  not,
};
