import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { helpArticles } from '../data/article_ids';
import { HelpArticlesHttpFacade } from '../domain/HelpArticlesHttpFacade';
import { HelpArticle } from '../domain/help_article';

const articlesString = process.env.REACT_APP_FRESHDESK_ARTICLE_IDS || '';
export const getArticleLink = (articleId: string) =>
  // process.env.REACT_APP_FRESHDESK_BASE_URL + articleId;
  // "http://help.c3rro.com/support/solutions/articles/77000481269"
  "http://activehouse.c3rro.com/support/solutions/articles/" + articleId;


// export const availableArticles = articlesString.split(',');
export const availableArticles = (
  pathname:string,
) => {
  for (const oneHelpArticle of helpArticles) {
    if (pathname.includes(oneHelpArticle.n))
    {
      return oneHelpArticle.ids;
    }
  }

  return helpArticles[0].ids;
};

export const useHelpArticles = (
  id: string,
  facade = new HelpArticlesHttpFacade()
) => {
  const [helpArticle, setArticle] = useState<HelpArticle | null>(null);
  useEffect(() => {
    facade.getArticles(id).then((response) => {
      if (response instanceof HelpArticle) {
        setArticle(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { helpArticle };
};
