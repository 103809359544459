import _ from 'lodash';
import React, { useContext } from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { IndoorAirQualityJsonDefinition } from '../../../data/qualitative/indoorair_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';



export const IndoorAirQualityPage: React.FC = () => {
  const freshwaterjson = useWithVariantCaseIndex(
    IndoorAirQualityJsonDefinition
  );

  return (
    <>
      <FormBuilder jsonKeys={freshwaterjson.keys} />
    </>
  );
};

export default IndoorAirQualityPage;
