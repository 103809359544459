
import React, { useState } from "react";
import { appColors } from "src/app/theme";
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { useVariantsLength } from "src/features/sidebar/application/new_variantcase";
import { GroupBlock } from "src/features/sidebar/presentation/sidebar";
import { FlexRow, P } from "src/ui";
import { useCaseStateError, useFirstCaseStateError } from "../application/use_input_state";
import { CaseStatusJsonDefinition } from "../data/Status_json";

// export const Target = () => <p>Target!</p>;

// const statusJson = new StatusJsonDefinition();



export const CaseStatus: React.FC<any> = ({ variantcase }) => {
  const Json = new CaseStatusJsonDefinition(variantcase);

  // const name = useVariantNameFromIndex(variantcase);


  return (
    <>
      {/* <FlexRow alignItems="center">
        <FlexCol flex={1} position="relative">
          <P
            fontWeight={700}
            fontSize={appFontSizes.body}
          >
            {useVariantNameFromIndex(variantcase)}
          </P>
        </FlexCol></FlexRow> */}
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />

      </FlexRow>
    </>
  );
};



export const Status: React.FC = () => {
  const [isOpen, setOpen] = useState<string>('baseline');

  const {
    ArrayOfVariantsIndex,
    variantsLength,
  } = useVariantsLength();

  // console.log(ArrayOfVariantsIndex);
  return (
    <>
      {useFirstCaseStateError().map(function (name) {
        return <P mb={3} color={appColors.red[500]}>{name}</P>;
      })}

      <GroupBlock variantcase={'baseline'} setOpen={setOpen} isOpen={isOpen}>
        <CaseStatus variantcase={0} />
      </GroupBlock>

      {ArrayOfVariantsIndex.map((c) => {
        const variantCase = `variantcase${c + 1}`; // to correct variant case
        const variantCaseId = c + 1; //`variantcase${c + 1}`; // to correct variant case
        return (
          <GroupBlock variantcase={variantCase} setOpen={setOpen} isOpen={isOpen}>
            <CaseStatus variantcase={variantCaseId} />
          </GroupBlock>
        );
      })}

    </>
  );
};




function globalJson(globalJson: any, arg1: string): React.ReactNode {
  throw new Error("Function not implemented.");
}

