import React from 'react';
import _ from 'lodash';
import { useState } from 'react';
import { useOnMount } from '../../hooks/useOnMount';
import { globalJSON } from '../data/source/global.activejob';
import { FieldDefinition } from '../../form/domain/field_definitions';
import { globalJsonFacade } from '../../form/infrastructure/global_json_facade';
import { validateField } from '../../form/application/field_validation';
import { keyForFieldDefinition } from '../../form/domain/key_for_field_definition';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Unit } from '../../form/domain/unit';

export const JsonContext = React.createContext<any>({});

const facade = globalJsonFacade;

export const JsonContextContainer = ({ children }: any) => {
  const [globalJson, setGlobalJson] = useState(globalJSON);
  const [errors, setErrors] = useState<any>({});

  useOnMount(() => {
    facade.get().then((v: typeof globalJSON) => {
      setGlobalJson(v);
    });
  });


  const onSaveDraftJsonToGlobal = (fieldDefinition: FieldDefinition) => {
    // const isValid = validateField({ fieldDefinition, draftJson });

    // const key = keyForFieldDefinition(fieldDefinition) as string;
    // setErrors({ ...errors, [key]: !isValid });

    // if (isValid) {
    //   facade.save(draftJson).then((v) => {
    //     setDraftJson(v);
    //     setGlobalJson(v);
    //   });
    // }
  };



  const onSaveAndCalcDraftJsonToGlobal = (fieldDefinition: FieldDefinition) => {
    // const isValid = validateField({ fieldDefinition, draftJson });

    // const key = keyForFieldDefinition(fieldDefinition) as string;
    // setErrors({ ...errors, [key]: !isValid });

    // if (isValid) {
    //   facade.save(draftJson).then((v) => {
    //     setDraftJson(v);
    //     setGlobalJson(v);
    //   });
    // }
  };

  const onUpdateFieldToDraftJson = (value: any, field: any) => {
    const data = _.set({}, field.fieldData.key, value) as any;

    setGlobalJson(data);
  };

  const setThisDraftJson = (json: any) => {
    setGlobalJson(json);
  }


  const onUpdateFieldToDraftJsonDebounced = useDebouncedCallback(
    
    // function

    // used for text and number input

      (value: any, field: any, unit: Unit | undefined) => {
      //  console.log(value);
      // set SI here

/*       const calcVal = unit ? 
        unit.currentSystem==2 ? value * unit.conversionFactor : 
        value : 
        value;
      
      const data = _.set({}, field.fieldData.key, calcVal) as any */

      const data = unit ? 
        unit.currentSystem==2 ? _.set({}, field.fieldData.key, value * unit.conversionFactor) as any : 
        _.set({}, field.fieldData.key, value) as any : 
        _.set({}, field.fieldData.key, value) as any;

      facade.save(data).then((v) => {
        setGlobalJson(v);
      });

      //const debugUnitSystem = unit ? unit.currentSystem : "notdef"
      //const debugConversion =  unit ? unit.conversionFactor : "notdef"
      //console.log("estt: " + value + "/" + calcVal +  " unit:" + debugUnitSystem + " factor:" + debugConversion);

    },
    // delay in ms
    1
  ); 


  const onSaveFieldToGlobalDirectly = (value: any, field: any) => {
    // used for dropdown and yes/now
    const data = _.set({}, field.fieldData.key, value) as any;

    facade.save(data).then((v) => {
      setGlobalJson(v);
    });
  };

  const onSaveKeyToGlobalDirectly = useDebouncedCallback( (value: any, key: any) => {
    const data = _.set({}, key, value) as any;

    facade.save(data).then((v) => {
      setGlobalJson(v);
    });
  }, 1);

  const onMergeJsonWithGlobalJson = useDebouncedCallback( (json: any) => {
    facade.save(json).then((v) => {
      setGlobalJson(v);
    });
  }, 1);

  const onNewJson = () => {
    facade.clear();
    facade.new();
    const data = globalJSON;
    data.progVers = 'new';
    facade.saveWithoutMerge(data).then((v) => {
      setGlobalJson(v);
    });
  }

  const onLoadJson = (json: any) => {
    facade.saveWithoutMerge(json).then((v) => {
      setGlobalJson(v);
    });
  }

  return (
    <JsonContext.Provider
      value={{
        globalJson,
        setGlobalJson,
        onUpdateFieldToDraftJson,
        onUpdateFieldToDraftJsonDebounced,
        onSaveDraftJsonToGlobal,
        onSaveAndCalcDraftJsonToGlobal,
        // onSaveAndCalcWithoutEnvironmentDraftJsonToGlobal,
        onSaveFieldToGlobalDirectly,
        onSaveKeyToGlobalDirectly,
        onMergeJsonWithGlobalJson,
        onNewJson,
        onLoadJson,
        errors,
        setThisDraftJson
      }}
    >
      {children}
    </JsonContext.Provider>
  );
};
