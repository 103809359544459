export class RecycledScore {
    val: number;
    constructor({ percentage }: { percentage?: number }) {
      if (!percentage && percentage != 0) {
        this.val = NaN;
        return;
      }
    
      this.val =
              percentage > 20
            ? 1
            : percentage > 10
            ? 1+((1)/(10-20))*(percentage-20)
            : percentage > 5
            ? 2+((1)/(5-10))*(percentage-10)
            : percentage > 0
            ? 3+((1)/(0-5))*(percentage-5)
            : 5;
    }
  }

  export class RecyclableScore {
    val: number;
    constructor({ percentage }: { percentage?: number }) {
      if (!percentage && percentage != 0) {
        this.val = NaN;
        return;
      }
    
      this.val =
              percentage > 50
            ? 1
            : percentage > 30
            ? 1+((1)/(30-50))*(percentage-50)
            : percentage > 10
            ? 2+((1)/(10-30))*(percentage-30)
            : percentage > 5
            ? 3+((1)/(5-10))*(percentage-10)
            : 5;
    }
  }

  export class ResponsibleWoodScore {
    val: number;
    constructor({ percentage }: { percentage?: number }) {
      if (!percentage && percentage != 0) {
        this.val = 0;
        return;
      }
    
      this.val =
              percentage > 75
            ? 1
            : percentage > 50
            ? 1+((1)/(50-75))*(percentage-75)
            : percentage > 25
            ? 2+((1)/(25-50))*(percentage-50)
            : percentage > 0
            ? 3+((1)/(0-25))*(percentage-25)
            : 5;
    }
  }

  export class DeclaredOriginScore {
    val: number;
    constructor({ percentage }: { percentage?: number }) {
      if (!percentage && percentage != 0) {
        this.val = NaN;
        return;
      }
    
      this.val =
              percentage > 75
            ? 1
            : percentage > 50
            ? 1+((1)/(50-75))*(percentage-75)
            : percentage > 25
            ? 2+((1)/(25-50))*(percentage-50)
            : percentage > 0
            ? 3+((1)/(0-25))*(percentage-25)
            : 5;
    }
  }

  export class SustainableScore {
    val:number;
    constructor({ Recycled, Recyclable, ResponsWood, DeclaredOrigin }: { 
        Recycled?: number, 
        Recyclable?: number, 
        ResponsWood?: number, 
        DeclaredOrigin?: number, 
        }) {
        if (!Recycled || !Recyclable || !ResponsWood || !DeclaredOrigin) {
          this.val = 5;
          return;
        }
        if (Recycled > 4 || Recyclable > 4 || ResponsWood > 4|| DeclaredOrigin > 4) {
          this.val = 5;
          return;
        }
      
        this.val = 
        (
            Recycled + 
            Recyclable + 
            ResponsWood +
            DeclaredOrigin 
            ) / 4.0 ;
      }
  }
