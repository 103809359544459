import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from 'react-error-boundaries';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './app/app_routes';
import AppTheme from './app/theme/definitions';
import { Box } from '@chakra-ui/core';
import {
  AppLayout,
  appLayoutTheme,
  AppRightContentBox,
} from './ui/structure/app_layout';
import { FlexCol } from './ui';
import { Radar } from './features/radar/presentation/Radar';
import { RightTopTabs } from './features/right_top_tabs/presentation/Tabs';
import { Sidebar } from './features/sidebar/presentation/sidebar';
import { BrowserRouter, useLocation, withRouter } from 'react-router-dom';
import { JsonContextContainer } from './features/shared/json/application/use_application_json';
import { AnimatePresence } from 'framer-motion';

import { RadarLegend } from './features/radar/presentation/RadarPage';


export const AppRight = () => {

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   document.title = `Active House`;
  // });


  return (
      <FlexCol minHeight="100vh">
        <AppRightContentBox minHeight="30vh" mb={0}>
          <RightTopTabs />
        </AppRightContentBox>

        <Box mt={appLayoutTheme.spacing}>
          <AppRightContentBox minHeight="600px" mb={12 * appLayoutTheme.spacing}>
            <Radar
              RadarLegend={RadarLegend}
              Size={200}
            />
          </AppRightContentBox>
        </Box>
      </FlexCol>
  );
};

export const AppLeft = () => {

  // const [pass, setPass] = useState("");
  // let location = useLocation();   // or useParams  /blog/:slug

  // useEffect(() => {
  //   // const queryString = require('query-string');
  //   const values = queryString.parse(location.search)
  //   const __pass = (values.pass || "emp").toString();
  //   if (__pass != "emp")
  //   {
  //     setPass(__pass);
  //   }
    
  //   console.log(pass);
  // })

  return (
      <Sidebar />
  );
};

const ScrollToTopOnNavigate = withRouter(
  ({ children, location: { pathname } }: any) => {
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return children || null;
  }
);


ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <JsonContextContainer>
        <BrowserRouter>
          <AppTheme>
            <ScrollToTopOnNavigate>
              <AnimatePresence exitBeforeEnter initial={false}>
                <AppLayout
                  right={<AppRight />}
                  left={<AppLeft />}
                  center={<AppRoutes />}
                />
              </AnimatePresence>
            </ScrollToTopOnNavigate>
          </AppTheme>
        </BrowserRouter>
      </JsonContextContainer>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

