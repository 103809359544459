import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { ResultsIcon } from 'src/ui/elements/icons';

export class ResultsjsonDefinition implements JsonDefinition {
  keys = [
    {
      key: 'ahh_res',
      extraOptions: {
        IconComponent: ResultsIcon,
        // isAbsoluteTitle: true,
      }
    },
    

  ];
}



export class ComfortResultsjsonDefinition implements JsonDefinition {
  keys = [
    {
      key: 'ahh_cores',
    },
    

  ];
}
