import { Collapse, useDisclosure } from '@chakra-ui/core';
import React from 'react';
import { appColors } from 'src/app/theme';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, Divider, FlexRow, FlexCol, P, ButtonBase, globalButtonProps } from 'src/ui';
import { LayerIcon, TrashIcon } from 'src/ui/elements/icons';
import {
  ConstructionsJsonDefinition,
  ConstructionsTitleJsonDefinition,
  LayerExpandJsonDefinition,
  LayerJsonDefinition,
} from '../../data/constructions_json_definitions';
import { useSustainableConstructionCalc } from '../applications/use_number_of_areas';
import { useNumberOfConstructions } from '../applications/use_number_of_constructions';
import { useNumberOfConstructionLayers } from '../applications/use_number_of_construction_layers';

const titleDefinition = new ConstructionsTitleJsonDefinition();

const LayerExpand: React.FC<any> = ({
  indexConstruction,
  indexLayer,
  onRemoveLayer,
}) => {
  const Json = new LayerExpandJsonDefinition(indexConstruction, indexLayer);
  return (
    <>
      <FlexRow alignItems="center">
        <P mr={5}> </P>
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};


const Layer: React.FC<any> = ({
  indexConstruction,
  indexLayer,
  onRemoveLayer,
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const Json = new LayerJsonDefinition(indexConstruction, indexLayer);
  return (
    <>
      <FlexRow alignItems="center">
        <P mr={2}> <LayerIcon /> </P>
        <FormBuilder jsonKeys={Json.keys} />
        <ButtonBase
          mt={1}
          ml={2}
          {...globalButtonProps(appColors.primary.main, isOpen)}
          onClick={onToggle}>
          <P>{i18n.t(`pages.more`)}</P>
        </ButtonBase>
        <FlexRow
          mb={3}
          ml={1}
          py={2}
          alignSelf="flex-end"
          as="button"
          onClick={() => onRemoveLayer(indexConstruction, indexLayer)}
        >
          <TrashIcon />
        </FlexRow>
      </FlexRow>
      <Collapse in={isOpen} animateOpacity>
        <Box>
          <LayerExpand
            indexConstruction={indexConstruction}
            indexLayer={indexLayer}
          />

        </Box>
      </Collapse>

    </>
  );
};

const Construction: React.FC<any> = ({
  indexConstruction,
  onRemoveConstruction,
}) => {
  const Json = new ConstructionsJsonDefinition(indexConstruction);
  const { layers, addLayer, removeLayer } = useNumberOfConstructionLayers(
    indexConstruction
  );

  const mappingLayer = [...(Array(layers).keys() as any)];

  return (
    <>
      <FlexRow alignItems="center">

        <FormBuilder jsonKeys={Json.keys} />

        <FlexCol
          mb={3}
          ml={1}
          py={2}
          alignSelf="flex-end"
          as="button"
          onClick={() => onRemoveConstruction(indexConstruction)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexCol>
      </FlexRow>

      {mappingLayer.map((a, i) => (
        <AnimateList key={i}>
          <Layer
            key={layers}
            indexConstruction={indexConstruction}
            indexLayer={i}
            onRemoveLayer={removeLayer}
          />
        </AnimateList>
      ))}

      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={addLayer}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.layer`)}
          </P>
        </Box>
      </AnimatePageEnter>

      <Divider my={4} />
    </>
  );
};

export const ConstructionsPage: React.FC = () => {

  const { useEnvironmentLoads, useSustainableConstruction } = useSustainableConstructionCalc();

  if (useEnvironmentLoads || useSustainableConstruction) {
    return (
      <>
        <FormBuilder jsonKeys={titleDefinition.keys} />

        <ConstructionsInputPage></ConstructionsInputPage>
      </>
    );
  } else {
    return (
      <>
        <FormBuilder jsonKeys={titleDefinition.keys} />

        <P>{i18n.t(`pages.noneed`)}</P>
      </>
    );
  }
};

export const ConstructionsInputPage: React.FC = () => {
  const {
    constructions,
    addConstruction,
    removeConstruction,
  } = useNumberOfConstructions();
  const mappingConstructions = [...(Array(constructions).keys() as any)];

  return (
    <>
      {/* <FormBuilder jsonKeys={titleDefinition.keys} /> */}
      {mappingConstructions.map((a, i) => (
        <AnimateList key={i}>
          <Construction
            key={constructions}
            indexConstruction={i}
            onRemoveConstruction={removeConstruction}
          />
        </AnimateList>
      ))}

      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={addConstruction}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.construction`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </>
  );
};
