import _ from 'lodash';
import { useNumberOfSystems } from 'src/features/project_information/presentation/applications/use_number_of_systems';
import { getRandomInt } from 'src/features/shared/utils';
import { Calculation } from "../calculation";
import { calculationErrors } from "../calculation_errors";
import { EnergyCopPrimaryCalc, EnergyDemandScore, EnergyPrimaryCalc, EnergySupplyScore, PrimaryEnergyScore } from './energy_calculations/energydemand_score';
import { roundDecimals } from './helper_calculations';

export class EnergyCalculations implements Calculation {
  i: number;
  draftJson: any;

  public constructor({ i, draftJson, energyCalculations }: any) {
    this.i = i;
    this.draftJson = { ...draftJson };
  }

  public calculate() {
    const { draftJson, i } = this;
    if (_.isNil(i)) throw calculationErrors.UndefinedIndex;

    this.updatesEnergyScore();
    this.updateEnergyDemandScore();

    return draftJson;

  }

  private updatesEnergyScore() {
    const { draftJson, i } = this;

    // const indooraircategory = _.get(
    //   draftJson,
    //   `lVariant[${i}].Active.ahr_telvl`
    // );


    // const valuee = 34;
    //  _.set(draftJson, `lVariant[${i}].Active.ahr_fwtap`, valuee);
  }


  private updateEnergyDemandScore() {
    const { draftJson, i } = this;

    const systemdefinitinoCasePath = `lVariant[${i}].HVAC.lSystem`;
    const draftJsonForSystems = _.get(draftJson, systemdefinitinoCasePath);

    let headDemandTotal = 0;
    let dhwDemandTotal = 0;
    let coolingDemandTotal = 0;
    let auxiliaryDemandTotal = 0;
    let electricityDemandTotal = 0;

    let heatSupplyTotal = 0;
    let electricitySupplyTotal = 0;

    let heatPrimaryDemandTotal = 0;
    let dhwPrimaryDemandTotal = 0;
    let coolingPrimaryDemandTotal = 0;
    let auxiliaryPrimaryDemandTotal = 0;
    let electricityPrimaryDemandTotal = 0;

    let heatSupplyPrimaryTotal = 0;
    let electricitySupplyPrimaryTotal = 0;

    let lightingTotal = 0;
    let mechanicalVentilationTotal = 0;

    let i_dev = 0;


    // console.log("foundheat pump " + foundcoupledHeatpump);
    i_dev = 0;
    // ###### for all systems
    if (draftJsonForSystems) {
      for (const dJFS of draftJsonForSystems) {
        const deviceType = _.get(dJFS, `lDevice.[0].Active.ahtypeDev`);
        const deviceUsed = _.get(dJFS, `lDevice.[0].Active.ahsysused`);
        let thisDeviceEnergyTotal = 0;
        let thisDevicePrimaryEnergyTotal = 0;

        if (deviceType == 1 && deviceUsed) {   // thermal
          const deviceTypeHeatDemand = _.get(dJFS, `lDevice.[0].Active.ahheatDev`);

          if (deviceTypeHeatDemand == 5) {  // heat pump
            const heatDemand = new EnergyCopPrimaryCalc({
              demand: _.get(dJFS, `lDevice.[0].Active.ahheatdem`),
              cop: _.get(dJFS, `lDevice.[0].Active.ahCOP`),
              pefactor: _.get(dJFS, `lDevice.[0].Active.ahpeheat`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpeheat`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
            });
            headDemandTotal += heatDemand.energy;
            heatPrimaryDemandTotal += heatDemand.primaryEnergy;
            thisDeviceEnergyTotal += heatDemand.energy;
            thisDevicePrimaryEnergyTotal += heatDemand.primaryEnergy;

            const dhwDemand = new EnergyCopPrimaryCalc({
              demand: _.get(dJFS, `lDevice.[0].Active.ahdhwdem`),
              cop: _.get(dJFS, `lDevice.[0].Active.ahdwhCOP`),
              pefactor: _.get(dJFS, `lDevice.[0].Active.ahpedhw`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpedhw`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
            });
            dhwDemandTotal += dhwDemand.energy;
            dhwPrimaryDemandTotal += dhwDemand.primaryEnergy;
            thisDeviceEnergyTotal += dhwDemand.energy;
            thisDevicePrimaryEnergyTotal += dhwDemand.primaryEnergy;

            const coolingDemand = new EnergyCopPrimaryCalc({
              demand: _.get(dJFS, `lDevice.[0].Active.ahhcooldem`),
              cop: _.get(dJFS, `lDevice.[0].Active.ahcoolCOP`),
              pefactor: _.get(dJFS, `lDevice.[0].Active.ahpecool`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpecool`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
            });
            coolingDemandTotal += coolingDemand.energy;
            coolingPrimaryDemandTotal += coolingDemand.primaryEnergy;
            thisDeviceEnergyTotal += coolingDemand.energy;
            thisDevicePrimaryEnergyTotal += coolingDemand.primaryEnergy;


            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_heateldem`, heatDemand.electricalEnergy);
            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_dhweldem`, dhwDemand.electricalEnergy);
            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_cooleldem`, coolingDemand.electricalEnergy);

            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_heatpe`, heatDemand.primaryEnergy);
            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_dhwpe`, dhwDemand.primaryEnergy);
            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_coolpe`, coolingDemand.primaryEnergy);

            let i_c_dev = 0;
            const coupledHeatPump = _.get(dJFS, `lDevice.[0].Active.ahcoupdevid`);
            for (const dJFSi of draftJsonForSystems) {
              const thisid = _.get(dJFSi, `lDevice.[0].id`);
              if (coupledHeatPump == thisid) {

                const peheatfactor = _.get(dJFS, `lDevice.[0].Active.ahpeheat`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpeheat`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`);
                const pedhwfactor = _.get(dJFS, `lDevice.[0].Active.ahpedhw`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpedhw`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`);
                const pecoolingfactor = _.get(dJFS, `lDevice.[0].Active.ahpecool`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpecool`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`);
              
                const heatenergydemand = (_.get(dJFS, `lDevice.[0].Active.ahheatdem`) - heatDemand.electricalEnergy);
                const dwhenergydemand = (_.get(dJFS, `lDevice.[0].Active.ahdhwdem`) - dhwDemand.electricalEnergy);
                const coolingenergydemand = (_.get(dJFS, `lDevice.[0].Active.ahhcooldem`) - coolingDemand.electricalEnergy);
                const denergydemand = heatenergydemand + dwhenergydemand + coolingenergydemand;

                const pefactor = ((+peheatfactor*heatenergydemand) + (+pedhwfactor*dwhenergydemand) + (+pecoolingfactor*coolingenergydemand) ) / (heatenergydemand + dwhenergydemand + coolingenergydemand);
                _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_c_dev}].lDevice[0].Active.ahheatprodpef`, roundDecimals(pefactor.toString(),3));
                _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_c_dev}].lDevice[0].Active.ahheatprod`, denergydemand);

              }
              i_c_dev++;
            }


          }
          else {
            const heatDemand = new EnergyPrimaryCalc({
              demand: _.get(dJFS, `lDevice.[0].Active.ahheatdem`),
              pefactor: _.get(dJFS, `lDevice.[0].Active.ahpeheat`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpeheat`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
            });
            headDemandTotal += heatDemand.energy;
            heatPrimaryDemandTotal += heatDemand.primaryEnergy;
            thisDeviceEnergyTotal += heatDemand.energy;
            thisDevicePrimaryEnergyTotal += heatDemand.primaryEnergy;

            const dhwDemand = new EnergyPrimaryCalc({
              demand: _.get(dJFS, `lDevice.[0].Active.ahdhwdem`),
              pefactor: _.get(dJFS, `lDevice.[0].Active.ahpedhw`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpedhw`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
            });
            dhwDemandTotal += dhwDemand.energy;
            dhwPrimaryDemandTotal += dhwDemand.primaryEnergy;
            thisDeviceEnergyTotal += dhwDemand.energy;
            thisDevicePrimaryEnergyTotal += dhwDemand.primaryEnergy;

            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_heatpe`, heatDemand.primaryEnergy);
            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_dhwpe`, dhwDemand.primaryEnergy);
            _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_coolpe`, 0);
          }

          const auxiliaryDemand = new EnergyPrimaryCalc({
            demand: _.get(dJFS, `lDevice.[0].Active.ahhauxen`),
            pefactor: _.get(dJFS, `lDevice.[0].Active.ahpeaux`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahpeaux`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
          });

          auxiliaryDemandTotal += auxiliaryDemand.energy;
          electricityDemandTotal += auxiliaryDemand.energy;
          auxiliaryPrimaryDemandTotal += auxiliaryDemand.primaryEnergy;
          electricityPrimaryDemandTotal += auxiliaryDemand.primaryEnergy;
          thisDeviceEnergyTotal += auxiliaryDemand.energy;
          thisDevicePrimaryEnergyTotal += auxiliaryDemand.primaryEnergy;

          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_auxpe`, auxiliaryDemand.primaryEnergy);

          const energyDemandScore = new EnergyDemandScore({
            energyDemand: thisDeviceEnergyTotal - auxiliaryDemand.energy,
          });
          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_edsum`, thisDeviceEnergyTotal - auxiliaryDemand.energy);
          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_edsc`, energyDemandScore.val);
          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_pesum`, thisDevicePrimaryEnergyTotal - auxiliaryDemand.primaryEnergy);

        } // end heat energy

        if (deviceType == 2 && deviceUsed) {
          const deviceTypeElectricityDemand = _.get(dJFS, `lDevice.[0].Active.ahelDev`);

          const electricityDemand = new EnergyPrimaryCalc({
            demand: _.get(dJFS, `lDevice.[0].Active.aheldem`),
            pefactor: _.get(dJFS, `lDevice.[0].Active.aheldempef`) != "" ? _.get(dJFS, `lDevice.[0].Active.aheldempef`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
          });

          if (deviceTypeElectricityDemand == 3) {

            lightingTotal += electricityDemand.energy;
          }
          else if (deviceTypeElectricityDemand == 1) {
            mechanicalVentilationTotal += electricityDemand.energy;
          }
          else {
            electricityDemandTotal += electricityDemand.energy;
          }

          electricityPrimaryDemandTotal += electricityDemand.primaryEnergy;
          thisDeviceEnergyTotal += electricityDemand.energy;
          thisDevicePrimaryEnergyTotal += electricityDemand.primaryEnergy;

        }  // end electricity

        if (deviceType == 3 && deviceUsed) {
          const deviceTypeHeatProduction = _.get(dJFS, `lDevice.[0].Active.ahdeviceThrenw`);

          const heatSupply = new EnergyPrimaryCalc({
            demand: _.get(dJFS, `lDevice.[0].Active.ahheatprod`),
            pefactor: _.get(dJFS, `lDevice.[0].Active.ahheatprodpef`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahheatprodpef`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
          });

          heatSupplyTotal += heatSupply.energy;
          heatSupplyPrimaryTotal += heatSupply.primaryEnergy;
          thisDeviceEnergyTotal += heatSupply.energy;
          thisDevicePrimaryEnergyTotal += heatSupply.primaryEnergy;

        } // end heat supply

        if (deviceType == 4 && deviceUsed) {
          const deviceTypeElectricityProduction = _.get(dJFS, `lDevice.[0].Active.ahdeviceTerenw`);

          const electricitySupply = new EnergyPrimaryCalc({
            demand: _.get(dJFS, `lDevice.[0].Active.ahelprod`),
            pefactor: _.get(dJFS, `lDevice.[0].Active.ahelprodpef`) != "" ? _.get(dJFS, `lDevice.[0].Active.ahelprodpef`) : _.get(draftJson, `lVariant[${i}].Active.ahdefpe`),
          });

          electricitySupplyTotal += electricitySupply.energy;
          electricitySupplyPrimaryTotal += electricitySupply.primaryEnergy;
          thisDeviceEnergyTotal += electricitySupply.energy;
          thisDevicePrimaryEnergyTotal += electricitySupply.primaryEnergy;

        } // end electricity supply

        if (deviceType != 1) {
          const energyDemandScore = new EnergyDemandScore({
            energyDemand: thisDeviceEnergyTotal,
          });
          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_edsum`, thisDeviceEnergyTotal);
          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_edsc`, energyDemandScore.val);
          _.set(draftJson, `lVariant[${i}].HVAC.lSystem.[${i_dev}].lDevice[0].Active.ahr_pesum`, thisDevicePrimaryEnergyTotal);
        }


        i_dev++;
      }  // end system loop
    }

    _.set(draftJson, `lVariant[${i}].Active.ahr_edsh`, headDemandTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_eddhw`, dhwDemandTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_edmv`, mechanicalVentilationTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_edcool`, coolingDemandTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_edsaux`, auxiliaryDemandTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_edsys`, electricityDemandTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_edl`, lightingTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_eddev`, electricityDemandTotal + mechanicalVentilationTotal + lightingTotal - auxiliaryDemandTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_edpetot`, electricityPrimaryDemandTotal);
    _.set(draftJson, `lVariant[${i}].Active.ahr_edpedev`, electricityPrimaryDemandTotal - auxiliaryPrimaryDemandTotal);

    _.set(draftJson, `lVariant[${i}].Active.ahr_edpesaux`, auxiliaryPrimaryDemandTotal);
    _.set(draftJson, `lVariant[${i}].Active.ahr_edpefsaux`, auxiliaryPrimaryDemandTotal / auxiliaryDemandTotal);


    const energyDemandScore = new EnergyDemandScore({
      energyDemand: headDemandTotal + dhwDemandTotal + coolingDemandTotal + electricityDemandTotal + lightingTotal + mechanicalVentilationTotal,
    });
    const energyDemandTotal = headDemandTotal + dhwDemandTotal + coolingDemandTotal + electricityDemandTotal + lightingTotal + mechanicalVentilationTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_edsum`, energyDemandTotal);
    _.set(draftJson, `lVariant[${i}].Active.ahr_edsc`, energyDemandScore.val);


    const energySupplyScore = new EnergySupplyScore({
      energyDemand: headDemandTotal + dhwDemandTotal + coolingDemandTotal + electricityDemandTotal + lightingTotal + mechanicalVentilationTotal,
      energySupply: heatSupplyTotal + electricitySupplyTotal,
    });

    const energyHeatSupplyTotal = heatSupplyTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_eshd`, energyHeatSupplyTotal);
    const energyElectricitySupplyTotal = electricitySupplyTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_esel`, energyElectricitySupplyTotal);
    const percentageOfRenewables = energySupplyScore.percentageCoverDemand;
    _.set(draftJson, `lVariant[${i}].Active.ahr_esperc`, percentageOfRenewables);

    const energySupplyTotal = heatSupplyTotal + electricitySupplyTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_essum`, energySupplyTotal);
    _.set(draftJson, `lVariant[${i}].Active.ahr_essc`, energySupplyScore.val);


    // Primary Energy

    _.set(draftJson, `lVariant[${i}].Active.ahr_espehd`, heatSupplyPrimaryTotal);
    _.set(draftJson, `lVariant[${i}].Active.ahr_espeel`, electricitySupplyPrimaryTotal);


    const primaryEnergyScore = new PrimaryEnergyScore({
      primaryenergyDemand: heatPrimaryDemandTotal + dhwPrimaryDemandTotal + coolingPrimaryDemandTotal + electricityPrimaryDemandTotal,
      primaryenergySupply: heatSupplyPrimaryTotal + electricitySupplyPrimaryTotal,
    });

    const pedemtTotal = heatPrimaryDemandTotal + dhwPrimaryDemandTotal + coolingPrimaryDemandTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_pedemtsum`, pedemtTotal);

    const pedemTotal = heatPrimaryDemandTotal + dhwPrimaryDemandTotal + coolingPrimaryDemandTotal + electricityPrimaryDemandTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_pedemsum`, pedemTotal);

    const pesupTotal = heatSupplyPrimaryTotal + electricitySupplyPrimaryTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_pesupsum`, pesupTotal);

    const peTotal = primaryEnergyScore.primaryEnergyTotal;
    _.set(draftJson, `lVariant[${i}].Active.ahr_pesum`, peTotal);
    _.set(draftJson, `lVariant[${i}].Active.ahr_pesc`, primaryEnergyScore.val);

  }

}