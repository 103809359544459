
export const roundDecimals = (value: string | number, decimals: number) => {
  if (decimals === 1) {
    return String(Math.round((+value + Number.EPSILON) * 10) / 10);
  }
  else if (decimals === 2) {
    // return String(value.toFixed(2));
    return String(Math.round((+value + Number.EPSILON) * 100) / 100);
  }
  else if (decimals === 3) {
    // return String(value.toFixed(2));
    return String(Math.round((+value + Number.EPSILON) * 1000) / 1000);
  }
  else if (decimals === 4) {
    // return String(value.toFixed(2));
    return String(Math.round((+value + Number.EPSILON) * 10000) / 10000);
  }
  else if (decimals === 5) {
    // return String(value.toFixed(2));
    return String(Math.round((+value + Number.EPSILON) * 100000) / 100000);
  }
  else if (decimals === 40) {
    return String((+value).toExponential(0));
  }
  else if (decimals === 41) {
    return String((+value).toExponential(1));
  }
  else if (decimals === 42) {
    return String((+value).toExponential(2));
  }
  else if (decimals === 43) {
    return String((+value).toExponential(3));
  }
  else if (decimals === 44) {
    return String((+value).toExponential(4));
  }
  else if (decimals === 99) {
    return String(Math.round(+value));
  }
  return value;
};


export const calculationValueToSelect = (value: number) => {
  return Math.ceil(value);
};