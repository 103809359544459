import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { BaselineRoutes } from 'src/features/baseline/presentation/routes/routes_config';
import { ProjectInformationPage } from 'src/features/project_information/presentation/pages/project_information';
import { ProjectInformationTabsPage } from 'src/features/project_information/presentation/pages/project_information_tabs';
import { RadarRoutes } from 'src/features/radar/presentation/routes/routes_config';
import { SettingsRoutes } from 'src/features/settings/routes/routes_config';
import { P } from 'src/ui';

import { ProjectInformationRoutes } from '../features/project_information/presentation/routes/routes_config';
import { i18n } from 'src/features/shared/translations/translate';
import { appColors } from './theme';
import _ from 'lodash';
import Cookies from 'universal-cookie/es6/Cookies';
import { Link } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';

const mapRoutes = (r: any) => r.map((a: any) => a);

const NotFound = () => <P>Page not found</P>;

const setTemporaryCookie = () => {
  const cookies = new Cookies();

  cookies.set('activehouse_radar_preaccess', "temporary_activehouce_cookie", { 
    path: '/', 
    secure: true
    });
}

const setConstantCookie = (__pass: string) => {
  const cookies = new Cookies();

  cookies.set('activehouse_radar_preaccess', __pass, { 
    path: '/', 
    secure: true, 
    expires: new Date(Date.now() + 365 * (24 * 60 * 60 * 1000))
  });
  
  // console.log("set pass " + cookies.get('activehouse_radar_preaccess'));
    
}

export const AppRoutes = () => {

  //const [pass, setPass] = useState("");
  let location = useLocation();   // or useParams  /blog/:slug
  let history = useHistory();

  const cookies = new Cookies();

  useEffect(() => {
    // const queryString = require('query-string');
    
    const values = queryString.parse(location.search);
    const __pass = (values.pass || "emp").toString();
    if (__pass != "emp")
    {
      setConstantCookie(__pass);
      //setPass(__pass);
      history.push({
        pathname: '/',
        search: '',  // query string  ?query=abc
      });
    }
    if (values.a38a7d1ebed64e5e99c6858ef0eba3b3 === null)
    {
      setConstantCookie("a38a7d1e-bed6-4e5e-99c6-858ef0eba3b3");
      //setPass(__pass);
      history.push({
        pathname: '/',
        search: '',  // query string  ?query=abc
      });
    }

  })

  //console.log("get cookie " + cookies.get('activehouse_radar_preaccess'));

  if (cookies.get('activehouse_radar_preaccess') == "a38a7d1e-bed6-4e5e-99c6-858ef0eba3b3"
   || cookies.get('activehouse_radar_preaccess') == "activehouse_is_the_best"
    || cookies.get('activehouse_radar_preaccess') == "temporary_activehouce_cookie")
  {
    // console.log("access allowed ");
    return (
      <Switch>
        {mapRoutes(ProjectInformationRoutes)}
        {mapRoutes(BaselineRoutes)}
        {mapRoutes(RadarRoutes)}
        {mapRoutes(SettingsRoutes)}
        <Route path="/" exact component={ProjectInformationTabsPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
  return (
    <> 
      <P 
        marginTop="100"
        fontWeight="bold" 
        fontSize="2.5em"
        color={appColors.primary.dark}>
        {i18n.t(`access.notallowed_partone`)} 
        <a href="https://www.activehouse.info/submit-your-project/create-a-radar/"> {i18n.t(`access.notallowed_link`)}</a>

      </P>
      <P> ---------------------------------------------------------------- </P>
      <P>
        or meanwhile click <Link href="/project-information/main" color={appColors.primary.dark}  onClick={setTemporaryCookie} > here </Link> to activate
      </P>
    </>
  );
};
