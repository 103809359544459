import { JsonDefinition } from 'src/features/shared/form/data/json_definition';



export class CaseStatusJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      // { key: `lVariant[${variantcaseIndex}].n` },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_sc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_dlsc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_tesc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_iasc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_aqsc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_edsc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_pesc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_essc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_scsc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2, },
      },
      {
        key: `lVariant[${variantcaseIndex}].Active.ahr_fwsc`,
        extraOptions: { styleType: 2, roundDecimal: 1, inline: true, score: 2,  },
      },
    ];
  }
}