import _ from "lodash";
import { definitionsJson } from "src/features/shared/json/data/source/active.def";

const getLastKeyForDeepObject = (key: string) => key.split(".").reverse()[0];

export const getDefinitionForValue = (
  value: { key: string },
  jsonSource = definitionsJson
) => {
  const key = getLastKeyForDeepObject(value.key);
  const definition = _.get(jsonSource, key);

  if (!definition) throw Error("Not definition found for " + key);
  return definition;
};
