import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { IndoorAirQualityIcon } from 'src/ui/elements/icons';

export class IndoorAirQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_ia`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: IndoorAirQualityIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_iasc`,
        },
      },

      { key: `aht_qia1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqia1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqia1c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qia2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqia2` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqia2c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qia3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqia3` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqia3c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qia4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqia4` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqia4c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qia5` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqia5` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqia5c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
