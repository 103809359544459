export const globalJSON = {
  "progVers": "emptyActive",
  "ahsettings": {
    "ah_lang": 1,
    "ah_unit": 1,
    "ahhlp": false,
    "ahnat": true,
  },
  "projD": {
    "ahpic": null
  },
  "lLcaMat": [
  ],
  "lMaterial": [
  ],
  "lAssembly": [
  ],
  "lWindow": [
  ],
  "lVariant": [
    {
      "Active": {
        "ahshowradar": true,
        "ahudefenvload": 1,
        "ahudefsusconst": 1,
        "ahthufile": false,
        "ahco2ufile": false,
        "aHfiledata": {
          "aHfileOk": false
        },
        "ahr_dlsc": null,
        "ahr_telvl": null,
        "ahr_tesc": null,
        "ahco2lvl": null,
        "ahr_co2lvl": null,
        "ahr_iasc": null,
        "ahr_aqlvl": null,
        "ahr_aqsc": null,
        "ahr_edsh": null,
        "ahr_eddhw": null,
        "ahr_edmv": null,
        "ahr_edcool": null,
        "ahr_edsys": null,
        "ahr_edl": null,
        "ahr_edsum": null,
        "ahr_edsc": null,
        "ahr_esel": null,
        "ahr_eshd": null,
        "ahr_esperc": null,
        "ahr_essum": null,
        "ahr_essc": null,
        "ahr_pedemsum": null,
        "ahr_pesupsum": null,
        "ahr_pesum": null,
        "ahr_pesc": null,
        "ahr_elsc": null,
        "ahr_fwsc": null,
        "ahr_fwred": null,
        "ahr_scrc": null,
        "ahr_sccw": null,
        "ahr_scve": null,
        "ahr_scsc": null,
        "ahfwtoi": null,
        "ahr_fwtoi": null,
        "ahfwshw": null,
        "ahr_fwshw": null,
        "ahfwtap": null,
        "ahr_fwtap": null,
        "ahdlvalidation": null,
        "ahdlopt": 1,
        "ahtevalidation": null,
        "ahiavalidation": null,
        "ahr_sc": null,
        "ahaqoutdbuse": 1,
        "qa": {
          "ahqm11": null,
          "ahqm12": null,
          "ahqm13": null,
          "ahqm1c": null,
          "ahqm21": null,
          "ahqm22": null,
          "ahqm23": null,
          "ahqm24": null,
          "ahqm25": null,
          "ahqm2c": null,
          "ahqm31": null,
          "ahqm32": null,
          "ahqm33": null,
          "ahqm34": null,
          "ahqm35": null,
          "ahqm3c": null,
          "ahqcf1": null,
          "ahqcf1c": null,
          "ahqdl1": null,
          "ahqdl1c": null,
          "ahqdl2": null,
          "ahqdl2c": null,
          "ahqdl3": null,
          "ahqdl3c": null,
          "ahqdl4": null,
          "ahqdl4c": null,
          "ahqdl5": null,
          "ahqdl5c": null,
          "ahqdl6": null,
          "ahqdl6c": null,
          "ahqdl7": null,
          "ahqdl7c": null,
          "ahqdl8": null,
          "ahqdl8c": null,
          "ahqte1": null,
          "ahqte1c": null,
          "ahqte2": null,
          "ahqte2c": null,
          "ahqte3": null,
          "ahqte3c": null,
          "ahqte4": null,
          "ahqte4c": null,
          "ahqte5": null,
          "ahqte5c": null,
          "ahqte6": null,
          "ahqte6c": null,
          "ahqia1": null,
          "ahqia1c": null,
          "ahqia2": null,
          "ahqia2c": null,
          "ahqia3": null,
          "ahqia3c": null,
          "ahqia4": null,
          "ahqia4c": null,
          "ahqia5": null,
          "ahqia5c": null,
          "ahqaq1": null,
          "ahqaq1c": null,
          "ahqaq2": null,
          "ahqaq2c": null,
          "ahqaq3": null,
          "ahqaq3c": null,
          "ahqeg1": null,
          "ahqeg1c": null,
          "ahqeg2": null,
          "ahqeg2c": null,
          "ahqeg3": null,
          "ahqeg3c": null,
          "ahqeg4": null,
          "ahqeg4c": null,
          "ahqeg5": null,
          "ahqeg5c": null,
          "ahqed1": null,
          "ahqed1c": null,
          "ahqed2": null,
          "ahqed2c": null,
          "ahqed3": null,
          "ahqed3c": null,
          "ahqed4": null,
          "ahqed4c": null,
          "ahqes1": null,
          "ahqes1c": null,
          "ahqes2": null,
          "ahqes2c": null,
          "ahqpe1": null,
          "ahqpe1c": null,
          "ahqev1": null,
          "ahqev1c": null,
          "ahqsc1": null,
          "ahqsc1c": null,
          "ahqsc2": null,
          "ahqsc2c": null,
          "ahqsc3": null,
          "ahqsc3c": null,
          "ahqsc4": null,
          "ahqsc4c": null,
          "ahqsc5": null,
          "ahqsc5c": null,
          "ahqsc6": null,
          "ahqsc6c": null,
          "ahqfw1": null,
          "ahqfw1c": null,
          "ahqfw2": null,
          "ahqfw2c": null,
          "ahqfw3": null,
          "ahqfw3c": null,
        }
      },
      "building": {
        "lZone": [
          {
            "n": "DefaultZone",
            "flAreaZ": {
              "sel": 6,
              "val": [
                null,
                null,
                null
              ],
              "iV": null
            },
            "lRoom": []
          }
        ],
        "lComponent": [
        ]
      },
      "HVAC": {
        "lSystem": [
        ],
      }
    }
  ]
}
