import React from "react";
import { Route } from "react-router";
import { ProjectInformationPage } from "../pages/project_information";
import { RoomsPage } from "../pages/rooms";
import { AreasPage } from "../pages/areas";
import { SystemsPage } from "../pages/systems";
import { projectInformationRoutePaths } from "./routes_path";
import { ConstructionsPage } from "../pages/constructions";
import { SystemsTabsPage } from "../pages/systems_tabs";
import { ConstructionsTabsPage } from "../pages/constructions_tabs";
import { ProjectInformationTabsPage } from "../pages/project_information_tabs";

export const ProjectInformationRoutes = [
  <Route
    exact
    path={projectInformationRoutePaths.main}
    render={(props) => (
      <ProjectInformationTabsPage {...props} tabsi={0} />
    )}
  />,
  <Route
  exact
  path={projectInformationRoutePaths.contacts}
  render={(props) => (
    <ProjectInformationTabsPage {...props} tabsi={1} />
  )}
/>,
  <Route
    exact
    path={projectInformationRoutePaths.rooms}
    component={RoomsPage}
  />,
  <Route
    exact
    path={projectInformationRoutePaths.areas}
    component={AreasPage}
  />,
  // <Route
  //   exact
  //   path={projectInformationRoutePaths.constructions}
  //   component={ConstructionsPage}
  // />,
  <Route
  exact
  path={projectInformationRoutePaths.constructions}
  render={(props) => (
    <ConstructionsTabsPage {...props} tabsi={0} />
  )}
/>,
<Route
  exact
  path={projectInformationRoutePaths.windowsdoors}
  render={(props) => (
    <ConstructionsTabsPage {...props} tabsi={1} />
  )}
/>,
  // <Route
  //   exact
  //   path={projectInformationRoutePaths.systems}
  //   component={() => <SystemsTabsPage tabsi={1} />}
  // />,
  //   <Route
  //   exact
  //   path={projectInformationRoutePaths.systemselectricitydemand}
  //   component={SystemsTabsPage}
  // />,
  //   <Route
  //   exact
  //   path={projectInformationRoutePaths.systemsheatsupply}
  //   component={SystemsTabsPage}
  // />,
  //   <Route
  //   exact
  //   path={projectInformationRoutePaths.systemselectricitysupply}
  //   component={SystemsTabsPage}
  // />,
  <Route
    exact
    path={projectInformationRoutePaths.systems}
    render={(props) => (
      <SystemsTabsPage {...props} tabsi={0} />
    )}
  />,
  <Route
    exact
    path={projectInformationRoutePaths.systemselectricitydemand}
    render={(props) => (
      <SystemsTabsPage {...props} tabsi={1} />
    )}
  />,
  <Route
    exact
    path={projectInformationRoutePaths.systemsheatsupply}
    render={(props) => (
      <SystemsTabsPage {...props} tabsi={2} />
    )}
  />,
  <Route
    exact
    path={projectInformationRoutePaths.systemselectricitysupply}
    render={(props) => (
      <SystemsTabsPage {...props} tabsi={3} />
    )}
  />,


];
