import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { FreshwaterQualityJsonDefinition } from '../../../data/qualitative/freshwater_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const FreshwaterQualityPage: React.FC = () => {
  const freshwaterjson = useWithVariantCaseIndex(
    FreshwaterQualityJsonDefinition
  );

  return <FormBuilder jsonKeys={freshwaterjson.keys} />;
};

export default FreshwaterQualityPage;
