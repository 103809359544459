export class MeanScore {
  val: number;
  constructor({ daylightScore,
                thermalScore,
                indoorairScore,
                aqusticScore,
                demandScore,
                primaryScore,
                supplyScore,
                sustainableScore,
                freshwaterScore }: { 
                  daylightScore?: number, 
                  thermalScore?: number, 
                  indoorairScore?: number, 
                  aqusticScore?: number, 
                  demandScore?: number, 
                  primaryScore?: number, 
                  supplyScore?: number, 
                  sustainableScore?: number, 
                  freshwaterScore?: number, 
                }) {
    if (!daylightScore 
      || !thermalScore 
      || !indoorairScore 
      || !aqusticScore 
      || !demandScore 
      || !primaryScore 
      || !supplyScore 
      || !sustainableScore 
      || !freshwaterScore) {
      this.val = NaN;
      return;
    }

    this.val = (daylightScore + 
    thermalScore + 
    indoorairScore + 
    aqusticScore + 
    demandScore + 
    primaryScore + 
    supplyScore + 
    sustainableScore + 
    freshwaterScore)  / 9;

  }
}
