import _ from 'lodash';
import { useContext } from 'react';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { i18n } from 'src/features/shared/translations/translate';
import { useVariantNameFromIndex } from 'src/features/sidebar/application/use_variantcase';

export const useCaseStateError = (variantcase: number) => {
  const { globalJson } = useContext(JsonContext);

  const caseerror = _.get(globalJson, `lVariant.[${variantcase}].Active.ahstate.ahr_casemsg`);

  return caseerror;
};


export const useFirstCaseStateError = () => {
  const { globalJson } = useContext(JsonContext);

  const variantPath = `lVariant`;
  const allVariants = _.get(globalJson, variantPath);
  let VariantIndex = 0;
  let returnStrings = new Array();

  if (allVariants) {
    for (const dVar of allVariants) {
      const caseerror = _.get(dVar, `Active.ahstate.ahr_caseerror`);
      if (caseerror > 0) {
        const variantNameFromJson = _.get(dVar, `n`);
        const variantNameResult = variantNameFromJson ? variantNameFromJson : VariantIndex == 0 ? "baseline" : "Case " + (VariantIndex + 1);
        returnStrings.push(_.get(dVar, `Active.ahstate.ahr_casemsg`) + " " + i18n.t(`error.tocalc`) + ": " + variantNameResult);
      }

      const ScoreError:number = _.get(dVar, `Active.ahr_sc`);
      if (Number.isNaN(ScoreError)) {
        const variantNameFromJson = _.get(dVar, `n`);
        const variantNameResult = variantNameFromJson ? variantNameFromJson : VariantIndex == 0 ? "baseline" : "Case " + (VariantIndex + 1);
        returnStrings.push(i18n.t(`error.score`) + " " + i18n.t(`error.tocalc`) + ": " + variantNameResult);
      }

      VariantIndex++;
    }
  }

  return returnStrings;
};


export const useErrorReturnTrue = () => {
  const { globalJson } = useContext(JsonContext);
  const allVariants = _.get(globalJson, `lVariant`);

  if (allVariants) {
    for (const dVar of allVariants) {
      const caseerror = _.get(dVar, `Active.ahstate.ahr_caseerror`);
      if (caseerror > 0) {
        return true;
      }
      const ScoreError:number = _.get(dVar, `Active.ahr_sc`);
      if (Number.isNaN(ScoreError)) {
        return true;
      }
    }
  }

  return false;
};
