import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import 'leaflet/dist/leaflet.css';
import { Box } from 'src/ui';
import L from 'leaflet';
import { Unit } from 'src/features/shared/form/domain/unit';


const defaultcenter = {
  lat: 48.1093,
  lng: 11.5817,
    // const longitute: number = 11.5809;
  // const latitutde: number = 48.1103;
}

function DraggableMarker() {
  const { globalJson, onMergeJsonWithGlobalJson, onUpdateFieldToDraftJsonDebounced } = useContext(JsonContext);
  const [draggable, setDraggable] = useState(false)
  const [position, setPosition] = useState(defaultcenter)
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current
        if (marker != null) {
          const dragedpostions = marker.getLatLng();
          const j = {};
          _.set(j, `lVariant[0].cliLoc.latB`, dragedpostions.lat);
          _.set(j, `lVariant[0].cliLoc.longB`, dragedpostions.lng);
          if (j) {
            // _.set(globalJson, `lVariant[0].cliLoc.latB`, dragedpostions.lat);
            // _.set(globalJson, `lVariant[0].cliLoc.longB`, dragedpostions.lng);
            onMergeJsonWithGlobalJson.callback(j);
          }
        }
      },
    }),
    [],
  )
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d)
  }, [])

  const activehouseicon = new L.Icon({
    iconUrl: '/pin_ah.svg',
    // iconSize: [60, 60],
    // iconAnchor: undefined,
    // popupAnchor: undefined,
    // shadowUrl: undefined,
    // shadowSize: undefined,
    // shadowAnchor: undefined,
    iconSize: new L.Point(60, 60),
    // className: 'leaflet-div-icon'
  });


  const currentpos = {
    lat: _.get(globalJson, `lVariant[0].cliLoc.latB`) || defaultcenter.lat,
    lng: _.get(globalJson, `lVariant[0].cliLoc.longB`) || defaultcenter.lng,
  }

  useEffect(() => {
    //console.log(currentpos);
    setPosition(currentpos);
  }, []);


  return (
    <Marker
      icon={activehouseicon}
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
      {/* <Popup minWidth={90}>
        <span> Activehouse */}
        {/* <span onClick={toggleDraggable}> */}
          {/* {draggable
            ? 'Marker is draggable'
            : 'Click here to make marker draggable'} */}
        {/* </span>
      </Popup> */}
    </Marker>
  )
}


export const Map: React.FC = () => {

  const { globalJson } = useContext(JsonContext);

  let longitute: number = _.get(globalJson, `lVariant[0].cliLoc.longB`);
  let latitutde: number = _.get(globalJson, `lVariant[0].cliLoc.latB`);
  let zoom: number = 14;

  if (!longitute || !latitutde) 
  {
    longitute = defaultcenter.lng;
    latitutde = defaultcenter.lat;;
    zoom = 1;
  }

  useEffect(() => {
    //console.log("test");
  });

  return (
    <>
      <Box
      // border="2px"
      // borderColor={appColors.primary.dark}
      >
        <MapContainer center={[latitutde, longitute]} zoom={zoom} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <DraggableMarker />
        </MapContainer>
      </Box>
    </>
  )

};
