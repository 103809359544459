import { Collapse, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/core';
import React from 'react';
import { appColors } from 'src/app/theme';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, ButtonBase, Divider, FlexCol, FlexRow, globalButtonProps, P } from 'src/ui';
import { SustainableConstructionIcon, TrashIcon } from 'src/ui/elements/icons';
import { SystemsElectricityDemandJsonDefinition } from '../../data/systems_electricitydemand_json';
import { SystemsElectricitySupplyJsonDefinition } from '../../data/systems_electricitysupply_json';
import { SystemsHeatSupplyJsonDefinition } from '../../data/systems_heatsupply_json';
import {
  DeviceJsonDefinition,
  SystemAddExpandJsonDefinition,
  SystemsJsonDefinition,
  SystemsTitleJsonDefinition,
  OperationJsonDefinition,
} from '../../data/systems_json_definition';
import { useSystemTypeWithIndex } from '../applications/check_type_of_systems';
import { useSustainableConstructionCalc } from '../applications/use_number_of_areas';
import { useNumberOfSystems } from '../applications/use_number_of_systems';
import { useNumberOfSystemDevices } from '../applications/use_number_of_systems_devices';
import { useNumberOfSystemOperations } from '../applications/use_number_of_systems_operations';

const titleDefinition = new SystemsTitleJsonDefinition();



export const LifeCycleExpand: React.FC<any> = ({
  indexVariant,
  indexSystem,
  buttonName,
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const Json = new SystemAddExpandJsonDefinition(indexVariant, indexSystem);

  const { devices, addDevice, removeDevice, isHeatPumpRenewablepart } = useNumberOfSystemDevices(
    indexVariant,
    indexSystem,
  );
  const { operations, addOperation, removeOperation } = useNumberOfSystemOperations(
    indexVariant,
    indexSystem
  )
  const mappingDevice = [...(Array(devices).keys() as any)];
  const mappingOperation = [...(Array(operations).keys() as any)];

  const { useEnvironmentLoads } = useSustainableConstructionCalc();

  return (
    <>
      {useEnvironmentLoads && isHeatPumpRenewablepart() ?
        <FlexRow alignItems="center">
          <P ml={2}> <SustainableConstructionIcon /> </P>
          <ButtonBase
            mt={1}
            ml={2}
            {...globalButtonProps(appColors.primary.main, isOpen)}
            onClick={onToggle}>
            <P>{buttonName}</P>
          </ButtonBase>
          <FormBuilder jsonKeys={Json.keys} />
        </FlexRow>
        : <></>
      }


      <Collapse in={isOpen} animateOpacity>
        <Box>
          {mappingDevice.map((a, i) => (
            <AnimateList key={i}>
              <Device
                key={devices}
                indexVariant={indexVariant}
                indexSystem={indexSystem}
                indexDevice={i}
                onRemoveDevice={removeDevice}
              />
            </AnimateList>
          ))}

          <AnimatePageEnter>
            <Box as="button" ml={2} mt={2} onClick={addDevice}>
              <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
                {i18n.t(`add.device`)}
              </P>
            </Box>
          </AnimatePageEnter>

          {mappingOperation.map((a, i) => (
            <AnimateList key={i}>
              <Operation
                key={devices}
                indexVariant={indexVariant}
                indexSystem={indexSystem}
                indexOperation={i}
                onRemoveOperation={removeOperation}
              />
            </AnimateList>
          ))}

          <AnimatePageEnter>
            <Box as="button" ml={2} mt={2} onClick={addOperation}>
              <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
                {i18n.t(`add.operation`)}
              </P>
            </Box>
          </AnimatePageEnter>
        </Box>
      </Collapse>

    </>
  );
};


export const LifeCycleModal: React.FC<any> = ({
  indexVariant,
  indexSystem,
  buttonName,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const Json = new SystemAddExpandJsonDefinition(indexVariant, indexSystem);

  const { devices, addDevice, removeDevice,isHeatPumpRenewablepart } = useNumberOfSystemDevices(
    indexVariant,
    indexSystem
  );
  const { operations, addOperation, removeOperation } = useNumberOfSystemOperations(
    indexVariant,
    indexSystem
  )
  const mappingDevice = [...(Array(devices).keys() as any)];
  const mappingOperation = [...(Array(operations).keys() as any)];

  const { useEnvironmentLoads } = useSustainableConstructionCalc();

  return (
    <>
      {useEnvironmentLoads && isHeatPumpRenewablepart() &&
        <FlexRow alignItems="center">
          <Box width="100%" textAlign="center">
            {/* <FormBuilder jsonKeys={Json.keys} /> */}
            <P ml={2}>  </P>
            <ButtonBase
              mt={1}
              ml={2}
              mb={1}
              {...globalButtonProps(appColors.primary.main, isOpen)}
              onClick={onOpen}>
              <P>{buttonName} </P>
            </ButtonBase>
          </Box>
        </FlexRow>
      }
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><P>Lifecycle Assesment input</P></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {mappingDevice.map((a, i) => (
              <AnimateList key={i}>
                <Device
                  key={devices}
                  indexVariant={indexVariant}
                  indexSystem={indexSystem}
                  indexDevice={i}
                  onRemoveDevice={removeDevice}
                />
              </AnimateList>
            ))}
            <AnimatePageEnter>
              <Box as="button" ml={2} mt={2} onClick={addDevice}>
                <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
                  {i18n.t(`add.device`)}
                </P>
              </Box>
            </AnimatePageEnter>
            {mappingOperation.map((a, i) => (
              <AnimateList key={i}>
                <Operation
                  key={devices}
                  indexVariant={indexVariant}
                  indexSystem={indexSystem}
                  indexOperation={i}
                  onRemoveOperation={removeOperation}
                />
              </AnimateList>
            ))}
            <AnimatePageEnter>
              <Box as="button" ml={2} mt={2} mb={2} onClick={addOperation}>
                <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
                  {i18n.t(`add.operation`)}
                </P>
              </Box>
            </AnimatePageEnter>
          </ModalBody>
          <ModalFooter>
            <ButtonBase
              mt={1}
              ml={2}
              mb={1}
              {...globalButtonProps(appColors.primary.main, isOpen)}
              onClick={onClose}>
              <P>Close</P>
            </ButtonBase>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};





export const Device: React.FC<any> = ({
  indexVariant,
  indexSystem,
  indexDevice,
  onRemoveDevice, }) => {
  const Json = new DeviceJsonDefinition(indexVariant, indexSystem, indexDevice);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        {indexDevice > 0 ?
          <FlexRow
            pb={0}
            pl={1}
            mt={6}
            mb={0}
            alignSelf="self-start"
            as="button"
            onClick={() => onRemoveDevice(indexVariant, indexSystem, indexDevice)}
          >
            <AnimatePageEnter>
              <TrashIcon />
            </AnimatePageEnter>
          </FlexRow>
          :
          <FlexRow
            pb={0}
            pl={1}
            mt={6}
            mb={0}
            alignSelf="self-start"
            as="button"
            cursor="not-allowed"
          >
            <AnimatePageEnter>
              <TrashIcon />
            </AnimatePageEnter>
          </FlexRow>
        }
      </FlexRow>
    </>
  );
};


export const Operation: React.FC<any> = ({
  indexVariant,
  indexSystem,
  indexOperation,
  onRemoveOperation, }) => {
  const Json = new OperationJsonDefinition(indexVariant, indexSystem, indexOperation);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        <FlexRow
          pb={0}
          pl={1}
          mt={0}
          mb={6}
          alignSelf="flex-end"
          as="button"
          onClick={() => onRemoveOperation(indexVariant, indexSystem, indexOperation)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexRow>
      </FlexRow>
    </>
  );
};


export const System: React.FC<any> = ({
  indexVariant,
  indexSystem,
  typeOfSystem,
  onRemoveSystem,
}) => {
  // const Json = new SystemsJsonDefinition(indexSystem);

  if (typeOfSystem === useSystemTypeWithIndex(indexSystem, 0)) {

    const Json =
      typeOfSystem == 2 ? new SystemsElectricityDemandJsonDefinition(0, indexSystem)
        : typeOfSystem == 3 ? new SystemsHeatSupplyJsonDefinition(0, indexSystem)
          : typeOfSystem == 4 ? new SystemsElectricitySupplyJsonDefinition(0, indexSystem)
            : new SystemsJsonDefinition(indexSystem);

    return (
      <>
        <FlexRow alignItems="center">
          <FormBuilder jsonKeys={Json.keys} />
          <FlexCol
            pb={1}
            pl={1}
            mt={5}
            alignSelf="self-start"
            as="button"
            onClick={() => onRemoveSystem(indexSystem)}
          >
            <AnimatePageEnter>
              <TrashIcon />
            </AnimatePageEnter>
          </FlexCol>
        </FlexRow>
        <LifeCycleExpand
          indexVariant={indexVariant}
          indexSystem={indexSystem}
          buttonName={i18n.t(`pages.environmentalload`)}
        />
        <Divider my={4} />
      </>
    );
  } else {
    return <></>;
  }
};

export const SystemsPage: React.FC = () => {
  const { systems, addSystem, removeSystem } = useNumberOfSystems(0);
  const mappingSystems = [...(Array(systems).keys() as any)];
  return (
    <div>
      <FormBuilder jsonKeys={titleDefinition.keys} />
      {mappingSystems.map((a, i) => (
        <AnimateList key={i}>
          <System
            key={systems}
            indexVariant={0}
            indexSystem={a}
            typeOfSystem={1}
            onRemoveSystem={() => removeSystem(i)}
          />
        </AnimateList>
      ))}
      <AnimatePageEnter>
        <Box as="button" mt={2} mb={5} onClick={() => addSystem(1)}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.system`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </div>
  );
};
