export const projectInformationRoutePaths = {
  main: "/project-information/main",
  contacts: "/project-information/contacts",
  rooms: "/rooms",
  areas: "/areas",
  constructions: "/constructions/opaque",
  windowsdoors: "/constructions/windowsdoors",
  systems: "/systems/thermaldemand",
  systemselectricitydemand: "/systems/electricitydemand",
  systemsheatsupply: "/systems/thermalproduction",
  systemselectricitysupply: "/systems/electricityproduction",
};


