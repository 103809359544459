import React from 'react';
import { useParams } from 'react-router';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { Box, FlexRow, P } from 'src/ui';
import {
  AcousticQuantityJsonDefinition,
  AcousticRoomsJsonDefinition,
} from '../../../data/quantitative/acoustic_json_definition';
import {
  getVariantCaseNumber,
  useWithVariantCaseIndex,
} from '../use_with_variantcase_index';

import { useListOfRooms } from '../use_rooms_list';
import { NotUsedRoomsJsonDefinition } from 'src/features/baseline/data/quantitative/daylight_json_definition';
import { appColors } from 'src/app/theme';
import { NotUsedRoom } from './daylight';
import { TrashIcon } from 'src/ui/elements/icons';

export const RoomAcoustic: React.FC<any> = ({ variantcase, index, remove, roomIsUsed  }) => {
  const Json = new AcousticRoomsJsonDefinition(variantcase, index);

  if (!roomIsUsed(index,4)) return <> </>;

  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        <FlexRow
          mt={4}
          ml={1}
          py={2}
          alignSelf="self-start"
          as="button"
          onClick={() => remove(index, 4)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexRow>
      </FlexRow>
    </>
  );
};

export const AcousticQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(AcousticQuantityJsonDefinition);

  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { rooms, removeThisRoom, bringBackThisRoom, getRoomName, roomIsUsed, notUsedRoomsDefined } = useListOfRooms(variantcaseIndex);
  const mappingRooms = [...(Array(rooms).keys() as any)];

  const deviderDefinition = new NotUsedRoomsJsonDefinition();

  return (
    <>
      <FormBuilder jsonKeys={keys} />
      {/* <Divider my={2} /> */}
      <Box my={-2}>
      {mappingRooms.map((a, i) => (
        <AnimateList key={i}>
          <RoomAcoustic key={rooms} variantcase={variantcaseIndex} index={a} remove={removeThisRoom} roomIsUsed={roomIsUsed}/>
        </AnimateList>
      ))}
      </Box>

      {notUsedRoomsDefined(4) ? (
        <>
        <FormBuilder jsonKeys={deviderDefinition.keys} />
        <Box my={-2}>
          <P color={appColors.gray.light}>Bring back room:</P>
          {mappingRooms.map((a, i) => (
              <NotUsedRoom key={rooms} variantcase={variantcaseIndex} index={a} bringback={bringBackThisRoom} usedfortype={4} getRoomName={getRoomName} roomIsUsed={roomIsUsed}/>
          ))}
        </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AcousticQuantityPage;
