export const appFontSizes = {
  h1: "48px",
  h2: "24px",
  h3: "24px",
  h4: "18px",
  body: "16px",
  small2: "16px",
  small: "14px",
  tiny: "12px",
  xs: "10px",
};
