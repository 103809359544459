import _ from 'lodash';
import React, { useContext } from "react";
import { appColors } from 'src/app/theme';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { Box, P } from "src/ui";
import { PictureUploader } from 'src/ui/elements/picture_upload';
import { Icon, MinusIcon } from 'src/ui/elements/icons';
import { Alert, AlertDescription, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, AlertTitle, Button, CloseButton, useToast } from '@chakra-ui/core';
import { i18n } from 'src/features/shared/translations/translate';





export const Picture: React.FC = () => {

  const { globalJson, onMergeJsonWithGlobalJson } = useContext(JsonContext);
  const toast = useToast();

  return (
    <Box
    // height="300px"
    >
      <PictureUploader
        backgroundImage={_.get(globalJson, 'projD.ahpic')}
        onUpload={(ReadFile) => {

          const j = {};

          var reader = new FileReader();
          reader.onloadend = function () {
            _.set(j, 'projD.ahpic', reader.result);
            if (j) {
              onMergeJsonWithGlobalJson.callback(j);
            }

          }
          if (ReadFile[0] != null) {
            reader.readAsDataURL(ReadFile[0]);
          }
          else {

          }
        }}
        onUploadFailed={() => {
          toast({
            // title: "Reject file",
            // description: "Sorry, wrong file type, or file size",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
            render: () => (
              <Box color="white" p={3} bg="red.500">
                <P>{i18n.t(`form.filereject`)}</P>
              </Box>
            ),
          })
        }}
      />
      {/* <img src={`${_.get(globalJson, 'projD.picture')}`} /> */}
    </Box>
  )
};

