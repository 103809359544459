import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { LocalStorageService } from './localStorage_service';

export class HttpService {
  protected readonly instance: AxiosInstance;

  constructor(
    baseURL = process.env.NX_API_URL,
    private storageService = new LocalStorageService()
  ) {
    this.instance = axios.create({ baseURL });
    this.instance.interceptors.request.use(
      function (config) {
        return {
          ...config,
          data: {
            ...config.data,
          },
        };
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    this.instance.interceptors.response.use(
      function (response) {
        return {
          ...response,
          response: response.data.response
            ? JSON.parse(response.data.response)
            : null,
        };
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  async get(path: string, data: any) {
    try {
      return await this.instance.get(path, data);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async post<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.post(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async put<T>(path: string, payload: T, config: AxiosRequestConfig = {}) {
    try {
      return await this.instance.put(path, payload, config);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  async delete<T>(path: string, payload: T) {
    try {
      return await this.instance.delete(path, payload);
    } catch (error) {
      this.manageErrors(error);
    }
  }

  manageErrors(error: any) {}
}
