export const oekobauJson = {
  "oekobau": [
    {
      "id": "1",
      "grp": "Casement frame",
      "n": "7.1.1 Holz-Flügelrahmen",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-0.762747329",
      "ODP": "3.70E-07",
      "AP": "0.013967848",
      "EP": "0.001060608",
      "POCP": "0.005313406",
      "PEnr": "43.80487686",
      "PE": "51.67501397",
      "eol": "End of life - Holz-Flügelrahmen",
      "d": "38442",
      "w": "2.14",
      "wd": "163"
    },
    {
      "id": "2",
      "grp": "Casement frame",
      "n": "7.1.5 Aluminium-Flügelrahmenprofil, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "12.94462501",
      "ODP": "1.35E-06",
      "AP": "0.063233422",
      "EP": "0.002889493",
      "POCP": "0.003697765",
      "PEnr": "183.6217216",
      "PE": "48.16730813",
      "eol": "End of life - Aluminium-Flügelrahmenprofil, pulverbeschichtet",
      "d": "38442",
      "w": "0.98",
      "wd": "163"
    },
    {
      "id": "3",
      "grp": "Casement frame",
      "n": "7.1.6 Aluminium-Flügelrahmenprofil, thermisch getrennt, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "18.73208021",
      "ODP": "1.71E-06",
      "AP": "0.088224201",
      "EP": "0.005491955",
      "POCP": "0.005641557",
      "PEnr": "254.991933",
      "PE": "62.87884394",
      "eol": "End of life - Aluminium-Flügelrahmenprofil, thermisch getrennt, pulverbeschichtet",
      "d": "38442",
      "w": "1.51",
      "wd": "163"
    },
    {
      "id": "4",
      "grp": "Casement frame",
      "n": "7.1.9 Flügelrahmen PVC-U",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "8.574773703",
      "ODP": "3.80E-07",
      "AP": "0.044451527",
      "EP": "0.00322425",
      "POCP": "0.003182934",
      "PEnr": "156.6908284",
      "PE": "5.730636724",
      "eol": "End of life - Flügelrahmen PVC-U",
      "d": "38442",
      "w": "3.1",
      "wd": "163"
    },
    {
      "id": "5",
      "grp": "Distribution",
      "n": "coppertube blank - KME",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "1.962185292",
      "ODP": "2.45E-07",
      "AP": "0.010264004",
      "EP": "0.001014307",
      "POCP": "0.000781178",
      "PEnr": "28.35109962",
      "PE": "1.631799209",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "6",
      "grp": "Distribution",
      "n": "coppertube blank - Wieland",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "1.962185292",
      "ODP": "2.45E-07",
      "AP": "0.010264004",
      "EP": "0.001014307",
      "POCP": "0.000781178",
      "PEnr": "28.35109962",
      "PE": "1.631799209",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "7",
      "grp": "Distribution",
      "n": "coppertube blank, PE-Extrusion ummantelt - KME",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.063265547",
      "ODP": "3.98E-07",
      "AP": "0.010502896",
      "EP": "0.001009344",
      "POCP": "0.001448922",
      "PEnr": "35.84236105",
      "PE": "1.706142194",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "8",
      "grp": "Distribution",
      "n": "coppertube blank, PE-Extrusion ummantelt - Wieland",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.063265547",
      "ODP": "3.98E-07",
      "AP": "0.010502896",
      "EP": "0.001009344",
      "POCP": "0.001448922",
      "PEnr": "35.84236105",
      "PE": "1.706142194",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "9",
      "grp": "Distribution",
      "n": "coppertube blank, PE-Schaum ummantelt - KME",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.160720046",
      "ODP": "3.85E-07",
      "AP": "0.010750749",
      "EP": "0.001038752",
      "POCP": "0.003919534",
      "PEnr": "36.60848544",
      "PE": "1.750925445",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "10",
      "grp": "Distribution",
      "n": "coppertube blank, PE-Schaum ummantelt - Wieland",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.160720046",
      "ODP": "3.85E-07",
      "AP": "0.010750749",
      "EP": "0.001038752",
      "POCP": "0.003919534",
      "PEnr": "36.60848544",
      "PE": "1.750925445",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "11",
      "grp": "Distribution",
      "n": "coppertube blank, PU-Schaum ummantelt - KME",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.328367538",
      "ODP": "4.07E-07",
      "AP": "0.010814552",
      "EP": "0.001160442",
      "POCP": "0.001237175",
      "PEnr": "39.5718623",
      "PE": "1.804678259",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "12",
      "grp": "Distribution",
      "n": "coppertube blank, PU-Schaum ummantelt - Wieland",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.328367538",
      "ODP": "4.07E-07",
      "AP": "0.010814552",
      "EP": "0.001160442",
      "POCP": "0.001237175",
      "PEnr": "39.5718623",
      "PE": "1.804678259",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "13",
      "grp": "Distribution",
      "n": "coppertube blank, PVC-Extrusion ummantelt - KME",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "1.916764141",
      "ODP": "3.26E-07",
      "AP": "0.009467538",
      "EP": "0.000996769",
      "POCP": "0.000973987",
      "PEnr": "31.70417174",
      "PE": "1.767544439",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "14",
      "grp": "Distribution",
      "n": "coppertube blank, PVC-Extrusion ummantelt - Wieland",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "1.916764141",
      "ODP": "3.26E-07",
      "AP": "0.009467538",
      "EP": "0.000996769",
      "POCP": "0.000973987",
      "PEnr": "31.70417174",
      "PE": "1.767544439",
      "eol": "4.8. Recyclingpotential copper tube ",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "15",
      "grp": "Distribution",
      "n": "PE-X- tube",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "3.032066187",
      "ODP": "2.18E-07",
      "AP": "0.005925199",
      "EP": "0.000535232",
      "POCP": "0.001111567",
      "PEnr": "95.62831077",
      "PE": "1.062251664",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "16",
      "grp": "Distribution",
      "n": "PEX-Alu-tube",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "8.13287006",
      "ODP": "5.94E-07",
      "AP": "0.033612887",
      "EP": "0.001418373",
      "POCP": "0.003657809",
      "PEnr": "125.6238224",
      "PE": "23.65458805",
      "eol": "End of life - PEX-Alu-tube",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "17",
      "grp": "Distribution",
      "n": "Polybutadien-tube (PB)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "4.734378744",
      "ODP": "1.71E-07",
      "AP": "0.008142991",
      "EP": "0.001160003",
      "POCP": "0.00104025",
      "PEnr": "113.5294374",
      "PE": "0.839315623",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "18",
      "grp": "Distribution",
      "n": "Polypropylen-tube (PP)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.858700755",
      "ODP": "1.79E-07",
      "AP": "0.005530651",
      "EP": "0.000506305",
      "POCP": "0.000942075",
      "PEnr": "91.2094604",
      "PE": "0.878259201",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "19",
      "grp": "Distribution",
      "n": "Polyurethan rigit foam (tubeisolierung)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "8.893842568",
      "ODP": "5.05E-07",
      "AP": "0.016025798",
      "EP": "0.003005832",
      "POCP": "0.00278004",
      "PEnr": "156.7277086",
      "PE": "2.525993023",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "20",
      "grp": "Distribution",
      "n": "Polyvinylchlorid-tube (PVC)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "3.597956306",
      "ODP": "0",
      "AP": "0.015491893",
      "EP": "0.001314002",
      "POCP": "0.001299661",
      "PEnr": "67.41568407",
      "PE": "1.006438671",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "21",
      "grp": "End of Life",
      "n": "6.8 Incineration of plastics (including credits)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.813",
      "ODP": "-7.47E-08",
      "AP": "0.001",
      "EP": "-0.000145",
      "POCP": "-0.000141",
      "PEnr": "-27.6",
      "PE": "-0.356",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "22",
      "grp": "End of Life",
      "n": "End of life - Aluminium-Rahmenprofil, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-9.01576139",
      "ODP": "-5.59E-07",
      "AP": "-0.045322438",
      "EP": "-0.001731269",
      "POCP": "-0.004055843",
      "PEnr": "-100.841574",
      "PE": "-35.71285984",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "23",
      "grp": "End of Life",
      "n": "End of life - Aluminium-Rahmenprofil, thermisch getrennt, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-12.63974391",
      "ODP": "-7.83E-07",
      "AP": "-0.063540281",
      "EP": "-0.002427172",
      "POCP": "-0.004055843",
      "PEnr": "-141.3759322",
      "PE": "-50.06802899",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "24",
      "grp": "End of Life",
      "n": "End of life - Blendrahmen PVC-U",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-0.52289941",
      "ODP": "-4.64E-08",
      "AP": "-0.00412803",
      "EP": "-0.000688817",
      "POCP": "-0.000740863",
      "PEnr": "-57.04809023",
      "PE": "-0.561997236",
      "eol": "",
      "d": "modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "25",
      "grp": "End of Life",
      "n": "End of life - Fensterglas einfach",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "0.1518",
      "ODP": "1.24E-09",
      "AP": "0.00061875",
      "EP": "0.0000795",
      "POCP": "0.0000131",
      "PEnr": "1.2015",
      "PE": "0.081",
      "eol": "",
      "d": "modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "26",
      "grp": "End of Life",
      "n": "End of life - Holz-Blendrahmen",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "1.529648235",
      "ODP": "-2.93E-08",
      "AP": "0.001078546",
      "EP": "0.000283183",
      "POCP": "4.35E-06",
      "PEnr": "-13.33081314",
      "PE": "-0.139013276",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "27",
      "grp": "End of Life",
      "n": "End of life - Isolierglas 2-Scheiben",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "0.4048",
      "ODP": "3.30E-09",
      "AP": "0.00165",
      "EP": "0.0000106",
      "POCP": "0.000262",
      "PEnr": "3.204",
      "PE": "0.216",
      "eol": "",
      "d": "modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "28",
      "grp": "End of Life",
      "n": "End of life - PEX-Alu-tube",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.988",
      "ODP": "-0.000000177",
      "AP": "-0.0114",
      "EP": "-0.000467",
      "POCP": "-0.000642",
      "PEnr": "-28.8",
      "PE": "-2.67",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "29",
      "grp": "End of Life",
      "n": "End of life - window handle",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "0.0813",
      "ODP": "-7.47E-09",
      "AP": "0.0001",
      "EP": "-0.0000145",
      "POCP": "-0.0000141",
      "PEnr": "-2.76",
      "PE": "-0.0356",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "30",
      "grp": "End of Life",
      "n": "End of life included in production dataset (cradle to grave)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "0",
      "ODP": "0",
      "AP": "0",
      "EP": "0",
      "POCP": "0",
      "PEnr": "0",
      "PE": "0",
      "eol": "",
      "d": "modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "31",
      "grp": "End of Life",
      "n": "3.4 Incineration of natural wood (including credits)",
      "no": "3.4 Holz,_naturbelassen_in_MVA.xml",
      "ref": "Verbrennungsgut",
      "densB": "",
      "u": "39",
      "GWP": "0.994717663",
      "ODP": "-3.26E-08",
      "AP": "-0.000404971",
      "EP": "2.93E-07",
      "POCP": "-5.91E-05",
      "PEnr": "-13.68891012",
      "PE": "-0.154794005",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "32",
      "grp": "End of Life",
      "n": "3.4 Incineration of wooden particle board (including credits)",
      "no": "3.4 Particleboard_in_MVA.xml",
      "ref": "Verbrennungsgut",
      "densB": "",
      "u": "39",
      "GWP": "1.176652488",
      "ODP": "-2.25E-08",
      "AP": "0.00082965",
      "EP": "0.000217833",
      "POCP": "3.35E-06",
      "PEnr": "-10.25447165",
      "PE": "-0.106933289",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "33",
      "grp": "End of Life",
      "n": "4.8 Recyclingpotential Aluminium sheet",
      "no": "4.8 Recyclingpotential_Aluminiumblech.xml",
      "ref": "Aluminium Blech Schrott",
      "densB": "2700",
      "u": "39",
      "GWP": "-8.838981755",
      "ODP": "-5.48E-07",
      "AP": "-0.044433763",
      "EP": "-0.001697323",
      "POCP": "-0.003976317",
      "PEnr": "-98.86428822",
      "PE": "-35.01260769",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "34",
      "grp": "End of Life",
      "n": "4.8 Recyclingpotential Copper sheet",
      "no": "4.8 Recyclingpotential_copperblech.xml",
      "ref": "copperblech Schrott",
      "densB": "8920",
      "u": "39",
      "GWP": "-1.497573205",
      "ODP": "-6.13E-08",
      "AP": "-0.008251594",
      "EP": "-0.000509779",
      "POCP": "-0.000556318",
      "PEnr": "-21.35800921",
      "PE": "-2.91638084",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "35",
      "grp": "End of Life",
      "n": "4.8 Recyclingpotential steel profile, hot rolled",
      "no": "4.8 Recyclingpotential_steelprofil,_warmgewalzt.xml",
      "ref": "steelprofil Schrott",
      "densB": "7850",
      "u": "39",
      "GWP": "-1.040822261",
      "ODP": "3.34E-08",
      "AP": "-0.00357616",
      "EP": "-0.000329625",
      "POCP": "-0.000551011",
      "PEnr": "-13.94621329",
      "PE": "-0.065354304",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "36",
      "grp": "End of Life",
      "n": "4.8 Recyclingpotential steel sheet",
      "no": "4.8 Recyclingpotential_steel_Feinblech.xml",
      "ref": "steel Feinblech Schrott",
      "densB": "7850",
      "u": "39",
      "GWP": "-1.053199607",
      "ODP": "3.38E-08",
      "AP": "-0.003618687",
      "EP": "-0.000333545",
      "POCP": "-0.000557563",
      "PEnr": "-14.11206015",
      "PE": "-0.066131491",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "37",
      "grp": "End of Life",
      "n": "4.8 Recyclingpotential steel sheet, hot rolled strip",
      "no": "4.8 Recyclingpotential_steelblech,_Warmband.xml",
      "ref": "steelblech Schrott",
      "densB": "7850",
      "u": "39",
      "GWP": "-1.040822261",
      "ODP": "3.34E-08",
      "AP": "-0.00357616",
      "EP": "-0.000329625",
      "POCP": "-0.000551011",
      "PEnr": "-13.94621329",
      "PE": "-0.065354304",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "38",
      "grp": "End of Life",
      "n": "4.8 Recyclingpotential steel sheet, zinced",
      "no": "4.8 Recyclingpotential_steel_Feinblech,_verzinkt.xml",
      "ref": "steelblech, verzinkt Schrott",
      "densB": "7850",
      "u": "39",
      "GWP": "-1.084705578",
      "ODP": "3.48E-08",
      "AP": "-0.003726938",
      "EP": "-0.000343523",
      "POCP": "-0.000574243",
      "PEnr": "-14.5342158",
      "PE": "-0.068109783",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "39",
      "grp": "End of Life",
      "n": "4.8. Recyclingpotential copper tube ",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "-1",
      "ODP": "-0.000000146",
      "AP": "-0.00762",
      "EP": "-0.000791",
      "POCP": "-0.000595",
      "PEnr": "-12.8",
      "PE": "-0.836",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "40",
      "grp": "End of Life",
      "n": "4.8. Recyclingpotential reinforcement steel (secondary production)",
      "no": "",
      "ref": "",
      "densB": "7850",
      "u": "39",
      "GWP": "0",
      "ODP": "0",
      "AP": "0",
      "EP": "0",
      "POCP": "0",
      "PEnr": "0",
      "PE": "0",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "41",
      "grp": "End of Life",
      "n": "6.2.5 Incineration of carpet (including credits)",
      "no": "6.2.5 Verbrennung Altteppichboden MVA.xml",
      "ref": "Verbrennung MVA [Verbrennung] (kg)",
      "densB": "2.11",
      "u": "39",
      "GWP": "1.085628233",
      "ODP": "-3.49E-08",
      "AP": "0.001182147",
      "EP": "0.000267989",
      "POCP": "2.83E-05",
      "PEnr": "-11.90463428",
      "PE": "-0.180320579",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "42",
      "grp": "End of Life",
      "n": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "no": "9.5.1 Bauschuttaufbereitung.xml",
      "ref": "Bauschutt-Rezyklat [Sonstige Bauprozesse] (kg)",
      "densB": "",
      "u": "39",
      "GWP": "0.051099598",
      "ODP": "1.92E-09",
      "AP": "0.00010014",
      "EP": "9.90E-05",
      "POCP": "7.64E-06",
      "PEnr": "0.295418435",
      "PE": "0.009266573",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "43",
      "grp": "End of Life",
      "n": "9.5.2 Building material disposal",
      "no": "9.5.2 Bauschutt-Deponierung.xml",
      "ref": "Bauschutt [Deponie] (kg)",
      "densB": "",
      "u": "39",
      "GWP": "0.02024",
      "ODP": "1.65E-10",
      "AP": "0.0000825",
      "EP": "0.0000106",
      "POCP": "0.0000131",
      "PEnr": "0.1602",
      "PE": "0.0108",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "44",
      "grp": "End of Life",
      "n": "9.5.4 Incineration of household waste (including credit)",
      "no": "9.5.4 Verbrennung Hausmüll MVA.xml",
      "ref": "Verbrennung MVA [Verbrennung] (kg)",
      "densB": "",
      "u": "39",
      "GWP": "0.571373334",
      "ODP": "-1.14E-08",
      "AP": "0.001003887",
      "EP": "0.000161078",
      "POCP": "2.91E-05",
      "PEnr": "-6.040915194",
      "PE": "-0.054391423",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "45",
      "grp": "End of Life",
      "n": "End of life - Aluminium-Flügelrahmenprofil, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-8.662202119",
      "ODP": "-5.37E-07",
      "AP": "-0.043545088",
      "EP": "-0.001663377",
      "POCP": "-0.00389679",
      "PEnr": "-96.88700245",
      "PE": "-34.31235553",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "46",
      "grp": "End of Life",
      "n": "End of life - Aluminium-Flügelrahmenprofil, thermisch getrennt, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-13.34686245",
      "ODP": "-8.27E-07",
      "AP": "-0.067094982",
      "EP": "-0.002562958",
      "POCP": "-0.006004238",
      "PEnr": "-149.2850752",
      "PE": "-52.86903761",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "47",
      "grp": "End of Life",
      "n": "End of life - electric heat pump (brine-water, Earth-tube collector) 70 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-170.2062579",
      "ODP": "5.49E-06",
      "AP": "-1.455953602",
      "EP": "-0.053929759",
      "POCP": "-0.130237035",
      "PEnr": "-2299.370617",
      "PE": "-208.4658584",
      "eol": "",
      "d": "38442",
      "w": "799.92764",
      "wd": "39"
    },
    {
      "id": "48",
      "grp": "End of Life",
      "n": "End of life - electric heat pump (brine-water, geothermal probe) 20 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-93.16438004",
      "ODP": "3.49E-06",
      "AP": "-0.651815084",
      "EP": "-0.027993261",
      "POCP": "-0.066104466",
      "PEnr": "-1271.855166",
      "PE": "-80.11892916",
      "eol": "",
      "d": "38442",
      "w": "1498.05198",
      "wd": "39"
    },
    {
      "id": "49",
      "grp": "End of Life",
      "n": "End of life - electric heat pump (water-water) 10 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-109.8827567",
      "ODP": "3.81E-06",
      "AP": "-0.783789197",
      "EP": "-0.03568531",
      "POCP": "-0.077666929",
      "PEnr": "-1498.679014",
      "PE": "-96.7955679",
      "eol": "",
      "d": "38442",
      "w": "104.21998",
      "wd": "39"
    },
    {
      "id": "50",
      "grp": "End of Life",
      "n": "End of life - electric heat pump (water-water) 20 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-109.8827567",
      "ODP": "3.81E-06",
      "AP": "-0.783789197",
      "EP": "-0.03568531",
      "POCP": "-0.077666929",
      "PEnr": "-1498.679014",
      "PE": "-96.7955679",
      "eol": "",
      "d": "38442",
      "w": "104.21998",
      "wd": "39"
    },
    {
      "id": "51",
      "grp": "End of Life",
      "n": "End of life - electric heat pump (water-water) 70 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-198.8145198",
      "ODP": "6.22E-06",
      "AP": "-1.729624982",
      "EP": "-0.064118195",
      "POCP": "-0.152845793",
      "PEnr": "-2682.070387",
      "PE": "-249.9567044",
      "eol": "",
      "d": "38442",
      "w": "199.21998",
      "wd": "39"
    },
    {
      "id": "52",
      "grp": "End of Life",
      "n": "End of life - floor heating copper (100mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-3.487159558",
      "ODP": "-6.10E-08",
      "AP": "-0.074298833",
      "EP": "-0.001660103",
      "POCP": "-0.004854916",
      "PEnr": "-74.78575449",
      "PE": "-14.28315572",
      "eol": "",
      "d": "38442",
      "w": "5.7",
      "wd": "39"
    },
    {
      "id": "53",
      "grp": "End of Life",
      "n": "End of life - floor heating copper (200mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-1.299029783",
      "ODP": "-1.10E-07",
      "AP": "-0.042282361",
      "EP": "-0.0010404",
      "POCP": "-0.00271629",
      "PEnr": "-50.33245714",
      "PE": "-8.341019907",
      "eol": "",
      "d": "38442",
      "w": "3.3",
      "wd": "39"
    },
    {
      "id": "54",
      "grp": "End of Life",
      "n": "End of life - floor heating PEX (100mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1.876988875",
      "ODP": "-3.36E-07",
      "AP": "-0.021672434",
      "EP": "-0.00088814",
      "POCP": "-0.001219512",
      "PEnr": "-54.63378176",
      "PE": "-5.064310862",
      "eol": "",
      "d": "38442",
      "w": "1.9",
      "wd": "39"
    },
    {
      "id": "55",
      "grp": "End of Life",
      "n": "End of life - floor heating PEX (200mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1.383044434",
      "ODP": "-2.48E-07",
      "AP": "-0.015969162",
      "EP": "-0.000654419",
      "POCP": "-0.000898588",
      "PEnr": "-40.25647077",
      "PE": "-3.731597477",
      "eol": "",
      "d": "38442",
      "w": "1.4",
      "wd": "39"
    },
    {
      "id": "56",
      "grp": "End of Life",
      "n": "End of life - floor heating PP (100mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1.876988875",
      "ODP": "-3.36E-07",
      "AP": "-0.021672434",
      "EP": "-0.00088814",
      "POCP": "-0.001219512",
      "PEnr": "-54.63378176",
      "PE": "-5.064310862",
      "eol": "",
      "d": "38442",
      "w": "1.9",
      "wd": "39"
    },
    {
      "id": "57",
      "grp": "End of Life",
      "n": "End of life - Flügelrahmen PVC-U",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-0.326258367",
      "ODP": "-6.73E-08",
      "AP": "-0.003990407",
      "EP": "-0.000747284",
      "POCP": "-0.001086964",
      "PEnr": "-65.9613237",
      "PE": "-0.671764675",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "58",
      "grp": "End of Life",
      "n": "End of life - fuel gas condensing boiler <20 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-100.9453081",
      "ODP": "1.29E-06",
      "AP": "-0.670957819",
      "EP": "-0.033343582",
      "POCP": "-0.070542332",
      "PEnr": "-1530.630607",
      "PE": "-142.5085396",
      "eol": "",
      "d": "38442",
      "w": "132.994",
      "wd": "39"
    },
    {
      "id": "59",
      "grp": "End of Life",
      "n": "End of life - fuel gas condensing boiler <20 KW (wall-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-32.6360363",
      "ODP": "4.16E-07",
      "AP": "-0.216919976",
      "EP": "-0.010780199",
      "POCP": "-0.022806504",
      "PEnr": "-494.8659691",
      "PE": "-46.07292104",
      "eol": "",
      "d": "38442",
      "w": "42.999",
      "wd": "39"
    },
    {
      "id": "60",
      "grp": "End of Life",
      "n": "End of life - fuel gas condensing boiler 120 - 400 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-747.4592404",
      "ODP": "9.53E-06",
      "AP": "-4.968331863",
      "EP": "-0.246896705",
      "POCP": "-0.52232395",
      "PEnr": "-11332.86538",
      "PE": "-1055.362937",
      "eol": "",
      "d": "38442",
      "w": "973.323",
      "wd": "39"
    },
    {
      "id": "61",
      "grp": "End of Life",
      "n": "End of life - fuel gas condensing boiler 20 - 120 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-216.1314981",
      "ODP": "2.76E-06",
      "AP": "-1.436637728",
      "EP": "-0.071396641",
      "POCP": "-0.151040365",
      "PEnr": "-3277.331094",
      "PE": "-305.1233409",
      "eol": "",
      "d": "38442",
      "w": "282.991",
      "wd": "39"
    },
    {
      "id": "62",
      "grp": "End of Life",
      "n": "End of life - fuel gas heat pump (air) 20-70 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-116.5551453",
      "ODP": "4.48E-06",
      "AP": "-0.54871806",
      "EP": "-0.039992337",
      "POCP": "-0.072827682",
      "PEnr": "-1718.861947",
      "PE": "-32.54617539",
      "eol": "",
      "d": "38442",
      "w": "156.818576",
      "wd": "39"
    },
    {
      "id": "63",
      "grp": "End of Life",
      "n": "End of life - fuel gas low temperature boiler < 20 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-32.99599984",
      "ODP": "1.18E-06",
      "AP": "-0.182014522",
      "EP": "-0.01227961",
      "POCP": "-0.02293018",
      "PEnr": "-545.0783868",
      "PE": "-16.39214021",
      "eol": "",
      "d": "38442",
      "w": "117.529",
      "wd": "39"
    },
    {
      "id": "64",
      "grp": "End of Life",
      "n": "End of life - fuel gas low temperature boiler 120 - 400 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-264.7576335",
      "ODP": "9.50E-06",
      "AP": "-1.451674875",
      "EP": "-0.09818206",
      "POCP": "-0.183313678",
      "PEnr": "-4353.208853",
      "PE": "-129.7010317",
      "eol": "",
      "d": "38442",
      "w": "936.485",
      "wd": "39"
    },
    {
      "id": "65",
      "grp": "End of Life",
      "n": "End of life - fuel gas low temperature boiler 20 - 120 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-82.0623458",
      "ODP": "2.93E-06",
      "AP": "-0.451832466",
      "EP": "-0.030519746",
      "POCP": "-0.056973419",
      "PEnr": "-1354.468607",
      "PE": "-40.55982159",
      "eol": "",
      "d": "38442",
      "w": "292.005",
      "wd": "39"
    },
    {
      "id": "66",
      "grp": "End of Life",
      "n": "End of life - fuel oil condensing boiler <20 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-123.0583283",
      "ODP": "1.57E-06",
      "AP": "-0.81796211",
      "EP": "-0.040650062",
      "POCP": "-0.085998148",
      "PEnr": "-1866.044937",
      "PE": "-173.731616",
      "eol": "",
      "d": "38442",
      "w": "161.997",
      "wd": "39"
    },
    {
      "id": "67",
      "grp": "End of Life",
      "n": "End of life - fuel oil condensing boiler <20 KW (wall-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-64.51491547",
      "ODP": "8.23E-07",
      "AP": "-0.428864819",
      "EP": "-0.02131157",
      "POCP": "-0.045087608",
      "PEnr": "-978.313533",
      "PE": "-91.08824224",
      "eol": "",
      "d": "38442",
      "w": "85.0003",
      "wd": "39"
    },
    {
      "id": "68",
      "grp": "End of Life",
      "n": "End of life - fuel oil condensing boiler 120 - 400 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-1155.419349",
      "ODP": "1.47E-05",
      "AP": "-7.678855206",
      "EP": "-0.38164154",
      "POCP": "-0.807332571",
      "PEnr": "-17517.48244",
      "PE": "-1630.814331",
      "eol": "",
      "d": "38442",
      "w": "1503.875",
      "wd": "39"
    },
    {
      "id": "69",
      "grp": "End of Life",
      "n": "End of life - fuel oil condensing boiler 20 - 120 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-336.1134108",
      "ODP": "4.29E-06",
      "AP": "-2.234155793",
      "EP": "-0.111033255",
      "POCP": "-0.23488741",
      "PEnr": "-5096.703926",
      "PE": "-474.512108",
      "eol": "",
      "d": "38442",
      "w": "438.978",
      "wd": "39"
    },
    {
      "id": "70",
      "grp": "End of Life",
      "n": "End of life - fuel oil low temperature boiler <20KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-73.35357931",
      "ODP": "2.45E-06",
      "AP": "-0.355791235",
      "EP": "-0.025326092",
      "POCP": "-0.047071758",
      "PEnr": "-1122.418211",
      "PE": "-37.90540979",
      "eol": "",
      "d": "38442",
      "w": "220.953",
      "wd": "39"
    },
    {
      "id": "71",
      "grp": "End of Life",
      "n": "End of life - fuel oil low temperature boiler 120 - 400 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-330.2538213",
      "ODP": "1.10E-05",
      "AP": "-1.601709269",
      "EP": "-0.114026078",
      "POCP": "-0.211917851",
      "PEnr": "-5053.273689",
      "PE": "-170.6254225",
      "eol": "",
      "d": "38442",
      "w": "991.991",
      "wd": "39"
    },
    {
      "id": "72",
      "grp": "End of Life",
      "n": "End of life - fuel oil low temperature boiler 20 - 120 KW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-117.4978892",
      "ODP": "3.92E-06",
      "AP": "-0.569851308",
      "EP": "-0.040565974",
      "POCP": "-0.075396007",
      "PEnr": "-1797.82534",
      "PE": "-60.70436231",
      "eol": "",
      "d": "38442",
      "w": "353.998",
      "wd": "39"
    },
    {
      "id": "73",
      "grp": "End of Life",
      "n": "End of life - Holz-Flügelrahmen",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "2.518036325",
      "ODP": "-4.82E-08",
      "AP": "0.001775452",
      "EP": "0.000466163",
      "POCP": "7.16E-06",
      "PEnr": "-21.94456933",
      "PE": "-0.228837239",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "74",
      "grp": "End of Life",
      "n": "End of life - Multilayer parquet",
      "no": "3.3.2 Mehrschichtparkett.xml",
      "ref": "Mehrschichtparkett [Holzwerkstoffe] (m²)",
      "densB": "590",
      "u": "38",
      "GWP": "7.648241175",
      "ODP": "-1.47E-07",
      "AP": "0.005392728",
      "EP": "0.001415917",
      "POCP": "2.18E-05",
      "PEnr": "-66.65406571",
      "PE": "-0.69506638",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "75",
      "grp": "End of Life",
      "n": "End of life - pellet boiler < 20 KW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-234.1366794",
      "ODP": "1.01E-05",
      "AP": "-0.828055353",
      "EP": "-0.076877369",
      "POCP": "-0.132393956",
      "PEnr": "-3285.639459",
      "PE": "-2.975324198",
      "eol": "",
      "d": "38442",
      "w": "334.99",
      "wd": "39"
    },
    {
      "id": "76",
      "grp": "End of Life",
      "n": "End of life - pellet boiler 20 - 120 KW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-584.2971374",
      "ODP": "2.51E-05",
      "AP": "-2.06644673",
      "EP": "-0.191852373",
      "POCP": "-0.330392354",
      "PEnr": "-8199.499675",
      "PE": "-7.419934861",
      "eol": "",
      "d": "38442",
      "w": "833.483",
      "wd": "39"
    },
    {
      "id": "77",
      "grp": "End of Life",
      "n": "End of life - PEX-Alu-tube",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.987888882",
      "ODP": "-1.77E-07",
      "AP": "-0.011406544",
      "EP": "-0.000467442",
      "POCP": "-0.000641848",
      "PEnr": "-28.75462198",
      "PE": "-2.665426769",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "78",
      "grp": "End of Life",
      "n": "End of life - PEX-tube",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.987888882",
      "ODP": "-1.77E-07",
      "AP": "-0.011406544",
      "EP": "-0.000467442",
      "POCP": "-0.000641848",
      "PEnr": "-28.75462198",
      "PE": "-2.665426769",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "79",
      "grp": "End of Life",
      "n": "End of life - Polybutadien-tube (PB)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.987888882",
      "ODP": "-1.77E-07",
      "AP": "-0.011406544",
      "EP": "-0.000467442",
      "POCP": "-0.000641848",
      "PEnr": "-28.75462198",
      "PE": "-2.665426769",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "80",
      "grp": "End of Life",
      "n": "End of life - Polypropylen-tube (PP)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.987888882",
      "ODP": "-1.77E-07",
      "AP": "-0.011406544",
      "EP": "-0.000467442",
      "POCP": "-0.000641848",
      "PEnr": "-28.75462198",
      "PE": "-2.665426769",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "81",
      "grp": "End of Life",
      "n": "End of life - Polyurethan rigit foam (tubeisolierung)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.751788538",
      "ODP": "-1.21E-07",
      "AP": "-0.007473086",
      "EP": "-0.000271406",
      "POCP": "-0.000427543",
      "PEnr": "-19.38678379",
      "PE": "-1.838926231",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "82",
      "grp": "End of Life",
      "n": "End of life - Polyvinylchlorid-tube (PVC)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "1.578602854",
      "ODP": "-2.08E-08",
      "AP": "0.000290454",
      "EP": "-2.28E+09",
      "POCP": "-5.89E+09",
      "PEnr": "-2.228545522",
      "PE": "-0.775483716",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "83",
      "grp": "End of Life",
      "n": "End of life - radiator (1kg)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "-0.9237475",
      "ODP": "3.94E-08",
      "AP": "-0.003270633",
      "EP": "-0.000304924",
      "POCP": "-0.000522574",
      "PEnr": "-12.93107114",
      "PE": "-0.014685301",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "84",
      "grp": "End of Life",
      "n": "End of life - solar collector -  evacuated tube collector",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-32.35297199",
      "ODP": "-9.30E-07",
      "AP": "-0.295736011",
      "EP": "-0.007484083",
      "POCP": "-0.023144705",
      "PEnr": "-391.2819429",
      "PE": "-107.1803431",
      "eol": "",
      "d": "38442",
      "w": "29.504",
      "wd": "39"
    },
    {
      "id": "85",
      "grp": "End of Life",
      "n": "End of life - solar collector -  flat plate collector",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-56.40691327",
      "ODP": "-3.40E-06",
      "AP": "-0.328259174",
      "EP": "-0.010367474",
      "POCP": "-0.030539404",
      "PEnr": "-650.722655",
      "PE": "-212.6938929",
      "eol": "",
      "d": "38442",
      "w": "18.268",
      "wd": "39"
    },
    {
      "id": "86",
      "grp": "End of Life",
      "n": "End of life - stainless steel tube",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "-0.311774811",
      "ODP": "1.42E-08",
      "AP": "-0.001107359",
      "EP": "-0.00010218",
      "POCP": "-0.000178124",
      "PEnr": "-4.368660119",
      "PE": "-0.001337132",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "87",
      "grp": "End of Life",
      "n": "End of life - steeltube",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "-0.832519723",
      "ODP": "3.56E-08",
      "AP": "-0.002947163",
      "EP": "-0.000274467",
      "POCP": "-0.000471028",
      "PEnr": "-11.66021667",
      "PE": "-0.012465895",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "88",
      "grp": "End of Life",
      "n": "End of life - storage tank (stainless steel )",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "0.021283809",
      "ODP": "-3.65E-09",
      "AP": "-0.001669307",
      "EP": "-0.000116433",
      "POCP": "-0.000200081",
      "PEnr": "-5.937493061",
      "PE": "-0.294031885",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "89",
      "grp": "End of Life",
      "n": "End of life - storage tank (steel)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "-0.407462835",
      "ODP": "1.39E-08",
      "AP": "-0.003184078",
      "EP": "-0.000258282",
      "POCP": "-0.000441238",
      "PEnr": "-11.94087462",
      "PE": "-0.303194567",
      "eol": "",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "90",
      "grp": "End of Life",
      "n": "End of life - transfer station district heating",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-1.02558497",
      "ODP": "2.95E-08",
      "AP": "-0.010078321",
      "EP": "-0.000352893",
      "POCP": "-0.000860538",
      "PEnr": "-14.93314756",
      "PE": "-1.545375093",
      "eol": "",
      "d": "38442",
      "w": "1.002708758",
      "wd": "39"
    },
    {
      "id": "91",
      "grp": "End of Life",
      "n": "End of life - woodchip boiler < 20 KW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-400.3697835",
      "ODP": "1.73E-05",
      "AP": "-1.414717187",
      "EP": "-0.131025146",
      "POCP": "-0.226233734",
      "PEnr": "-5627.564556",
      "PE": "-4.399138233",
      "eol": "",
      "d": "38442",
      "w": "630.983",
      "wd": "39"
    },
    {
      "id": "92",
      "grp": "End of Life",
      "n": "End of life - woodchip boiler 120 - 400 KW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-2162.349738",
      "ODP": "9.34E-05",
      "AP": "-7.640749473",
      "EP": "-0.707665764",
      "POCP": "-1.221858072",
      "PEnr": "-30393.80452",
      "PE": "-23.75952028",
      "eol": "",
      "d": "38442",
      "w": "3398.993",
      "wd": "39"
    },
    {
      "id": "93",
      "grp": "End of Life",
      "n": "End of life - woodchip boiler 20 - 120 KW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "-584.9683786",
      "ODP": "2.53E-05",
      "AP": "-2.067004269",
      "EP": "-0.191438448",
      "POCP": "-0.330543008",
      "PEnr": "-8222.266893",
      "PE": "-6.42746145",
      "eol": "",
      "d": "38442",
      "w": "920.983",
      "wd": "39"
    },
    {
      "id": "94",
      "grp": "End of Life",
      "n": "End of life Laminate",
      "no": "3.3.1 Laminat.xml",
      "ref": "Laminatfußboden (Egger)",
      "densB": "880",
      "u": "38",
      "GWP": "7.248179329",
      "ODP": "-1.39E-07",
      "AP": "0.005110647",
      "EP": "0.001341853",
      "POCP": "2.06E-05",
      "PEnr": "-63.16754535",
      "PE": "-0.658709062",
      "eol": "",
      "d": "2008 / modified",
      "w": "",
      "wd": ""
    },
    {
      "id": "95",
      "grp": "Heat generator",
      "n": "electric heat pump (brine-water, Earth-tube collector) 10 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "5921.006028",
      "ODP": "0.000733114",
      "AP": "13.05682883",
      "EP": "1.304297818",
      "POCP": "2.686727764",
      "PEnr": "195399.2365",
      "PE": "1834.498877",
      "eol": "End of life - electric heat pump (brine-water, Earth-tube collector) 70 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "96",
      "grp": "Heat generator",
      "n": "electric heat pump (brine-water, Earth-tube collector) 20 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "11740.04926",
      "ODP": "0.001117056",
      "AP": "25.65173568",
      "EP": "2.558948476",
      "POCP": "5.354516791",
      "PEnr": "393240.86",
      "PE": "3524.952792",
      "eol": "End of life - electric heat pump (brine-water, Earth-tube collector) 70 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "97",
      "grp": "Heat generator",
      "n": "electric heat pump (brine-water, Earth-tube collector) 70 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "41136.76971",
      "ODP": "0.003930654",
      "AP": "89.55505949",
      "EP": "8.935742785",
      "POCP": "18.78266474",
      "PEnr": "1386520.093",
      "PE": "12212.98541",
      "eol": "End of life - electric heat pump (brine-water, Earth-tube collector) 70 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "98",
      "grp": "Heat generator",
      "n": "electric heat pump (brine-water, geothermal probe) 10 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "2503.74729",
      "ODP": "0.000469703",
      "AP": "5.774189337",
      "EP": "0.950260318",
      "POCP": "1.289421473",
      "PEnr": "48335.87867",
      "PE": "875.0678025",
      "eol": "End of life - electric heat pump (brine-water, geothermal probe) 20 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "99",
      "grp": "Heat generator",
      "n": "electric heat pump (brine-water, geothermal probe) 20 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "6866.640831",
      "ODP": "0.00068115",
      "AP": "15.44562343",
      "EP": "2.662419869",
      "POCP": "3.632374176",
      "PEnr": "134625.2546",
      "PE": "2207.18092",
      "eol": "End of life - electric heat pump (brine-water, geothermal probe) 20 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "100",
      "grp": "Heat generator",
      "n": "electric heat pump (brine-water, geothermal probe) 70 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "23099.28569",
      "ODP": "0.002359527",
      "AP": "51.65408323",
      "EP": "8.892119464",
      "POCP": "12.21893061",
      "PEnr": "463609.9189",
      "PE": "7300.23872",
      "eol": "End of life - electric heat pump (brine-water, geothermal probe) 20 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "101",
      "grp": "Heat generator",
      "n": "electric heat pump (water-water) 10 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "789.1253354",
      "ODP": "0.000385498",
      "AP": "2.076438219",
      "EP": "0.198365443",
      "POCP": "0.311266973",
      "PEnr": "16218.10323",
      "PE": "405.7171909",
      "eol": "End of life - electric heat pump (water-water) 10 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "102",
      "grp": "Heat generator",
      "n": "electric heat pump (water-water) 20 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1229.800774",
      "ODP": "0.000415113",
      "AP": "3.029538865",
      "EP": "0.28743221",
      "POCP": "0.509279742",
      "PEnr": "31485.25841",
      "PE": "535.6497537",
      "eol": "End of life - electric heat pump (water-water) 20 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "103",
      "grp": "Heat generator",
      "n": "electric heat pump (water-water) 70 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "3783.575859",
      "ODP": "0.001460578",
      "AP": "8.872963891",
      "EP": "0.848279918",
      "POCP": "1.603191004",
      "PEnr": "112611.7335",
      "PE": "1466.493821",
      "eol": "End of life - electric heat pump (water-water) 70 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "104",
      "grp": "Heat generator",
      "n": "fuel gas heat pump (air) 20-70 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "421.6537598",
      "ODP": "0.000802669",
      "AP": "1.221642979",
      "EP": "0.185236698",
      "POCP": "0.153091862",
      "PEnr": "5737.379532",
      "PE": "227.8166908",
      "eol": "End of life - fuel gas heat pump (air) 20-70 kW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "105",
      "grp": "Heat generator",
      "n": "fuel gas-condensing boiler < 20 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "535.2129544",
      "ODP": "0.0000394",
      "AP": "2.360805268",
      "EP": "0.424831183",
      "POCP": "0.202360515",
      "PEnr": "7839.067155",
      "PE": "667.6945639",
      "eol": "End of life - fuel gas condensing boiler <20 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "106",
      "grp": "Heat generator",
      "n": "fuel gas-condensing boiler < 20 kW (wall-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "173.0387747",
      "ODP": "0.0000127",
      "AP": "0.763267869",
      "EP": "0.137351435",
      "POCP": "0.065424828",
      "PEnr": "2534.435246",
      "PE": "215.8711748",
      "eol": "End of life - fuel gas condensing boiler <20 KW (wall-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "107",
      "grp": "Heat generator",
      "n": "fuel gas-condensing boiler 120-400 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "3263.785158",
      "ODP": "0.000195707",
      "AP": "12.84830837",
      "EP": "2.964776372",
      "POCP": "1.20624558",
      "PEnr": "46704.45843",
      "PE": "4287.060593",
      "eol": "End of life - fuel gas condensing boiler 120 - 400 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "108",
      "grp": "Heat generator",
      "n": "fuel gas-condensing boiler 20-120 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1033.979179",
      "ODP": "6900814515",
      "AP": "4.313372873",
      "EP": "0.880534871",
      "POCP": "0.386501624",
      "PEnr": "14968.63452",
      "PE": "1324.351079",
      "eol": "End of life - fuel gas condensing boiler 20 - 120 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "109",
      "grp": "Heat generator",
      "n": "fuel gas-low temperature boiler < 20 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "297.8816898",
      "ODP": "0.0000165",
      "AP": "0.81958363",
      "EP": "0.070950892",
      "POCP": "0.085160518",
      "PEnr": "3659.21809",
      "PE": "174.9338474",
      "eol": "End of life - fuel gas low temperature boiler < 20 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "110",
      "grp": "Heat generator",
      "n": "fuel gas-low temperature boiler 120-400 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "2142.743071",
      "ODP": "0.0000997",
      "AP": "4.998013969",
      "EP": "0.505483093",
      "POCP": "0.58200974",
      "PEnr": "25410.07464",
      "PE": "1176.62074",
      "eol": "End of life - fuel gas low temperature boiler 120 - 400 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "111",
      "grp": "Heat generator",
      "n": "fuel gas-low temperature boiler 20-120 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "737.1309612",
      "ODP": "0.0000408",
      "AP": "2.028122203",
      "EP": "0.175573393",
      "POCP": "0.210736196",
      "PEnr": "9055.014258",
      "PE": "432.8871477",
      "eol": "End of life - fuel gas low temperature boiler 20 - 120 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "112",
      "grp": "Heat generator",
      "n": "fuel oil-condensing boiler < 20 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "643.5496543",
      "ODP": "0.0000468",
      "AP": "2.81894204",
      "EP": "0.515593066",
      "POCP": "0.242967951",
      "PEnr": "9411.827005",
      "PE": "805.593269",
      "eol": "End of life - fuel oil condensing boiler <20 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "113",
      "grp": "Heat generator",
      "n": "fuel oil-condensing boiler < 20 kW (wall-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "342.0533919",
      "ODP": "0.0000252",
      "AP": "1.508785322",
      "EP": "0.271508651",
      "POCP": "0.129328148",
      "PEnr": "5009.930137",
      "PE": "426.7220897",
      "eol": "End of life - fuel oil condensing boiler <20 KW (wall-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "114",
      "grp": "Heat generator",
      "n": "fuel oil-condensing boiler 120-400 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "4974.076499",
      "ODP": "0.000292886",
      "AP": "19.39471932",
      "EP": "4.56341779",
      "POCP": "1.835000279",
      "PEnr": "71046.26455",
      "PE": "6559.498857",
      "eol": "End of life - fuel oil condensing boiler 120 - 400 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "115",
      "grp": "Heat generator",
      "n": "fuel oil-condensing boiler 20-120 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1537.370643",
      "ODP": "0.0000976",
      "AP": "6.24018342",
      "EP": "1.351064321",
      "POCP": "0.571563515",
      "PEnr": "22133.17845",
      "PE": "1993.199712",
      "eol": "End of life - fuel oil condensing boiler 20 - 120 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "116",
      "grp": "Heat generator",
      "n": "fuel oil-low temperature boiler < 20 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "553.7801709",
      "ODP": "0.00003",
      "AP": "1.576720453",
      "EP": "0.184765651",
      "POCP": "0.160483641",
      "PEnr": "6830.962019",
      "PE": "376.5579881",
      "eol": "End of life - fuel oil low temperature boiler <20KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "117",
      "grp": "Heat generator",
      "n": "fuel oil-low temperature boiler 120-400 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "2303.926986",
      "ODP": "0.000108984",
      "AP": "5.845162284",
      "EP": "0.78277012",
      "POCP": "0.643450388",
      "PEnr": "27684.65167",
      "PE": "1517.444502",
      "eol": "End of life - fuel oil low temperature boiler 120 - 400 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "118",
      "grp": "Heat generator",
      "n": "fuel oil-low temperature boiler 20-120 kW (floor-mounted appliance)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "887.0505905",
      "ODP": "0.000048",
      "AP": "2.525606517",
      "EP": "0.29595946",
      "POCP": "0.257064294",
      "PEnr": "10941.90296",
      "PE": "603.1743339",
      "eol": "End of life - fuel oil low temperature boiler 20 - 120 KW (floor-mounted appliance)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "119",
      "grp": "Heat generator",
      "n": "pellet boiler < 20 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "790.3692433",
      "ODP": "0.0000322",
      "AP": "2.87125759",
      "EP": "0.408342389",
      "POCP": "0.317256886",
      "PEnr": "10992.99495",
      "PE": "530.3739712",
      "eol": "End of life - pellet boiler < 20 KW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "120",
      "grp": "Heat generator",
      "n": "pellet boiler 20-120 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1782.086711",
      "ODP": "0.0000542",
      "AP": "5.905012023",
      "EP": "0.96968847",
      "POCP": "0.71222138",
      "PEnr": "24347.27204",
      "PE": "1144.721144",
      "eol": "End of life - pellet boiler 20 - 120 KW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "121",
      "grp": "Heat generator",
      "n": "solar collector -  evacuated tube collector",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "122.5238721",
      "ODP": "0.0000118",
      "AP": "1.046013962",
      "EP": "0.061042823",
      "POCP": "0.063785067",
      "PEnr": "1705.785653",
      "PE": "189.405173",
      "eol": "End of life - solar collector -  evacuated tube collector",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "122",
      "grp": "Heat generator",
      "n": "solar collector -  flat plate collector",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "120.5519748",
      "ODP": "0.00001027",
      "AP": "0.802650191",
      "EP": "0.027535464",
      "POCP": "0.059955934",
      "PEnr": "1557.314629",
      "PE": "310.2038767",
      "eol": "End of life - solar collector -  flat plate collector",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "123",
      "grp": "Heat generator",
      "n": "transfer station district heating",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "4.2987232",
      "ODP": "0.0000299",
      "AP": "0.017450696",
      "EP": "0.002600137",
      "POCP": "0.001541557",
      "PEnr": "62.64780762",
      "PE": "4.334846677",
      "eol": "End of life - transfer station district heating",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "124",
      "grp": "Heat generator",
      "n": "woodchip boiler < 20 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1162.363948",
      "ODP": "0.0000315",
      "AP": "3.575934926",
      "EP": "0.290724055",
      "POCP": "0.469735834",
      "PEnr": "16077.84977",
      "PE": "587.6503402",
      "eol": "End of life - woodchip boiler < 20 KW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "125",
      "grp": "Heat generator",
      "n": "woodchip boiler 120-400 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "5717.661367",
      "ODP": "0.0000934",
      "AP": "15.60422339",
      "EP": "1.42494861",
      "POCP": "2.302985507",
      "PEnr": "77751.50567",
      "PE": "2647.50046",
      "eol": "End of life - woodchip boiler 120 - 400 KW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "126",
      "grp": "Heat generator",
      "n": "woodchip boiler 20-120 kW",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1639.6145",
      "ODP": "0.0000379",
      "AP": "4.836117694",
      "EP": "0.40955478",
      "POCP": "0.661802199",
      "PEnr": "22539.29095",
      "PE": "803.4572385",
      "eol": "End of life - woodchip boiler 20 - 120 KW",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "127",
      "grp": "Joint tape",
      "n": "7.3.4 Fugendichtungsbänder Butyl",
      "no": "",
      "ref": "",
      "densB": "1200",
      "u": "39",
      "GWP": "5.576454565",
      "ODP": "2.02E-07",
      "AP": "0.009132981",
      "EP": "0.000885949",
      "POCP": "0.001191172",
      "PEnr": "129.019062",
      "PE": "1.006082858",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "128",
      "grp": "Joint tape",
      "n": "7.3.4 Fugendichtungsbänder Gewebebänder",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "5.788398392",
      "ODP": "6.63E-07",
      "AP": "0.035685614",
      "EP": "0.012255229",
      "POCP": "0.004153617",
      "PEnr": "141.273791",
      "PE": "38.90205127",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "129",
      "grp": "Joint tape",
      "n": "7.3.4 Fugendichtungsbänder PE/PP-Folie",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.514171712",
      "ODP": "1.15E-07",
      "AP": "0.004994835",
      "EP": "0.000475462",
      "POCP": "0.001061121",
      "PEnr": "86.99421932",
      "PE": "0.578267977",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "130",
      "grp": "Joint tape",
      "n": "7.3.4 Fugendichtungsbänder Polyisobutylen",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "6.721625035",
      "ODP": "2.39E-07",
      "AP": "0.012562896",
      "EP": "0.00266266",
      "POCP": "0.002748516",
      "PEnr": "139.525488",
      "PE": "1.162407053",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "131",
      "grp": "Joint tape",
      "n": "7.3.5 Elastomer Fugenbänder, Polysulfid",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "9.854874891",
      "ODP": "4.78E-07",
      "AP": "0.021730542",
      "EP": "0.001834711",
      "POCP": "0.002889274",
      "PEnr": "202.182769",
      "PE": "2.322069925",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "132",
      "grp": "Joint tape",
      "n": "7.3.5 Elastomer Fugenbänder, Polyurethan",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "13.03884139",
      "ODP": "5.82E-05",
      "AP": "0.02810164",
      "EP": "0.003771193",
      "POCP": "0.003438526",
      "PEnr": "209.7346631",
      "PE": "4.419465945",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "133",
      "grp": "Joint tape",
      "n": "7.3.5 Elastomer Fugenbänder, Silikonkautschuk",
      "no": "",
      "ref": "",
      "densB": "1100",
      "u": "39",
      "GWP": "7.094227121",
      "ODP": "9.46E-07",
      "AP": "0.023468315",
      "EP": "0.001957982",
      "POCP": "0.001848771",
      "PEnr": "129.9971066",
      "PE": "31.38226025",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "134",
      "grp": "Metal",
      "n": "4.1.2 Reinforcement steel",
      "no": "4.1.2 Bewehrungssteel.xml",
      "ref": "Bewehrungssteel",
      "densB": "7850",
      "u": "39",
      "GWP": "0.794870524",
      "ODP": "7.14E-08",
      "AP": "0.001493046",
      "EP": "0.000125969",
      "POCP": "0.000249092",
      "PEnr": "11.29278036",
      "PE": "0.895809673",
      "eol": "4.8. Recyclingpotential reinforcement steel (secondary production)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "135",
      "grp": "Metal",
      "n": "4.1.3 steel, hot rolled profile (I/U/T/H/L)",
      "no": "4.1.3 steel,_warmgewalzte_Profile_(I_U_T_H_L).xml",
      "ref": "steelprofil (warmgewalzt)",
      "densB": "7850",
      "u": "39",
      "GWP": "1.74584391",
      "ODP": "6.69E-09",
      "AP": "0.00481308",
      "EP": "0.000455524",
      "POCP": "0.000762522",
      "PEnr": "23.55699979",
      "PE": "0.779917163",
      "eol": "4.8 Recyclingpotential steel profile, hot rolled",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "136",
      "grp": "Metal",
      "n": "4.1.4 Steel sheet (0,3-3,0mm)",
      "no": "4.1.4 steel_Feinblech_(0,3-3,0mm).xml",
      "ref": "steel Feinblech",
      "densB": "7850",
      "u": "39",
      "GWP": "1.84051252",
      "ODP": "1.21E-08",
      "AP": "0.005012829",
      "EP": "0.00046973",
      "POCP": "0.00079177",
      "PEnr": "24.42417747",
      "PE": "0.822320826",
      "eol": "4.8 Recyclingpotential steel sheet",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "137",
      "grp": "Metal",
      "n": "4.1.4 Steel sheet (20µm zinced)",
      "no": "4.1.4 steel_Feinblech_(20µm_bandverzinkt).xml",
      "ref": "steelblech 0,75mm BVZ (0,02mm 2s)",
      "densB": "7850",
      "u": "39",
      "GWP": "2.141744231",
      "ODP": "4.65E-08",
      "AP": "0.006080924",
      "EP": "0.000530446",
      "POCP": "0.000868762",
      "PEnr": "29.44263485",
      "PE": "1.201639303",
      "eol": "4.8 Recyclingpotential steel sheet, zinced",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "138",
      "grp": "Metal",
      "n": "4.1.4 steel, hot rolled sheet (2-20mm)",
      "no": "4.1.4 steel,_warmgewalzte_Bleche_(2-20mm).xml",
      "ref": "steel Warmband (St)",
      "densB": "7850",
      "u": "39",
      "GWP": "1.74584391",
      "ODP": "6.69E-09",
      "AP": "0.00481308",
      "EP": "0.000455524",
      "POCP": "0.000762522",
      "PEnr": "23.55699979",
      "PE": "0.779917163",
      "eol": "4.8 Recyclingpotential steel sheet, hot rolled strip",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "139",
      "grp": "Metal",
      "n": "4.3.1 Aluminium sheet",
      "no": "4.3.1 Aluminium-Blech.xml",
      "ref": "Aluminium Blech[Metallische Werkstoffe] (kg)",
      "densB": "2700",
      "u": "39",
      "GWP": "12.26026828",
      "ODP": "8.45E-07",
      "AP": "0.055067393",
      "EP": "0.002086065",
      "POCP": "0.005595794",
      "PEnr": "146.2535134",
      "PE": "49.18302451",
      "eol": "4.8 Recyclingpotential steel sheet, hot rolled strip",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "140",
      "grp": "Metal",
      "n": "4.3.2 Aluminium profile",
      "no": "4.3.2 Aluminium Extrusionsprofil.xml",
      "ref": "Aluminium-Extrusionsprofil [Metallische Werkstoffe] (kg)",
      "densB": "2700",
      "u": "39",
      "GWP": "12.60190847",
      "ODP": "8.95E-07",
      "AP": "0.055883852",
      "EP": "0.002138997",
      "POCP": "0.005614189",
      "PEnr": "151.7860008",
      "PE": "49.82461661",
      "eol": "4.8 Recyclingpotential Aluminium sheet",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "141",
      "grp": "Metal",
      "n": "4.4.1 Copper sheet",
      "no": "4.4.1 copperblech.xml",
      "ref": "copperblech [Metallische Werkstoffe] (kg)",
      "densB": "8920",
      "u": "39",
      "GWP": "0.879117531",
      "ODP": "8.62E-08",
      "AP": "0.002740693",
      "EP": "0.000271522",
      "POCP": "0.000211494",
      "PEnr": "11.78961057",
      "PE": "0.848529907",
      "eol": "4.8 Recyclingpotential Aluminium sheet",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "142",
      "grp": "Metal",
      "n": "4.5.1 Zinc sheet",
      "no": "4.5.1 Titanzinkblech.xml",
      "ref": "Titanzinkblech [Metallische Werkstoffe] (kg)",
      "densB": "7140",
      "u": "26",
      "GWP": "0.96",
      "ODP": "0.000000176",
      "AP": "0.00332",
      "EP": "0.000277",
      "POCP": "0.000294",
      "PEnr": "16.3",
      "PE": "0.9",
      "eol": "4.8 Recyclingpotential Copper sheet",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "143",
      "grp": "Metal",
      "n": "4.6.1 Lead sheet",
      "no": "4.6.1 Bleibleche.xml",
      "ref": "Blei (Blech)",
      "densB": "11340",
      "u": "39",
      "GWP": "1.18209624",
      "ODP": "9.12E-08",
      "AP": "0.011905482",
      "EP": "0.000218591",
      "POCP": "0.001075349",
      "PEnr": "19.12996125",
      "PE": "0.638810693",
      "eol": "",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "144",
      "grp": "Mineral building materials",
      "n": "1.1.1 Cement",
      "no": "1.1.1 Zement.xml",
      "ref": "Zement (kg)",
      "densB": "3050",
      "u": "39",
      "GWP": "0.6702",
      "ODP": "1.63E-08",
      "AP": "0.000924",
      "EP": "0.000122",
      "POCP": "0.000102",
      "PEnr": "2.713",
      "PE": "0.0549",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "145",
      "grp": "Mineral building materials",
      "n": "1.1.3 Gypsum",
      "no": "1.1.3 Gips_(CaSO4-Alpha-Halbhydrat).xml",
      "ref": "Gips (Alpha-Halbhydrat)",
      "densB": "1225",
      "u": "39",
      "GWP": "0.243296139",
      "ODP": "5.79E-09",
      "AP": "0.000299642",
      "EP": "3.24E-05",
      "POCP": "3.04E-05",
      "PEnr": "3.559262558",
      "PE": "0.029349115",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "146",
      "grp": "Mineral building materials",
      "n": "1.1.4 Loamstone",
      "no": "1.1.4 Lehmstein.xml",
      "ref": "Lehmstein",
      "densB": "1200",
      "u": "39",
      "GWP": "0.059656493",
      "ODP": "1.34E-09",
      "AP": "5.92E-05",
      "EP": "7.18E-06",
      "POCP": "6.46E-06",
      "PEnr": "1.008591369",
      "PE": "0.006396302",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "147",
      "grp": "Mineral building materials",
      "n": "1.1.4 Tamped loam wall",
      "no": "1.1.4 Stampflehmwand.xml",
      "ref": "Stampflehmwand",
      "densB": "2000",
      "u": "39",
      "GWP": "0.003583627",
      "ODP": "6.82E-12",
      "AP": "6.10E-06",
      "EP": "8.38E-07",
      "POCP": "1.58E-06",
      "PEnr": "0.048772801",
      "PE": "5.79E-05",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "148",
      "grp": "Mineral building materials",
      "n": "1.2.1 Gravel 2/32",
      "no": "1.2.1 Kies_2_32.xml",
      "ref": "Zuschläge",
      "densB": "2000",
      "u": "39",
      "GWP": "0.003914692",
      "ODP": "5.11E-10",
      "AP": "7.91E-06",
      "EP": "8.31E-07",
      "POCP": "6.94E-07",
      "PEnr": "0.059993165",
      "PE": "0.002427863",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "149",
      "grp": "Mineral building materials",
      "n": "1.2.1. Sand 0/2",
      "no": "1.2.1 Sand_0_2.xml",
      "ref": "Zuschläge",
      "densB": "2100",
      "u": "39",
      "GWP": "0.00279384",
      "ODP": "3.17E-10",
      "AP": "7.18E-06",
      "EP": "8.72E-07",
      "POCP": "6.75E-07",
      "PEnr": "0.04250111",
      "PE": "0.001504286",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "150",
      "grp": "Mineral building materials",
      "n": "1.3.02 Masonary (average)",
      "no": "1.3.02 Mauerziegel_Durchschnitt.xml",
      "ref": "Mauerziegel (porosiert)",
      "densB": "740",
      "u": "39",
      "GWP": "0.126373554",
      "ODP": "2.06E-09",
      "AP": "0.000228266",
      "EP": "2.71E-05",
      "POCP": "2.00E-05",
      "PEnr": "1.595856229",
      "PE": "0.271523446",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "151",
      "grp": "Mineral building materials",
      "n": "1.3.03 Aerated concrete P4 05 (reinforced)",
      "no": "1.3.03 PorenConcrete_P4_05_bewehrt.xml",
      "ref": "PorenConcrete",
      "densB": "474",
      "u": "39",
      "GWP": "0.520602535",
      "ODP": "2.45E-08",
      "AP": "0.000710247",
      "EP": "0.000115434",
      "POCP": "8.02E-05",
      "PEnr": "4.385200528",
      "PE": "0.127405507",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "152",
      "grp": "Mineral building materials",
      "n": "1.3.04 Expanded clay stone (for outer walls)",
      "no": "1.3.04 Blaehton_LB_Planstein_Aussenwand.xml",
      "ref": "Blähton (Steine/Elemente)",
      "densB": "500",
      "u": "39",
      "GWP": "0.290962",
      "ODP": "1.08E-08",
      "AP": "0.001303562",
      "EP": "9.74E-05",
      "POCP": "0.0001167",
      "PEnr": "3.414776642",
      "PE": "0.357971965",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "153",
      "grp": "Mineral building materials",
      "n": "1.3.04 Pumice stone (for outer walls)",
      "no": "1.3.04 Bims_LB_Planstein_Aussenwand.xml",
      "ref": "Bimsstein",
      "densB": "500",
      "u": "39",
      "GWP": "0.167683844",
      "ODP": "4.48E-09",
      "AP": "0.00056642",
      "EP": "6.09E-05",
      "POCP": "5.72E-05",
      "PEnr": "1.488470458",
      "PE": "0.11485744",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "154",
      "grp": "Mineral building materials",
      "n": "1.3.05 Concrete masonry",
      "no": "1.3.05 Concrete-Mauersteine.xml",
      "ref": "Concretemauersteine",
      "densB": "2000",
      "u": "39",
      "GWP": "0.126664651",
      "ODP": "3.46E-09",
      "AP": "0.000256454",
      "EP": "3.65E-05",
      "POCP": "2.06E-05",
      "PEnr": "0.737212041",
      "PE": "0.017356048",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "155",
      "grp": "Mineral building materials",
      "n": "1.3.08 Natural stone board, hard, facade",
      "no": "1.3.08 Natural stone board,_hard,_facade.xml",
      "ref": "Naturstein, bearbeitet",
      "densB": "2600",
      "u": "39",
      "GWP": "0.681793008",
      "ODP": "4.58E-08",
      "AP": "0.009727519",
      "EP": "0.000626484",
      "POCP": "0.000539446",
      "PEnr": "9.160893337",
      "PE": "0.539130367",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "156",
      "grp": "Mineral building materials",
      "n": "1.3.08 Natural stone board, hard, inside flooring",
      "no": "1.3.08 Natural stone board,_hard,_inside flooring.xml",
      "ref": "Naturstein, bearbeitet",
      "densB": "2600",
      "u": "39",
      "GWP": "0.933531432",
      "ODP": "6.60E-08",
      "AP": "0.012346019",
      "EP": "0.000736839",
      "POCP": "0.000681946",
      "PEnr": "12.41880605",
      "PE": "0.790937241",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "157",
      "grp": "Mineral building materials",
      "n": "1.3.08 Natural stone board, hard, outside flooring",
      "no": "1.3.08 Natural stone board,_hard,_Aussenbereich.xml",
      "ref": "Naturstein, gebrochen",
      "densB": "2600",
      "u": "39",
      "GWP": "0.18090132",
      "ODP": "5.57E-09",
      "AP": "0.00450995",
      "EP": "0.000406592",
      "POCP": "0.000255776",
      "PEnr": "2.676228459",
      "PE": "0.037609337",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "158",
      "grp": "Mineral building materials",
      "n": "1.3.08 Natural stone board, soft, facade",
      "no": "1.3.08 Natural stone board,_soft,_facade.xml",
      "ref": "Naturstein, bearbeitet",
      "densB": "2600",
      "u": "39",
      "GWP": "0.347177965",
      "ODP": "1.90E-08",
      "AP": "0.006246964",
      "EP": "0.000479798",
      "POCP": "0.000350032",
      "PEnr": "4.830419719",
      "PE": "0.20442434",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "159",
      "grp": "Mineral building materials",
      "n": "1.3.08 Natural stone board, soft, inside flooring",
      "no": "1.3.08 Natural stone board,_soft,_inside flooring.xml",
      "ref": "Naturstein, bearbeitet",
      "densB": "2600",
      "u": "39",
      "GWP": "0.51603977",
      "ODP": "3.25E-08",
      "AP": "0.008003409",
      "EP": "0.000553823",
      "POCP": "0.000445619",
      "PEnr": "7.015771535",
      "PE": "0.37333206",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "160",
      "grp": "Mineral building materials",
      "n": "1.3.09 Limestone",
      "no": "1.3.1 Kalksandstein.xml",
      "ref": "Kalksandstein [Mineralische Werkstoffe] (kg)",
      "densB": "1800",
      "u": "39",
      "GWP": "0.145",
      "ODP": "2.05E-09",
      "AP": "0.000127",
      "EP": "0.0000182",
      "POCP": "0.0000128",
      "PEnr": "1.121",
      "PE": "0.067",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "161",
      "grp": "Mineral building materials",
      "n": "1.3.10 Roof tile",
      "no": "1.3.10 Dachziegel.xml",
      "ref": "Dachziegel",
      "densB": "1800",
      "u": "39",
      "GWP": "0.385612736",
      "ODP": "9.71E-09",
      "AP": "0.00096984",
      "EP": "0.000105977",
      "POCP": "0.000127105",
      "PEnr": "3.901792351",
      "PE": "0.046608373",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "162",
      "grp": "Mineral building materials",
      "n": "1.3.11 Concrete roof tile",
      "no": "1.3.11 Concretedachsteine.xml",
      "ref": "Concrete Dachstein",
      "densB": "2100",
      "u": "39",
      "GWP": "0.254869759",
      "ODP": "9.95E-09",
      "AP": "0.000566658",
      "EP": "6.22E-05",
      "POCP": "5.67E-05",
      "PEnr": "2.345120769",
      "PE": "0.029461469",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "163",
      "grp": "Mineral building materials",
      "n": "1.3.13 Gypsumboard",
      "no": "1.3.13 Gipskartonplatte.xml",
      "ref": "Gipskartonplatte",
      "densB": "800",
      "u": "39",
      "GWP": "0.201289516",
      "ODP": "6.73E-09",
      "AP": "0.00029798",
      "EP": "7.50E-05",
      "POCP": "2.69E-05",
      "PEnr": "3.287060596",
      "PE": "0.13046468",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "164",
      "grp": "Mineral building materials",
      "n": "1.4.1 Concrete C20/25",
      "no": "1.4.1 ConcreteC2025.xml",
      "ref": "Concrete C20/25 [m3]",
      "densB": "2365",
      "u": "26",
      "GWP": "196.3",
      "ODP": "0.00000533",
      "AP": "0.356",
      "EP": "0.0501",
      "POCP": "0.0362",
      "PEnr": "1024",
      "PE": "19.3",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "165",
      "grp": "Mineral building materials",
      "n": "1.4.1 Concrete C25/30",
      "no": "1.4.1 ConcreteC2530.xml",
      "ref": "Concrete C25/30 [m3]",
      "densB": "2365",
      "u": "26",
      "GWP": "216.5",
      "ODP": "0.0000058",
      "AP": "0.385",
      "EP": "0.054",
      "POCP": "0.0394",
      "PEnr": "1108",
      "PE": "20.9",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "166",
      "grp": "Mineral building materials",
      "n": "1.4.3 Screed (cement)",
      "no": "1.4.3 Zementestrich.xml",
      "ref": "Estrich (unspezifisch)",
      "densB": "1900",
      "u": "39",
      "GWP": "0.162569769",
      "ODP": "4.50E-09",
      "AP": "0.000331101",
      "EP": "5.10E-05",
      "POCP": "3.87E-05",
      "PEnr": "1.417143217",
      "PE": "0.022609588",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "167",
      "grp": "Mineral building materials",
      "n": "1.4.3 Screed (gypsum)",
      "no": "1.4.3 Calciumsulfatestrich_(Anhydritfliessestrich).xml",
      "ref": "Calciumsulfatestrich",
      "densB": "",
      "u": "39",
      "GWP": "0.088205254",
      "ODP": "3.32E-09",
      "AP": "0.000207092",
      "EP": "3.65E-05",
      "POCP": "2.08E-05",
      "PEnr": "1.261131569",
      "PE": "0.032057694",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "168",
      "grp": "Mineral building materials",
      "n": "1.4.4 Plaster (gypsum) 1kg",
      "no": "1.4.4 Gipsputz_(Gips).xml",
      "ref": "Putz (Gipsputz)",
      "densB": "1000",
      "u": "39",
      "GWP": "0.137741785",
      "ODP": "7.34E-09",
      "AP": "0.000185665",
      "EP": "2.02E-05",
      "POCP": "1.74E-05",
      "PEnr": "2.120609315",
      "PE": "0.04524838",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "169",
      "grp": "Mineral building materials",
      "n": "1.4.4 Plaster (lime-gypsum) 1kg",
      "no": "1.4.4 Kalk-Gips-Innenputz.xml",
      "ref": "Mörtel",
      "densB": "1200",
      "u": "39",
      "GWP": "0.20602793",
      "ODP": "5.43E-09",
      "AP": "0.000260269",
      "EP": "3.85E-05",
      "POCP": "2.47E-05",
      "PEnr": "2.119274724",
      "PE": "0.057589769",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "170",
      "grp": "Mineral building materials",
      "n": "1.4.4 Plaster (Loam) 1kg",
      "no": "1.4.4 Lehmputz.xml",
      "ref": "Mörtel",
      "densB": "1600",
      "u": "39",
      "GWP": "0.092411681",
      "ODP": "2.80E-09",
      "AP": "0.000189255",
      "EP": "3.02E-05",
      "POCP": "1.65E-05",
      "PEnr": "0.929605526",
      "PE": "0.063271465",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "171",
      "grp": "Mineral building materials",
      "n": "1.4.4 Synthetic resin plaster",
      "no": "1.4.4 Kunstharzputz.xml",
      "ref": "Kunstharzputz [Mineralische Werkstoffe] (kg)",
      "densB": "1400",
      "u": "39",
      "GWP": "0.79433",
      "ODP": "4.48E-08",
      "AP": "0.006651",
      "EP": "0.00021703",
      "POCP": "0.0023172",
      "PEnr": "17.519",
      "PE": "0.43635",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "172",
      "grp": "Mineral building materials",
      "n": "1.5 Asphalt (base layer)",
      "no": "1.5 Asphalttragschicht.xml",
      "ref": "Asphalttragschicht, eingebaut",
      "densB": "2350",
      "u": "39",
      "GWP": "0.061976736",
      "ODP": "1.23E-09",
      "AP": "0.00017032",
      "EP": "1.83E-05",
      "POCP": "0.000150753",
      "PEnr": "2.540747615",
      "PE": "0.007064276",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "173",
      "grp": "Mineral building materials",
      "n": "1.5 Asphalt (covering layer)",
      "no": "1.5 Asphaltdeckschicht.xml",
      "ref": "Asphaltdeckschicht, eingebaut",
      "densB": "2350",
      "u": "39",
      "GWP": "0.068784229",
      "ODP": "1.34E-09",
      "AP": "0.00021",
      "EP": "2.14E-05",
      "POCP": "0.000156348",
      "PEnr": "3.508107059",
      "PE": "0.008082982",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "174",
      "grp": "Mineral building materials",
      "n": "1.5 Mastic asphalt",
      "no": "1.5 Gussasphalt.xml",
      "ref": "Gussasphalt, eingebaut",
      "densB": "2400",
      "u": "39",
      "GWP": "0.084633193",
      "ODP": "1.58E-09",
      "AP": "0.000238764",
      "EP": "2.40E-05",
      "POCP": "0.000159844",
      "PEnr": "3.997630814",
      "PE": "0.009449445",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "175",
      "grp": "Mineral building materials",
      "n": "2.01 Rock wool (facade-Insulation)",
      "no": "2.01 Mineralwolle_(facaden-Daemmung).xml",
      "ref": "Mineralwollplatte Volumen",
      "densB": "46",
      "u": "26",
      "GWP": "65.57647109",
      "ODP": "2.80E-06",
      "AP": "0.364818392",
      "EP": "0.045793008",
      "POCP": "0.019673947",
      "PEnr": "908.3168014",
      "PE": "32.63999145",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "176",
      "grp": "Mineral building materials",
      "n": "2.20 Calcium silicate-insulation",
      "no": "2.20 Calciumsilikat-Dämmplatte.xml",
      "ref": "Calciumsilikatplatte [Mineralische Werkstoffe] (m³)",
      "densB": "",
      "u": "26",
      "GWP": "504.1",
      "ODP": "0.0000286",
      "AP": "0.69",
      "EP": "0.094",
      "POCP": "0.087",
      "PEnr": "6457",
      "PE": "264",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "177",
      "grp": "Mineral building materials",
      "n": "2.6 Foamglas insulation",
      "no": "2.6 Schaumglas Perinsul.xml",
      "ref": "Schaumglas Perinsul [Mineralische Werkstoffe] (m³)",
      "densB": "200",
      "u": "26",
      "GWP": "223.75",
      "ODP": "0.000001721",
      "AP": "0.41088",
      "EP": "0.046102",
      "POCP": "0.03634",
      "PEnr": "3049",
      "PE": "1725.3",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "178",
      "grp": "Mineral building materials",
      "n": "7.2 Glass",
      "no": "7.2 Transparente_Fuellungen_(Fensterglas).xml",
      "ref": "Glas (Fensterglas)",
      "densB": "2500",
      "u": "39",
      "GWP": "1.02179675",
      "ODP": "4.79E-08",
      "AP": "0.006143008",
      "EP": "0.000913914",
      "POCP": "0.000397615",
      "PEnr": "17.43539074",
      "PE": "0.232762943",
      "eol": "9.5.2 Building material disposal",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "179",
      "grp": "Mineral building materials",
      "n": "Troldtekt",
      "no": "",
      "ref": "",
      "densB": "400",
      "u": "39",
      "GWP": "0.207583418",
      "ODP": "9.43E-11",
      "AP": "0.001195732",
      "EP": "0.000385424",
      "POCP": "0.000132917",
      "PEnr": "8.730565393",
      "PE": "4.934613195",
      "eol": "9.5.1 Mineral buildingmaterial treatment (including credit for gravel replacement)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "180",
      "grp": "Output",
      "n": "floor heating copper (100 mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "12.96875318",
      "ODP": "1.37E-06",
      "AP": "0.059858609",
      "EP": "0.00596946",
      "POCP": "0.005049637",
      "PEnr": "229.5721685",
      "PE": "7.744554479",
      "eol": "End of life - floor heating copper (100mm Abstand)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "181",
      "grp": "Output",
      "n": "floor heating copper (200 mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "7.788584008",
      "ODP": "7.26E-07",
      "AP": "0.032761638",
      "EP": "0.00329169",
      "POCP": "0.002987326",
      "PEnr": "154.7252655",
      "PE": "4.049803712",
      "eol": "End of life - floor heating copper (200mm Abstand)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "182",
      "grp": "Output",
      "n": "floor heating PEX (100 mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "5.640481024",
      "ODP": "2.96E-07",
      "AP": "0.011589866",
      "EP": "0.001149151",
      "POCP": "0.002036583",
      "PEnr": "175.5066733",
      "PE": "1.417304609",
      "eol": "End of life - floor heating PEX (100mm Abstand)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "183",
      "grp": "Output",
      "n": "floor heating PEX (200 mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "4.124447931",
      "ODP": "1.87E-07",
      "AP": "0.008627266",
      "EP": "0.000881535",
      "POCP": "0.001480799",
      "PEnr": "127.6925179",
      "PE": "0.886178777",
      "eol": "End of life - floor heating PEX (200mm Abstand)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "184",
      "grp": "Output",
      "n": "floor heating PP (100 mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "5.467115593",
      "ODP": "2.57E-07",
      "AP": "0.011195317",
      "EP": "0.001120224",
      "POCP": "0.001867091",
      "PEnr": "171.0878229",
      "PE": "1.233312146",
      "eol": "End of life - floor heating PP (100mm Abstand)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "185",
      "grp": "Output",
      "n": "floor heating PP (200 mm Abstand)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "38",
      "GWP": "4.037765215",
      "ODP": "1.68E-07",
      "AP": "0.008429992",
      "EP": "0.000867072",
      "POCP": "0.001396054",
      "PEnr": "125.4830927",
      "PE": "0.794182546",
      "eol": "End of life - floor heating PP (100mm Abstand)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "186",
      "grp": "Output",
      "n": "radiator",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "3.71041465",
      "ODP": "1.93E-07",
      "AP": "0.014540716",
      "EP": "0.001114807",
      "POCP": "0.001931122",
      "PEnr": "55.08514303",
      "PE": "1.327014753",
      "eol": "End of life - radiator (1kg)",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "187",
      "grp": "Plastic",
      "n": "2.03 XPS-insulation",
      "no": "2.03 XPS-Daemmstoff.xml",
      "ref": "XPS Extruded Polystyrene foam",
      "densB": "32",
      "u": "39",
      "GWP": "2.660765864",
      "ODP": "1.53E-07",
      "AP": "0.005938032",
      "EP": "0.000637",
      "POCP": "0.002611193",
      "PEnr": "82.13463756",
      "PE": "0.714052369",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "188",
      "grp": "Plastic",
      "n": "2.04 PUR-insulation",
      "no": "2.04 Polyurethan-hardschaum-Daemmstoff.xml",
      "ref": "Polyurethan-hardschaum-Dämmplatte",
      "densB": "32",
      "u": "39",
      "GWP": "4.164300471",
      "ODP": "2.34E-07",
      "AP": "0.01059601",
      "EP": "0.001608964",
      "POCP": "0.001632877",
      "PEnr": "86.37674316",
      "PE": "7.469534585",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "189",
      "grp": "Plastic",
      "n": "2.2 EPS-insulation",
      "no": "2.2 EPS.xml",
      "ref": "EPS [Kunststoffe] (kg)",
      "densB": "25",
      "u": "39",
      "GWP": "2.678",
      "ODP": "8.22E-08",
      "AP": "0.0057",
      "EP": "0.000598",
      "POCP": "0.0009421",
      "PEnr": "81.587",
      "PE": "0.3769",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "190",
      "grp": "Plastic",
      "n": "6.2.5 Carpet",
      "no": "6.2.5 Teppich_GK22_LC2_3.xml",
      "ref": "1m² Teppichboden",
      "densB": "2.11",
      "u": "38",
      "GWP": "7.01",
      "ODP": "0.00000039",
      "AP": "0.025",
      "EP": "0.0037",
      "POCP": "0.0017",
      "PEnr": "144.1",
      "PE": "2.98",
      "eol": "6.2.5 Incineration of carpet (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "191",
      "grp": "Plastic",
      "n": "6.3.1 Bitumen sheeting (schistosed)",
      "no": "6.3.1 Bitumenbahnen_(geschiefert).xml",
      "ref": "Bitumenbahn (geschiefert)",
      "densB": "1045",
      "u": "39",
      "GWP": "0.790004823",
      "ODP": "3.87E-08",
      "AP": "0.002197708",
      "EP": "0.000205052",
      "POCP": "0.000474772",
      "PEnr": "36.14746619",
      "PE": "0.213102457",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "192",
      "grp": "Plastic",
      "n": "6.3.1 Bitumen sheeting G 200 S4 (base layer)",
      "no": "6.3.1 Bitumenbahnen_G_200_S4_(untere_Lage).xml",
      "ref": "Bitumenbahn G 200 S4",
      "densB": "1045",
      "u": "39",
      "GWP": "0.618934826",
      "ODP": "2.05E-08",
      "AP": "0.002174242",
      "EP": "0.00020153",
      "POCP": "0.000400896",
      "PEnr": "33.53338446",
      "PE": "0.126721844",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "193",
      "grp": "Plastic",
      "n": "6.3.1 Bitumen sheeting PYE PV",
      "no": "6.3.1 Bitumenbahnen_PYE_PV.xml",
      "ref": "Bitumenbahn PYE PV",
      "densB": "1045",
      "u": "39",
      "GWP": "0.936864507",
      "ODP": "4.60E-08",
      "AP": "0.002606739",
      "EP": "0.000242196",
      "POCP": "0.000565619",
      "PEnr": "43.07985574",
      "PE": "0.253512063",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "194",
      "grp": "Plastic",
      "n": "6.3.1 Bitumen sheeting V 60",
      "no": "6.3.1 Bitumenbahnen_V_60.xml",
      "ref": "Bitumenbahn V60",
      "densB": "1045",
      "u": "39",
      "GWP": "0.438356756",
      "ODP": "2.19E-08",
      "AP": "0.001877048",
      "EP": "0.000162966",
      "POCP": "0.000289656",
      "PEnr": "32.10842994",
      "PE": "0.132762272",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "195",
      "grp": "Plastic",
      "n": "6.3.2 PVC roofing membrane",
      "no": "6.3.2 PVC-Dachbahnen.xml",
      "ref": "PVC-Dachbahn",
      "densB": "1350",
      "u": "39",
      "GWP": "5.354962122",
      "ODP": "1.58E-07",
      "AP": "0.017982996",
      "EP": "0.001790276",
      "POCP": "0.002549489",
      "PEnr": "89.56072324",
      "PE": "2.052346569",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "196",
      "grp": "Plastic",
      "n": "6.3.3 EPDM roofing membrane",
      "no": "6.3.3 Dachbahnen_EPDM.xml",
      "ref": "Elastomerdachbahn",
      "densB": "955",
      "u": "39",
      "GWP": "3.999889485",
      "ODP": "1.14E-07",
      "AP": "0.006768446",
      "EP": "0.000724671",
      "POCP": "0.002214301",
      "PEnr": "106.2409217",
      "PE": "0.649808007",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "197",
      "grp": "Plastic",
      "n": "6.3.4 EVA roofing membrane",
      "no": "6.3.4 EVA-Dachbahnen.xml",
      "ref": "Dachbahnen (EVA)",
      "densB": "930",
      "u": "39",
      "GWP": "3.177832393",
      "ODP": "1.70E-07",
      "AP": "0.00554928",
      "EP": "0.00051601",
      "POCP": "0.001639055",
      "PEnr": "89.61113652",
      "PE": "0.833151412",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "198",
      "grp": "Plastic",
      "n": "6.5.2 Transparent sheet PC",
      "no": "6.5.2 Transparente_Platten_PC.xml",
      "ref": "Transparente Platten PC",
      "densB": "1200",
      "u": "39",
      "GWP": "7.135574421",
      "ODP": "4.55E-07",
      "AP": "0.012589463",
      "EP": "0.001543731",
      "POCP": "0.001812356",
      "PEnr": "140.4413971",
      "PE": "2.194638461",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "199",
      "grp": "Plastic",
      "n": "6.5.2 Transparent sheet PMMA, casted",
      "no": "6.5.2 Transparente_Platten_PMMA,_gegossen.xml",
      "ref": "Transparent Platten PMMA",
      "densB": "1190",
      "u": "39",
      "GWP": "6.822998265",
      "ODP": "4.09E-07",
      "AP": "0.017412039",
      "EP": "0.001543164",
      "POCP": "0.003158198",
      "PEnr": "139.0886323",
      "PE": "1.93459908",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "200",
      "grp": "Plastic",
      "n": "6.5.2 Transparent sheet PMMA, extruded",
      "no": "6.5.2 Transparente_Platten_PMMA,_extrudiert.xml",
      "ref": "Transparent Platten PMMA",
      "densB": "1190",
      "u": "39",
      "GWP": "6.088300432",
      "ODP": "2.93E-07",
      "AP": "0.016059547",
      "EP": "0.001434378",
      "POCP": "0.003041274",
      "PEnr": "127.1891117",
      "PE": "1.38461967",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "201",
      "grp": "Plastic",
      "n": "6.5.2 Transparent sheet PVC",
      "no": "6.5.2 Transparente_Platten_PVC.xml",
      "ref": "Transparente Platten PVC",
      "densB": "1220",
      "u": "39",
      "GWP": "3.144849091",
      "ODP": "2.42E-07",
      "AP": "0.005663033",
      "EP": "0.000562229",
      "POCP": "0.00152957",
      "PEnr": "71.6299419",
      "PE": "1.16378985",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "202",
      "grp": "Plastic",
      "n": "6.6.2 Foil PE",
      "no": "6.6.2 Dampfbremse_PE.xml",
      "ref": "Dampfsperre",
      "densB": "950",
      "u": "39",
      "GWP": "2.121151512",
      "ODP": "6.23E-08",
      "AP": "0.007530437",
      "EP": "0.000998962",
      "POCP": "0.00096708",
      "PEnr": "67.94765049",
      "PE": "1.181225777",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "203",
      "grp": "Plastic",
      "n": "6.6.5 Kraft paper",
      "no": "6.6.5 Kraftpapier.xml",
      "ref": "Kraftliner braun (Wellkartonrohpapier t92)",
      "densB": "1150",
      "u": "39",
      "GWP": "-0.578624604",
      "ODP": "1.02E-07",
      "AP": "0.003843047",
      "EP": "0.00106312",
      "POCP": "0.000301254",
      "PEnr": "13.10043104",
      "PE": "27.87925264",
      "eol": "6.8 Incineration of plastics (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "204",
      "grp": "Small iron work",
      "n": "7.4 small iron work for casement window",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "3.405569459",
      "ODP": "2.30E-07",
      "AP": "0.014004396",
      "EP": "0.002615858",
      "POCP": "0.000981256",
      "PEnr": "55.10289537",
      "PE": "3.350854765",
      "eol": "End of life included in production dataset (cradle to grave)",
      "d": "38442",
      "w": "1.014",
      "wd": "39"
    },
    {
      "id": "205",
      "grp": "Small iron work",
      "n": "7.4 small iron work for horizonal sliding window",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "2.760276184",
      "ODP": "1.51E-07",
      "AP": "0.011094026",
      "EP": "0.00252691",
      "POCP": "0.000803492",
      "PEnr": "44.56733396",
      "PE": "1.82840864",
      "eol": "End of life included in production dataset (cradle to grave)",
      "d": "38442",
      "w": "0.696",
      "wd": "39"
    },
    {
      "id": "206",
      "grp": "Small iron work",
      "n": "7.4 small iron work for tilt and turn window",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "3.392249055",
      "ODP": "3.36E-07",
      "AP": "0.014159189",
      "EP": "0.002803503",
      "POCP": "0.000980549",
      "PEnr": "52.21733062",
      "PE": "4.38866922",
      "eol": "End of life included in production dataset (cradle to grave)",
      "d": "38442",
      "w": "1.647",
      "wd": "39"
    },
    {
      "id": "207",
      "grp": "Small iron work",
      "n": "7.4 small iron work for vertical sliding window",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1.937846193",
      "ODP": "1.24E-07",
      "AP": "0.005853216",
      "EP": "0.003171778",
      "POCP": "0.000469874",
      "PEnr": "23.73047119",
      "PE": "2.146836556",
      "eol": "End of life included in production dataset (cradle to grave)",
      "d": "38442",
      "w": "0.93",
      "wd": "39"
    },
    {
      "id": "208",
      "grp": "Window handle",
      "n": "7.4 window handle",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "162",
      "GWP": "1.243687561",
      "ODP": "5.15E-08",
      "AP": "0.002045032",
      "EP": "0.000240815",
      "POCP": "0.000293339",
      "PEnr": "22.46285674",
      "PE": "0.260137514",
      "eol": "End of life - window handle",
      "d": "38442",
      "w": "0.1",
      "wd": "39"
    },
    {
      "id": "209",
      "grp": "Storage",
      "n": "storage tank (stainless steel )",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "5.29528133",
      "ODP": "4.32E-07",
      "AP": "0.024439072",
      "EP": "0.010615719",
      "POCP": "0.001797977",
      "PEnr": "78.8901123",
      "PE": "7.060670768",
      "eol": "4.8 Recyclingpotential steel sheet",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "210",
      "grp": "Storage",
      "n": "storage tank (steel)",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "39",
      "GWP": "2.9874955",
      "ODP": "1.19E-07",
      "AP": "0.006882712",
      "EP": "0.000801184",
      "POCP": "0.001185299",
      "PEnr": "47.82107593",
      "PE": "1.21910337",
      "eol": "4.8 Recyclingpotential steel sheet",
      "d": "38442",
      "w": "",
      "wd": ""
    },
    {
      "id": "211",
      "grp": "Window frame",
      "n": "7.1.1 Holz-Blendrahmen",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "-0.461759012",
      "ODP": "2.24E-07",
      "AP": "0.008455984",
      "EP": "0.000642081",
      "POCP": "0.003216679",
      "PEnr": "26.5190003",
      "PE": "31.28349648",
      "eol": "End of life - Holz-Blendrahmen",
      "d": "38442",
      "w": "1.3",
      "wd": "163"
    },
    {
      "id": "212",
      "grp": "Window frame",
      "n": "7.1.5 Aluminium-Rahmenprofil, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "13.42446243",
      "ODP": "1.40E-06",
      "AP": "0.065464798",
      "EP": "0.002990845",
      "POCP": "0.003831462",
      "PEnr": "190.2130891",
      "PE": "50.10156587",
      "eol": "End of life - Aluminium-Rahmenprofil, pulverbeschichtet",
      "d": "38442",
      "w": "1.02",
      "wd": "163"
    },
    {
      "id": "213",
      "grp": "Window frame",
      "n": "7.1.6 Aluminium-Rahmenprofil, thermisch getrennt, pulverbeschichtet",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "17.49794652",
      "ODP": "1.53E-06",
      "AP": "0.081700631",
      "EP": "0.005454574",
      "POCP": "0.005343345",
      "PEnr": "235.7474478",
      "PE": "56.95532158",
      "eol": "End of life - Aluminium-Rahmenprofil, thermisch getrennt, pulverbeschichtet",
      "d": "38442",
      "w": "1.43",
      "wd": "163"
    },
    {
      "id": "214",
      "grp": "Window frame",
      "n": "7.1.9 Blendrahmen PVC-U",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "6",
      "GWP": "7.263199896",
      "ODP": "3.34E-07",
      "AP": "0.036652061",
      "EP": "0.002666398",
      "POCP": "0.002655991",
      "PEnr": "131.3938917",
      "PE": "4.959826078",
      "eol": "End of life - Blendrahmen PVC-U",
      "d": "38442",
      "w": "2.8",
      "wd": "163"
    },
    {
      "id": "215",
      "grp": "Window pane",
      "n": "7.2 Fensterglas einfach",
      "no": "",
      "ref": "",
      "densB": "2500",
      "u": "38",
      "GWP": "8.429823184",
      "ODP": "3.95E-07",
      "AP": "0.050679813",
      "EP": "0.007539789",
      "POCP": "0.003280326",
      "PEnr": "143.8419736",
      "PE": "1.920294277",
      "eol": "End of life - Fensterglas einfach",
      "d": "38442",
      "w": "7.5",
      "wd": "15"
    },
    {
      "id": "216",
      "grp": "Window pane",
      "n": "7.2 Isolierglas 2-Scheiben",
      "no": "",
      "ref": "",
      "densB": "2500",
      "u": "38",
      "GWP": "37.48036539",
      "ODP": "8.38E-07",
      "AP": "0.161640665",
      "EP": "0.024443295",
      "POCP": "0.010774247",
      "PEnr": "432.4696861",
      "PE": "4.160499523",
      "eol": "End of life - Isolierglas 2-Scheiben",
      "d": "38442",
      "w": "20",
      "wd": "15"
    },
    {
      "id": "217",
      "grp": "Wood",
      "n": "2.10 Wood fiber insulation",
      "no": "2.10 Holzfaserdaemmplatte_(Nassverfahren).xml",
      "ref": "Holzfaserplatten (Holzsoftfaserplatte)",
      "densB": "130",
      "u": "39",
      "GWP": "-1.248528974",
      "ODP": "5.51E-08",
      "AP": "0.001185369",
      "EP": "0.000123184",
      "POCP": "0.000189839",
      "PEnr": "12.06594572",
      "PE": "18.59995115",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "218",
      "grp": "Wood",
      "n": "2.11 Cellulose insulation (fibres)",
      "no": "2.11 Zellulosefaser_Einblas-Daemmstoff.xml",
      "ref": "Zellulosefaser Dämmstoff",
      "densB": "45",
      "u": "39",
      "GWP": "-0.593403916",
      "ODP": "1.82E-08",
      "AP": "0.000797894",
      "EP": "0.000107383",
      "POCP": "6.97E-05",
      "PEnr": "3.77148441",
      "PE": "0.745397185",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "219",
      "grp": "Wood",
      "n": "2.12 Flax fibre insulation ",
      "no": "2.12 Flachsfaser Vlies.xml",
      "ref": "Flachsfaser Vlies [Mineralische Werkstoffe] (kg)",
      "densB": "38",
      "u": "39",
      "GWP": "-0.2988",
      "ODP": "1.24E-07",
      "AP": "0.00417",
      "EP": "0.00193",
      "POCP": "0.000234",
      "PEnr": "18.944",
      "PE": "15.93",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "220",
      "grp": "Wood",
      "n": "2.13 Hemp fibre insulation ",
      "no": "2.13 Hanffaser Vlies.xml",
      "ref": "Hanffaser Vlies [Mineralische Werkstoffe] (kg)",
      "densB": "38",
      "u": "39",
      "GWP": "-0.2654",
      "ODP": "1.09E-07",
      "AP": "0.0036",
      "EP": "0.0007339",
      "POCP": "0.0001842",
      "PEnr": "18.568",
      "PE": "15.99",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "221",
      "grp": "Wood",
      "n": "3.1.1 Sawn solid timber beech (12% Moisture content / 10,7% Water content) 1m3",
      "no": "3.1.1 Sawn solid timber_beech_(12%_Moisture content_10,7%_Wasserg.xml",
      "ref": "Sawn solid timber (12% HolzMoisture content / 10,7% Water content)",
      "densB": "740",
      "u": "39",
      "GWP": "-1.499802793",
      "ODP": "4.22E-08",
      "AP": "0.000645716",
      "EP": "9.57E-05",
      "POCP": "0.000343007",
      "PEnr": "8.002268287",
      "PE": "18.13101615",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "222",
      "grp": "Wood",
      "n": "3.1.1 Sawn solid timber Douglas fir (12% Moisture content, 10,7% Water content)",
      "no": "3.1.1 Sawn solid timber_Douglas fir_(12%_Moisture content,_10,7%_Wasserg.xml",
      "ref": "Sawn solid timber (12% HolzMoisture content / 10,7% Water content)",
      "densB": "550",
      "u": "39",
      "GWP": "-1.415915244",
      "ODP": "7.54E-09",
      "AP": "0.002129636",
      "EP": "0.000217507",
      "POCP": "0.00025294",
      "PEnr": "4.023810867",
      "PE": "19.94073896",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "223",
      "grp": "Wood",
      "n": "3.1.1 Sawn solid timber larch (12% Moisture content / 10,7% Water content, Absch.) 1m3",
      "no": "3.1.1 Sawn solid timber_Laerche_(12%_Moisture content_10,7%_Wasserg.xml",
      "ref": "Sawn solid timber (12% HolzMoisture content / 10,7% Water content)",
      "densB": "660",
      "u": "39",
      "GWP": "-1.403773711",
      "ODP": "5.75E-08",
      "AP": "0.001472712",
      "EP": "0.000169676",
      "POCP": "0.000141359",
      "PEnr": "9.987887247",
      "PE": "19.22823551",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "224",
      "grp": "Wood",
      "n": "3.1.1 Sawn solid timber Oak (12% Moisture content / 10,7% Water content) 1m3",
      "no": "3.1.1 Sawn solid timber_Oak_(12%_Moisture content_10,7%_Wasserg.xml",
      "ref": "Sawn solid timber (12% HolzMoisture content / 10,7% Water content)",
      "densB": "720",
      "u": "39",
      "GWP": "-1.499802793",
      "ODP": "4.22E-08",
      "AP": "0.000645716",
      "EP": "9.57E-05",
      "POCP": "0.000343007",
      "PEnr": "8.002268287",
      "PE": "18.13101615",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "225",
      "grp": "Wood",
      "n": "3.1.1 Sawn solid timber pine (12% Moisture content / 10,7% Water content) 1m3",
      "no": "3.1.1 Sawn solid timber_pine_(12%_Moisture content_10,7%_Wasserg.xml",
      "ref": "Sawn solid timber (12% HolzMoisture content / 10,7% Water content)",
      "densB": "480",
      "u": "39",
      "GWP": "-1.473140291",
      "ODP": "4.56E-08",
      "AP": "0.00078584",
      "EP": "0.000116219",
      "POCP": "9.64E-05",
      "PEnr": "8.749591697",
      "PE": "19.15531639",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "226",
      "grp": "Wood",
      "n": "3.1.1 Sawn solid timber spruce (12% Moisture content / 10,7% Water content) 1m3",
      "no": "3.1.1 Sawn solid timber_fir_(12%_Moisture content_10,7%_Wasserg.xml",
      "ref": "Sawn solid timber (12% HolzMoisture content / 10,7% Water content)",
      "densB": "630",
      "u": "39",
      "GWP": "-1.473140291",
      "ODP": "4.56E-08",
      "AP": "0.00078584",
      "EP": "0.000116219",
      "POCP": "9.64E-05",
      "PEnr": "8.749591697",
      "PE": "19.15531639",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "227",
      "grp": "Wood",
      "n": "3.1.2 Solid structural timber (15% Moisture content/13% Water content) 1m3",
      "no": "3.1.2 Solid structural timber_(15%_Moisture content_13%_Wasserg.xml",
      "ref": "Solid structural timber (15 % Moisture content)",
      "densB": "530",
      "u": "39",
      "GWP": "-1.405905979",
      "ODP": "4.80E-08",
      "AP": "0.000784351",
      "EP": "0.000116723",
      "POCP": "9.53E-05",
      "PEnr": "8.840485512",
      "PE": "18.56009264",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "228",
      "grp": "Wood",
      "n": "3.1.2 Solid structural timber pine",
      "no": "3.1.2 Solid structural timber pine.xml",
      "ref": "KVH pine [Holzwerkstoffe] (kg)",
      "densB": "515",
      "u": "39",
      "GWP": "-1.2124",
      "ODP": "0.000000049",
      "AP": "0.000998754",
      "EP": "0.00014729",
      "POCP": "0.000238555",
      "PEnr": "9.458",
      "PE": "18.251",
      "eol": "3.4 Incineration of natural wood (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "229",
      "grp": "Wood",
      "n": "3.1.3 Glued laminated timber beam (12% Moisture content) 1m3",
      "no": "3.1.3 Balkenschichtholz_Nadelholz_(12%_Moisture content).xml",
      "ref": "Balkenschichtholz fir (12% Moisture content)",
      "densB": "465",
      "u": "39",
      "GWP": "-1.345552748",
      "ODP": "5.50E-08",
      "AP": "0.00093099",
      "EP": "0.000142292",
      "POCP": "0.000113199",
      "PEnr": "10.31510945",
      "PE": "18.77958637",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "230",
      "grp": "Wood",
      "n": "3.1.5 Cross laminated timber board (12% Moisture content) 1m3",
      "no": "3.1.5 Brettschichtholzplatte_(12%_Moisture content).xml",
      "ref": "Brettschichtholz Platte",
      "densB": "515",
      "u": "39",
      "GWP": "-1.337396071",
      "ODP": "5.51E-08",
      "AP": "0.000939379",
      "EP": "0.000143879",
      "POCP": "0.000114477",
      "PEnr": "10.40379247",
      "PE": "18.74346984",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "231",
      "grp": "Wood",
      "n": "3.2.1 3-ply core plywood",
      "no": "3.2.1 Drei-Schichtholzplatte.xml",
      "ref": "3-Schichtholzplatte",
      "densB": "470",
      "u": "39",
      "GWP": "-1.249655206",
      "ODP": "1.98E-08",
      "AP": "0.003339534",
      "EP": "0.000537127",
      "POCP": "0.000360389",
      "PEnr": "5.758362276",
      "PE": "22.33746555",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "232",
      "grp": "Wood",
      "n": "3.2.1 5-ply core plywood",
      "no": "3.2.1 Fuenf-Schichtholzplatte.xml",
      "ref": "5-Schichtholzplatte",
      "densB": "470",
      "u": "39",
      "GWP": "-1.358872119",
      "ODP": "1.72E-08",
      "AP": "0.00273914",
      "EP": "0.000456864",
      "POCP": "0.000303674",
      "PEnr": "4.512965445",
      "PE": "21.78694942",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "233",
      "grp": "Wood",
      "n": "3.2.2 Plywood",
      "no": "3.2.2 Sperrholzplatte.xml",
      "ref": "0",
      "densB": "460",
      "u": "26",
      "GWP": "-666.9531712",
      "ODP": "2.17E-05",
      "AP": "1.188457114",
      "EP": "0.16715046",
      "POCP": "0.244393533",
      "PEnr": "3510.296193",
      "PE": "9882.955888",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "234",
      "grp": "Wood",
      "n": "3.2.3 Laminated Veneer Lumber",
      "no": "3.2.3_Furnierschichtholz.xml",
      "ref": "Furnierschichtholz",
      "densB": "465",
      "u": "39",
      "GWP": "-0.826203578",
      "ODP": "4.05E-08",
      "AP": "0.002720446",
      "EP": "0.000497934",
      "POCP": "0.000306183",
      "PEnr": "13.74146131",
      "PE": "28.36222799",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "235",
      "grp": "Wood",
      "n": "3.2.4 OSB-board",
      "no": "3.2.4 OSB-Platte.xml",
      "ref": "OSB-Platte [Holzwerkstoffe] (kg)",
      "densB": "620",
      "u": "26",
      "GWP": "-886.1167978",
      "ODP": "1.93E-05",
      "AP": "1.325596768",
      "EP": "0.227614968",
      "POCP": "0.254049453",
      "PEnr": "4863.353682",
      "PE": "15212.20609",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "236",
      "grp": "Wood",
      "n": "3.2.6 Particleboard P2 (8,5% Moisture content) 1m3",
      "no": "3.2.6_Particleboard_P2_(8,5%_Moisture content).xml",
      "ref": "Particleboard P2 (8,5% Moisture content)",
      "densB": "680",
      "u": "26",
      "GWP": "-904.4072867",
      "ODP": "1.84E-05",
      "AP": "0.715837775",
      "EP": "0.141361146",
      "POCP": "0.224465658",
      "PEnr": "3640.340171",
      "PE": "13901.20788",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "237",
      "grp": "Wood",
      "n": "3.2.6 Particleboard P4 (8,5% Moisture content) 1m3",
      "no": "3.2.6_Particleboard_P4_(8,5%_Moisture content).xml",
      "ref": "Particleboard P4 (8,5% Moisture content)",
      "densB": "680",
      "u": "26",
      "GWP": "-886.4643177",
      "ODP": "1.95E-05",
      "AP": "0.750787629",
      "EP": "0.154766442",
      "POCP": "0.229217679",
      "PEnr": "4106.917452",
      "PE": "13959.13454",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "238",
      "grp": "Wood",
      "n": "3.2.6 Particleboard P5 (8,5% Moisture content) 1m3",
      "no": "3.2.6 Particleboard_P5_(8,5%_Moisture content).xml",
      "ref": "Particleboard P5 (8,5% Moisture content)",
      "densB": "680",
      "u": "26",
      "GWP": "-755.0548429",
      "ODP": "4.72E-05",
      "AP": "0.898488519",
      "EP": "0.150308197",
      "POCP": "0.296697824",
      "PEnr": "6248.740752",
      "PE": "13266.8912",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "239",
      "grp": "Wood",
      "n": "3.2.6 Particleboard P6/P7 (8,5% Moisture content) 1m3",
      "no": "3.2.6 Particleboard_P6_P7_(8,5%_Moisture content).xml",
      "ref": "Particleboard P6/P7 (8,5% Moisture content)",
      "densB": "680",
      "u": "26",
      "GWP": "-741.9500636",
      "ODP": "5.04E-05",
      "AP": "0.983541277",
      "EP": "0.151486973",
      "POCP": "0.322131598",
      "PEnr": "7481.619297",
      "PE": "13686.62906",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "240",
      "grp": "Wood",
      "n": "3.2.7 Highdensity fibreboard HDF",
      "no": "3.2.7 Hochdichte_Faserplatte_HDF.xml",
      "ref": "HDF-Platte",
      "densB": "900",
      "u": "39",
      "GWP": "-1.052141798",
      "ODP": "4.96E-08",
      "AP": "0.002100989",
      "EP": "0.000655357",
      "POCP": "0.001049229",
      "PEnr": "11.76895422",
      "PE": "17.54194338",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "241",
      "grp": "Wood",
      "n": "3.2.7 Mediumdensity fibreboard MDF",
      "no": "3.2.7 MDF-Platte.xml",
      "ref": "MDF-Platte [Holzwerkstoffe] (kg)",
      "densB": "720",
      "u": "39",
      "GWP": "-0.7118",
      "ODP": "6.42E-08",
      "AP": "0.002",
      "EP": "0.000461336",
      "POCP": "0.0019",
      "PEnr": "14.34",
      "PE": "18.889",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "242",
      "grp": "Wood",
      "n": "3.2.8 Wood cement board",
      "no": "3.2.8 Holzzementplatte.xml",
      "ref": "Holzzement",
      "densB": "1350",
      "u": "39",
      "GWP": "0.586948274",
      "ODP": "3.94E-08",
      "AP": "0.001496698",
      "EP": "0.000180883",
      "POCP": "0.000124958",
      "PEnr": "7.165583809",
      "PE": "2.560960752",
      "eol": "3.4 Incineration of wooden particle board (including credits)",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "243",
      "grp": "Wood",
      "n": "3.3.1 Laminate",
      "no": "3.3.1 Laminat.xml",
      "ref": "Laminatfußboden (Egger)",
      "densB": "880",
      "u": "38",
      "GWP": "-3.547261425",
      "ODP": "4.56E-07",
      "AP": "0.020906041",
      "EP": "0.006591884",
      "POCP": "0.008056473",
      "PEnr": "125.2275737",
      "PE": "120.8018364",
      "eol": "End of life Laminate",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "244",
      "grp": "Wood",
      "n": "3.3.2 Multilayer parquet",
      "no": "3.3.2 Mehrschichtparkett.xml",
      "ref": "Mehrschichtparkett [Holzwerkstoffe] (m²)",
      "densB": "590",
      "u": "38",
      "GWP": "-7.1896",
      "ODP": "7.08E-07",
      "AP": "0.03184",
      "EP": "0.00476",
      "POCP": "0.00583",
      "PEnr": "150.244",
      "PE": "251.268",
      "eol": "End of life - Multilayer parquet",
      "d": "2008",
      "w": "",
      "wd": ""
    },
    {
      "id": "245",
      "grp": "Operation",
      "n": "operation - continuous-flow water heater",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.708718374",
      "ODP": "1.17E-07",
      "AP": "0.001211642",
      "EP": "8.04E-05",
      "POCP": "9.96E-05",
      "PEnr": "11.0485066",
      "PE": "0.55486819",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "246",
      "grp": "Operation",
      "n": "operation - district heating 120-400 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.237535907",
      "ODP": "4.16E-10",
      "AP": "0.000247488",
      "EP": "2.42E-05",
      "POCP": "2.85E-05",
      "PEnr": "3.610037135",
      "PE": "0.00233408",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "247",
      "grp": "Operation",
      "n": "operation - district heating 20-120 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.239765278",
      "ODP": "5.13E-10",
      "AP": "0.00025019",
      "EP": "2.45E-05",
      "POCP": "2.88E-05",
      "PEnr": "3.64414119",
      "PE": "0.002795075",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "248",
      "grp": "Operation",
      "n": "operation - fuel gas-condensing boiler 120-400 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.222942749",
      "ODP": "6.42E-10",
      "AP": "0.000114707",
      "EP": "1.88E-05",
      "POCP": "1.09E-05",
      "PEnr": "3.751908811",
      "PE": "0.003309265",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "249",
      "grp": "Operation",
      "n": "operation - fuel gas-condensing boiler 20-120 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.229182724",
      "ODP": "1.28E-09",
      "AP": "0.000122473",
      "EP": "1.94E-05",
      "POCP": "1.15E-05",
      "PEnr": "3.852196586",
      "PE": "0.006329496",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "250",
      "grp": "Operation",
      "n": "operation - fuel gas-condensing boiler < 20 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.243991711",
      "ODP": "2.98E-09",
      "AP": "0.00014229",
      "EP": "2.10E-05",
      "POCP": "1.32E-05",
      "PEnr": "4.088765309",
      "PE": "0.014388679",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "251",
      "grp": "Operation",
      "n": "operation - fuel gas low temperature boiler 120-400 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.247903284",
      "ODP": "6.77E-10",
      "AP": "0.000182882",
      "EP": "2.38E-05",
      "POCP": "2.26E-05",
      "PEnr": "4.187577567",
      "PE": "0.003509791",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "252",
      "grp": "Operation",
      "n": "operation - fuel gas low temperature boiler 20-120 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.254884782",
      "ODP": "1.32E-09",
      "AP": "0.000191733",
      "EP": "2.45E-05",
      "POCP": "2.34E-05",
      "PEnr": "4.30054909",
      "PE": "0.006535861",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "253",
      "grp": "Operation",
      "n": "operation - fuel gas low temperature boiler < 20 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.271128592",
      "ODP": "3.02E-09",
      "AP": "0.000196549",
      "EP": "2.52E-05",
      "POCP": "2.21E-05",
      "PEnr": "4.563713498",
      "PE": "0.014607285",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "254",
      "grp": "Operation",
      "n": "operation - woodchip boiler 120-400 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.02496398",
      "ODP": "2.04E-09",
      "AP": "0.000480407",
      "EP": "8.77E-05",
      "POCP": "7.97E-05",
      "PEnr": "0.518390034",
      "PE": "5.624618558",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "255",
      "grp": "Operation",
      "n": "operation - woodchip boiler 20-120 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.025357947",
      "ODP": "2.11E-09",
      "AP": "0.000487405",
      "EP": "9.94E-05",
      "POCP": "8.08E-05",
      "PEnr": "0.529182808",
      "PE": "5.702012603",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "256",
      "grp": "Operation",
      "n": "operation - woodchip boiler < 20 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.027690413",
      "ODP": "2.47E-09",
      "AP": "0.000508776",
      "EP": "0.000120725",
      "POCP": "8.41E-05",
      "PEnr": "0.575435516",
      "PE": "5.919774343",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "257",
      "grp": "Operation",
      "n": "operation - fuel oil-condensing boiler 120-400 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.293644973",
      "ODP": "8.26E-10",
      "AP": "0.000572593",
      "EP": "4.77E-05",
      "POCP": "3.56E-05",
      "PEnr": "4.155033613",
      "PE": "0.006071503",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "258",
      "grp": "Operation",
      "n": "operation - fuel oil-condensing boiler 20-120 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.300655056",
      "ODP": "1.47E-09",
      "AP": "0.000585346",
      "EP": "4.86E-05",
      "POCP": "3.66E-05",
      "PEnr": "4.259712334",
      "PE": "0.009121822",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "259",
      "grp": "Operation",
      "n": "operation - fuel oil-condensing boiler < 20 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.316663478",
      "ODP": "3.17E-09",
      "AP": "0.000614616",
      "EP": "4.86E-05",
      "POCP": "3.85E-05",
      "PEnr": "4.50460323",
      "PE": "0.017238029",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "260",
      "grp": "Operation",
      "n": "operation - fuel oil-low temperature boiler 120-400 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.327795383",
      "ODP": "8.83E-10",
      "AP": "0.000639242",
      "EP": "5.32E-05",
      "POCP": "3.98E-05",
      "PEnr": "4.637913044",
      "PE": "0.006595521",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "261",
      "grp": "Operation",
      "n": "operation - fuel oil-low temperature boiler 20-120 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.335799696",
      "ODP": "1.52E-09",
      "AP": "0.000653935",
      "EP": "5.44E-05",
      "POCP": "4.08E-05",
      "PEnr": "4.756649971",
      "PE": "0.009661095",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "262",
      "grp": "Operation",
      "n": "operation - fuel oil-low temperature boiler < 20 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.353860653",
      "ODP": "3.23E-09",
      "AP": "0.000687274",
      "EP": "5.43E-05",
      "POCP": "4.30E-05",
      "PEnr": "5.031018558",
      "PE": "0.017809292",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "263",
      "grp": "Operation",
      "n": "operation - pellet boiler 20-120 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.029446323",
      "ODP": "3.30E-09",
      "AP": "0.000468146",
      "EP": "8.25E-05",
      "POCP": "7.59E-05",
      "PEnr": "0.683776586",
      "PE": "5.130939364",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "264",
      "grp": "Operation",
      "n": "operation - pellet boiler < 20 kW",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.035265166",
      "ODP": "4.21E-09",
      "AP": "0.000492296",
      "EP": "8.69E-05",
      "POCP": "7.92E-05",
      "PEnr": "0.782539723",
      "PE": "5.308559886",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "265",
      "grp": "Operation",
      "n": "operation - solar collector -  evacuated tube collector",
      "no": "1125.291991",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.002734253",
      "ODP": "4.53E-10",
      "AP": "4.67E-06",
      "EP": "3.10E-07",
      "POCP": "3.84E-07",
      "PEnr": "0.042625411",
      "PE": "0.002140695",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "266",
      "grp": "Operation",
      "n": "operation - solar collector -  flat plate collector",
      "no": "1406.865664",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.002734253",
      "ODP": "4.53E-10",
      "AP": "4.67E-06",
      "EP": "3.10E-07",
      "POCP": "3.84E-07",
      "PEnr": "0.042625411",
      "PE": "0.002140695",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "267",
      "grp": "Operation",
      "n": "operation - electric heat pump Sole-Wasser (0/35)",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.161437974",
      "ODP": "2.67E-08",
      "AP": "0.000275998",
      "EP": "1.83E-05",
      "POCP": "2.27E-05",
      "PEnr": "2.516723967",
      "PE": "0.126392654",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "268",
      "grp": "Operation",
      "n": "operation - electric heat pump Sole-Wasser (0/50)",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.23702766",
      "ODP": "3.92E-08",
      "AP": "0.000405228",
      "EP": "2.69E-05",
      "POCP": "3.33E-05",
      "PEnr": "3.695123148",
      "PE": "0.185573161",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "269",
      "grp": "Operation",
      "n": "operation - electric heat pump Sole-Wasser (5/55)",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.301579874",
      "ODP": "4.99E-08",
      "AP": "0.000515588",
      "EP": "3.42E-05",
      "POCP": "4.24E-05",
      "PEnr": "4.701454559",
      "PE": "0.236112235",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "270",
      "grp": "Operation",
      "n": "operation - electric heat pump water-water (10/35)",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.129445243",
      "ODP": "2.14E-08",
      "AP": "0.000221303",
      "EP": "1.47E-05",
      "POCP": "1.82E-05",
      "PEnr": "2.017975929",
      "PE": "0.101344977",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "271",
      "grp": "Operation",
      "n": "operation - electric heat pump water-water (10/50)",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.188990054",
      "ODP": "3.13E-08",
      "AP": "0.000323102",
      "EP": "2.14E-05",
      "POCP": "2.66E-05",
      "PEnr": "2.946244857",
      "PE": "0.147963667",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "272",
      "grp": "Operation",
      "n": "operation - electric heat pump water-water (7/55)",
      "no": "3.6",
      "ref": "MJ",
      "densB": "",
      "u": "36",
      "GWP": "0.236237568",
      "ODP": "3.91E-08",
      "AP": "0.000403877",
      "EP": "2.68E-05",
      "POCP": "3.32E-05",
      "PEnr": "3.682806071",
      "PE": "0.184954584",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    },
    {
      "id": "273",
      "grp": "Operation",
      "n": "operation - electric current",
      "no": "",
      "ref": "",
      "densB": "",
      "u": "36",
      "GWP": "0.0304",
      "ODP": "4.81E-12",
      "AP": "0.0000177",
      "EP": "0.0000033",
      "POCP": "0.00000208",
      "PEnr": "1.0728",
      "PE": "16.6032",
      "eol": "",
      "d": "",
      "w": "",
      "wd": ""
    }
  ]
};