import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { i18n } from 'src/features/shared/translations/translate';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from 'src/ui/tabs';
import { ConstructionsPage } from './constructions';
import { WindowsDoorsPage } from './windowsdoors';

export const ConstructionsTabsPage = ({
  tabsi,
}: any) => {

  const { replace } = useHistory();
  const { pathname } = useLocation();

  const onChange = (index: number) => {
    if (index === 0) {
      replace(pathname.replace('windowsdoors', 'opaque'));
    } else {
      replace(pathname.replace('opaque', 'windowsdoors'));
    } 
  };

  const defaultIndex = pathname.includes('constructions') ? 0 : 1;

  return (
    <Tabs
      pt={12}
      isLazy
      defaultIndex={tabsi}
      onChange={(index: number) => onChange(index)}
    >
      <TabList>
        <Tab>{i18n.t(`tabs.opaque`)}</Tab>
        <Tab>{i18n.t(`tabs.windows`)}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0} py={0}>
          <ConstructionsPage />
        </TabPanel>
        <TabPanel px={0} py={0}>
          <WindowsDoorsPage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};