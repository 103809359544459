import React from 'react';
import {
  theme as baseTheme,
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/core';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { appColors } from './colors';
import { appFontSizes } from './fontSizes';
import { appSpaces } from './spaces';




const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '74em',
  xl: '80em',
});

const overrides = {
  ...baseTheme,
  breakpoints,
  fontSizes: appFontSizes,
  space: appSpaces,
  lineHeight: {
    h1: '76px',
    h2: '48px',
    h3: '32px',
    h4: '22px',
    body: '19px',
    small: '24px',
    tiny: '16px',
    xs: '20px',
  },
  colors: appColors,
  fonts: {
    // body: "system-ui, sans-serif",
    body: 'Regular',
    // body: 'MyriadPro Regular',
    bold: 'Bold',
    h2: 'Bold',
    heading: 'Bold',
    monospace: 'Regular',
    mono: "Menlo, monospace",
  },
  borderRadius: {
    1: '8px',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    extra: '0 16px 32px rgba(0, 0, 0, 0.4)',
    top: '0 -3px 4px rgba(0, 0, 0, .125)',
  },
  variantcases: {},
};
export const appTheme = extendTheme(overrides);

export const AppTheme: React.FC = ({ children }) => {
  return <ChakraProvider theme={appTheme as any}>{children}</ChakraProvider>;
};

export default AppTheme;
