import _ from 'lodash';
import { JsonDefinition } from "src/features/shared/form/data/json_definition";
import { getNameOfDefinitionEnum } from 'src/features/shared/form/utils/get_name_for_definition_enum';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { oekobauJson } from "src/features/shared/json/data/source/oekobau.data";
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { i18n } from 'src/features/shared/translations/translate';

export class WindowsDoorsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexConstruction: number) {
    this.keys = [
      {
        key: `lWindow.[${indexConstruction}].n`,
        extraOptions: { gridColSpan: 6, preLabel: "Window or door" },
      },
    ];
  }
}

const ahmaterialWindowFromJson = (currentJson: any, indexVar: number, indexOne: number, indexTwo: number) => {
  const draftJsonForMaterials = _.get(oekobauJson, 'oekobau');

  const LayerGroup = 
    indexTwo == 0 ? "Window frame" 
    : indexTwo == 1 ? "Casement frame" 
    : indexTwo == 2 ? "Window pane" 
    : indexTwo == 3 ? "Window handle" 
    : indexTwo == 4 ? "Small iron work" 
    : indexTwo == 5 ? "Joint tape" 
    : "";

  const filtered = draftJsonForMaterials.filter(function (value, i, arr) {
    return value.grp == LayerGroup;
  });

  // here i just get the   example.json data,  not the current json data..
  const emptymap = [
    { value: -1, label: 'not assigned' },
  ];

  const returna = filtered.map((material: { id: any; n: string; }) => {
    return {
      value: material.id,
      label: material.n
    }
  });

  const returnall = emptymap.concat(returna);

  return returnall;
};

export class WindowFrameJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexWindowDoor: number) {
    this.keys = [
      {
        key: `ahs_fr`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.aHlrtbW`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.aHlrtbL`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexOne: indexWindowDoor, indexTwo: 0 },
        dropdownOptionsFromJson: ahmaterialWindowFromJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.sus.aHrecycled`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recycled`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.sus.aHrecyclable`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recyclable`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.sus.aHcertifiedwood`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.certwood`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.sus.aHverifiedepd`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.frame.sus.aHlifetime`,
        extraOptions: { gridColSpan: 1, },
      },
    ];
  }
}

export class WindowCasementJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexWindowDoor: number) {
    this.keys = [
      {
        key: `ahs_ca`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.aHlrtbW`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.aHlrtbL`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexOne: indexWindowDoor, indexTwo: 1 },
        dropdownOptionsFromJson: ahmaterialWindowFromJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.sus.aHrecycled`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recycled`)},
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.sus.aHrecyclable`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recyclable`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.sus.aHcertifiedwood`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.certwood`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.sus.aHverifiedepd`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.casement.sus.aHlifetime`,
        extraOptions: { gridColSpan: 1, },
      },
    ];
  }
}

export class WindowPaneJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexWindowDoor: number,) {
    this.keys = [
      {
        key: `ahs_pa`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.aHlrtbW`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.aHlrtbL`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexOne: indexWindowDoor, indexTwo: 2 },
        dropdownOptionsFromJson: ahmaterialWindowFromJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.aHcpane`,
        extraOptions: { gridColSpan: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.sus.aHrecycled`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recycled`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.sus.aHrecyclable`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recyclable`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.sus.aHcertifiedwood`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.certwood`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.sus.aHverifiedepd`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.pane.sus.aHlifetime`,
        extraOptions: { gridColSpan: 1, },
      },
    ];
  }
}

export class WindowHandlesJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexWindowDoor: number) {
    this.keys = [
      {
        key: `ahs_ha`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.handl.caHchand`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.handl.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexOne: indexWindowDoor, indexTwo: 3 },
        dropdownOptionsFromJson: ahmaterialWindowFromJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.handl.sus.aHrecycled`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recycled`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.handl.sus.aHrecyclable`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recyclable`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.handl.sus.aHcertifiedwood`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.certwood`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.handl.sus.aHverifiedepd`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.handl.sus.aHlifetime`,
        extraOptions: { gridColSpan: 1, },
      },
    ];
  }
}

export class WindowIronworkJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexWindowDoor: number) {
    this.keys = [
      {
        key: `ahs_iw`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.ironw.aHciw`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.ironw.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexOne: indexWindowDoor, indexTwo: 4 },
        dropdownOptionsFromJson: ahmaterialWindowFromJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.ironw.sus.aHrecycled`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recycled`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.ironw.sus.aHrecyclable`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recyclable`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.ironw.sus.aHcertifiedwood`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.certwood`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.ironw.sus.aHverifiedepd`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.ironw.sus.aHlifetime`,
        extraOptions: { gridColSpan: 1, },
      },
    ];
  }
}

export class WindowJointtapeJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexWindowDoor: number) {
    this.keys = [
      {
        key: `ahs_jt`,
        extraOptions: { gridColSpan: 1 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 2 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.tape.lcaMaterial`,
        extraOptions: { gridColSpan: 2, indexOne: indexWindowDoor, indexTwo: 5 },
        dropdownOptionsFromJson: ahmaterialWindowFromJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lWindow.[${indexWindowDoor}].eco.tape.sus.aHrecycled`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recycled`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.tape.sus.aHrecyclable`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.recyclable`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.tape.sus.aHcertifiedwood`,
        extraOptions: { gridColSpan: 1, noLabel:true, preLabel: i18n.t(`prelabel.certwood`) },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.tape.sus.aHverifiedepd`,
        extraOptions: { gridColSpan: 1 },
      },
      {
        key: `lWindow.[${indexWindowDoor}].eco.tape.sus.aHlifetime`,
        extraOptions: { gridColSpan: 1, },
      },
      // {
      //   key: `lWindow.[${indexWindowDoor}].n`,
      //   // extraOptions: { noLabel:true, disabled: true, gridColSpan: 1 },
      //   extraOptions: { gridColSpan: 2, preLabel: "Layer" },
      // },
    ];
  }
}