import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { getRandomInt } from 'src/features/shared/utils';

export const useNumberOfConstructions = (facade = globalJsonFacade) => {
  const [constructions, setConstructions] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForConstructions = _.get(
    globalJson,
    `lAssembly`
  );

  const numberOfConstructions = _.size(draftJsonForConstructions);

  const removeConstruction = (indexConstruction: number) => {
    const newDraftJsonForConstruction = [...(draftJsonForConstructions as any)];

    const filtered = newDraftJsonForConstruction.filter(function (
      value,
      i,
      arr
    ) {
      return i !== indexConstruction;
    });

    const newDraftJson = _.set(
      globalJson,
      `lAssembly`,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {

      const draftJsonForConstructions = _.get(
        newDraftJson,
        `lAssembly`
      );

      const numberOfConstructions = _.size(draftJsonForConstructions);
      setConstructions(numberOfConstructions);

      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addConstruction = () => {
    setConstructions(constructions + 1);

    const thisid = _.uniqueId("10") + getRandomInt(50000);   // get a random id

    _.set(globalJson, `lAssembly.[${constructions}].id`, thisid );


    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  useEffect(() => {
    setConstructions(numberOfConstructions);
  }, [numberOfConstructions]);

  return {
    constructions,
    addConstruction,
    removeConstruction,
  };
};
