import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { EnvironmentQualityJsonDefinition } from '../../../data/qualitative/environment_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const EnvironmentQualityPage: React.FC = () => {
  const environmentjson = useWithVariantCaseIndex(
    EnvironmentQualityJsonDefinition
  );

  return <FormBuilder jsonKeys={environmentjson.keys} />;
};

export default EnvironmentQualityPage;
