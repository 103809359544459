import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { getRandomInt } from 'src/features/shared/utils';

export const useNumberOfWindowsDoors = (facade = globalJsonFacade) => {
  const [windowsdoors, setWindowsdoors] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForWindowsDoors = _.get(
    globalJson,
    `lWindow`
  );

  const numberOfWindowsDoors = _.size(draftJsonForWindowsDoors);

  const removeWindowDoor = (indexWindowDoor: number) => {
    const newDraftJsonForWindowsDoors = [...(draftJsonForWindowsDoors as any)];

    const filtered = newDraftJsonForWindowsDoors.filter(function (
      value,
      i,
      arr
    ) {
      return i !== indexWindowDoor;
    });

    const newDraftJson = _.set(
      globalJson,
      `lWindow`,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftJsonForWindowsDoors = _.get(
        newDraftJson,
        `lWindow`
      );

      const numberOfWindowsDoors = _.size(draftJsonForWindowsDoors);
      setWindowsdoors(numberOfWindowsDoors);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addWindowDoor = () => {
    setWindowsdoors(windowsdoors + 1);

    const thisid = _.uniqueId("10") + getRandomInt(50000);   // get a random id

    _.set(globalJson, `lWindow.[${windowsdoors}].id`, thisid );


    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  useEffect(() => {
    setWindowsdoors(numberOfWindowsDoors);
  }, [numberOfWindowsDoors]);

  return {
    windowsdoors,
    addWindowDoor,
    removeWindowDoor,
  };
};
