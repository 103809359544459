import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { HouseIcon } from 'src/ui/elements/icons';

export class ComfortQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `ahh_cf`,
        extraOptions: { isAbsoluteTitle: true, IconComponent: HouseIcon },
      },

      {
        key: `aht_dl`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_dlsc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      // {
      //   key: `lVariant.${variantcaseIndex}.Active.ahr_dfAvg`,
      //   extraOptions: { disabled: true, gridColSpan: 6, roundDecimal: 2 },
      // },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_dfsAvg`,
        extraOptions: { disabled: true, gridColSpan: 6 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 6 } },
      {
        key: `aht_te`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_tesc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_telvl`,
        extraOptions: { disabled: true, gridColSpan: 6 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 6 } },
      {
        key: `aht_ia`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_iasc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_co2lvl`,
        extraOptions: { disabled: true, gridColSpan: 6 },
      },

      { key: `empty`, extraOptions: { gridColSpan: 6 } },
      {
        key: `aht_aq`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_aqsc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_aqlvl`,
        extraOptions: { disabled: true, gridColSpan: 6 },
      },
    ];
  }
}
