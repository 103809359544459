import { TextProps } from '@chakra-ui/core';
import React from 'react';
import { appFontSizes } from 'src/app/theme';
import { P } from '..';
import { FormGridItem } from './grid_items';

export const JustText = ({
  title,
  gridColSpan,
}: {
  fontSize?: typeof appFontSizes;
  textProps?: TextProps;
  title: string;
  gridColSpan: number;
}) => {
  return (
    <FormGridItem colSpan={gridColSpan} mt={6.5} mx={2}>
      <P
      //color={textProps?.color || appColors.primary.dark}
      //fontSize={textProps?.fontSize || appFontSizes.body}
      //fontWeight="bold"
      >
        {title}
      </P>
    </FormGridItem>
  );
};


export const JustNothing = ({
  gridColSpan,
}: {
  fontSize?: typeof appFontSizes;
  textProps?: TextProps;
  title?: string;
  gridColSpan: number;
}) => {
  return (
    <FormGridItem colSpan={gridColSpan}>
      
    </FormGridItem>
  );
};