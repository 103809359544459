import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { EnergySupplyIcon } from 'src/ui/elements/icons';

export class HeatSupplyQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_es`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: EnergySupplyIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_essc`,
        },
      },

      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `aht_devi`,
        extraOptions: {
          styleType: 1,
          gridColSpan: 2,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_eshd`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_espehd`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 2,
          gridColSpan: 2,
          roundDecimal: 2
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
    ];
  }
}

export class HeatSupplySytemsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0, index: number) {
    this.keys = [
      {
        key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceThrenw`,
        extraOptions: { gridColSpan: 2, disabled: true },
      },
      {
        key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahheatprod`,
        shouldDisable: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
            { key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahdeviceThrenw`, value: 6 },
        ]),
        extraOptions: { gridColSpan: 2, roundDecimal: 2 },
      },
      {
        key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahheatprodpef`,
        extraOptions: { gridColSpan: 1, disabled: true, 
          placeholderkey: `lVariant[${variantcaseIndex}].Active.ahdefpe` },
      },
      {
        key: `lVariant.[${variantcaseIndex}].HVAC.lSystem.${index}.lDevice.0.Active.ahr_pesum`,
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2 },
      },
    ];
  }
}
