import React from 'react';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from 'src/ui/tabs';
import { Target } from './Target';
import { Map } from './Map';
import { Help } from 'src/features/help/presentation/Help';
import { Status } from './Status';
import { Picture } from './picture';
import { i18n } from 'src/features/shared/translations/translate';
import { appColors } from 'src/app/theme';
import { useErrorReturnTrue } from '../application/use_input_state';

export const RightTopTabs = () => (
  <Tabs
    isLazy
  >
    <TabList>
      <Tab>{i18n.t(`tabs.help`)}</Tab>
      <Tab>{i18n.t(`tabs.target`)}</Tab>
      <Tab>{i18n.t(`tabs.map`)}</Tab>
      <Tab>{i18n.t(`tabs.picture`)}</Tab>
      <Tab 
        color={useErrorReturnTrue() ? appColors.red[500] : appColors.primary.light}
      >
        {useErrorReturnTrue() ? i18n.t(`error.error`) : i18n.t(`tabs.status`)}
      </Tab>
    </TabList>
    <TabPanels>
      <TabPanel px={2}>
        <Help />
      </TabPanel>
      <TabPanel px={2}>
        <Target />
      </TabPanel>
      <TabPanel px={2}>
        <Map />
      </TabPanel>
      <TabPanel px={2}>
        <Picture />
      </TabPanel>
      <TabPanel px={2}>
        <Status />
      </TabPanel>
    </TabPanels>
  </Tabs>
);
