export class AcousticScore {
    val: number;
    constructor({ insideSystemNoise, outsideSystemNoise, acousticPrivacy, useAcousticPrivacy }: 
      { insideSystemNoise?: number, outsideSystemNoise?: number, acousticPrivacy?: number, useAcousticPrivacy?:number }) {
        if (!insideSystemNoise || !outsideSystemNoise  || (!acousticPrivacy && useAcousticPrivacy==1)) {
          this.val = 5;
          return;
        }
    
        let inside = insideSystemNoise < 1.5
        ? 1
        : insideSystemNoise < 2.5
        ? 2
        : insideSystemNoise < 3.5
        ? 3
        : insideSystemNoise < 4.5
        ? 4
        : 5;

        let outside = outsideSystemNoise < 1.5
          ? 1
          : outsideSystemNoise < 2.5
          ? 2
          : outsideSystemNoise < 3.5
          ? 3
          : outsideSystemNoise < 4.5
          ? 4
          : 5;
      
        let privacy = 0;
        const acousticPrivacy__ = acousticPrivacy || 0;
        if (useAcousticPrivacy == 1) {
           privacy = acousticPrivacy__ < 1.5
            ? 1
            : acousticPrivacy__ < 2.5
            ? 2
            : acousticPrivacy__ < 3.5
            ? 3
            : acousticPrivacy__ < 4.5
            ? 4
            : 5;
        }
        
        if (useAcousticPrivacy == 1)
        {
          this.val = (inside + outside + privacy) / 3;
        }
        else
        {
          this.val = (inside + outside) / 2;
        }

        if (this.val > 4 || inside > 4 || privacy > 4 || (useAcousticPrivacy == 1? outside > 4 : false))
        {
          this.val = 5;
        }
          
      }
  }
  