import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { SustainableConstructionIcon } from 'src/ui/elements/icons';

export class SustainableQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_sc`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: SustainableConstructionIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_scsc`,
        },
      },

      { key: `aht_qsc1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc1c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qsc2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc2` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc2c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qsc3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc3` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc3c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qsc4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc4` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc4c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qsc5` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc5` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc5c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qsc6` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc6` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqsc6c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
