import _ from 'lodash';

import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { getNameOfDefinitionEnum } from 'src/features/shared/form/utils/get_name_for_definition_enum';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { oekobauJson } from 'src/features/shared/json/data/source/oekobau.data';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { i18n } from 'src/features/shared/translations/translate';
import { SustainableConstructionIcon } from 'src/ui/elements/icons';
import { constructionsFromJson, windowsdoorsFromJson } from '../use_construction_entries';


export class SustainableQuantityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_sc`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: SustainableConstructionIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_scsc`,
        },
      },
      { key: `aht_sccalc` },
      {
        key: `lVariant.${0}.Active.ahudefenvload`,
        extraOptions: { disabled: true, gridColSpan: 3 },
      },
      {
        key: `lVariant.${0}.Active.ahudefsusconst`,
        extraOptions: { disabled: true, gridColSpan: 3 },
      },
    ];
  }
}

export class SustainableQuantityInputJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      { key: `aht_b` },
      {
        key: `lVariant.${variantcaseIndex}.Active.aHlifetime`,
        extraOptions: { gridColSpan: 3, preLabel: i18n.t(`pages.building`) },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.aHspace`,
        extraOptions: { gridColSpan: 3, preLabel: i18n.t(`pages.building`) },
      },
      { 
        key: `emptydivider`
      },
      { 
        key: `empty`,
        extraOptions: { gridColSpan: 3},
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
        extraOptions: { gridColSpan: 3  }
      },
    ];
  }
}




export class SustainableAreasJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex: number, index: number, typeOfArea: number) {
    if (typeOfArea == 3) {
      this.keys = [
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].n`,
          extraOptions: { disabled: true, gridColSpan: 2 },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].idWtC`,
          extraOptions: { gridColSpan: 2 },
          dropdownOptionsFromJson: windowsdoorsFromJson,
        },
        { key: `empty`, extraOptions: { gridColSpan: 1 } },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaGWP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 1,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 1, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.gwp`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaODP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 2,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 42, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.odp`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaAP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 3,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ap`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaEP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 4,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ep`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaPOCP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 5,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 3, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.pocp`) },
        },
        // {
        //   key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.ahr_elsc`,
        //   extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 1, styleType: 2  },
        // },
      ];
    } else {
      this.keys = [
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].n`,
          extraOptions: { disabled: true, gridColSpan: 2 },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].idAssC`,
          extraOptions: { gridColSpan: 2 },
          dropdownOptionsFromJson: constructionsFromJson,
        },
        { key: `empty`, extraOptions: { gridColSpan: 1 } },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaGWP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 1,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 1, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.gwp`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaODP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 2,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 42, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.odp`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaAP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 3,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ap`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaEP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 4,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ep`)  },
        },
        {
          key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.lca.lcaPOCP`,
          shouldHide: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            {
              key: `lVariant.${variantcaseIndex}.Active.aHshnload`,
              value: 5,
            }
          ]),
          extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 3, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.pocp`)  },
        },
        // {
        //   key: `lVariant.${variantcaseIndex}.building.lComponent.[${index}].eco.ahr_elsc`,
        //   extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 1, styleType: 2  },
        // },
      ];
    }
  }
}


const ahTypeFromOkoJson = (currentJson: any, indexVar: number, indexOne: number, indexTwo: number) => {
  const draftJsonForMaterials = _.get(oekobauJson, 'oekobau');
  const thisdevicepath = `lVariant.[${indexVar}].HVAC.lSystem.[${indexOne}].lDevice.[${indexTwo}].eco.lcaMaterial`;
  const thisId = _.get(currentJson, thisdevicepath);

  const filtered = draftJsonForMaterials.filter(function (value, i, arr) {
    return value.id == thisId;
  });
  const emptymap = [
    { value: -1, label: 'not assigned' },
  ];
  const returna = filtered.map((material: { id: any; n: string; }) => {
    return {
      value: material.id,
      label: material.n
    }
  });
  const returnall = emptymap.concat(returna);
  return returnall;
};

const ahOpTypeFromOkoJson = (currentJson: any, indexVar: number, indexOne: number, indexTwo: number) => {
  const draftJsonForMaterials = _.get(oekobauJson, 'oekobau');
  const thisdevicepath = `lVariant.[${indexVar}].HVAC.lSystem.[${indexOne}].lOperation.[${indexTwo}].eco.lcaMaterial`;
  const thisId = _.get(currentJson, thisdevicepath);

  const filtered = draftJsonForMaterials.filter(function (value, i, arr) {
    return value.id == thisId;
  });
  const emptymap = [
    { value: -1, label: 'not assigned' },
  ];
  const returna = filtered.map((material: { id: any; n: string; }) => {
    return {
      value: material.id,
      label: material.n
    }
  });
  const returnall = emptymap.concat(returna);
  return returnall;
};




export class SustainableSystemDeviceJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(indexVariant = 0, indexSystem: number, indexDevice: number) {
    this.keys = [
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[0].n`,
        extraOptions: { gridColSpan: 2, disabled: true },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaMaterial`,
        extraOptions: { gridColSpan: 2, disabled: true, indexVar: indexVariant, indexOne: indexSystem, indexTwo: indexDevice },
        dropdownOptionsFromJson: ahTypeFromOkoJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaProd.lcaGWP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 1,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 1, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.gwp`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaProd.lcaODP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 2,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 42, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.odp`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaProd.lcaAP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 3,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ap`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaProd.lcaEP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 4,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ep`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[${indexDevice}].eco.lcaProd.lcaPOCP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 5,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 3, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.pocp`)  },
      },
    ];
  }
}

export class SustainableSystemOperationJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(indexVariant = 0, indexSystem: number, indexOperation: number) {
    this.keys = [
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lDevice.[0].n`,
        extraOptions: { gridColSpan: 2, disabled: true },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaMaterial`,
        extraOptions: { gridColSpan: 2, disabled: true, indexVar: indexVariant, indexOne: indexSystem, indexTwo: indexOperation },
        dropdownOptionsFromJson: ahOpTypeFromOkoJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaProd.lcaGWP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 1,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 1, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.gwp`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaProd.lcaODP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 2,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 42, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.odp`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaProd.lcaAP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 3,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ap`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaProd.lcaEP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 4,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 2, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.ep`)  },
      },
      {
        key: `lVariant.[${indexVariant}].HVAC.lSystem.[${indexSystem}].lOperation.[${indexOperation}].eco.lcaProd.lcaPOCP`,
        shouldHide: (json: typeof globalJSON) =>
        jsonValuesUtils.not(json)([
          {
            key: `lVariant.${indexVariant}.Active.aHshnload`,
            value: 5,
          }
        ]),
        extraOptions: { disabled: true, gridColSpan: 1, roundDecimal: 3, styleType: 2, noGridIfHide: true, noLabel: true, preLabel: i18n.t(`environmentload.pocp`)  },
      },
      // ahr_elsc

    ];
  }
}



export class EnvironmentalLoadUserDefinedJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_udel`
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elgwp`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
   
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elopd`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
     
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elpocp`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
   
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elap`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
    
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.lca.ah_elep`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
   
    ];
  }
}

export class SustainableConstructionUserDefinedJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_udsc`
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ah_scrc`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
   
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ah_scrv`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
     
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ah_sccw`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)
   
      {
        key: `lVariant.${variantcaseIndex}.Active.eco.sus.ah_scve`,
        extraOptions: { gridColSpan: 3 },
      }, // Dropdown (with selected item from dropdown)

    ];
  }
}