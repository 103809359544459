import React, { useEffect, useState } from 'react';

import { radarConfig } from './radar_config';
import { AnimatedEnter } from './components/animated';
import { NoOverflowBox } from './components/NoOverflowBox';
import RadarChart from './externalLib/components';
import { OutsideCircle } from './components/OutsideCircle';
import { radarTheme200, radarTheme400 } from './components/theme';
import { FlexCol } from 'src/ui';
import { useRadarData } from '../application/use_radar_data';

const { captions, options } = radarConfig;

export const Radar = ({ RadarLegend, Size }: any) => {
  const { radarData, dotsData, nationData } = useRadarData();


  const [visibleData, setVisibleData] = useState<any>({});
  // const filtered = radarData.filter((r: any) => !!visibleData[r.name]);

  // useEffect(() => {

  //   visibleData["Baseline"] = true;
  // }, []);

  const checkDots =
    dotsData[0].data.daylight > 1 || !dotsData[0].data.daylight ||
    dotsData[0].data.thermalEnvironment > 1 || !dotsData[0].data.daylight ||
    dotsData[0].data.indoorAirQuality > 1 || !dotsData[0].data.indoorAirQuality ||
    dotsData[0].data.acousticQuality > 1 || !dotsData[0].data.acousticQuality ||
    dotsData[0].data.energyDemand > 1 || !dotsData[0].data.energyDemand ||
    dotsData[0].data.primaryEnergyPerformance > 1 || !dotsData[0].data.primaryEnergyPerformance ||
    dotsData[0].data.energySupply > 1 || !dotsData[0].data.energySupply ||
    dotsData[0].data.sustainableConstruction > 1 || !dotsData[0].data.sustainableConstruction ||
    dotsData[0].data.freshwater > 1 || !dotsData[0].data.freshwater;

  const checkNation =
    nationData[0].data.daylight > 1 || !nationData[0].data.daylight ||
    nationData[0].data.thermalEnvironment > 1 || !nationData[0].data.daylight ||
    nationData[0].data.indoorAirQuality > 1 || !nationData[0].data.indoorAirQuality ||
    nationData[0].data.acousticQuality > 1 || !nationData[0].data.acousticQuality ||
    nationData[0].data.energyDemand > 1 || !nationData[0].data.energyDemand ||
    nationData[0].data.primaryEnergyPerformance > 1 || !nationData[0].data.primaryEnergyPerformance ||
    nationData[0].data.energySupply > 1 || !nationData[0].data.energySupply ||
    nationData[0].data.sustainableConstruction > 1 || !nationData[0].data.sustainableConstruction ||
    nationData[0].data.freshwater > 1 || !nationData[0].data.freshwater;

  let chartData = [...nationData, ...radarData, ...dotsData];

  if (checkDots && checkNation) {
    chartData = [...radarData];
  }
  if (!checkDots && checkNation) {
    chartData = [...radarData, ...dotsData];
  }
  if (checkDots && !checkNation) {
    chartData = [...nationData, ...radarData];
  }

  return (
    <FlexCol width="100%" align="center" pl={0} pt={3}>
      <FlexCol width="100%" alignItems="center" >
        {/* <AnimatedEnter> */}
        <OutsideCircle RadarSize={Size}>
          <NoOverflowBox>
            <RadarChart
              size={Size == 200 ? radarTheme200.size : radarTheme400.size}
              // size={Size}
              captions={captions}
              options={options}
              data={chartData}
            />
          </NoOverflowBox>
        </OutsideCircle>
        {/* </AnimatedEnter> */}
      </FlexCol>

      <RadarLegend {...{ radarData, setVisibleData, visibleData }} />
    </FlexCol>
  );
};
