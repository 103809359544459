import React from 'react';
import { ManagementQualityJsonDefinition } from 'src/features/baseline/data/qualitative/management_json_definitions';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const ManagementQualityPage: React.FC = () => {
  const managementjson = useWithVariantCaseIndex(
    ManagementQualityJsonDefinition
  );

  return <FormBuilder jsonKeys={managementjson.keys} />;
};

export default ManagementQualityPage;
