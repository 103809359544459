import React from 'react';
import bytes from 'bytes';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { Box, FlexCol, P } from '..';
import { ExternalLink, Icon, MinusIcon } from './icons';
import { appColors, appFontSizes } from 'src/app/theme';
import { UnderlineOnHover } from 'src/features/help/presentation/animations';
import { FlexRow } from '../structure';
import { i18n } from 'src/features/shared/translations/translate';

const DEFAULT_MAX_FILE_SIZE = bytes('10MB');



type Props = {
  isFileSet?: boolean;
  acceptedFiles?: Array<string>;
  fileMaxSize?: number;
  label?: {
    orDropFilesHereText: string;
    addFileText: string;
  };
  isDisabled?: boolean;
  isMultiple?: boolean;
  onUpload: DropzoneProps['onDrop'];
  onUploadFailed?: DropzoneProps['onDropRejected'];
  renderTrigger?: any;
};


export const DefaultFileUploadTrigger = ({
  label,
  inputProps,
  isFileSet,
}: {
  label: any;
  inputProps: any;
  isFileSet: any;
}) => (
  <Box
    padding="lg"
    border={isFileSet ? `2px dashed ${appColors.gray.lightest}` : '2px dashed #88B854'}
    borderColor={appColors.primary.dark}
  >
    <FlexCol
      pt={isFileSet ? 2 : 6}
      pb={isFileSet ? 2 : 6}
      alignItems="center"
      justifyContent="center"
    >
     {!isFileSet ? <Icon color={appColors.primary.main}>
        <MinusIcon height={40} width={40} />
      </Icon>
      : null}
      <P>{isFileSet ? i18n.t(`pages.filedone`) : label}</P>
      {/* <FileHeaderColumn variantcase={0} /> */}

      <input {...inputProps} />
    </FlexCol>
  </Box>
);

export const FileUploader = (props: Props) => (
  <Dropzone
    accept={props.acceptedFiles}
    onDropRejected={props.onUploadFailed}
    maxSize={props.fileMaxSize}
    maxFiles={1}
    disabled={props.isDisabled}
    onDrop={props.onUpload}
  >
    {({ getRootProps, getInputProps }) => (
      <Box outline="none" cursor="pointer" {...getRootProps()}>
        {props.renderTrigger({
          label: props.label,
          inputProps: getInputProps(),
          isFileSet: props.isFileSet,
        })}
      </Box>
    )}
  </Dropzone>
);

FileUploader.defaultProps = {
  label: i18n.t(`pages.importhourlydata`),
  acceptedFiles: '.csv', //'.jpeg,.jpg,.png,',
  fileMaxSize: DEFAULT_MAX_FILE_SIZE,
  renderTrigger: ({ label, inputProps, isFileSet }: any) => (
    <DefaultFileUploadTrigger label={label} inputProps={inputProps} isFileSet={isFileSet} />
  ),
};






export const SmallFileUploadTrigger = ({
  label,
  inputProps,
}: {
  label: any;
  inputProps: any;
}) => (
  <UnderlineOnHover borderColor={appColors.primary.main}>
    <P
      fontSize={appFontSizes.small}
      color={appColors.primary.main}
    >
      <FlexRow alignItems="center">
        <ExternalLink style={{ marginRight: 8, height: 30, width: 20 }} />
          {i18n.t(`pages.loadproject`)}
              <input {...inputProps} />
      </FlexRow>
    </P>
  </UnderlineOnHover>
);



export const ProjectUploader = (props: Props) => (
  <Dropzone
    accept={props.acceptedFiles}
    onDropRejected={props.onUploadFailed}
    maxSize={props.fileMaxSize}
    disabled={props.isDisabled}
    onDrop={props.onUpload}
  >
    {({ getRootProps, getInputProps }) => (
      <Box outline="none" cursor="pointer" {...getRootProps()}>
        {props.renderTrigger({
          label: props.label,
          inputProps: getInputProps(),
        })}
      </Box>
    )}
  </Dropzone>
);

ProjectUploader.defaultProps = {
  label: 'Drop file or search.',
  acceptedFiles: '.json', //'.jpeg,.jpg,.png,',
  fileMaxSize: DEFAULT_MAX_FILE_SIZE,
  renderTrigger: ({ label, inputProps }: any) => (
    <SmallFileUploadTrigger label={label} inputProps={inputProps} />
  ),
};