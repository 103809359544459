import React, { useState } from 'react';
import { motion } from 'framer-motion';

export const RevealContent = ({ children, isOpen }: any) => {
  const height = {
    height: isOpen ? 'auto' : 0,
    pointerEvents: !isOpen ? 'none' : 'initial',
  };

  const opacity = {
    opacity: isOpen ? 1 : 0,
  };

  return (
    <motion.div
      transition={{ delay: isOpen ? 0 : 0.1 }}
      initial={height as any}
      animate={height as any}
    >
      <motion.div
        transition={{ delay: isOpen ? 0.1 : 0, duration: 0.3 }}
        initial={opacity}
        animate={opacity}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export const RevealIcon = ({ children, isOpen }: any) => {
  const height = {
    height: isOpen ? 'auto' : 0,
    pointerEvents: !isOpen ? 'none' : 'initial',
  };

  const opacity = {
    opacity: isOpen ? 1 : 0,
  };

  return (
    <motion.div
      whileTap={{ scale: 1.1 }}
      transition={{ delay: isOpen ? 0 : 0.1 }}
      initial={height as any}
      animate={height as any}
    >
      <motion.div
        transition={{ delay: isOpen ? 0.1 : 0 }}
        initial={opacity}
        animate={opacity}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export const UnderlineOnHover = ({
  children,
  borderColor = 'black',
  borderWidth = '4px',
}: any) => {
  const [visible, setVisible] = useState(false);

  return (
    <motion.div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      style={{
        display: 'inline-block',
        cursor: 'pointer',
        overflow: 'hidden',
        maxWidth: 'max-content',
      }}
    >
      {children}
      <motion.div
        initial={{ x: 'calc(-1 * 100%)' }}
        transition={{ duration: 0.3 }}
        animate={{
          x: visible ? 0 : 'calc(-1 * 100%)',
        }}
      >
        <div
          style={{
            borderBottom: `${borderWidth} solid ${borderColor}`,
            maxWidth: '100%',
          }}
        />
      </motion.div>
    </motion.div>
  );
};
