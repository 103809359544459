import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { i18n } from 'src/features/shared/translations/translate';

export const useListOfRooms = (indexVariant: number, facade = globalJsonFacade) => {
  const [render, setRender] = useState(0);
  const [rooms, setRooms] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  // may change ROOM_DEFINITION_PATH to the current variantCase. but then, the rooms have to be set for all variant cases before.
  const draftJsonForRooms = _.get(globalJson, `lVariant.[${indexVariant}].building.lZone.[0].lRoom`);

  const numberOfRooms = _.size(draftJsonForRooms);

  useEffect(() => {
    setRooms(numberOfRooms);
  }, [numberOfRooms]);

  const removeThisRoom = (index: number, list: number) => {

    if (list === 1) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahrod`, false);
    }
    if (list === 2) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahrot`, false);
      // const indexFileData = (((_.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahcolumnte`) -3 ) / 2 ) - 1);
      // _.set(globalJson, `lVariant[${indexVariant}].Active.aHfiledata.aHfileR.[${indexFileData}].ahRteid`, -1);
    }
    if (list === 3) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahroi`, false);
      // _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahcolumnia`);
    }
    if (list === 4) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahroa`, false);
    }

    facade.save(globalJson).then((vv) => {
      setGlobalJson(vv);
    });

    setRender(render + 1);
  };


  const bringBackThisRoom = (index: number, list: number) => {
    if (list === 1) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahrod`, true);
    }
    if (list === 2) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahrot`, true);
    }
    if (list === 3) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahroi`, true);
    }
    if (list === 4) {
      _.set(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahroa`, true);
    }

    facade.save(globalJson).then((vv) => {
      setGlobalJson(vv);
    });

    setRender(render + 1);
  };


  const roomIsUsed = (index: number, list: number) => {

    if (list === 1) {
      return _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahrod`);
    }
    if (list === 2) {
      return _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahrot`);
    }
    if (list === 3) {
      return _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahroi`);
    }
    if (list === 4) {
      return _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahroa`);
    }
    return true;
  }

  const getRoomName = (index: number) => {

    const name = _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].n`);

    if (name == undefined) return _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahndef`);
    if (name == "NaN") return i18n.t(`pages.nan`);
    if (name == "") return _.get(globalJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom[${index}].Active.ahndef`);

    return name;
  }

  const notUsedRoomsDefined = (list: number) => {
    const globaldraftJsonForRooms = _.get(
      globalJson,
      `lVariant[${indexVariant}].building.lZone.[0].lRoom`
    );
    if (globaldraftJsonForRooms) {
      for (var dJFR of globaldraftJsonForRooms) {
        if (list === 1 && !_.get(dJFR, `Active.ahrod`)) {
          return true;
        }
        if (list === 2 && !_.get(dJFR, `Active.ahrot`)) {
          return true;
        }
        if (list === 3 && !_.get(dJFR, `Active.ahroi`)) {
          return true;
        }
        if (list === 4 && !_.get(dJFR, `Active.ahroa`)) {
          return true;
        }
      }
    }
    return false;

  }



  return {
    rooms,
    removeThisRoom,
    bringBackThisRoom,
    roomIsUsed,
    getRoomName,
    notUsedRoomsDefined
  };
};


