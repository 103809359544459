import _ from 'lodash';
import { calcHourlyIndoorAirComfort, calcHourlyThermalComfort } from "./comfort_conditions";
import { calcRuningMeanTemperature } from "./running_mean_temperature";

export const checkFileToRoomChangesThermalEnv = (draftJson: any, indexVariant: number, indexRoom: number, indexToFile: number) => {

  const countFileRooms = _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.ah_crooms`);

  const indexFileData = indexToFile < countFileRooms ? indexToFile : countFileRooms - 1;

  const fileDataId = _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.aHfileR.[${indexFileData}].ahRteid`);
  const roomId = _.get(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].id`);

  const usedIndexFileData = _.get(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].Active.ahcolumnte`);

  if (fileDataId == roomId) { // same Id, same room for fileset 

    const fileDataUseCooling = _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.aHfileR.[${indexFileData}].ahtecool`);
    const roomUseCooling = _.get(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].Active.ahtecool`);

    if (fileDataUseCooling != roomUseCooling) {
      updateFileToRoomThermalEnv(draftJson, indexVariant, indexRoom, indexFileData);
    }
  } else {
    updateFileToRoomThermalEnv(draftJson, indexVariant, indexRoom, indexFileData);
  }

  return indexFileData;
}

export const updateFileToRoomThermalEnv = (draftJson: any, indexVariant: number, indexRoom: number, indexFileData: number) => {
  const exteriorTemperature = _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.aHextT`);

  // console.log("update file "+ indexFileData + " to index room " + indexRoom);

  const runningMeanTemperature = calcRuningMeanTemperature(exteriorTemperature);
  const roomId = _.get(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].id`);
  const isMechColling = _.get(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].Active.ahtecool`) > 1 ? false : true;

  const thermComfort = calcHourlyThermalComfort(
    runningMeanTemperature,
    _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.aHfileR.[${indexFileData}].aHoptemp`),
    false,
    isMechColling
  );

  _.set(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].Active.ahcolumnte`, ((indexFileData + 1) * 2) + 3);

  _.set(draftJson, `lVariant.${indexVariant}.Active.aHfiledata.aHfileR.[${indexFileData}].ahRteid`, roomId);
  _.set(draftJson, `lVariant.${indexVariant}.Active.aHfiledata.aHfileR.[${indexFileData}].ahtecool`, isMechColling ? 1 : 2);

  _.set(draftJson, `lVariant.${indexVariant}.Active.aHfiledata.aHfileR.[${indexFileData}].aHRth`, thermComfort);
}


export const checkFileToRoomChangesIndoorAir = (draftJson: any, indexVariant: number, indexRoom: number, indexToFile: number) => {

  const countFileRooms = _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.ah_crooms`);

  const indexFileData = indexToFile < countFileRooms ? indexToFile : countFileRooms - 1;

  const fileDataId = _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.aHfileR.[${indexFileData}].ahRiaid`);
  const roomId = _.get(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].id`)

  if (fileDataId != roomId) { // same Id, same room for fileset 
    updateFileToRoomIndoorAir(draftJson, indexVariant, indexRoom, indexFileData);
  }

  return indexFileData;
}

export const updateFileToRoomIndoorAir = (draftJson: any, indexVariant: number, indexRoom: number, indexFileData: number) => {
  const exteriorTemperature = _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.aHextT`);

  const runningMeanTemperature = calcRuningMeanTemperature(exteriorTemperature);
  const roomId = _.get(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].id`);
 
  const indoorAirComfort = calcHourlyIndoorAirComfort(
    runningMeanTemperature,
    _.get(draftJson, `lVariant[${indexVariant}].Active.aHfiledata.aHfileR.[${indexFileData}].aHco2`),
    400,
  )

  _.set(draftJson, `lVariant[${indexVariant}].building.lZone.[0].lRoom.[${indexRoom}].Active.ahcolumnia`, ((indexFileData + 1) * 2) + 4);

  _.set(draftJson, `lVariant.${indexVariant}.Active.aHfiledata.aHfileR.[${indexFileData}].ahRiaid`, roomId);
  _.set(draftJson, `lVariant.${indexVariant}.Active.aHfiledata.aHfileR.[${indexFileData}].aHRina`, indoorAirComfort);
}


