import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { AcousticQualityJsonDefinition } from '../../../data/qualitative/acoustic_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const AcousticQualityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(AcousticQualityJsonDefinition);

  return <FormBuilder jsonKeys={keys} />;
};

export default AcousticQualityPage;
