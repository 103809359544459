import React from 'react';
import { useParams } from 'react-router';
import { useSystemTypeWithIndex } from 'src/features/project_information/presentation/applications/check_type_of_systems';
import { AnimateList } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, P, FlexRow } from 'src/ui';
import { PrimaryElectricityDemandJsonDefinition, PrimaryElectricitySupplyJsonDefinition, PrimaryEnergyQuantityJsonDefinition, PrimaryThermalDemandHeadlineJsonDefinition, PrimaryThermalDemandJsonDefinition, PrimaryThermalSupplyJsonDefinition } from '../../../data/quantitative/primaryenergy_json_definition';
import { useListOfSystems } from '../use_systems_list';
import { getVariantCaseNumber, useWithVariantCaseIndex } from '../use_with_variantcase_index';



export const PrimaryEnergyDivider: React.FC<any> = ({
  variantcase,
  typeOfSystem
}) => {
  const Json = new PrimaryThermalDemandHeadlineJsonDefinition(variantcase, typeOfSystem);
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
      </FlexRow>
    </>
  );
};

export const PrimaryEnergy: React.FC<any> = ({
  variantcase,
  index,
  typeOfSystem,
  systemIsUsed
}) => {
  const Json =
    typeOfSystem == 1 ? new PrimaryThermalDemandJsonDefinition(variantcase, index) :
      typeOfSystem == 2 ? new PrimaryElectricityDemandJsonDefinition(variantcase, index) :
        typeOfSystem == 3 ? new PrimaryThermalSupplyJsonDefinition(variantcase, index) :
          new PrimaryElectricitySupplyJsonDefinition(variantcase, index);


  if (typeOfSystem === useSystemTypeWithIndex(index, variantcase) && systemIsUsed(index)) {
    return (
      <>
        <FlexRow alignItems="center">
          <FormBuilder jsonKeys={Json.keys} />
        </FlexRow>

        {/* <Divider my={2} /> */}
      </>
    );
  } else {
    return <></>;
  }
};





export const PrimaryEnergyQuantityPage: React.FC = () => {
  const { keys } = useWithVariantCaseIndex(PrimaryEnergyQuantityJsonDefinition);
  const { variantcase } = useParams() as { variantcase: string };
  const variantcaseIndex = getVariantCaseNumber(variantcase) as number;
  const { systems, systemIsUsed } = useListOfSystems(variantcaseIndex);
  const mappingSystems = [...(Array(systems).keys() as any)];

  return (
    <>
      <FormBuilder jsonKeys={keys} />

      <PrimaryEnergyDivider
        variantcase={variantcaseIndex}
        typeOfSystem={1}
      />

      <Box my={-2}>
        {mappingSystems.length > 0 ? null : <P>{i18n.t(`pages.NoSystemdefined`)}</P>}
        {mappingSystems.map((a, i) => (
          <AnimateList key={i}>
            <PrimaryEnergy
              key={systems}
              variantcase={variantcaseIndex}
              index={a}
              typeOfSystem={1}
              systemIsUsed={systemIsUsed}
            />
          </AnimateList>
        ))}
      </Box>

      <PrimaryEnergyDivider
        variantcase={variantcaseIndex}
        typeOfSystem={2}
      />

      <Box my={-2}>
        {mappingSystems.length > 0 ? null : <P>{i18n.t(`pages.NoSystemdefined`)}</P>}
        {mappingSystems.map((a, i) => (
          <AnimateList key={i}>
            <PrimaryEnergy
              key={systems}
              variantcase={variantcaseIndex}
              index={a}
              typeOfSystem={2}
              systemIsUsed={systemIsUsed}
            />
          </AnimateList>
        ))}
      </Box>

      <PrimaryEnergyDivider
        variantcase={variantcaseIndex}
        typeOfSystem={3}
      />
      <Box my={-2}>
        {mappingSystems.length > 0 ? null : <P>{i18n.t(`pages.NoSystemdefined`)}</P>}
        {mappingSystems.map((a, i) => (
          <AnimateList key={i}>
            <PrimaryEnergy
              key={systems}
              variantcase={variantcaseIndex}
              index={a}
              typeOfSystem={3}
              systemIsUsed={systemIsUsed}
            />
          </AnimateList>
        ))}
      </Box>

      <PrimaryEnergyDivider
        variantcase={variantcaseIndex}
        typeOfSystem={4}
      />
      <Box my={-2}>
        {mappingSystems.length > 0 ? null : <P>{i18n.t(`pages.NoSystemdefined`)}</P>}
        {mappingSystems.map((a, i) => (
          <AnimateList key={i}>
            <PrimaryEnergy
              key={systems}
              variantcase={variantcaseIndex}
              index={a}
              typeOfSystem={4}
              systemIsUsed={systemIsUsed}
            />
          </AnimateList>
        ))}
      </Box>


    </>
  );
};

export default PrimaryEnergyQuantityPage;
function systemIsUsed(index: any) {
  throw new Error('Function not implemented.');
}

