import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { EnergySupplyQualityJsonDefinition } from '../../../data/qualitative/energysupply_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const EnergySupplyQualityPage: React.FC = () => {
  const energydemandjson = useWithVariantCaseIndex(
    EnergySupplyQualityJsonDefinition
  );

  return <FormBuilder jsonKeys={energydemandjson.keys} />;
};

export default EnergySupplyQualityPage;
