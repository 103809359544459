import React from 'react';
import {
  NumberInputWithLabel,
  SelectWithLabel,
  SelectWithLabelProps,
  TextAreaInputWithLabel,
  TextAreaInputWithLabelProps,
  TextInputWithLabel,
} from '..';
import { NumberInputWithLabelProps, TextInputWithLabelProps } from '../elements';
import { OutputWithLabel, SelectOutputWithLabel, SimpleLabel, SimpleOutput } from '../elements/outputs';
// import { TextInputWithLabel, TextInputWithLabelProps } from '../elements';
import { FormGridItem } from './grid_items';

export const FormTextInput = (
  props: TextInputWithLabelProps & { gridColSpan: number }
) => {

  if (props.isDisabled) {
    return (
      <FormGridItem mt={2} colSpan={props.gridColSpan || 6}>
        <OutputWithLabel {...props} />
      </FormGridItem>
    );
  }
  else {
    return (
      <FormGridItem mt={2} colSpan={props.gridColSpan || 6}>
        <TextInputWithLabel {...props} />
      </FormGridItem>
    );
  }
};

export const FormNumberInput = (
  props: NumberInputWithLabelProps & { gridColSpan: number }
) => {

  if (props.isDisabled) {
    return (
      <FormGridItem mt={2} colSpan={props.gridColSpan || 6}>
        <OutputWithLabel {...props} />
      </FormGridItem>
    );
  }
  else {
    return (
      <FormGridItem mt={2} colSpan={props.gridColSpan || 6}>
        <NumberInputWithLabel {...props} />
      </FormGridItem>
    );
  }
};

export const FormSelect = (
  props: SelectWithLabelProps & { gridColSpan: number }
) => {
  if (props.valuetext) {
    return (
      <FormGridItem mt={2} colSpan={props.gridColSpan || 6}>
        <SelectOutputWithLabel {...props} />
      </FormGridItem>
    );
  }
  else {
    return (
      <FormGridItem mt={2} mb={1} colSpan={props.gridColSpan || 6}>
        <SelectWithLabel {...props} />
      </FormGridItem>
    );
  }
};

export const FormTextAreaInput = (
  props: TextAreaInputWithLabelProps & { gridColSpan: number }
) => {
  return (
    <FormGridItem mt={2} mb={1} colSpan={props.gridColSpan || 6}>
      <TextAreaInputWithLabel {...props} />
    </FormGridItem>
  );
};


export const FormSimpleOutput = (
  props: TextInputWithLabelProps & { gridColSpan: number }
) => {
  const gridColSpan = props.gridColSpan || 6;

  return (
    <>
      <FormGridItem mt={2} colSpan={gridColSpan - 1}>
        <SimpleLabel {...props} />
      </FormGridItem>
      <FormGridItem mt={2} colSpan={1}>
        <SimpleOutput {...props} />
      </FormGridItem>
    </>
  );

};
