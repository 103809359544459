import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';

export const ROOM_DEFINITION_PATH = 'lVariant[0].building.lZone.[0].lRoom';

export class RoomsTitleJsonDefinition implements JsonDefinition {
  keys: any;

  constructor() {
    this.keys = [{ key: 'aht_r' }];
  }
}

export class RoomsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(index: number) {
    this.keys = [
      {
        key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        shouldDisable: (json: typeof globalJSON) =>
        jsonValuesUtils.or(json)([
            { key: `projD.ahbtype`, value: 2 },
          { key: `projD.ahbtype`, value: 3 },
          { key: `projD.ahbtype`, value: 4 }
        ]),
        extraOptions: { gridColSpan: 2 },
      }, 
      {
        key: `${ROOM_DEFINITION_PATH}.[${index}].n`,
        extraOptions: { gridColSpan: 2,
        placeholderkey: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahndef` },
      }, 
      // {
      //   key: `${ROOM_DEFINITION_PATH}.[${index}].n`,
      //   extraOptions: { gridColSpan: 1 },
      // },
      {
        key: `${ROOM_DEFINITION_PATH}.[${index}].Active.aharea`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahdhours`,
        // shouldDisable: (json: typeof globalJSON) =>
        //   jsonValuesUtils.or(json)([
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 1,
        //     },
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 2,
        //     },
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 3,
        //     },
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 4,
        //     },
        //   ]),
        extraOptions: { gridColSpan: 2,
          placeholderkey: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahdhdef`, },
      }, 
      {
        key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahdlhours`,
        // shouldDisable: (json: typeof globalJSON) =>
        //   jsonValuesUtils.or(json)([
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 1,
        //     },
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 2,
        //     },
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 3,
        //     },
        //     {
        //       key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahroomtype`,
        //       value: 4,
        //     },
        //   ]),
        extraOptions: { gridColSpan: 2,
          placeholderkey: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahdlhdef`, },
      }, 
      {
        key: `${ROOM_DEFINITION_PATH}.[${index}].Active.ahocu`,
        extraOptions: { gridColSpan: 2 },
      },
    ];
  }
}
