import { FlexProps } from '@chakra-ui/core';
import React from 'react';
import { i18n } from 'src/features/shared/translations/translate';
import { FlexCol } from 'src/ui';
import { radarTheme200, radarTheme400 } from './theme';

const align = { align: 'center', justify: 'center' } as any;



const TextContainer: React.FC<
  FlexProps & { rotateDeg: string | number; label: string; RadarSize:number }
> = ({ rotateDeg, label, RadarSize, ...props }) => (
  <FlexCol
    {...align}
    bg={RadarSize == 200 ? radarTheme200.outsideCircle.textBackgroundColor : radarTheme400.outsideCircle.textBackgroundColor}
    color={RadarSize == 200 ? radarTheme200.outsideCircle.textColor : radarTheme400.outsideCircle.textColor}
    position={'absolute'}
    p={ 1}
    style={{ transform: `rotate(${rotateDeg}deg)` }}
    {...props}
  >
    {label}
  </FlexCol>
);

export const OutsideCircle: React.FC<{RadarSize:number}> = ({children , RadarSize} : any) => {

  const radarTheme = RadarSize == 200 ? radarTheme200 : radarTheme400

  return (
  <FlexCol overflow="hidden">
    <FlexCol
      {...align}
      mt={2}
      p={6}
      width={radarTheme.outsideCircleSize}
      height={radarTheme.outsideCircleSize}
      fontSize={12}
      borderRadius="50%"
      borderType="solid"
      borderWidth={radarTheme.outsideCircle.borderWidth}
      borderColor={radarTheme.outsideCircle.borderColor}
      position="relative"
    >
      <TextContainer
        top={radarTheme.outsideCircle.labelConfig.comfort.top}
        rotateDeg={radarTheme.outsideCircle.labelConfig.comfort.rotateDeg}
        label={i18n.t(`radar.comfort`)}
        RadarSize={RadarSize}
      />

      <TextContainer
        width="80px"
        left={radarTheme.outsideCircle.labelConfig.enviroment.left}
        bottom={radarTheme.outsideCircle.labelConfig.enviroment.bottom}
        rotateDeg={radarTheme.outsideCircle.labelConfig.enviroment.rotateDeg}
        label={i18n.t(`radar.environment`)}
        RadarSize={RadarSize}
      />

      <TextContainer
        right={radarTheme.outsideCircle.labelConfig.energy.right}
        bottom={radarTheme.outsideCircle.labelConfig.energy.bottom}
        rotateDeg={radarTheme.outsideCircle.labelConfig.energy.rotateDeg}
        label={i18n.t(`radar.energy`)}
        RadarSize={RadarSize}
      />
      {children}
    </FlexCol>
  </FlexCol>
  )
};
