import _ from 'lodash';
import { useContext } from 'react';
import { getVariantCaseNumber } from 'src/features/baseline/presentation/pages/use_with_variantcase_index';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';

export const useVariantName = (variantCaseString:string, maxlength:number) => {
  const { globalJson } = useContext(JsonContext);

  const casenumber = getVariantCaseNumber(variantCaseString);
  const variantNamePath = `lVariant.[${casenumber}].n`;
  const variantNameFromJson = _.get(globalJson, variantNamePath);

  const variantNameResult = variantNameFromJson ? variantNameFromJson : casenumber == 0 ? variantCaseString : "Case " + (casenumber + 1);

  const variantNameShorten = variantNameResult.length > maxlength ? variantNameResult.slice(0,maxlength).concat('...') : variantNameResult;

  return variantNameShorten;
};


export const useVariantNameFromIndex = (variantCase:number) => {
  const { globalJson } = useContext(JsonContext);

  const variantNamePath = `lVariant.[${variantCase}].n`;
  const variantNameFromJson = _.get(globalJson, variantNamePath);

  const variantNameResult = variantNameFromJson ? variantNameFromJson : variantCase == 0 ? "baseline" : "Case " + (variantCase + 1);

  return variantNameResult;
};

