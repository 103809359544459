import { useMemo } from 'react';
import { useParams } from 'react-router';

export const WrongVariantCaseError =
  'VariantCase should be baseline or variantcasen: Ex: variantcase1, variantcase2...variantcase100';

export const getVariantCaseNumber = (variantcase: string) => {
  if (variantcase === 'baseline') return 0;
  if (!variantcase.includes('variantcase')) throw WrongVariantCaseError;

  if (variantcase.length === 'variantcase'.length + 1) {
    return Number(variantcase.charAt(variantcase.length - 1));
  }

  if (variantcase.length === 'variantcase'.length + 2) {
    return Number(
      variantcase.substr(variantcase.length - 2, variantcase.length - 1)
    );
  }

  if (variantcase.length === 'variantcase'.length + 3) {
    return Number(
      variantcase.substr(variantcase.length - 3, variantcase.length - 1)
    );
  }

  throw WrongVariantCaseError;
};

export function useWithVariantCaseIndex(ClassToStart: any) {
  const { variantcase } = useParams() as { variantcase: string };

  return useMemo(() => {
    const variantcaseIndex = getVariantCaseNumber(variantcase) as number;

    return new ClassToStart(variantcaseIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantcase]);
}

