import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { ThermalQualityJsonDefinition } from '../../../data/qualitative/thermal_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const ThermalQualityPage: React.FC = () => {
  const thermaljson = useWithVariantCaseIndex(ThermalQualityJsonDefinition);

  return <FormBuilder jsonKeys={thermaljson.keys} />;
};

export default ThermalQualityPage;
