// import React from 'react';

export const calcHourlyThermalComfort = (
  runningMeanTemperatures: any,
  operativeRoomTemperatures: any,
  isNightSetback: boolean,
  isMechColling: boolean,
) => {

  const summerTRMborder = 12;

  const dayBeginHour = 8;
  const dayEndHour = 22;

  let daytime = 0;
  let nightSetbackTemperature = 0;

  let winterScore = new Array();
  let summerScore = new Array();

  let countErrorData = 0;

  // const newoperativeRoomTemperatures = oldArray.filter( value => !Number.isNaN(value) );

  for (let i in operativeRoomTemperatures) {
    if (Number.isNaN(operativeRoomTemperatures[i]) || Number.isNaN(runningMeanTemperatures[i])) {
      countErrorData++;
    }
    else {

      if (runningMeanTemperatures[i] < summerTRMborder)  //winter
      {
        nightSetbackTemperature = isNightSetback ?
          daytime < dayBeginHour && daytime > dayEndHour ? 2 : 0
          : 0;

        winterScore.push(
          operativeRoomTemperatures[i] >= 21 - nightSetbackTemperature ? 1
            : operativeRoomTemperatures[i] >= 20 - nightSetbackTemperature ? 2
              : operativeRoomTemperatures[i] >= 19 - nightSetbackTemperature ? 3
                : operativeRoomTemperatures[i] >= 18 - nightSetbackTemperature ? 4
                  : 5);

        // summerScore.push(NaN);
      }
      else {
        if (isMechColling) {
          // WENN(M10<=25.5;"I";WENN(M10<=26;"II";WENN(M10<=27;"III";WENN(M10<=28;"IV";"X"))))
          summerScore.push(
            operativeRoomTemperatures[i] <= 25.5 ? 1
              : operativeRoomTemperatures[i] <= 26 ? 2
                : operativeRoomTemperatures[i] <= 27 ? 3
                  : operativeRoomTemperatures[i] <= 28 ? 4
                    : 5);
        }
        else {
          // WENN(M10<=0.33*$J10+20.8;"I";WENN(M10<=0.33*$J10+21.8;"II";WENN(M10<=0.33*$J10+22.8;"III";WENN(M10<=0.33*$J10+23.8;"IV";"X"))))
          summerScore.push(
            operativeRoomTemperatures[i] < 0.33 * runningMeanTemperatures[i] + 20.8 ? 1
              : operativeRoomTemperatures[i] < 0.33 * runningMeanTemperatures[i] + 21.8 ? 2
                : operativeRoomTemperatures[i] < 0.33 * runningMeanTemperatures[i] + 22.8 ? 3
                  : operativeRoomTemperatures[i] < 0.33 * runningMeanTemperatures[i] + 23.8 ? 4
                    : 5);
        }
      }
    }
    daytime++;
    if (daytime > 24) {
      daytime = 0;
    }
  }

  const ahr_ftws = countHourlyScores(winterScore);
  const ahr_ftss = countHourlyScores(summerScore);

  return { ahr_ftws, ahr_ftss };
};



export const calcHourlyIndoorAirComfort = (
  runningMeanTemperatures: any,
  co2concentrations: any,
  outdoorCo2concentration: number,
) => {
  const summerTRMborder = 12;

  let co2Score = new Array();
  let winterCo2Score = new Array();
  let summerCo2Score = new Array();

  let countErrorData = 0;

  for (let i in co2concentrations) {
    if (Number.isNaN(co2concentrations[i])) {
      countErrorData++;
    }
    else {
      const currentCo2Score = (
        co2concentrations[i] - outdoorCo2concentration <= 400 ? 1
          : co2concentrations[i] - outdoorCo2concentration <= 550 ? 2
            : co2concentrations[i] - outdoorCo2concentration <= 800 ? 3
              : co2concentrations[i] - outdoorCo2concentration <= 1100 ? 4
                : 5);

      co2Score.push(currentCo2Score);

      if (runningMeanTemperatures[i] < summerTRMborder)  // winter 
      {
        winterCo2Score.push(currentCo2Score);
      } else {
        summerCo2Score.push(currentCo2Score);
      }


    }
  }

  const ahr_tco2s = countHourlyScores(co2Score);
  const ahr_twco2s = countHourlyScores(winterCo2Score);
  const ahr_tsco2s = countHourlyScores(summerCo2Score);

  return { ahr_tco2s, ahr_twco2s, ahr_tsco2s }

}





const countHourlyScores = (hourlyScores: any) => {

  let score1 = 0;
  let score2 = 0;
  let score3 = 0;
  let score4 = 0;
  let score5 = 0;

  for (let hour of hourlyScores) {
    if (hour === 1) {
      score1++;
    } else if (hour === 2) {
      score2++;
    } else if (hour === 3) {
      score3++;
    } else if (hour === 4) {
      score4++;
    } else {
      score5++;
    }
  }

  const ahr_fpc1 = score1 / hourlyScores.length;
  const ahr_fpc2 = score2 / hourlyScores.length;
  const ahr_fpc3 = score3 / hourlyScores.length;
  const ahr_fpc4 = score4 / hourlyScores.length;
  const ahr_fpc5 = score5 / hourlyScores.length;

  const ahr_ffs =
    ahr_fpc1 >= 0.95 ? 1
      : ahr_fpc1 + ahr_fpc2 >= 0.95 ? 2
        : ahr_fpc1 + ahr_fpc2 + ahr_fpc3 >= 0.95 ? 3
          : ahr_fpc1 + ahr_fpc2 + ahr_fpc3 + ahr_fpc4 >= 0.95 ? 4
            : 5;

  return { ahr_fpc1, ahr_fpc2, ahr_fpc3, ahr_fpc4, ahr_fpc5, ahr_ffs };
};



