import { motion } from 'framer-motion';
import React from 'react';
import { Box } from 'src/ui';

export const AnimatePageEnter: React.FC = ({ children }) => (
  <>
    <motion.div>{children}</motion.div>
  </>
);

export const PageContainer: React.FC<any> = ({ children, animate }) => {
  return (
    <>
      <Box pb={4}>{children}</Box>
    </>
  );
};

export const AnimateList: React.FC = ({ children }) => (
  <>
    <motion.div>{children}</motion.div>
  </>
);
