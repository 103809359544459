import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { EnergyQualityJsonDefinition } from '../../../data/qualitative/energy_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const EnergyQualityPage: React.FC = () => {
  const energyjson = useWithVariantCaseIndex(EnergyQualityJsonDefinition);

  return <FormBuilder jsonKeys={energyjson.keys} />;
};

export default EnergyQualityPage;
