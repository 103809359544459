import { Collapse, useDisclosure } from '@chakra-ui/core';
import React from 'react';
import { appColors } from 'src/app/theme';
import { AnimateList, AnimatePageEnter } from 'src/features/shared/animated';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { i18n } from 'src/features/shared/translations/translate';
import { Box, Divider, FlexRow, FlexCol, P, ButtonBase, globalButtonProps } from 'src/ui';
import { TrashIcon } from 'src/ui/elements/icons';
import {
  ConstructionsTitleJsonDefinition,
  LayerExpandJsonDefinition,
} from '../../data/constructions_json_definitions';
import { WindowCasementJsonDefinition, WindowFrameJsonDefinition, WindowHandlesJsonDefinition, WindowIronworkJsonDefinition, WindowJointtapeJsonDefinition, WindowPaneJsonDefinition, WindowsDoorsJsonDefinition } from '../../data/windowsdoors_json_definitions';
import { useSustainableConstructionCalc } from '../applications/use_number_of_areas';
import { useNumberOfWindowsDoors } from '../applications/use_number_of_windowsdoors';

const titleDefinition = new ConstructionsTitleJsonDefinition();


const LayerExpand: React.FC<any> = ({
  indexConstruction,
  indexLayer,
  onRemoveLayer,
}) => {
  const Json = new LayerExpandJsonDefinition(indexConstruction, indexLayer);
  return (
    <>
        <FlexRow alignItems="center">
          <P mr={5}> </P>
          <FormBuilder jsonKeys={Json.keys} />
        </FlexRow>
    </>
  );
};



const WindowDoor: React.FC<any> = ({
  indexWindowDoor,
  onRemoveWindowDoor,
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const Json = new WindowsDoorsJsonDefinition(indexWindowDoor);

  const FrameJson = new WindowFrameJsonDefinition(indexWindowDoor);
  const CasementJson = new WindowCasementJsonDefinition(indexWindowDoor);
  const PaneJson = new WindowPaneJsonDefinition(indexWindowDoor);
  const HandleJson = new WindowHandlesJsonDefinition(indexWindowDoor);
  const SmallIronJson = new WindowIronworkJsonDefinition(indexWindowDoor);
  const TapeJson = new WindowJointtapeJsonDefinition(indexWindowDoor);
 
  return (
    <>
      <FlexRow alignItems="center">
        <FormBuilder jsonKeys={Json.keys} />
        <ButtonBase 
            mt={1}
            ml ={2}
            {...globalButtonProps(appColors.primary.main, isOpen)}
            onClick={onToggle}>
              <P>{i18n.t(`pages.expand`)}</P>
          </ButtonBase>
        <FlexCol
          pb={5}
          pl={1}
          alignSelf="flex-end"
          as="button"
          onClick={() => onRemoveWindowDoor(indexWindowDoor)}
        >
          <AnimatePageEnter>
            <TrashIcon />
          </AnimatePageEnter>
        </FlexCol>
      </FlexRow>
      <Collapse in={isOpen} animateOpacity>
      <FlexRow alignItems="center"><FormBuilder jsonKeys={FrameJson.keys} /></FlexRow>
        <FlexRow alignItems="center"><FormBuilder jsonKeys={CasementJson.keys} /></FlexRow>
        <FlexRow alignItems="center"><FormBuilder jsonKeys={PaneJson.keys} /></FlexRow>
        <FlexRow alignItems="center"><FormBuilder jsonKeys={HandleJson.keys} /></FlexRow>
        <FlexRow alignItems="center"><FormBuilder jsonKeys={SmallIronJson.keys} /></FlexRow>
        <FlexRow alignItems="center"><FormBuilder jsonKeys={TapeJson.keys} /></FlexRow>
      </Collapse>
      <Divider my={4} />
    </>
  );
};

export const WindowsDoorsPage: React.FC = () => {

  const { useEnvironmentLoads, useSustainableConstruction } = useSustainableConstructionCalc();

  if (useEnvironmentLoads || useSustainableConstruction) {
    return (
      <>
        <FormBuilder jsonKeys={titleDefinition.keys} />

        <WindowsDoorsInputPage />
      </>
    );
  } else {
    return (
      <>
        <FormBuilder jsonKeys={titleDefinition.keys} />

        <P>{i18n.t(`pages.noneed`)}</P>
      </>
    );
  }
};

export const WindowsDoorsInputPage: React.FC = () => {
  const {
    windowsdoors,
    addWindowDoor,
    removeWindowDoor,
  } = useNumberOfWindowsDoors();
  const mappingWindowsDoors = [...(Array(windowsdoors).keys() as any)];

  return (
    <>
      {/* <FormBuilder jsonKeys={titleDefinition.keys} /> */}
      {mappingWindowsDoors.map((a, i) => (
        <AnimateList key={i}>
          <WindowDoor
            key={windowsdoors}
            indexWindowDoor={i}
            onRemoveWindowDoor={removeWindowDoor}
          />
        </AnimateList>
      ))}

      <AnimatePageEnter>
        <Box as="button" mt={2} onClick={addWindowDoor}>
          <P style={{ textDecoration: 'underline' }} color={appColors.primary.main}>
            {i18n.t(`add.window`)}
          </P>
        </Box>
      </AnimatePageEnter>
    </>
  );
};
