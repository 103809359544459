import { Divider, useDisclosure } from '@chakra-ui/core';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { appColors } from 'src/app/theme';
import { i18n } from 'src/features/shared/translations/translate';
import { ButtonBase, FlexCol, FlexRow, globalButtonProps, P } from 'src/ui';
import { InformationIcon, QuestionIcon } from 'src/ui/elements/icons';
import { onWidgetArticle } from '../application/freshworks_widget';
import {
  availableArticles,
  getArticleLink,
  useHelpArticles,
} from '../application/useHelpArticles';
import { HelpArticle } from '../domain/help_article';
import { RevealContent, UnderlineOnHover } from './animations';
import { AnotherTabLink, CloseOpenIcons } from './components';

const HelpArticleComponent: React.FC<{
  isOpen: boolean;
  onOpen: () => any;
  id: string;
}> = ({ isOpen, onOpen, id }) => {
  const { helpArticle } = useHelpArticles(id);

  if (!(helpArticle instanceof HelpArticle)) return null;

  return (
    <FlexCol mb={1}>
      <FlexRow
        as="button"
        onClick={() => onOpen()}
        justify="space-between"
        outline="none"
        align="left"
      >
        <P textAlign="left">{helpArticle.title}</P>
        <CloseOpenIcons isOpen={isOpen} />
      </FlexRow>
      <RevealContent isOpen={isOpen}>
        <P mt={1} color={appColors.gray.dark} noOfLines={5}>
          {helpArticle.description}
        </P>
        <UnderlineOnHover borderColor={appColors.green[50]}>
          <AnotherTabLink
            label={i18n.t('help.link_out')}
            link={getArticleLink(helpArticle.id)}
          ></AnotherTabLink>
        </UnderlineOnHover>
      </RevealContent>
    </FlexCol>
  );
};



export const Help: any = () => {
  const [open, setOpen] = useState<boolean>(true);
  // () => openWidgetArticle()

  return (
    <>
      <FlexCol width="100%" alignItems="center"> 
        <ButtonBase
          mt={1}
          ml={2}
          mb={4}
          width="150px"
          {...globalButtonProps(appColors.secondary.light, !open)}
          onClick={() => {
            onWidgetArticle(open);
            setOpen(!open);
          }}>
           <P><QuestionIcon /></P> 
          <P mx={2}>{i18n.t(`help.open`)}</P>
        </ButtonBase>
      </FlexCol>
      <Divider mb={2} />

      <HelpArticles />

    </>
  );
};


export const HelpArticles: any = () => {
  const [open, setOpen] = useState<string | null>(null);

  const { pathname } = useLocation();
  const ids = availableArticles(pathname);

  return ids.map((id: string) => {
    const isOpen = id === open;
    return (
      <>
        <HelpArticleComponent
          onOpen={() => (isOpen ? setOpen(null) : setOpen(id))}
          isOpen={isOpen}
          key={id}
          id={id}
        />
      </>
    );
  });

};
