export class EnergyDemandScore {
    val: number;
    constructor({ energyDemand }: { energyDemand?: number }) {
      if (!energyDemand) {
        this.val = NaN;
        return;
      }

      this.val =
            energyDemand <= 40
          ? 1
          : energyDemand  <= 60
          ? 1+((2-1)/(60-40))*(energyDemand-40)
          : energyDemand  <= 80
          ? 2+((3-2)/(80-60))*(energyDemand-60)
          : energyDemand  <= 100
          ? 3+((3-2)/(100-80))*(energyDemand-80)
          : 5;
        
    }
  }

export class EnergySupplyScore {
    val: number;
    percentageCoverDemand: number;
    constructor({ energyDemand, energySupply }: { energyDemand?: number , energySupply?: number  }) {
      if (!energyDemand || !energySupply) {
        this.percentageCoverDemand = 0;
        this.val = NaN;
        return;
      }

      this.percentageCoverDemand = ( energySupply / energyDemand ) * 100; 
  
      this.val =
            this.percentageCoverDemand >= 100
          ? 1
          : this.percentageCoverDemand  >= 75
          ? 1+((2-1)/(75-100))*(this.percentageCoverDemand-100)
          : this.percentageCoverDemand  >= 50
          ? 2+((3-2)/(50-75))*(this.percentageCoverDemand-75)
          : this.percentageCoverDemand  >= 10
          ? 3+((4-3)/(10-50))*(this.percentageCoverDemand-50)
          : 5;
        
    }
  }

  export class PrimaryEnergyScore {
    val: number;
    primaryEnergyTotal: number;
    constructor({ primaryenergyDemand, primaryenergySupply }: { primaryenergyDemand?: number , primaryenergySupply?: number  }) {
      if (!primaryenergyDemand || !primaryenergySupply) {
        this.primaryEnergyTotal = 0;
        this.val = NaN;
        return;
      }

      this.primaryEnergyTotal =  primaryenergyDemand - primaryenergySupply;

      if (this.primaryEnergyTotal < 0) 
      {
        this.primaryEnergyTotal = 0;
      }
  
      this.val =
            this.primaryEnergyTotal == 0
          ? 1
          : this.primaryEnergyTotal  < 50
          ? 1+((2-1)/(50-0))*(this.primaryEnergyTotal-0)
          : this.primaryEnergyTotal  < 100
          ? 2+((3-2)/(100-50))*(this.primaryEnergyTotal-50)
          : this.primaryEnergyTotal  < 130
          ? 3+((3-2)/(130-100))*(this.primaryEnergyTotal-100)
          : 5;
        
    }
  }

export class EnergyCopPrimaryCalc {

    primaryEnergy: number;
    energy: number;
    electricalEnergy: number;

    constructor({ demand, cop, pefactor }: 
        { demand?: number, cop?: number, pefactor?: number, }) {


        let thispefactor = 0;
        let thiscop = 0;
        if (!pefactor) {
            thispefactor = 0;
        }
        else
        {
            thispefactor = pefactor;
        }
        if (!cop) {
            thiscop = 1;
        }
        else
        {
            thiscop = cop;
        }
        if (!demand) {
            this.energy = 0;
            this.primaryEnergy = 0;
            this.electricalEnergy = 0;
            return;
        }
        this.energy = 1 * demand;
        this.electricalEnergy = demand / thiscop;
        this.primaryEnergy = (demand / thiscop ) * thispefactor;
    }
}  

export class EnergyPrimaryCalc {
    energy: number;
    primaryEnergy: number;
    constructor({ demand, pefactor }: { demand?: number, pefactor?:number}) {
        let thispefactor = 0;
        if (!pefactor) {
            thispefactor = 0;
        }
        else
        {
            thispefactor = pefactor;
        }
        if (!demand) {
            this.energy = 0;
            this.primaryEnergy = 0;
            return;
        }
        this.energy = 1 * demand;
        this.primaryEnergy = demand * thispefactor;
    }
}  
