import _ from 'lodash';
import { useContext } from 'react';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';

export const useSystemTypeWithIndex = (index: number, varaintCaseIndex:number) => {
  const { globalJson } = useContext(JsonContext);

  const typeOfSystemPath = `lVariant.[${varaintCaseIndex}].HVAC.lSystem.[${index}].lDevice.[0].Active.ahtypeDev`;
  const typeOfSystem = _.get(globalJson, typeOfSystemPath);

  return typeOfSystem;
};
