import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import zu from './zu.json';

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: 'zu',
      fallbackLng: 'zu',
      debug: false,
      resources: { zu },
      interpolation: {
        escapeValue: false,
      },
    });

export { i18n }