




export function onWidgetArticle(open) {
  
  if (open == undefined ? true : open)
  {
    window.openWidgetArticle();
  }
  else
  {
    window.closeWidgetArticle();
  }
  
}


export function showWidgetLauncher(show) {
  
  if (show == undefined ? false : show)
  {
    window.showWidgetLauncher();
  }
  else
  {
    window.hideWidgetLauncher();
  }
  
}
