import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  FlexProps,
  Hide,
  useBreakpoint,
} from '@chakra-ui/core';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { appColors } from 'src/app/theme';
import { BurguerIcon, Icon, MinusIcon } from '../elements/icons';
import { FlexCol, FlexRow } from './boxes';

const stickyOnScroll = {
  position: 'sticky',
} as any;

export const appLayoutTheme = {
  maxWidth: '1400px',
  alignment: 'center',
  minHeight: '100vh',
  spacing: 4,
  bg: 'white',
  widths: {
    leftLayout: '250px',
    rightLayout: '384px',
  },
  breakpointToHideLeft: 'lg',
  breakpointToHideRight: 'sm',
};

export const LeftLayout: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = React.useRef<any>();

  const drawer = (
    <FlexCol
      {...stickyOnScroll}
      mr={appLayoutTheme.spacing}
      top={0}
      bg={appLayoutTheme.bg}
      width={appLayoutTheme.widths.leftLayout}
      minHeight={appLayoutTheme.minHeight}
      height="100%"
      alignSelf="flex-start"
      id="noprint"
    >
      {children}
    </FlexCol>
  );
  return (
    <>
      <Hide below={appLayoutTheme.breakpointToHideLeft}>{drawer}</Hide>

      <Hide above={appLayoutTheme.breakpointToHideLeft}>
        <FlexRow position="fixed" top={1} left={1}>
          <BurguerIcon onClick={() => setIsOpen(true)} />
        </FlexRow>

        <FlexRow pl={6} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={() => setIsOpen(false)}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay>
            <DrawerContent>{drawer}</DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Hide>
    </>
  );
};

export const RightLayout: React.FC<{ revealRightMobile: boolean }> = ({
  children,
  revealRightMobile,
}) => {
  return revealRightMobile ? (
    <motion.div
      transition={{ duration: 0.3 }}
      initial={{}}
      animate={{
        height: revealRightMobile ? '100vh' : 0,
        overflow: 'scroll',
        width: revealRightMobile ? '100vw' : 0,
        right: revealRightMobile ? [-50, 0] : [0, 0],
        pointerEvents: revealRightMobile ? 'all' : 'none',
        position: 'absolute',
        zIndex: 4,
      }}
    >
      {children}
    </motion.div>
  ) : null;
};

export const CenterLayout: React.FC<FlexProps & any> = ({
  children,
  revealRightMobile,
  ...props
}) => (
  <FlexCol
    style={{ opacity: !revealRightMobile ? 1 : 0 }}
    borderRadius="8px"
    flex={1}
    mr={appLayoutTheme.spacing}
    px={appLayoutTheme.spacing}
    bg={appLayoutTheme.bg}
    minHeight={appLayoutTheme.minHeight}
    mt={appLayoutTheme.spacing}
    pb={2 * appLayoutTheme.spacing}
    {...props}
  >
    {children}
  </FlexCol>
);

export const AppRightContentBox = ({ children, minHeight, mb }: any) => (
  <FlexCol
    borderRadius="8px"
    p={1}
    bg={appLayoutTheme.bg}
    minHeight={minHeight}
    id="noprint"
    mb={mb}
  >
    {children}
  </FlexCol>
);

export const Reveal: React.FC = ({ children }) => (
  <>
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      animate={{
        opacity: [0, 0.5, 1],
      }}
    >
      {children}
    </motion.div>
  </>
);

export const AppLayout = ({ right, left, center }: any) => {
  const { pathname } = useLocation();
  const breakpoint = useBreakpoint();
  const [revealRightMobile, setRevealRightMobile] = useState(false);

  const isFullScreen = breakpoint === 'xl' || breakpoint === 'lg';

  return (
    <FlexRow width="100%" alignItems={appLayoutTheme.alignment}>
      <LeftLayout>{left}</LeftLayout>
      <FlexRow
        flex={1}
        minHeight={appLayoutTheme.minHeight}
        maxWidth={appLayoutTheme.maxWidth}
      >
        <CenterLayout revealRightMobile={revealRightMobile}>
          {center}
        </CenterLayout>
        {pathname.includes('radar') ? null : (
          <>
            {!isFullScreen && (
              <RightLayout revealRightMobile={revealRightMobile}>
                {right}
              </RightLayout>
            )}
            {isFullScreen && (
              <FlexCol
                {...(stickyOnScroll as any)}
                top={appLayoutTheme.spacing}
                mr={appLayoutTheme.spacing}
                mt={appLayoutTheme.spacing}
                width={appLayoutTheme.widths.rightLayout}
                minHeight={appLayoutTheme.minHeight}
                height="100%"
              >
                {right}
              </FlexCol>
            )}
          </>
        )}
      </FlexRow>

      <FlexRow
        display={['initial', 'initial', 'none']}
        zIndex={100}
        justifyContent="center"
        alignItems="center"
        height="64px"
        width="64px"
        borderRadius="50%"
        background={appColors.primary.dark}
        right={3}
        bottom={10}
        position="fixed"
        onClick={() => setRevealRightMobile(!revealRightMobile)}
      >
        <motion.div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
          initial={{ scale: 0 }}
          animate={{
            scale: revealRightMobile ? 1.4 : 0,
            rotate: revealRightMobile ? [45, 130, 0] : [-45, 130, 0],
          }}
        >
          <Icon color="white">
            <MinusIcon style={{ transform: 'rotate(45deg)' }} />
          </Icon>
        </motion.div>
      </FlexRow>
    </FlexRow>
  );
};
