import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { EnergyDemandIcon } from 'src/ui/elements/icons';

export class EnergyDemandQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_ed`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: EnergyDemandIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_edsc`,
          
        },
      },

      { key: `aht_qed1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed1c` ,
      extraOptions: {multiLine: true, }, 
    },

      { key: `aht_qed2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed2c` ,
      extraOptions: {multiLine: true,}, 
    },

      { key: `aht_qed3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed3c` ,
      extraOptions: {multiLine: true,}, 
    },

      { key: `aht_qed4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqed4c` ,
      extraOptions: {multiLine: true,}, 
    },
    ];
  }
}
