import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { ThermalEnvironmentIcon } from 'src/ui/elements/icons';

export class ThermalQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_te`,
        extraOptions: {
          isAbsoluteTitle: true,
          IconComponent: ThermalEnvironmentIcon,
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_tesc`,
        },
      },

      { key: `aht_qte1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqte1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqte1c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qte2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqte2` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqte2c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qte3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqte3` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqte3c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qte4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqte4` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqte4c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qte5` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqte5` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqte5c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qte6` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqte6` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqte6c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
