import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { i18n } from 'src/features/shared/translations/translate';

export const useListOfSystems = (variantcaseIndex:number, facade = globalJsonFacade) => {
  const [render, setRender] = useState(0);
  const [systems, setSystems] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const systemsDefinitionPath = `lVariant.[${variantcaseIndex}].HVAC.lSystem`;
  const draftJsonForSystems = _.get(globalJson, systemsDefinitionPath);

  const numberOfSystems = _.size(draftJsonForSystems);

  useEffect(() => {
    setSystems(numberOfSystems);
  }, [numberOfSystems]);




  const removeThisSystem = (index: number) => {

    _.set(globalJson, `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahsysused`, false);
   
    facade.save(globalJson).then((vv) => {
      setGlobalJson(vv);
    });

    setRender(render + 1);
  };




  const bringBackThisSystem = (index: number) => {
    _.set(globalJson, `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahsysused`, true);
   
    facade.save(globalJson).then((vv) => {
      setGlobalJson(vv);
    });
    setRender(render + 1);
  };


  const systemIsUsed = (index: number) => {
    if( _.get(globalJson, `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].Active.ahsysused`))
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  

  const getSystemName = (index: number) => {

    const name = _.get(globalJson, `lVariant.${variantcaseIndex}.HVAC.lSystem.${index}.lDevice.[0].n`);

    if (name == undefined) return i18n.t(`pages.nan`);
    if (name == "NaN") return i18n.t(`pages.nan`);
    if (name == "") return i18n.t(`pages.nan`);

    return name;
  }

  const notUsedSystemsDefined = (typeOfSyste_: number) => {
    const globaldraftJsonForSystems = _.get(
      globalJson,
      `lVariant.${variantcaseIndex}.HVAC.lSystem`
    );
    if (globaldraftJsonForSystems) {
      for (var dJFS of globaldraftJsonForSystems) {
        if (typeOfSyste_ === _.get(dJFS, `lDevice.[0].Active.ahtypeDev`) && !_.get(dJFS, `lDevice.[0].Active.ahsysused`)) {
          return true;
        }
      }
    }
    return false;

  }

  
  return {
    systems,
    removeThisSystem,
    bringBackThisSystem,
    systemIsUsed,
    getSystemName,
    notUsedSystemsDefined
  };
};




export const useListOfDevices = (variantcaseIndex:number, indexSystem: number) => {
  const [devices, setDevices] = useState(0);
  const { globalJson } = useContext(JsonContext);

  const devicesDefinitionPath = `lVariant.[${variantcaseIndex}].HVAC.lSystem.[${indexSystem}].lDevice`;
  const draftJsonForDevices = _.get(globalJson, devicesDefinitionPath);

  const numberOfDevices = _.size(draftJsonForDevices);

  useEffect(() => {
    setDevices(numberOfDevices);
  }, [numberOfDevices]);

  return {
    devices,
  };
};

export const useListOfOperations = (variantcaseIndex:number, indexSystem: number) => {
  const [operations, setOperations] = useState(0);
  const { globalJson } = useContext(JsonContext);

  const operationDefinitionPath = `lVariant.[${variantcaseIndex}].HVAC.lSystem.[${indexSystem}].lOperation`;
  const draftJsonForOperations = _.get(globalJson, operationDefinitionPath);

  const numberOfOperations = _.size(draftJsonForOperations);

  useEffect(() => {
    setOperations(numberOfOperations);
  }, [numberOfOperations]);

  return {
    operations,
  };
};
