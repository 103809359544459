import { Grid, GridProps } from '@chakra-ui/core';
import React from 'react';

const templateToAvoidFieldsSmallerOnMobile = [
  'repeat(6, 6fr)',
  'repeat(6, 6fr)',
  'repeat(6, 1fr)',
  'repeat(6, 1fr)',
];

export const FormContainer: React.FC<GridProps> = ({ children, ...props }) => (
  <Grid
    width="100%"
    columnGap={2}
    templateColumns={templateToAvoidFieldsSmallerOnMobile}
    {...props}
  >
    {children}
  </Grid>
);
