import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { DaylightQualityJsonDefinition } from '../../../data/qualitative/daylight_json_definition';
import { useWithVariantCaseIndex } from '../use_with_variantcase_index';

export const DaylightQualityPage: React.FC = () => {
  const daylightjson = useWithVariantCaseIndex(DaylightQualityJsonDefinition);

  return <FormBuilder jsonKeys={daylightjson.keys} />;
};

export default DaylightQualityPage;
