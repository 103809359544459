import _ from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { globalJsonFacade } from 'src/features/shared/form/infrastructure/global_json_facade';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';

export const useNumberOfContacts = (facade = globalJsonFacade) => {
  const [contacts, setContacts] = useState(0);
  const { globalJson, setGlobalJson } = useContext(JsonContext);

  const draftJsonForContacts = _.get(globalJson, `projD.lContact`);

  const numberOfContacts = _.size(draftJsonForContacts);

  const removeContact = (index: number) => {
    const newDraftJsonForContacts = [...(draftJsonForContacts as any)];

    const filtered = newDraftJsonForContacts.filter(function (value, i, arr) {
      return i !== index;
    });

    const newDraftJson = _.set(
      globalJson as any,
      `projD.lContact`,
      filtered
    );

    facade.setWithoutMerge(newDraftJson).then(() => {
      const draftJsonForContacts = _.get(newDraftJson, `projD.lContact`);
      const numberOfContacts = _.size(draftJsonForContacts);
      setContacts(numberOfContacts);
      facade.save(newDraftJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  const addContact = () => {
    setContacts(contacts + 1);

    _.set(
      globalJson,
      `projD.lContact.[${contacts}].aHCrole`,
      99
    );    


    facade.setWithoutMerge(globalJson).then((v) => {
      facade.save(globalJson).then((v) => {
        setGlobalJson(v);
      });
    });
  };

  useEffect(() => {
    setContacts(numberOfContacts);
  }, [numberOfContacts]);

  return {
    contacts,
    addContact,
    removeContact,
  };
};
