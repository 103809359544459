import { emotionStyled } from '../shared';

export { ReactComponent as CloudIcon } from './icons/Cloud.svg';
export { ReactComponent as HouseIcon } from './icons/House.svg';
export { ReactComponent as ProjectIcon } from './icons/Project.svg';
export { ReactComponent as EnergyIcon } from './icons/zap.svg';
export { ReactComponent as ResultsIcon } from './icons/Results.svg';
export { ReactComponent as SettingsIcon } from './icons/Settings.svg';
export { ReactComponent as SelectIcon } from './icons/SelectIcon.svg';
export { ReactComponent as TrashIcon } from './icons/Trash.svg';
export { ReactComponent as ArrowDownIcon } from './icons/ArrowDown.svg';
export { ReactComponent as BurguerIcon } from './icons/Burger.svg';
export { ReactComponent as MinusIcon } from './icons/Minus.svg';
export { ReactComponent as PlusIcon } from './icons/Plus.svg';
export { ReactComponent as LayerIcon } from './icons/LayerIcon.svg';

export { ReactComponent as InformationIcon } from './icons/Information.svg';
export { ReactComponent as QuestionIcon } from './icons/Question.svg';

export { ReactComponent as ClipIcon } from './icons/clip.svg';

export { ReactComponent as AcousticQualityIcon } from './icons/Acoustic Quality.svg';
export { ReactComponent as DaylightIcon } from './icons/Daylight.svg';
export { ReactComponent as EnergyDemandIcon } from './icons/Energy Demand.svg';
export { ReactComponent as EnergySupplyIcon } from './icons/Energy Supply.svg';
export { ReactComponent as FreshwaterConsumptionIcon } from './icons/Freshwater Consumption.svg';
export { ReactComponent as IndoorAirQualityIcon } from './icons/Indoor Air Quality.svg';
export { ReactComponent as PrimaryEnergyDemandIcon } from './icons/Primary Energy Demand.svg';
export { ReactComponent as SustainableConstructionIcon } from './icons/Sustainable Construction.svg';
export { ReactComponent as ThermalEnvironmentIcon } from './icons/Thermal Environment.svg';

export { ReactComponent as ExternalLink } from './icons/external-link.svg';

export { ReactComponent as ReUseIcon } from './icons/ReUse.svg';

export const Icon = emotionStyled.div`
  display: contents;

  & > svg> path {
    color: ${(props: any) => props.color};
    stroke: ${(props: any) => props.stroke};
    fill: ${(props: any) => props.fill};
` as React.FC<{ color?: string; stroke?: string }>;
