import React from 'react';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';
import { ProjectInformationJsonDefinition } from '../../data/project_information_json_definition';

const projectInformationJson = new ProjectInformationJsonDefinition();


export const ProjectInformationPage: React.FC = () => {
  return (
    <>
      <FormBuilder jsonKeys={projectInformationJson.keys} />
    </>
  );
};
