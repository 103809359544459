import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { DaylightIcon } from 'src/ui/elements/icons';

export class DaylightQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `aht_dl`,
        extraOptions: { 
          isAbsoluteTitle: true, 
          IconComponent: DaylightIcon,  
          titleValue: `lVariant.${variantcaseIndex}.Active.ahr_dlsc`,
        },
      },

      { key: `aht_qdl1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl1c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qdl2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl2` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl2c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qdl3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl3` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl3c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qdl4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl4` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl4c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qdl5` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl5` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl5c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qdl6` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl6` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl6c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qdl7` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl7` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl7c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qdl8` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl8` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqdl8c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
