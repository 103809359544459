export class ScoreTotal {
  weightedScoreTotal: number;
  weightSubTotal: number;
  oneIsOutOfActive: boolean;

  constructor()
  {
    this.weightedScoreTotal = 0;
    this.weightSubTotal = 0;
    this.oneIsOutOfActive = false;
  } 

  add(weightedScore: number, weightSub: number, score: number) {
    this.weightedScoreTotal += weightedScore;
    this.weightSubTotal += weightSub;
    if (weightedScore > 0  && weightSub > 0 && score > 4)
    {
      this.oneIsOutOfActive = true;
    }
  }

  val()
  {
    if (this.weightedScoreTotal > 0 && this.weightSubTotal > 0)
    {
      const ScoreTotal = this.weightedScoreTotal / this.weightSubTotal;
      if (ScoreTotal > 4 || this.oneIsOutOfActive)
      {
        return 5;
      }
      return ScoreTotal;
    }
    else
    {
      return 5;
    }
  }
}
