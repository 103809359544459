import { HttpService } from '../../../chore/axios_service';
import { articlesDto } from './articles_dto';
import { HelpArticle } from './help_article';

export class ArticleNotFoundFailure {
  error = 'HelpArticlesHttpFacade: Article Not Found';
}
export class ArticleServerError {
  error = 'HelpArticlesHttpFacade: Server Error';
}
export interface HelpArticlesFacade {
  getArticles: (
    id: string
  ) => Promise<HelpArticle | ArticleServerError | ArticleNotFoundFailure>;
}

const endpoint = 'https://cinchbuild.freshdesk.com/api/v2/solutions/articles/';

export class HelpArticlesHttpFacade implements HelpArticlesFacade {
  constructor(public httpService = new HttpService()) {}

  async getArticles(id: string) {
    try {
      const response = await this.httpService.get(endpoint + id, {
        auth: {
          username: process.env.REACT_APP_FRESHDESK_USERNAME,
          password: '',
        },
      });

      if (!response) {
        return new ArticleNotFoundFailure();
      }

      return articlesDto.responseToDomain(response.data);
    } catch (error) {
      return new ArticleServerError();
    }
  }
}
