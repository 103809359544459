import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { EnergyIcon } from 'src/ui/elements/icons';

export class EnergyQuantityJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `ahh_eg`,
        extraOptions: { isAbsoluteTitle: true, IconComponent: EnergyIcon },
      },

      {
        key: `aht_ed`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edsc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edsh`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      }, // just show result numbers
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_eddhw`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edmv`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edcool`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edsys`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edl`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_edsum`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 6 } },
      {
        key: `aht_es`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_essc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },

      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_eshd`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_esel`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_essum`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_esperc`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 99 },
      },

      { key: `empty`, extraOptions: { gridColSpan: 6 } },
      {
        key: `aht_pe`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 1,
          gridColSpan: 5,
        },
      },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_pesc`,
        extraOptions: {
          disabled: true,
          noLabel: true,
          styleType: 3,
          gridColSpan: 1,
          roundDecimal: 1,
          score: true,
        },
      },
      { key: `emptydivider`, extraOptions: { noLabel: true } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_pedemsum`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_pesupsum`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 3 } },
      {
        key: `lVariant.${variantcaseIndex}.Active.ahr_pesum`,
        extraOptions: { disabled:true, gridColSpan: 3, roundDecimal: 2 },
      },
    ];
  }
}
