import React from 'react';
import bytes from 'bytes';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { Box, FlexCol, P } from '..';
import { Icon, MinusIcon } from './icons';
import { appColors } from 'src/app/theme';
import { i18n } from 'src/features/shared/translations/translate';
// import { Image } from '@chakra-ui/core';


const DEFAULT_MAX_PICTURE_SIZE = bytes('1MB');

type Props = {
    backgroundImage?: any;
    acceptedFiles?: Array<string>;
    
    fileMaxSize?: number;
    label?: {
        orDropFilesHereText: string;
        addFileText: string;
    };
    isDisabled?: boolean;
    isMultiple?: boolean;
    onUpload: DropzoneProps['onDrop'];
    onUploadFailed?: DropzoneProps['onDropRejected'];
    renderTrigger?: any;
};

const AddPictureIcon = ({
}) => (
    <>
        <Icon color={appColors.primary.main}>
            <MinusIcon height={40} width={40} />
        </Icon>
    </>
);


export const PictureFileUploadTrigger = ({
    label,
    inputProps,
    backgroundImage,
}: {
    label: any;
    inputProps: any;
    backgroundImage: any,
}) => (
    <Box
        height="256px"
        // padding="lg"
        border={backgroundImage ? '0px' : '2px dashed #88B854'}
        backgroundImage={backgroundImage ? `url(${backgroundImage})` : 'url()'}
        backgroundPosition="top"
        bgRepeat="no-repeat"
        backgroundSize="352px"
        bgPosition="center"
        borderColor={appColors.primary.dark}
    >

        <FlexCol pt={12} pb={6} alignItems="center" justifyContent="center">
            {backgroundImage ? null : AddPictureIcon(label)}
            <P> {backgroundImage ? null : label} </P>
            {/* <Image 
               src={`${backgroundImage}`} 
               boxSize="350px, 256px"
               objectFit="fill"
            /> */}
            <input {...inputProps} />
        </FlexCol>
    </Box>
);

export const PictureUploader = (
    props: Props,
) => (
    <Dropzone
        accept={props.acceptedFiles}
        onDropRejected={props.onUploadFailed}
        maxSize={props.fileMaxSize}
        maxFiles={1}
        disabled={props.isDisabled}
        onDrop={props.onUpload}
        
    >
        {({ getRootProps, getInputProps }) => (
            <Box outline="none" cursor="pointer" {...getRootProps()}>
                {props.renderTrigger({
                    label: props.label,
                    inputProps: getInputProps(),
                    backgroundImage: props.backgroundImage,
                })}
                
            </Box>
        )}
    </Dropzone>
);

PictureUploader.defaultProps = {
    acceptedFiles: 'image/*', //'.jpeg,.jpg,.png,',
    label: i18n.t(`pages.uploadpicture`),
    fileMaxSize: DEFAULT_MAX_PICTURE_SIZE,
    renderTrigger: ({ label, inputProps, backgroundImage }: any) => (
        <PictureFileUploadTrigger label={label} inputProps={inputProps} backgroundImage={backgroundImage} />
    ),
};

  // `${_.get(globalJson, 'projD.picture')}`