import _ from "lodash";
import { qualitativeRoutePaths, quantitativeRoutePaths } from 'src/features/baseline/presentation/routes/routes_path';
import { projectInformationRoutePaths } from 'src/features/project_information/presentation/routes/routes_path';
import { i18n } from 'src/features/shared/translations/translate';
import {
  ClipIcon,
  CloudIcon,
  EnergyIcon,
  HouseIcon,
  ProjectIcon,
  ResultsIcon,
  SettingsIcon,
} from 'src/ui/elements/icons';
import { definitionsJson } from 'src/features/shared/json/data/source/active.def';

export const projectInfo = {
  title: {
    label: _.get(definitionsJson, 'ahh_pj').n,
    Icon: ProjectIcon,
    route: projectInformationRoutePaths.main,
  },
  items: [
    {
      label: _.get(definitionsJson, 'aht_r').n,
      route: projectInformationRoutePaths.rooms,
    },
    {
      label: _.get(definitionsJson, 'ahh_a').n,
      route: projectInformationRoutePaths.areas,
      hideable: true,
    },
    {
      label: _.get(definitionsJson, 'ahh_c').n,
      route: projectInformationRoutePaths.constructions,
      hideable: true,
    },
    {
      label: _.get(definitionsJson, 'ahh_sy').n,
      route: projectInformationRoutePaths.systems,
    },
  ],
};

export const management = {
  title: {
    label: _.get(definitionsJson, 'aht_mgn').n,
    Icon: ClipIcon,
    route: qualitativeRoutePaths.management(''),
  },
  items: [],
};

export const comfortQualitative = {
  title: {
    label: _.get(definitionsJson, 'ahh_cf').n,
    Icon: HouseIcon,
    route: qualitativeRoutePaths.comfort(''),
  },
  items: [
    {
      label: _.get(definitionsJson, 'aht_dl').n,
      route: qualitativeRoutePaths.daylight(''),
    },
    {
      label: _.get(definitionsJson, 'aht_te').n,
      route: qualitativeRoutePaths.thermal(''),
    },
    {
      label: _.get(definitionsJson, 'aht_ia').n,
      route: qualitativeRoutePaths.indoorair(''),
    },
    {
      label: _.get(definitionsJson, 'aht_aq').n,
      route: qualitativeRoutePaths.acoustic(''),
    },
  ],
};


export const comfortQuantitative = {
  title: {
    label: _.get(definitionsJson, 'ahh_cf').n,
    Icon: HouseIcon,
    route: quantitativeRoutePaths.comfort(''),
  },
  items: [
    {
      label: _.get(definitionsJson, 'aht_dl').n,
      route: quantitativeRoutePaths.daylight(''),
    },
    {
      label: _.get(definitionsJson, 'aht_te').n,
      route: quantitativeRoutePaths.thermal(''),
    },
    {
      label: _.get(definitionsJson, 'aht_ia').n,
      route: quantitativeRoutePaths.indoorair(''),
    },
    {
      label: _.get(definitionsJson, 'aht_aq').n,
      route: quantitativeRoutePaths.acoustic(''),
    },
  ],
};

export const energyQualitative = {
  title: {
    label: _.get(definitionsJson, 'ahh_eg').n,
    Icon: EnergyIcon,
    route: qualitativeRoutePaths.energy(''),
  },
  items: [
    {
      label: _.get(definitionsJson, 'aht_ed').n,
      route: qualitativeRoutePaths.energydemand(''),
    },
    {
      label: _.get(definitionsJson, 'aht_es').n,
      route: qualitativeRoutePaths.energysupply(''),
    },
    {
      label: _.get(definitionsJson, 'aht_pe').n,
      route: qualitativeRoutePaths.primaryenergy(''),
    },
  ],
};

export const energyQuantitative = {
  title: {
    label: _.get(definitionsJson, 'ahh_eg').n,
    Icon: EnergyIcon,
    route: quantitativeRoutePaths.energy(''),
  },
  items: [
    {
      label: _.get(definitionsJson, 'aht_ed').n,
      route: quantitativeRoutePaths.energydemand(''),
    },
    {
      label: _.get(definitionsJson, 'aht_es').n,
      route: quantitativeRoutePaths.energysupply(''),
    },
    {
      label: _.get(definitionsJson, 'aht_pe').n,
      route: quantitativeRoutePaths.primaryenergy(''),
    },
  ],
};

export const environmentQualitative = {
  title: {
    label: _.get(definitionsJson, 'ahh_ev').n,
    Icon: CloudIcon,
    route: qualitativeRoutePaths.environment(''),
  },
  items: [
    {
      label: _.get(definitionsJson, 'aht_sc').n,
      route: qualitativeRoutePaths.sustainable(''),
    },
    {
      label: _.get(definitionsJson, 'aht_fw').n,
      route: qualitativeRoutePaths.freshwater(''),
    },
  ],
};

export const environmentQuantitative = {
  title: {
    label: _.get(definitionsJson, 'ahh_ev').n,
    Icon: CloudIcon,
    route: quantitativeRoutePaths.environment(''),
  },
  items: [
    {
      label: _.get(definitionsJson, 'aht_sc').n,
      route: quantitativeRoutePaths.sustainable(''),
    },
    {
      label: _.get(definitionsJson, 'aht_fw').n,
      route: quantitativeRoutePaths.freshwater(''),
    },
  ],
};

export const settings = {
  title: {
    label: _.get(definitionsJson, 'ahh_set').n,
    Icon: SettingsIcon,
    route: '/settings',
  },
  items: [],
};

export const results = {
  title: {
    label: _.get(definitionsJson, 'ahh_res').n,
    Icon: ResultsIcon,
    route: '/results',
  },
  items: [],
};
