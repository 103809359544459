export const quantitativeRoutePaths = {
  comfort: (variantcase = '/:variantcase/') =>
    `${variantcase}comfort/quantitative`,
  daylight: (variantcase = '/:variantcase/') =>
    `${variantcase}daylight/quantitative`,
  thermal: (variantcase = '/:variantcase/') =>
    `${variantcase}thermal/quantitative`,
  indoorair: (variantcase = '/:variantcase/') =>
    `${variantcase}indoorair/quantitative`,
  acoustic: (variantcase = '/:variantcase/') =>
    `${variantcase}acoustic/quantitative`,

  energy: (variantcase = '/:variantcase/') =>
    `${variantcase}energy/quantitative`,

  energydemand: (variantcase = '/:variantcase/') =>
    `${variantcase}energydemand/thermal`,
  electricitydemand: (variantcase = '/:variantcase/') =>
    `${variantcase}energydemand/electric`,

  energysupply: (variantcase = '/:variantcase/') =>
    `${variantcase}energysupply/thermal`,
  electricitysupply: (variantcase = '/:variantcase/') =>
    `${variantcase}energysupply/electric`,


  primaryenergy: (variantcase = '/:variantcase/') =>
    `${variantcase}primaryenergy/quantitative`,

  environment: (variantcase = '/:variantcase/') =>
    `${variantcase}environment/quantitative`,
  sustainable: (variantcase = '/:variantcase/') =>
    `${variantcase}sustainable/quantitative`,
  freshwater: (variantcase = '/:variantcase/') =>
    `${variantcase}freshwater/quantitative`,
};

export const qualitativeRoutePaths = {
  comfort: (variantcase = '/:variantcase/') =>
    `${variantcase}comfort/qualitative`,
  daylight: (variantcase = '/:variantcase/') =>
    `${variantcase}daylight/qualitative`,
  thermal: (variantcase = '/:variantcase/') =>
    `${variantcase}thermal/qualitative`,
  indoorair: (variantcase = '/:variantcase/') =>
    `${variantcase}indoorair/qualitative`,
  acoustic: (variantcase = '/:variantcase/') =>
    `${variantcase}acoustic/qualitative`,

  energy: (variantcase = '/:variantcase/') =>
    `${variantcase}energy/qualitative`,
  energydemand: (variantcase = '/:variantcase/') =>
    `${variantcase}energydemand/qualitative`,
  energysupply: (variantcase = '/:variantcase/') =>
    `${variantcase}energysupply/qualitative`,
  primaryenergy: (variantcase = '/:variantcase/') =>
    `${variantcase}primaryenergy/qualitative`,

  environment: (variantcase = '/:variantcase/') =>
    `${variantcase}environment/qualitative`,
  sustainable: (variantcase = '/:variantcase/') =>
    `${variantcase}sustainable/qualitative`,
  freshwater: (variantcase = '/:variantcase/') =>
    `${variantcase}freshwater/qualitative`,

  management: (variantcase = '/:variantcase/') =>
    `${variantcase}management/qualitative`,
};
