export const unitsJson = {
    "u": [
        [
            0,
            "",
            "",
            1.0
        ],
        [
            1,
            "kg/m³",
            "lb/ft³",
            16.01846
        ],
        [
            2,
            "m³/m³",
            "ft³/ft³",
            1.0
        ],
        [
            3,
            "J/kgK",
            "Btu/lb°F",
            4186.8
        ],
        [
            4,
            "W/mK",
            "Btu/hr ft °F",
            1.73073
        ],
        [
            5,
            "-",
            "-",
            1.0
        ],
        [
            6,
            "m",
            "in",
            0.0254
        ],
        [
            7,
            "kg/m²s^0.5",
            "lb/in²s^0.5",
            703.0696
        ],
        [
            8,
            "%/M.-%",
            "%/M.-%",
            1.0
        ],
        [
            9,
            "-",
            "perm in",
            0.0
        ],
        [
            10,
            "m²K/W",
            "hr ft² °F/Btu",
            0.17611
        ],
        [
            11,
            "W/mK²",
            "Btu/hr ft °F²",
            3.1153346459999995
        ],
        [
            12,
            "m²/s",
            "ft²/s",
            0.09290304
        ],
        [
            13,
            "W/m²K",
            "Btu/hr ft² °F",
            5.678263
        ],
        [
            14,
            "°C",
            "°F",
            0.0
        ],
        [
            15,
            "kg/m²",
            "lb/ft²",
            4.882428
        ],
        [
            16,
            "cm",
            "in",
            2.54
        ],
        [
            17,
            "K",
            "°F",
            0.55555555555555558
        ],
        [
            18,
            "m",
            "ft",
            0.3048
        ],
        [
            19,
            "hPa",
            "psi",
            68.94757
        ],
        [
            20,
            "mm",
            "in",
            25.4
        ],
        [
            21,
            "W/m²",
            "Btu/hr ft²",
            3.154591
        ],
        [
            22,
            "%",
            "%",
            1.0
        ],
        [
            23,
            "Ltr/m²h",
            "in/hr",
            25.4
        ],
        [
            24,
            "M.-%",
            "M.-%",
            1.0
        ],
        [
            25,
            "kg/s m²",
            "lb/hr ft²",
            0.0013562
        ],
        [
            26,
            "m³",
            "ft³",
            0.0283168466
        ],
        [
            27,
            "g/m³",
            "lb/ft³",
            16018.46337
        ],
        [
            28,
            "kg/s",
            "lb/s",
            0.45359237
        ],
        [
            29,
            "1/h",
            "1/hr",
            1.0
        ],
        [
            30,
            "h",
            "hr",
            1.0
        ],
        [
            31,
            "m",
            "perm",
            0.0
        ],
        [
            32,
            "s/m",
            "s/ft",
            3.280839895
        ],
        [
            33,
            "Wh",
            "Btu",
            0.2931
        ],
        [
            34,
            "ng/smPa",
            "perm in",
            1.457262974
        ],
        [
            35,
            "ng/sm²Pa",
            "perm",
            57.372558
        ],
        [
            36,
            "kWh",
            "kBtu",
            0.2931
        ],
        [
            37,
            "m²",
            "in²",
            0.00064516
        ],
        [
            38,
            "m²",
            "ft²",
            0.09290304
        ],
        [
            39,
            "kg",
            "lb",
            0.45359237
        ],
        [
            40,
            "kWh/m²a",
            "kBtu/ft²yr",
            3.154591186
        ],
        [
            41,
            "mm/a",
            "in/yr",
            25.4
        ],
        [
            42,
            "kg/sm²Pa",
            "lb/hr ft² psi",
            1.967045164E-07
        ],
        [
            43,
            "°",
            "°",
            1.0
        ],
        [
            44,
            "kg/kg",
            "lb/lb",
            1.0
        ],
        [
            45,
            "MJ/m²",
            "Btu/ft²",
            0.01135652827
        ],
        [
            46,
            "J/kg",
            "Btu/lb",
            2326.00032492
        ],
        [
            47,
            "mg/m³",
            "mg/m³",
            1.0
        ],
        [
            48,
            "ppmv",
            "ppmv",
            1.0
        ],
        [
            49,
            "ppmm",
            "ppmm",
            1.0
        ],
        [
            50,
            "m³/s",
            "ft³/s",
            0.0283168466
        ],
        [
            51,
            "m³/h",
            "cfm",
            1.6990107954953957
        ],
        [
            52,
            "Ltr/s",
            "Ltr/s",
            1.0
        ],
        [
            53,
            "W",
            "Btu/hr",
            0.2930712104
        ],
        [
            54,
            "kW",
            "kBtu/hr",
            0.2931
        ],
        [
            55,
            "kg/h",
            "lb/hr",
            0.45359237
        ],
        [
            56,
            "g/h",
            "g/hr",
            1.0
        ],
        [
            57,
            "g/s",
            "g/s",
            1.0
        ],
        [
            58,
            "mg/h",
            "mg/hr",
            1.0
        ],
        [
            59,
            "mg/s",
            "mg/s",
            1.0
        ],
        [
            60,
            "met",
            "met",
            1.0
        ],
        [
            61,
            "clo",
            "clo",
            1.0
        ],
        [
            62,
            "m/s",
            "ft/s",
            0.3048
        ],
        [
            63,
            "g/(h m²)",
            "lb/(hr ft²)",
            4882.428
        ],
        [
            64,
            "kWh",
            "Btu",
            0.0002930712104
        ],
        [
            65,
            "dm³/(sm²Pan)",
            "dm³/(sm²Pan)",
            1.0
        ],
        [
            66,
            "kJ/m2K",
            "Btu/ft²K",
            11.35653
        ],
        [
            67,
            "kW",
            "Btu/hr",
            0.0002931
        ],
        [
            68,
            "Wh/m²",
            "Btu/ft²",
            3.154591186
        ],
        [
            69,
            "mm/h",
            "in/hr",
            25.4
        ],
        [
            70,
            "kg/(h m²)",
            "lb/(hr ft²)",
            4.882428
        ],
        [
            71,
            "Pa",
            "psi",
            6894.76
        ],
        [
            72,
            "cm²",
            "in²",
            6.4516
        ],
        [
            73,
            "MJ/m³K",
            "Btu/ft³F",
            0.067066112
        ],
        [
            74,
            "kWh/m²a",
            "Btu/ft²yr",
            0.003154591186
        ],
        [
            75,
            "m/d",
            "ft/d",
            0.3048
        ],
        [
            76,
            "Wh/m²K",
            "Btu/ft²F",
            5.678824
        ],
        [
            77,
            "Wh/m³",
            "Btu/ft³",
            10.3498098
        ],
        [
            78,
            "J",
            "Btu",
            1054.804
        ],
        [
            79,
            "kJ",
            "Btu",
            1.054804
        ],
        [
            80,
            "Liter",
            "Liter",
            1.0
        ],
        [
            81,
            "W/K",
            "Btu/hr F",
            0.52758
        ],
        [
            82,
            "g/kg",
            "lbw/lbda",
            1000.0
        ],
        [
            83,
            "g/m²h",
            "lb/(ft²hr)",
            4882.427636
        ],
        [
            84,
            "g/(g/kg)/m²",
            "lb/(lbw/lbda) ft²",
            4.882427636
        ],
        [
            85,
            "kWh/kWh",
            "Btu/Btu",
            1.0
        ],
        [
            86,
            "g/kWh",
            "g/kBtu",
            3.4118048447628793
        ],
        [
            87,
            "Wh/m³",
            "W/cfm",
            0.588577779
        ],
        [
            88,
            "m³/h",
            "ft³/hr",
            0.0283168466
        ],
        [
            89,
            "Ltr/Person/day",
            "gal/Person/day",
            3.7854000004187407
        ],
        [
            90,
            "kh/a",
            "khr/yr",
            1.0
        ],
        [
            91,
            "kWh/m²Month",
            "kBtu/ft²Month",
            3.154591
        ],
        [
            92,
            "dm³/(sm Pan)",
            "dm³/(sm Pan)",
            1.0
        ],
        [
            93,
            "kWh",
            "kWh",
            1.0
        ],
        [
            94,
            "Liter",
            "gal",
            3.7854000004187407
        ],
        [
            95,
            "kWh/a",
            "kBtu/yr",
            0.2931
        ],
        [
            96,
            "kWh/m²",
            "kBtu/ft²",
            3.154591
        ],
        [
            97,
            "g/kg",
            "kgw/kga",
            1000.0
        ],
        [
            98,
            "kJ/kgK",
            "kBtu/lb°F",
            4186.8
        ],
        [
            99,
            "days/a",
            "days/yr",
            1.0
        ],
        [
            100,
            "mths",
            "mths",
            1.0
        ],
        [
            101,
            "hrs",
            "hrs",
            1.0
        ],
        [
            102,
            "kg/a",
            "lb/yr",
            0.45359237
        ],
        [
            103,
            "Wh/m³K",
            "Btu/ft³F",
            18.629657639999998
        ],
        [
            104,
            "kKh/a",
            "kFh/a",
            0.55555555555555558
        ],
        [
            105,
            "lux",
            "lux",
            1.0
        ],
        [
            106,
            "m²/Person",
            "ft²/Person",
            0.09290304
        ],
        [
            107,
            "hrs/d",
            "hrs/d",
            1.0
        ],
        [
            108,
            "hrs/a",
            "hrs/yr",
            1.0
        ],
        [
            109,
            "kWh/d",
            "kBtu/d",
            0.2931
        ],
        [
            110,
            "kWh/a",
            "kWh/yr",
            1.0
        ],
        [
            111,
            "W/m²",
            "W/m²",
            1.0
        ],
        [
            112,
            "W",
            "W",
            1.0
        ],
        [
            113,
            "kWh/d",
            "kWh/d",
            1.0
        ],
        [
            114,
            "1/m",
            "1/ft",
            3.280839895013123
        ],
        [
            115,
            "W/m²K²",
            "Btu/hr ft²°F²",
            10.220904
        ],
        [
            116,
            "a",
            "yr",
            1.0
        ],
        [
            117,
            "Kh/a",
            "Fh/a",
            0.55555555555555558
        ],
        [
            118,
            "Wh/m²d",
            "Btu/ft²d",
            3.154591186
        ],
        [
            119,
            "s",
            "s",
            1.0
        ],
        [
            120,
            "Ltr/h",
            "gal/h",
            3.7854000004187407
        ],
        [
            121,
            "m³/m²h",
            "cfm/ft²",
            18.27489035095029
        ],
        [
            122,
            "m³/h Pa",
            "cfm/Pa",
            1.6987620837002808
        ],
        [
            123,
            "Person",
            "Person",
            1.0
        ],
        [
            124,
            "W/Person",
            "Btu/hr Person",
            0.2930712104
        ],
        [
            125,
            "g/h Person",
            "g/hr Person",
            1.0
        ],
        [
            126,
            "g/m²h",
            "g/ft²hr",
            10.763910416709722
        ],
        [
            127,
            "met/Person",
            "met/Person",
            1.0
        ],
        [
            128,
            "met/m²",
            "met/ft²",
            10.763910416709722
        ],
        [
            129,
            "kWh/Wp",
            "kWh/Wp",
            1.0
        ],
        [
            130,
            "kg/Wp",
            "kg/Wp",
            1.0
        ],
        [
            131,
            "A",
            "A",
            1.0
        ],
        [
            132,
            "V",
            "V",
            1.0
        ],
        [
            133,
            "%/K",
            "%/K",
            1.0
        ],
        [
            134,
            "g/kg",
            "grains/lb",
            6.9999999999999938
        ],
        [
            135,
            "kWh/Person a",
            "kWh/Person yr",
            1.0
        ],
        [
            136,
            "g/kg",
            "lbw/lba",
            1000.0
        ],
        [
            137,
            "W/m²",
            "W/ft²",
            10.763910416709722
        ],
        [
            138,
            "kWh/m² Person a",
            "kWh/m² Person yr",
            1.0
        ],
        [
            139,
            "kWh/m² Person a",
            "kBtu/ft² Person yr",
            3.154591186
        ],
        [
            140,
            "kWh/m²a",
            "kWh/ft²yr",
            10.763910416709722
        ],
        [
            141,
            "Ns/m²",
            "lbf s/ft²",
            47.88026
        ],
        [
            142,
            "Ns/m²K",
            "lbf s/ft²F",
            86.184468
        ],
        [
            143,
            "J/kgK²",
            "Btu/lb°F²",
            7536.2400000000007
        ],
        [
            144,
            "kg/kMol",
            "lb/kMol",
            0.45359237
        ],
        [
            145,
            "J/K",
            "Btu/°F",
            1899.100534716
        ],
        [
            146,
            "$/kWh",
            "$/kWh",
            1.0
        ],
        [
            147,
            "$",
            "$",
            1.0
        ],
        [
            148,
            "$/Therm",
            "$/Therm",
            1.0
        ],
        [
            149,
            "Liter",
            "oz",
            3.785411784
        ],
        [
            150,
            "°C/d",
            "°F/days",
            0.55555555555555558
        ],
        [
            151,
            "d/week",
            "d/week",
            1.0
        ],
        [
            152,
            "week/a",
            "week/y",
            1.0
        ],
        [
            153,
            "kWh/use",
            "kWh/use",
            1.0
        ],
        [
            154,
            "min/use",
            "min/use",
            1.0
        ],
        [
            155,
            "kW",
            "kW",
            1.0
        ],
        [
            156,
            "kg CO2-eq.",
            "kg CO2-eq.",
            1.0
        ],
        [
            157,
            "kg R11-eq.",
            "kg R11-eq.",
            1.0
        ],
        [
            158,
            "kg SO2-eq.",
            "kg SO2-eq.",
            1.0
        ],
        [
            159,
            "kg  PO4-eq.",
            "kg PO4-eq.",
            1.0
        ],
        [
            160,
            "kg C2H4-eq.",
            "kg C2H4-eq.",
            1.0
        ],
        [
            161,
            "MJ",
            "MJ",
            1.0
        ],
        [
            162,
            "Piece",
            "Piece",
            1.0
        ]
    ]
};