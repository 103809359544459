export class JsonRepository {
  storage!: LocalForage;

  constructor(storage: LocalForage) {
    this.storage = storage;
  }

  async set(json: any) {
    try {
      return this.storage.setItem('active', json);
    } catch (error) {
      alert('failed to save');
    }
  }

  async get() {
    return await this.storage.getItem('active');
  }

  async clear() {
     //const currentJson = globalJSON;
     this.storage.clear().then(function() {
      // Run this code once the database has been entirely deleted.
  }).catch(function(err) {
      // This code runs if there were any errors
  });
  }
}
