import { Button, ButtonProps, color } from '@chakra-ui/core';

import React from 'react';
import { appFontSizes, appColors, appTheme } from '../../app/theme';
import { emotionStyled } from '../shared';

export const ButtonBase = emotionStyled((props: ButtonProps) => (
  <Button {...props} />
))`
  border-radius: ${appTheme.borderRadius[1]};
  fontSize: ${appFontSizes.body};
  font-weight: normal;
`;

const Transform = 'scale(0.96)';

export const globalButtonProps = (
  bg: string,
  isActive: boolean,
) => ({
  mw: '72px',
  py: '14px',
  border: '2px',
  borderColor: appColors.primary.main,
  _hover: {
    bg : appColors.primary.main,
    color: appColors.white,
  },
  _active: {
    bg : appColors.primary.main,
    color: appColors.white,
    transform: 'scale(0.90)',
  },
  _focus: {
    boxShadow: '0 0 1px 2px rgba(244, 183, 64, .75), 0 1px 1px rgba(0, 0, 0, .15)',
  },
  bg: isActive ? appColors.primary.main : appColors.white,
  color: isActive ? appColors.white : appColors.primary.main,
});


export const PrimaryButton = (props: ButtonProps) => (
  <ButtonBase
    _active={{
      transform: Transform,
    }}
    _hover={{
      bg: 'grey.500',
    }}
    _focus={{
      boxShadow:
        "0 0 1px 2px rgba(244, 183, 64, .75), 0 1px 1px rgba(0, 0, 0, .15)",
    }}
    fontSize={appFontSizes.body}
    bg={appColors.primary.main}
    color={appColors.white}
    {...props}
  />
);

export const InvertedButton = emotionStyled((props: ButtonProps) => (
  <ButtonBase
    _active={{
      transform: Transform,
    }}
    fontSize={appFontSizes.body}
    bg={appColors.white}
    color={appColors.primary}
    {...props}
  />
))`
  border: 1px solid ${appColors.borders[100]};
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px 1px;
  border-radius: ${appTheme.borderRadius[1]};
`;
