import _ from 'lodash';
import React, { useContext } from 'react';
import { appColors } from 'src/app/theme';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { ButtonBase, FlexCol, FlexRow, globalButtonProps, P } from 'src/ui';
import { ResultsIcon } from 'src/ui/elements/icons';
import { Title } from 'src/ui/form_elements/title';
import { definitionsJson } from 'src/features/shared/json/data/source/active.def';
import { i18n } from 'src/features/shared/translations/translate';
import { useRadarData } from 'src/features/radar/application/use_radar_data';
import { onPrintButton } from 'src/features/radar/application/print_report';
import { Radar } from 'src/features/radar/presentation/Radar';
import { drawLegend, RadarLegend } from 'src/features/radar/presentation/RadarPage';
import { createPdf, modifyPdf } from '../components/createpdf';
import { ComfortResultsjsonDefinition, ResultsjsonDefinition } from '../data/results_json_definition';
import { FormBuilder } from 'src/features/shared/form/presentation/form_builder';

const resultsJson = new ResultsjsonDefinition();
const comfortResultsJson = new ComfortResultsjsonDefinition();

export const Results = () => {
  const { radarData, dotsData } = useRadarData();
  const { globalJson, onSaveKeyToGlobalDirectly } = useContext(JsonContext);

  return (
    <>
      <div id="print">
        <FlexRow>

          {/* <FlexCol align="flex-start" justify="center">
            <Title title={_.get(definitionsJson, 'ahh_res').n} IconComponent={ResultsIcon} />
          </FlexCol> */}
          <FormBuilder jsonKeys={resultsJson.keys} />

        </FlexRow>



        <FlexRow width="100%" id="noprint">
          <FlexCol width="100%" height="50" alignItems="flex-end" justify="center">
            <ButtonBase
              mt={1}
              ml={2}
              mb={2}
              w="150px"
              {...globalButtonProps(appColors.secondary.light, false)}
              onClick={() => onPrintButton()}>
              <P>{i18n.t(`pages.print`)}</P>
            </ButtonBase>
          </FlexCol>
        </FlexRow>

        <FlexRow width="100%">
          <FlexCol width="100%" height="1000" align="flex-start" justify="center">
            <FlexRow width="100%">
              <Radar
                RadarLegend={RadarLegend}
                Size={400}
              />
            </FlexRow>
          </FlexCol>

          <FlexCol align="flex-start" alignItems="flex-end" justify="center" id="onlyprint">
            {drawLegend(radarData, globalJson, onSaveKeyToGlobalDirectly)}
          </FlexCol>

        </FlexRow>

        <FlexRow width="100%" id="noprint">
        
          <FlexCol width="100%" height="50" alignItems="flex-begin" justify="center">
          <FormBuilder jsonKeys={comfortResultsJson.keys} />
            <ButtonBase
              mt={1}
              ml={2}
              mb={2}
              w="150px"
              {...globalButtonProps(appColors.secondary.light, false)}
              onClick={() => modifyPdf()}>
              <P>test comfort pdf</P>
            </ButtonBase>
          </FlexCol>
        </FlexRow>
      </div>
    </>
  )
};

