import { getSpaces } from "./utils";

export const APP_FONT_SIZE_BASE = 8;

const appSpaces = getSpaces(APP_FONT_SIZE_BASE);
/*
  this will a theme defined
  {
   0: 0,
   0.5: APP_FONT_SIZE_BASE * 0.5,
   1: APP_FONT_SIZE_BASE * 1
   ... and so on
  }

  Allows us to use the spaces as <Box mt={2}/> means marginTop = 16
*/

export { appSpaces };
