import _ from 'lodash';
import { useContext } from 'react';
import { appColors } from 'src/app/theme';
import { JsonContext } from 'src/features/shared/json/application/use_application_json';
import { useVariantsLength } from 'src/features/sidebar/application/new_variantcase';
import { SCALE } from '../presentation/radar_config';

const base = (name: string) => ({
  name: name,
  data: {
    daylight: 5 / SCALE,
    thermalEnvironment: 5 / SCALE,
    indoorAirQuality: 5 / SCALE,
    acousticQuality: 5 / SCALE,
    energyDemand: 5 / SCALE,
    primaryEnergyPerformance: 5 / SCALE,
    energySupply: 5 / SCALE,
    sustainableConstruction: 5 / SCALE,
    freshwater: 5 / SCALE,
  },
  meta: { color: appColors.primary.main },
});

const dotsData = [
  {
    data: {
      daylight: 5 / SCALE,
      thermalEnvironment: 5 / SCALE,
      indoorAirQuality: 5 / SCALE,
      acousticQuality: 5 / SCALE,
      energyDemand: 5 / SCALE,
      primaryEnergyPerformance: 5 / SCALE,
      energySupply: 5 / SCALE,
      sustainableConstruction: 5 / SCALE,
      freshwater: 5 / SCALE,
    },
    meta: { isDot: true },
  },
];

export const useRadarData = () => {
  const { globalJson } = useContext(JsonContext);
  const { ArrayOfVariantsIndex } = useVariantsLength();

  dotsData[0].data.daylight =
    (_.get(globalJson, `ActiveTarget.ahr_dlsc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.thermalEnvironment =
    (_.get(globalJson, `ActiveTarget.ahr_tesc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.indoorAirQuality =
    (_.get(globalJson, `ActiveTarget.ahr_iasc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.acousticQuality =
    (_.get(globalJson, `ActiveTarget.ahr_aqsc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.energyDemand =
    (_.get(globalJson, `ActiveTarget.ahr_edsc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.energySupply =
    (_.get(globalJson, `ActiveTarget.ahr_essc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.primaryEnergyPerformance =
    (_.get(globalJson, `ActiveTarget.ahr_pesc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.freshwater =
    (_.get(globalJson, `ActiveTarget.ahr_fwsc`) - SCALE) / (-1 * SCALE) + 0.2;

  dotsData[0].data.sustainableConstruction =
    (_.get(globalJson, `ActiveTarget.ahr_scsc`) - SCALE) / (-1 * SCALE) + 0.2;


  const nationData = [
    {
      data: {
        daylight: 1 / SCALE,
        thermalEnvironment: 1 / SCALE,
        indoorAirQuality: 1 / SCALE,
        acousticQuality: 1 / SCALE,
        energyDemand: 1 / SCALE,
        primaryEnergyPerformance: 1 / SCALE,
        energySupply: 1 / SCALE,
        sustainableConstruction: 1 / SCALE,
        freshwater: 1 / SCALE,
      },
      meta: {
        color: appColors.black,
        fill: "none",
        strokeDasharray: 5,
      },
    },
  ];

  nationData[0].data.daylight =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_dlsc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.thermalEnvironment =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_tesc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.indoorAirQuality =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_iasc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.acousticQuality =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_aqsc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.energyDemand =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_edsc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.energySupply =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_essc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.primaryEnergyPerformance =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_pesc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.freshwater =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_fwsc`) - SCALE) / (-1 * SCALE) + 0.2;

  nationData[0].data.sustainableConstruction =
    (_.get(globalJson, `ahsettings.ActiveNation.ahr_scsc`) - SCALE) / (-1 * SCALE) + 0.2;


  if (!_.get(globalJson, `ahsettings.ahnat`)) {
    nationData[0].data.sustainableConstruction = 0;
  }


  const radarData = [base('baseline')];
  const baselineIndex = 0;

  const showbaseline = _.get(globalJson, `lVariant[0].Active.ahshowradar`) > 0 ? true : false;

  if (showbaseline) {
    radarData[baselineIndex].data.daylight =
      (_.get(globalJson, `lVariant[0].Active.ahr_dlsc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.thermalEnvironment =
      (_.get(globalJson, `lVariant[0].Active.ahr_tesc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.indoorAirQuality =
      (_.get(globalJson, `lVariant[0].Active.ahr_iasc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.acousticQuality =
      (_.get(globalJson, `lVariant[0].Active.ahr_aqsc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.energyDemand =
      (_.get(globalJson, `lVariant[0].Active.ahr_edsc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.primaryEnergyPerformance =
      (_.get(globalJson, `lVariant[0].Active.ahr_pesc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.energySupply =
      (_.get(globalJson, `lVariant[0].Active.ahr_essc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.sustainableConstruction =
      (_.get(globalJson, `lVariant[0].Active.ahr_scsc`) - SCALE) / (-1 * SCALE) +
      0.2;

    radarData[baselineIndex].data.freshwater =
      (_.get(globalJson, `lVariant[0].Active.ahr_fwsc`) - SCALE) / (-1 * SCALE) +
      0.2;
  }
  else {
    radarData[baselineIndex].data.daylight = 0;
    radarData[baselineIndex].data.thermalEnvironment = 0;
    radarData[baselineIndex].data.indoorAirQuality = 0;
    radarData[baselineIndex].data.acousticQuality = 0;
    radarData[baselineIndex].data.energyDemand = 0;
    radarData[baselineIndex].data.primaryEnergyPerformance = 0;
    radarData[baselineIndex].data.energySupply = 0;
    radarData[baselineIndex].data.sustainableConstruction = 0;
    radarData[baselineIndex].data.freshwater = 0;
  }

  radarData[baselineIndex].meta.color = appColors.primary.main;

  ArrayOfVariantsIndex.forEach((a) => {

    const variante = +(a + 1);
    radarData.push(base('variantcase' + (variante)));

    const showcase = _.get(globalJson, `lVariant[${variante}].Active.ahshowradar`) > 0 ? true : false;

    if (showcase) {

      radarData[variante].data.daylight =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_dlsc`) - SCALE) /
        (-1 * SCALE) +
        0.2;


      radarData[variante].data.thermalEnvironment =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_tesc`) - SCALE) /
        (-1 * SCALE) +
        0.2;

      radarData[variante].data.indoorAirQuality =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_iasc`) - SCALE) /
        (-1 * SCALE) +
        0.2;

      radarData[variante].data.acousticQuality =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_aqsc`) - SCALE) /
        (-1 * SCALE) +
        0.2;

      radarData[variante].data.energyDemand =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_edsc`) - SCALE) /
        (-1 * SCALE) +
        0.2;

      radarData[variante].data.primaryEnergyPerformance =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_pesc`) - SCALE) /
        (-1 * SCALE) +
        0.2;

      radarData[variante].data.energySupply =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_essc`) - SCALE) /
        (-1 * SCALE) +
        0.2;

      radarData[variante].data.sustainableConstruction =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_scsc`) - SCALE) /
        (-1 * SCALE) +
        0.2;

      radarData[variante].data.freshwater =
        (_.get(globalJson, `lVariant[${variante}].Active.ahr_fwsc`) - SCALE) /
        (-1 * SCALE) +
        0.2;
    }
    else {
      radarData[variante].data.daylight = 0;
      radarData[variante].data.thermalEnvironment = 0;
      radarData[variante].data.indoorAirQuality = 0;
      radarData[variante].data.acousticQuality = 0;
      radarData[variante].data.energyDemand = 0;
      radarData[variante].data.primaryEnergyPerformance = 0;
      radarData[variante].data.energySupply = 0;
      radarData[variante].data.sustainableConstruction = 0;
      radarData[variante].data.freshwater = 0;
    }
    radarData[variante].meta.color = appColors.primary.light;

  });

  radarData.forEach((a) => {
    if (radarData) {
      if (!a.data.daylight) { a.data.daylight = 0.2 }
      if (!a.data.thermalEnvironment) { a.data.thermalEnvironment = 0.2; }
      if (!a.data.indoorAirQuality) { a.data.indoorAirQuality = 0.2; }
      if (!a.data.acousticQuality) { a.data.acousticQuality = 0.2; }
      if (!a.data.energyDemand) { a.data.energyDemand = 0.2; }
      if (!a.data.primaryEnergyPerformance) { a.data.primaryEnergyPerformance = 0.2; }
      if (!a.data.energySupply) { a.data.energySupply = 0.2; }
      if (!a.data.sustainableConstruction) { a.data.sustainableConstruction = 0.2; }
      if (!a.data.freshwater) { a.data.freshwater = 0.2; }
    }
  });

  return { radarData, dotsData, nationData };
};
