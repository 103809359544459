import { JsonDefinition } from 'src/features/shared/form/data/json_definition';

export class TargetsJsonDefinition implements JsonDefinition {
    keys = [
      { key: 'ActiveTarget.ahr_dlsc' },
      { key: 'ActiveTarget.ahr_tesc' },
      { key: 'ActiveTarget.ahr_iasc' },
      { key: 'ActiveTarget.ahr_aqsc' },
      { key: 'ActiveTarget.ahr_edsc' },
      { key: 'ActiveTarget.ahr_essc' },
      { key: 'ActiveTarget.ahr_pesc' },
      { key: 'ActiveTarget.ahr_fwsc' },
      { key: 'ActiveTarget.ahr_scsc' },
    ];
  }

