import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { EnergyIcon } from 'src/ui/elements/icons';
import { Energy } from '../../presentation/pages/tabs';

export class EnergyQualityJsonDefinition implements JsonDefinition {
  keys: any;
  constructor(variantcaseIndex = 0) {
    this.keys = [
      {
        key: `ahh_eg`,
        extraOptions: { isAbsoluteTitle: true, IconComponent: EnergyIcon },
      },

      { key: `aht_qeg1` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg1` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg1c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qeg2` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg2` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg2c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qeg3` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg3` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg3c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qeg4` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg4` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg4c`,
        extraOptions: { multiLine: true, },
      },

      { key: `aht_qeg5` },
      { key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg5` },
      {
        key: `lVariant.${variantcaseIndex}.Active.qa.ahqeg5c`,
        extraOptions: { multiLine: true, },
      },
    ];
  }
}
