import _ from 'lodash';
import { JsonDefinition } from "src/features/shared/form/data/json_definition";
import { getNameOfDefinitionEnum } from 'src/features/shared/form/utils/get_name_for_definition_enum';
import { globalJSON } from 'src/features/shared/json/data/source/global.activejob';
import { oekobauJson } from "src/features/shared/json/data/source/oekobau.data";
import { jsonValuesUtils } from 'src/features/shared/json/util/jsonValuesUtils';
import { i18n } from 'src/features/shared/translations/translate';

export class ConstructionsTitleJsonDefinition implements JsonDefinition {
  keys: any;

  constructor() {
    this.keys = [
      { 
        key: "ahh_c", extraOptions: 
        { 
          isAbsoluteTitle: true, 
        },
      },
      // { key: `aht_sccalc` },
      // {
      //   key: `lVariant.${0}.Active.ahudefenvload`,
      //   extraOptions: { gridColSpan: 3 },
      // },
      // {
      //   key: `lVariant.${0}.Active.ahudefsusconst`,
      //   extraOptions: { gridColSpan: 3 },
      // },
      // { key: `aht_evset` },
    ];
  }
}

export class ConstructionsJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexConstruction: number) {
    this.keys = [
      {
        key: `lAssembly.[${indexConstruction}].n`,
        extraOptions: { gridColSpan: 6, preLabel: i18n.t(`prelabel.construction`) },
      },
    ];
  }
}

const ahmaterialFromJson = (currentJson: any, indexVar: number, indexOne: number, indexTwo: number) => {
  const draftJsonForMaterials = _.get(oekobauJson, 'oekobau');

  const thislayerpath = `lAssembly.[${indexOne}].lLayer.[${indexTwo}].eco.lcamatG`;

  const LayerGroup = getNameOfDefinitionEnum({
    partJson: currentJson,
    key: thislayerpath,
  });

  const filtered = draftJsonForMaterials.filter(function (value, i, arr) {
    return value.grp == LayerGroup;
  });

  // here i just get the   example.json data,  not the current json data..
  const emptymap = [
    { value: -1, label: 'not assigned' },
  ];

  const returna = filtered.map((material: { id: any; n: string; }) => {
    return {
      value: material.id,
      label: material.n
    }
  });

  const returnall = emptymap.concat(returna);

  return returnall;
};



export class LayerExpandJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexConstruction: number, indexLayer: number) {
    this.keys = [

      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.lcamatG`,
        extraOptions: { gridColSpan: 2 },
      },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.lcaMaterial`,
        extraOptions: { gridColSpan: 3, indexOne: indexConstruction, indexTwo: indexLayer },
        dropdownOptionsFromJson: ahmaterialFromJson,
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.sus.aHlifetime`,
        extraOptions: { gridColSpan: 5 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.aHshare`,
        extraOptions: { gridColSpan: 5 },
      },

      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.sus.aHrecycled`,
        extraOptions: { gridColSpan: 5 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.sus.aHrecyclable`,
        extraOptions: { gridColSpan: 5 },
      },
      { key: `empty`, extraOptions: { gridColSpan: 1 } },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.sus.aHcertifiedwood`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            { key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.lcamatG`, value: 15 },
          ]),
        extraOptions: { gridColSpan: 5 },
      },
      {
        key: `empty`,
        shouldHideLine: (json: typeof globalJSON) =>
          jsonValuesUtils.not(json)([
            { key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.lcamatG`, value: 15 },
          ]),
        extraOptions: { gridColSpan: 1 }
      },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.sus.aHverifiedepd`,
        extraOptions: { gridColSpan: 5 },
      },
    ];
  }
}



export class LayerJsonDefinition implements JsonDefinition {
  keys: any;

  constructor(indexConstruction: number, indexLayer: number) {
    this.keys = [
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].n`,
        // extraOptions: { noLabel:true, disabled: true, gridColSpan: 1 },
        extraOptions: { gridColSpan: 3, preLabel: i18n.t(`prelabel.layer`) },
      },
      {
        key: `lAssembly.[${indexConstruction}].lLayer.[${indexLayer}].eco.aHthick`,
        extraOptions: { gridColSpan: 3 },
      },
    ];
  }
}
