import { appColors } from "src/app/theme";

const SIZE200 = 235;
const SIZE400 = 400;

export const radarTheme200 = {
  outsideCircle: {
    borderColor: appColors.primary.dark,
    borderWidth: 2,
    textColor: appColors.primary.dark,
    textBackgroundColor: appColors.white,
    labelConfig: {
      // if  the SIZE changs, we have to tweak this values manually
      enviroment: {
        left: "-18px",
        bottom: 40,
        rotateDeg: 244,
      },
      comfort: {
        top: -2,
        rotateDeg: 0,
      },
      energy: {
        right: "-8px",
        bottom: 40,
        rotateDeg: 120,
      },
    },
  },
  size: SIZE200,
  outsideCircleSize: SIZE200 + 115,
  ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE : 90,
  captionPositionX: 1.8,
  captionPositionY: 1.8
};


export const radarTheme400 = {
  outsideCircle: {
    borderColor: appColors.primary.dark,
    borderWidth: 2,
    textColor: appColors.primary.dark,
    textBackgroundColor: appColors.white,
    labelConfig: {
      // if  the SIZE changs, we have to tweak this values manually
      enviroment: {
        left: "18px",
        bottom: 40,
        rotateDeg: 232,
      },
      comfort: {
        top: -2,
        rotateDeg: 0,
      },
      energy: {
        right: "24px",
        bottom: 40,
        rotateDeg: 125,
      },
    },
  },
  size: SIZE400,
  outsideCircleSize: SIZE400 + 115,
  ADDITIONAL_SPACE_TO_REACH_OUTSIDE_CIRCLE : 102,
  captionPositionX: 1.95,
  captionPositionY: 1.95
};

// and in lineAxis the position of the numbers