import { JsonDefinition } from 'src/features/shared/form/data/json_definition';
import { EmailFieldJsonDefinition } from 'src/features/shared/form/domain/json_definitions';
import { ProjectIcon } from 'src/ui/elements/icons';

export class ProjectInformationJsonDefinition implements JsonDefinition {
  keys = [
    {
      key: 'ahh_pj',
      extraOptions: {
        IconComponent: ProjectIcon,
        isAbsoluteTitle: true,
      }
    },
    { key: 'aht_c' },
    { key: 'projD.ahType',extraOptions: { gridColSpan: 3 } },
    // { key: `empty`, extraOptions: { gridColSpan: 3 } },
    { key: 'projD.tBState',extraOptions: { gridColSpan: 3 } },
    // { key: `empty`, extraOptions: { gridColSpan: 3 } },
    { key: 'projD.ahbtype',extraOptions: { gridColSpan: 3 } },

    { key: `aht_sccalc` },
    {
      key: `lVariant.${0}.Active.ahudefenvload`,
      extraOptions: { gridColSpan: 3 },
    },
    {
      key: `lVariant.${0}.Active.ahudefsusconst`,
      extraOptions: { gridColSpan: 3 },
    },

    { key: `aht_def` },
    { key: `lVariant.[0].Active.ahdefpe`, extraOptions: { gridColSpan: 3 }, },
    { key: `empty`, extraOptions: { gridColSpan: 3 } },

    { key: 'aht_b' },
    { key: 'projD.bN', extraOptions: { gridColSpan: 3 } },
    { key: 'projD.bStr', extraOptions: { gridColSpan: 3 } },
    { key: 'projD.bLoc', extraOptions: { gridColSpan: 3 } },
    { key: 'projD.bPostC', extraOptions: { gridColSpan: 3 } },
    {
      key: `lVariant.${0}.cliLoc.latB`, extraOptions: {
        gridColSpan: 3,
        roundDecimal: 4
      }
    },
    {
      key: `lVariant.${0}.cliLoc.longB`, extraOptions: {
        gridColSpan: 3,
        roundDecimal: 4
      }
    },
    
    { key: 'projD.bYCon', extraOptions: { gridColSpan: 3 } },


    // { key: 'aht_oc' },
    // { key: 'projD.cN', extraOptions: { gridColSpan: 3 } },
    // { key: 'empty', extraOptions: { gridColSpan: 3 } },
    // new EmailFieldJsonDefinition({
    //   key: 'projD.cEmail',
    //   extraOptions: { gridColSpan: 3 },
    // }),
    // { key: 'projD.cTel', extraOptions: { gridColSpan: 3 } },
    // { key: 'aht_ai' },
    // { key: 'projD.rN', extraOptions: { gridColSpan: 3 } },
    // { key: 'projD.rC', extraOptions: { gridColSpan: 3 } },
    // new EmailFieldJsonDefinition({
    //   key: 'projD.rEmail',
    //   extraOptions: { gridColSpan: 3 },
    // }),
    // { key: 'projD.rTel', extraOptions: { gridColSpan: 3 } },
    // { key: 'aht_mi' },
    // { key: 'projD.mN', extraOptions: { gridColSpan: 3 } },
    // { key: 'projD.mC', extraOptions: { gridColSpan: 3 } },
    // new EmailFieldJsonDefinition({
    //   key: 'projD.mEmail',
    //   extraOptions: { gridColSpan: 3 },
    // }),
    // { key: 'projD.mTel', extraOptions: { gridColSpan: 3 } },
  ];
}


